import * as React from 'react';
import { Paper } from 'elements';
import { useQuery } from 'hooks';
import { Monitoring as BaseMonitoringComponent } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useCallback, useEffect } from 'react';

const OBSERVERS = {
  vm: StateHandlers.vmDetails,
  dsDetails: StateHandlers.dedicatedServerDetails,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { vm, dsDetails } = props;
  const { query } = useQuery<{ id: number }>();

  const fetchAllServices = useCallback(async () => {
    await vm.get({ id: query.id, include: 'serviceMonitoring' });
    return dsDetails.get({ id: vm.data?.dedicatedServer?.id });
  }, [vm.data?.dedicatedServer?.id, query.id]);

  useEffect(() => {
    fetchAllServices();
  }, []);

  return (
    <Paper className="p-30">
      <BaseMonitoringComponent
        entity="dedicatedServer"
        vmCount={1}
        serviceId={vm.data?.dedicatedServer?.id}
        monitoringId={dsDetails.data?.serviceMonitoring?.id}
        isDisabled={vm.isRequesting}
        fetchService={fetchAllServices}
        osType={vm.data?.os?.osFamilyId === 1 ? 'win' : 'lin'}
      />
    </Paper>
  );
});

const Monitoring = () => <View {...OBSERVERS} />;

export default Monitoring;
