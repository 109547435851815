import React, { memo, useMemo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogProps,
  Row,
  Col,
  Input,
  Select,
  Loader,
  TablePagination,
} from 'elements';
import Dropdown from 'pages/users/Dropdown';
import { useFormik } from 'formik';
import { defineDisplayPagination } from 'utils';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useState } from 'hooks';
import {
  DRIVE_LETTERS_OPTIONS,
  getInitialValues,
  validationSchema,
  DEFAULT_USERS_GET_PARAMS,
  PERMISSIONS_MAP,
} from './constants';
import { AnyFunc } from 'global-shapes';
import { IFileShareFormValues, IPermissionMap, IFileShare } from 'file-shares';
import { IUser } from 'core-infrastructure';
import { IPermission, PermissionOption } from 'auth-shapes';
import { IFSTypes } from './types';

interface IProps extends DialogProps<IFileShareFormValues> {
  onClose: AnyFunc;
  isNew: boolean;
  testId?: string;
  initialValues?: Partial<IFileShare>;
}

interface IUsersProps {
  users: IUser[];
  onPermissionChange: AnyFunc;
  permissions: IPermissionMap;
}

const OBSERVERS = {
  users: StateHandlers.coreUsers,
  usersWithFileSharePerms: StateHandlers.coreUsersWithFileSharePerms,
};

const INITIAL_STATE = {
  params: DEFAULT_USERS_GET_PARAMS,
};

const PERMISSION_OPTIONS: PermissionOption[] = [
  { key: 'none' },
  { key: 'viewOnly' },
  { key: 'modify' },
];

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    onSave,
    open,
    initialValues,
    isNew,
    onClose,
    testId,
    users,
    usersWithFileSharePerms,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [state, handleStateChange] = useState(INITIAL_STATE);

  const onPageChange = useCallback((params: IFSTypes.Query) => {
    const newParams: any = { ...state.params, ...params };
    return handleStateChange({ params: newParams });
  }, []);

  const initials = useMemo(
    () => (isNew ? getInitialValues() : initialValues),
    [isNew, open, initialValues]
  );

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    isValid,
    resetForm,
    submitCount,
    setValues,
  } = useFormik({
    initialValues: initials as IFileShareFormValues,
    validateOnMount: true,
    validationSchema,
    onSubmit: (val) =>
      Promise.resolve(
        onSave({
          ...val,
        })
      ),
  });

  React.useEffect(() => {
    users.get({
      ...DEFAULT_USERS_GET_PARAMS,
      perPage: 1000,
    });
  }, []);

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
    if (open) {
      // @ts-ignore
      setValues({
        ...initials,
        driveLetter: DRIVE_LETTERS_OPTIONS.find(
          (l) => l.value === initialValues?.driveLetter
        ),
      });
    }
  }, [open]);

  const isUsersLoading = users.isLoading || !users.dataReceived;

  const Users = memo((pr: IUsersProps) => {
    const { users: data, onPermissionChange, permissions } = pr;
    const hasUsers = !!data.length;
    const showPagination = defineDisplayPagination(
      users.meta.totalCount,
      state.params
    );

    if (!hasUsers)
      return (
        <div className="text-center steel fs-14 pt-30 pb-30">
          {t('table.no.users')}
        </div>
      );
    return (
      <>
        {data
          .slice(
            (state.params.page - 1) * state.params.perPage,
            (state.params.page - 1) * state.params.perPage +
              state.params.perPage
          )
          .map((u) => {
            const foundPermission =
              u.fileShareUsers &&
              u.fileShareUsers.find((p) => p.fileShareId === initialValues?.id);
            const currentPermission: string =
              permissions[u.id] ||
              (foundPermission &&
                PERMISSIONS_MAP[foundPermission.permission]) ||
              'none';
            return (
              <Row
                key={u.id}
                justifyContent="space-between"
                alignItems="center"
              >
                <Col>{u.username}</Col>
                <Col>
                  <Dropdown
                    // @ts-ignore
                    value={currentPermission}
                    disabled={false}
                    options={PERMISSION_OPTIONS}
                    onChange={(val) => onPermissionChange(u.id, val)}
                  />
                </Col>
              </Row>
            );
          })}
        {showPagination && (
          <TablePagination
            page={state.params.page}
            perPage={10}
            totalCount={users.meta.totalCount}
            onChange={onPageChange}
            shouldUseQuery={false}
          />
        )}
      </>
    );
  });

  return (
    <Dialog
      {...rest}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      testId={testId}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
            testId={`${testId}-cancel`}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            disabled={!isValid || users.isRequesting}
            testId={`${testId}`}
          >
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <Row columnSpacing={2} className="mb-25">
        <Col xs={8}>
          <Input
            name="name"
            label="forms.fileShareName"
            value={values.name}
            placeholder="forms.placeholders.fileShareName"
            error={!!submitCount && !!errors.name}
            helperText={errors.name}
            helperTextOptions={{ max: 30 }}
            disabled={!isNew}
            onChange={handleChange}
            testId="name"
          />
        </Col>
        <Col xs={4}>
          <Select
            name="driveLetter"
            label={t('forms.driveLetter')}
            value={values.driveLetter}
            placeholder={t('forms.placeholders.select')}
            options={DRIVE_LETTERS_OPTIONS}
            error={!!submitCount && !!errors.driveLetter}
            helperText={errors.driveLetter}
            isDisabled={!isNew}
            onChange={(val) => setFieldValue('driveLetter', val)}
            testId="driveLetter"
          />
        </Col>
      </Row>
      {isUsersLoading ? (
        <Loader />
      ) : (
        <Users
          users={isNew ? users.data : usersWithFileSharePerms.data}
          onPermissionChange={(id: number, perm: IPermission) =>
            setFieldValue('userPermissions', {
              ...values.userPermissions,
              [id]: perm,
            })
          }
          permissions={values.userPermissions || {}}
        />
      )}
    </Dialog>
  );
});

const CreateFileShareDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default CreateFileShareDialog;
