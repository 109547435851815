import React, { useMemo } from 'react';
import {
  Radio,
  Dialog,
  DialogProps,
  Col,
  Row,
  Button,
  Input,
  PrimaryTextH5,
  PrimaryTextSpan,
  CustomListUl,
} from 'elements';
import { numberToCurrency, showSystemMessage } from 'utils';
import { useFormik } from 'formik';
import {
  ISoftwarePackage,
  IInstallSoftwarePackageValues,
  IInstallPackageDependencyValues,
} from 'app-store';
import { useSpPrice } from 'hooks';
import { useTranslation } from 'react-i18next';
import { installPackageSchema } from './constants';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import ProviderLicenseInput from './ProviderLicenseInput';

type Props = DialogProps<Partial<IInstallSoftwarePackageValues>> & {
  currentPackage: ISoftwarePackage;
  avaliableRdsUsersCount: number;
};

const initialValues: IInstallSoftwarePackageValues = {
  licenseKey: '',
  providersLicenseUnits: 0,
  selectedType: 'own',
  activeRdsUsersBilling: false,
  cost: 0,
};

const _licenses = {
  own: false,
  provider: false,
  none: true,
};

const InstallPackageDialog = (props: Props) => {
  const {
    onSave,
    open,
    currentPackage,
    onClose,
    avaliableRdsUsersCount,
    ...rest
  } = props;
  const { t } = useTranslation();

  const prices = useSpPrice(currentPackage?.id);
  const pacPrice = prices[currentPackage?.id];

  const dependencyValues: IInstallPackageDependencyValues = useMemo(() => {
    const res: IInstallPackageDependencyValues = {
      minLicenseUnit: 0,
      interval: 0,
      cost: 0,
      costBasisType: 'PER_USER',
      licenceKey: '',
    };
    if (currentPackage && currentPackage.licenseSettings) {
      if (currentPackage.licenseSettings.providersLicense) {
        res.costBasisType =
          currentPackage.licenseSettings.providersLicense.costBasis;
        res.minLicenseUnit = +(
          currentPackage.licenseSettings.providersLicense.minLicenseUnit || 1
        );
        res.cost = +(currentPackage.licenseSettings.providersLicense.cost || 1);
        res.interval = +(
          currentPackage.licenseSettings.providersLicense.licensingInterval || 1
        );
      }
      if (currentPackage.licenseSettings.ownLicense) {
        res.licenceKey =
          currentPackage.licenseSettings.ownLicense.keyFormat || '';
      }
    }

    return res;
  }, [currentPackage]);

  const licenses = useMemo(() => {
    if (!currentPackage) return _licenses;
    const own =
      !!currentPackage.licenseSettings &&
      !!currentPackage.licenseSettings.ownLicense;
    const provider =
      !!currentPackage.licenseSettings &&
      !!currentPackage.licenseSettings.providersLicense;
    const none = !own && !provider;
    _licenses.own = own;
    _licenses.provider = provider;
    _licenses.none = none;
    return _licenses;
  }, [currentPackage]);

  const showOwnLicenseInfo = licenses.own && !licenses.provider;
  const showProviderLicenseInfo = !licenses.own ?? licenses.provider;
  const showAllLicenseInfo = licenses.provider && licenses.own;

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setValues,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: installPackageSchema(dependencyValues),
    onSubmit: async (val) => {
      if (showAllLicenseInfo && !val.selectedType) {
        return showSystemMessage(
          'softwarePackages.notify.selectLicense',
          'error'
        );
      }
      return onSave(val, currentPackage);
    },
  });

  const isOwnLicenseSelected = values.selectedType === 'own';
  const isProviderLicenseSelected = values.selectedType === 'provider';

  const cost = React.useMemo(() => {
    if (
      (showAllLicenseInfo && isProviderLicenseSelected) ||
      showProviderLicenseInfo
    ) {
      return pacPrice?.monthly;
    }
    return 0;
  }, [
    pacPrice?.monthly,
    isOwnLicenseSelected,
    showProviderLicenseInfo,
    showAllLicenseInfo,
    isProviderLicenseSelected,
  ]);

  const providersLicenseUnits = React.useMemo(() => {
    if (
      (showAllLicenseInfo && isProviderLicenseSelected) ||
      showProviderLicenseInfo
    ) {
      if (!values.activeRdsUsersBilling) {
        return avaliableRdsUsersCount;
      }
      return Number(values.providersLicenseUnits);
    }
    return 1;
  }, [
    values.providersLicenseUnits,
    isOwnLicenseSelected,
    avaliableRdsUsersCount,
    values.activeRdsUsersBilling,
    showProviderLicenseInfo,
    isProviderLicenseSelected,
    showAllLicenseInfo,
  ]);

  React.useEffect(() => {
    if (open && currentPackage) {
      const newValues = { ...values };

      if (currentPackage.licenseSettings) {
        newValues.selectedType = 'own';
        if (currentPackage.licenseSettings.providersLicense) {
          newValues.selectedType = 'provider';
          newValues.cost = +(
            currentPackage.licenseSettings.providersLicense.cost || 1
          );
          newValues.providersLicenseUnits = +(
            currentPackage.licenseSettings.providersLicense.minLicenseUnit || 1
          );
        }
        if (
          currentPackage.licenseSettings.providersLicense &&
          currentPackage.licenseSettings.ownLicense
        ) {
          newValues.selectedType = undefined;
        }
      }
      setValues(newValues);
    }

    if (!open) resetForm();
  }, [open, currentPackage]);

  return (
    <Dialog
      {...rest}
      title={t('softwarePackages.dialog.install.title')}
      open={open}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      handleSubmit={handleSubmit}
      actions={
        <Row justifyContent="flex-end" alignItems="center" columnSpacing={2}>
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="submit" disabled={isSubmitting}>
              {t('common.install')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Row
        justifyContent="space-between"
        columnSpacing={2}
        style={{ minHeight: 300 }}
      >
        <Col xs={7}>
          <div className="mb-25 steel">
            {t('softwarePackages.dialog.install.content')}
          </div>
          {showOwnLicenseInfo && (
            <>
              <div className="fs-12 mb-5">{t('forms.licenseKey')}</div>
              <Input
                name="licenseKey"
                fullWidth
                value={values.licenseKey}
                placeholder={
                  currentPackage?.licenseSettings?.ownLicense?.keyFormat
                }
                onChange={handleChange}
                error={!!errors.licenseKey}
                helperText={errors.licenseKey}
              />
            </>
          )}
          {showProviderLicenseInfo && (
            <ProviderLicenseInput
              {...currentPackage}
              values={values}
              setFieldValue={setFieldValue}
              dependencyValues={dependencyValues}
              handleChange={handleChange}
              errors={errors}
              cost={pacPrice?.monthly}
            />
          )}
          {showAllLicenseInfo && (
            <FormControl className="full-width">
              <RadioGroup
                value={values.selectedType || ''}
                onChange={handleChange}
                name="selectedType"
              >
                <Radio
                  value="own"
                  label={t('softwarePackages.dialog.install.ownLicenseKey')}
                  className="mb-15 ml-0"
                />
                {isOwnLicenseSelected && (
                  <div className="mb-20 full-width">
                    <>
                      <div className="fs-12 mb-5">{t('forms.licenseKey')}</div>
                      <Input
                        name="licenseKey"
                        fullWidth
                        value={values.licenseKey}
                        placeholder={
                          currentPackage?.licenseSettings?.ownLicense?.keyFormat
                        }
                        onChange={handleChange}
                        error={!!errors.licenseKey}
                        helperText={errors.licenseKey}
                      />
                    </>
                  </div>
                )}
                <Radio
                  value="provider"
                  label={t(
                    'softwarePackages.dialog.install.providerLicenseKey'
                  )}
                  className="mb-15 ml-0"
                />
                {values.selectedType === 'provider' && (
                  <div className="pl-35">
                    <ProviderLicenseInput
                      {...currentPackage}
                      setFieldValue={setFieldValue}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      dependencyValues={dependencyValues}
                      cost={pacPrice?.monthly}
                    />
                  </div>
                )}
              </RadioGroup>
            </FormControl>
          )}
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            className="full-height"
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={7}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={2}>
                  <span>{t('costsInfo.head.quantity')}</span>
                </Col>
                <Col xs={3} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t(`costsInfo.appStore`)}</h5>
              {/* <CustomListUl className="custom-list"> */}
              <CustomListUl>
                <li className="mb-5">
                  <Row>
                    <Col xs={7}>
                      <span>{currentPackage?.name}</span>
                    </Col>

                    <Col xs={2} className="steel">
                      <span>{providersLicenseUnits}</span>
                    </Col>

                    <Col xs={3} className="text-right steel">
                      <span>
                        {numberToCurrency(cost * providersLicenseUnits, false)}
                      </span>
                    </Col>
                  </Row>
                </li>
              </CustomListUl>
              {/* </CustomListUl> */}
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center">
                <h5 className="fs-17">{t('costsInfo.totalCost.NONE')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(cost * providersLicenseUnits, false)}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel">{t('costsInfo.chfExclVat')}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
};

export default InstallPackageDialog;
