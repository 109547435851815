import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { getAdditionalDiskColumns } from '../../services/constants';
import { Row, Col, Input, AddIcon, LinearTable } from 'elements';
import { CommonContentProps } from 'services-types';
import { validateCallback, validator } from 'utils';
import { AnyFunc, AnyShape } from 'global-shapes';
import * as StateHandlers from 'states';
import { useState } from 'hooks';

const INIT_STATE = {
  name: '',
  sizeGb: '',
};

type DiskFormProps = {
  onAdd: (values: IVmTypes.NewVMDisk) => void;
};

export const DiskInputForm = ({ onAdd }: DiskFormProps) => {
  const [state, handleChange] = useState(INIT_STATE);

  const onChange = (key: string) => (ev: any) =>
    handleChange(ev.target.value, key);

  const addNewDisk = () => {
    onAdd({ id: v4(), name: state.name, sizeGb: +state.sizeGb });
    handleChange(INIT_STATE);
  };

  return (
    <Row columnSpacing={2} alignItems="flex-end" className="full-width mb-25">
      <Col xs>
        <Input
          label="services.dialog.vms.table.head.diskName"
          placeholder="services.dialog.vms.table.head.placeholder.diskName"
          value={state.name}
          onChange={validateCallback({ restrict: validator.diskName, max: 30 })(
            onChange('name')
          )}
        />
      </Col>
      <Col xs={3}>
        <Input
          label="services.dialog.vms.table.head.diskSize"
          value={state.sizeGb}
          onChange={validateCallback({ restrict: validator.onlyNumbers })(
            onChange('sizeGb')
          )}
        />
      </Col>
      <Col>
        <AddIcon
          disabled={
            !(state.name.trim() && state.sizeGb.trim() && +state.sizeGb > 0)
          }
          size="medium"
          onClick={addNewDisk}
          className="mb-5"
        />
      </Col>
    </Row>
  );
};

interface DisksPageProps extends CommonContentProps {
  query: AnyShape;
  params?: AnyShape;
  hasSnapshot: boolean;
  hasBilling?: boolean;
  isEditable?: boolean;
  onPageChange: AnyFunc;
  shouldRenderForm?: boolean;
  cellClassName?: string;
  tableClassName?: string;
  fetchDisks?: AnyFunc;
  onEdit?: (disk: IVmTypes.VMDisk) => void;
}

const AdditionalDisks = (props: DisksPageProps) => {
  const {
    fetchDisks,
    query,
    params,
    onPageChange,
    hasSnapshot,
    isEditable,
    onEdit,
    hasBilling,
  } = props;
  const { t } = useTranslation();

  const fetchCostBreakdown = useCallback(
    () => StateHandlers.costBreakdown.get(),
    []
  );

  const fetchVM = useCallback(
    () =>
      StateHandlers.vmDetails.get({
        id: query.id,
        include: 'serviceMonitoring',
      }),
    [query.id]
  );

  const disks = props.disks || [];
  const onAdd = React.useCallback(
    (newDisk: IVmTypes.NewVMDisk) => {
      return props.onAdd && props.onAdd([...disks, newDisk]);
    },
    [JSON.stringify(disks)]
  );

  const onDelete = React.useCallback(
    (id: string | number) =>
      props.onDelete &&
      props.onDelete(
        disks.filter((d) => d.id !== id),
        id
      ),
    [JSON.stringify(disks)]
  );

  return (
    <div>
      {props.shouldRenderForm && (
        <>
          <DiskInputForm onAdd={onAdd} />
          <div className="steel mb-20 fs-14">
            {t('services.dialog.vms.additionalDisk.text')}
          </div>
        </>
      )}

      <div className="mb-25">
        <LinearTable
          query={query}
          params={params}
          data={disks}
          onPageChange={onPageChange}
          columns={getAdditionalDiskColumns({
            t,
            onDelete,
            hasSnapshot,
            onEdit,
            isEditable,
            hasBilling,
          })}
          cellClassName={props.cellClassName}
          noDataMessage="services.dialog.vms.table.noDisks"
          onUpdateTable={() => {
            fetchDisks && fetchDisks();
            fetchCostBreakdown();
            fetchVM();
          }}
        />
      </div>
    </div>
  );
};

AdditionalDisks.defaultProps = {
  shouldRenderForm: true,
};

export default AdditionalDisks;
