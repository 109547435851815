import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { IStatusNamespace } from 'enums';
import { remCalc } from 'utils';
import { DotsProgressSmall, styled } from 'elements';
import { ColorAccumulator } from 'components';

type Props = {
  inProgress?: boolean;
  status: IStatusNamespace;
  className?: string;
};


const SDot = styled(ColorAccumulator)(() => {
  return {
    width: remCalc(8),
    height: remCalc(8),
    borderRadius: '50%',
    marginRight: remCalc(10),
    display: 'inline-flex',
  };
});

const SStatusLine = styled(ColorAccumulator)(() => {
  return {
    height: remCalc(3),
    width: '100%',
  };
});

export const StatusDot = ({ status, className }: Props) => {
  return <SDot className={cn(className)} status={status} />;
};

const StatusIdentifier = ({ status, inProgress }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex align-center">
      <StatusDot status={status} inProgress={inProgress} />
      <strong>{t(`services.card.content.status.${status}`)}</strong>
      {inProgress && <DotsProgressSmall className="ml-10" size={20} />}
    </div>
  );
};

export const StatusLine = ({ status, className }: Props) => {
  return <SStatusLine status={status} className={cn(className)} />;
};

export default StatusIdentifier;
