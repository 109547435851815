import React, { useMemo, CSSProperties } from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { styled } from './utilities';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  Row,
  Column,
  InitialTableState,
} from '@tanstack/react-table';
import { TableParams } from 'global-shapes';
import { IAutomationTestProps } from 'automation-test';
import { useTranslation } from 'react-i18next';
import { remCalc } from '../utils';
import { TestSuffixes, PALETTE } from '../enums';
import { TablePagination } from './Pagination';
import { IconButton } from './Button';
import { default as Skeleton } from './Skeleton';
import DropVmComponent from 'components/DragAndDrop/DropVmComponent';
import FromMaxDirection from 'assets/images/icons/sort-maxmin.svg';
import FromMinDirection from 'assets/images/icons/sort-minmax.svg';
import DefaultDirection from 'assets/images/icons/sort-default.svg';
import { useTask } from 'hooks/useTask';

type IUpdateTableCallbackTypes = 'delete' | undefined;

interface TablePageParams {
  page: number;
  perPage: number;
  offset: number;
}

export interface TableProps extends IAutomationTestProps {
  className?: string;
  headerCellClassName?: string;
  columns: any[];
  data: any[];
  useDnD?: boolean;
  params?: TableParams | AnyShape;
  query?: AnyShape;
  initialTableState?: InitialTableState;
  hasSorting?: boolean;
  isLoading?: boolean;
  shouldUseQuery?: boolean;
  noDataMessage?: string;
  noDataClassName?: string;
  customizeRow?: AnyFunc;
  additionalTaskWatch?: string;
  activeStateProp?: string;
  rowClassName?: string | AnyFunc;
  cellClassName?: string | AnyFunc;
  contentClassName?: string | AnyFunc;
  usePagination?: boolean;
  headerRowClassName?: string;
  ContentUnderHeader?: any;
  onPageChange?: (params: TablePageParams) => void;
  onSort?: <F extends string[]>(
    orderBy: F | any,
    orderType: OrderTypes
  ) => void;
  onUpdateTable?: (cbType: IUpdateTableCallbackTypes) => void;
}

export const generateSortingIndicator = (
  column: string,
  query: AnyShape,
  size = 16
) => {
  if (query.orderBy === column) {
    return (
      query.orderType && (
        <img
          src={query.orderType === 'desc' ? FromMaxDirection : FromMinDirection}
          style={{
            width: remCalc(size),
          }}
          alt=""
        />
      )
    );
  }
  return (
    <img
      style={{
        width: remCalc(size),
      }}
      src={DefaultDirection}
      alt=""
    />
  );
};

const getStyles = (align = 'left') => {
  return {
    justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    padding: remCalc(15, 15),
    lineHeight: remCalc(24),
    minHeight: remCalc(54),
    fontSize: remCalc(14),
  };
};

const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : 0,
    backgroundColor: 'white',
  };
};

const LoadingWrapper = styled(Skeleton)(() => ({
  height: remCalc(54),
  marginBottom: remCalc(4),
  borderRadius: remCalc(3),
}));

const TableRow = styled('div')(() => ({
  display: 'flex',
  marginBottom: remCalc(4),
  flexWrap: 'nowrap',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#fff',
}));

const NoDataHolder = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: remCalc(3),
  width: '100%',
  padding: remCalc(15),
  textAlign: 'center',
}));

const PaginationHolder = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: remCalc(3),
  width: '100%',
  padding: remCalc(5, 15),
}));

type IRowItemProp = {
  row: Row<any>;
  useDnD?: boolean;
  customizeRow?: AnyFunc;
  rowClassName?: AnyFunc | string;
  testId?: string | number;
  cellClassName?: AnyFunc | string;
  onUpdateTable?: AnyFunc;
  additionalTaskWatch?: string;
  activeStateProp?: string;
  classes?: AnyShape;
};

const RowItem = (props: IRowItemProp) => {
  const {
    rowClassName,
    customizeRow,
    testId,
    cellClassName,
    onUpdateTable,
    additionalTaskWatch,
    activeStateProp,
    row,
  } = props;

  const task = useTask(row.original.task, {
    onComplete: onUpdateTable,
  });

  const additionalTask = useTask(
    additionalTaskWatch && row.original[additionalTaskWatch]
      ? row.original[additionalTaskWatch].task
      : null,
    { onComplete: onUpdateTable }
  );

  return (
    <TableRow
      id={row.id}
      className={cn(
        'relative',
        rowClassName,
        customizeRow && customizeRow(row.original),
        {
          disabled:
            task.isTaskActive ||
            additionalTask.isTaskActive ||
            !!row.original[activeStateProp ?? ''],
        }
      )}
      data-test-id={cn({
        [`${testId}-${TestSuffixes.tableRow}-${row.original.id || row.index}`]:
          !!testId,
      })}
    >
      {row.getVisibleCells().map((cell) => {
        const columnDef = cell.column.columnDef;

        const size = cell.column.getSize();
        const columnWidth = columnDef.sizeUnit
          ? `${size}${columnDef.sizeUnit}`
          : size;

        return (
          <div
            id={cell.id}
            key={cell.id}
            style={{
              ...getStyles(columnDef.alignment),
              width: columnWidth || undefined,
              minWidth: columnWidth || undefined,
              maxWidth: columnDef.maxWidth || undefined,
              ...columnDef.style,
              ...(columnDef.customStyles || {}),
              ...getCommonPinningStyles(cell.column),
            }}
            className={cn(
              'td flex align-center',
              typeof cellClassName === 'function'
                ? cellClassName(row.original)
                : cellClassName,
              {
                'justify-end': columnDef.alignment === 'right',
              }
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        );
      })}
    </TableRow>
  );
};

type IRowProps = {
  rows: Row<any>[];
  useDnD?: boolean;
  customizeRow?: AnyFunc;
  rowClassName?: AnyFunc | string;
  testId?: string | number;
  cellClassName?: AnyFunc | string;
  onUpdateTable?: AnyFunc;
  additionalTaskWatch?: string;
  activeStateProp?: string;
  classes?: AnyShape;
};

const Rows = ({
  rows,
  classes,
  rowClassName,
  customizeRow,
  testId,
  cellClassName,
  onUpdateTable,
  additionalTaskWatch,
  activeStateProp,
  useDnD,
}: IRowProps) => {
  return rows.map((row) => {
    const props = {
      key: row.original.uniqId || row.original.id || uuid(),
      classes,
      rowClassName,
      customizeRow,
      testId,
      cellClassName,
      onUpdateTable,
      additionalTaskWatch,
      activeStateProp,
      row,
    };

    if (useDnD) {
      return (
        <DropVmComponent vm={row.original} key={props.key}>
          <RowItem {...props} />
        </DropVmComponent>
      );
    }
    return <RowItem {...props} />;
  });
};

export function Table(props: TableProps) {
  const {
    columns,
    data,
    query,
    hasSorting,
    onSort,
    isLoading,
    noDataMessage,
    noDataClassName,
    headerRowClassName,
    rowClassName,
    cellClassName,
    params,
    onPageChange,
    className,
    shouldUseQuery,
    customizeRow,
    testId,
    onUpdateTable,
    additionalTaskWatch,
    activeStateProp,
    usePagination,
    headerCellClassName,
    contentClassName,
    ContentUnderHeader,
    useDnD,
    initialTableState,
  } = props;
  const { t } = useTranslation();

  const table = useReactTable({
    columns,
    data: React.useMemo(() => (isLoading ? [] : data), [isLoading, data]),
    getCoreRowModel: getCoreRowModel(),
    initialState: initialTableState,
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const _customizeRow = (row: any) => customizeRow && customizeRow(row);

  const showPagination = useMemo(() => {
    if (!usePagination) return false;
    if (params && query) {
      if (query.perPage >= (params.totalCount || 0)) return false;
    }
    return true;
  }, [JSON.stringify(params), usePagination, JSON.stringify(query)]);

  const rows = table.getRowModel().rows;

  return (
    <div
      className={cn(className)}
      data-test-id={cn({ [`${testId}-${TestSuffixes.table}`]: !!testId })}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id} className={headerRowClassName}>
          {headerGroup.headers.map((header) => {
            const columnDef = header.column.columnDef;
            const columnId = columnDef.sortId || header.id;

            const isCurrentColumnSelected = query?.orderBy === columnId;

            const nextOrderType = query?.orderType === 'desc' ? 'asc' : 'desc';

            const size = header.column.getSize();
            const columnWidth = columnDef.sizeUnit
              ? `${size}${columnDef.sizeUnit}`
              : size;

            const FilterComponent = columnDef.filterComponent;

            return (
              <div
                key={header.id}
                style={{
                  ...getStyles(columnDef.alignment),
                  width: columnWidth || undefined,
                  minWidth: columnWidth || undefined,
                  maxWidth: columnDef.maxWidth || undefined,
                  ...columnDef.style,
                  ...(columnDef.customStyles || {}),
                  ...getCommonPinningStyles(header.column),
                }}
                className={cn(
                  'th bolder flex align-center',
                  headerCellClassName,
                  {
                    'justify-end': columnDef.alignment === 'right',
                  }
                )}
                onClick={() => {
                  hasSorting &&
                    query &&
                    onSort &&
                    !columnDef.disableSort &&
                    onSort(
                      columnId,
                      !isCurrentColumnSelected ? 'asc' : nextOrderType
                    );
                }}
              >
                <div className="flex aligh-center">
                  {flexRender(columnDef.header, header.getContext())}
                  {hasSorting && !columnDef.disableSort && (
                    <IconButton size="small" className="ml-5 p-0">
                      {generateSortingIndicator(columnId, query || {})}
                    </IconButton>
                  )}
                  {FilterComponent && <FilterComponent />}
                </div>
              </div>
            );
          })}
        </TableRow>
      ))}
      {ContentUnderHeader}
      <div className={cn(contentClassName as string)}>
        {isLoading ? (
          <>
            <LoadingWrapper animation="wave" variant="rectangular" />
            <LoadingWrapper animation="wave" variant="rectangular" />
            <LoadingWrapper animation="wave" variant="rectangular" />
            <LoadingWrapper animation="wave" variant="rectangular" />
            <LoadingWrapper animation="wave" variant="rectangular" />
          </>
        ) : rows.length ? (
          <Rows
            rows={rows}
            rowClassName={rowClassName}
            customizeRow={_customizeRow}
            testId={testId}
            cellClassName={cellClassName}
            onUpdateTable={onUpdateTable}
            additionalTaskWatch={additionalTaskWatch}
            activeStateProp={activeStateProp}
            useDnD={useDnD}
          />
        ) : (
          <NoDataHolder className={cn('tr', noDataClassName)}>
            {t(noDataMessage || 'common.noData')}
          </NoDataHolder>
        )}
      </div>
      {!!data.length && showPagination && (
        <PaginationHolder className={cn('flex justify-end')}>
          <TablePagination
            {...params}
            {...query}
            onChange={onPageChange}
            shouldUseQuery={shouldUseQuery}
          />
        </PaginationHolder>
      )}
    </div>
  );
}

Table.defaultProps = {
  usePagination: true,
  perPage: 20,
  page: 1,
  nextOffset: null,
  nextPage: null,
  activeStateProp: undefined,
  totalCount: 0,
};

interface ILinearTable extends TableProps {
  collapseEdgePaddings: boolean;
  cellClassName?: string | AnyFunc;
  noDataMessage?: string;
  noDataClassName?: string;
  data: AnyShape[];
  columns: any[];
}

const CTable = styled(Table)(() => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  '& .th': {
    display: 'flex',
    flex: 1,
    boxShadow: '0 1px 0 0 rgba(23, 29, 41, 0.16)',
    alignItems: 'center',
  },
  '& .td': {
    display: 'flex',
    flex: 1,
  },
  '&._no-edges': {
    '& .th:first-of-type': {
      paddingLeft: '0 !important',
    },

    '& .th:last-of-type': {
      paddingRight: '0 !important',
    },

    '& .td:first-of-type': {
      paddingLeft: '0 !important',
    },

    '& .td:last-of-type': {
      paddingRight: '0 !important',
    },
  },
  '& ._noData_linear': {
    height: remCalc(300),
    color: PALETTE.steel,
    fontSize: remCalc(13),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& ._row_linear': {
    boxShadow: 'none',
    borderBottom: `1px solid ${PALETTE.pageBackground}`,
    marginBottom: 0,
  },
}));

export const LinearTable = (props: ILinearTable) => {
  const { noDataClassName } = props;
  return (
    <CTable
      {...props}
      className={cn(
        {
          '_no-edges': props.collapseEdgePaddings,
        },
        props.className
      )}
      noDataClassName={cn('_noData_linear', noDataClassName)}
      rowClassName="_row_linear"
    />
  );
};

LinearTable.defaultProps = {
  usePagination: false,
  collapseEdgePaddings: true,
};

export { createColumnHelper };
