import * as React from 'react';
import * as Translations from 'i18next';
import cn from 'classnames';
import { MuiIcons, styled } from 'elements';
import { withTranslation } from 'react-i18next';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';
import { ISearchEntity } from 'types';

const SChip = styled('div')(() => ({
  border: `1px solid ${PALETTE.paleGrey}`,
  borderRadius: remCalc(20),
  margin: remCalc(2, 5, 2, 0),
  padding: remCalc(4, 6, 4, 10),
  lineHeight: 1,
}));

const SDot = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  width: remCalc(7),
  height: remCalc(7),
  backgroundColor: theme.palette.primary.main,
  marginRight: remCalc(8),
}));

const SCancelIcon = styled(MuiIcons.Clear)(() => ({
  '&:hover': {
    color: PALETTE.error,
  },
}));

type IProps = Translations.WithT &
  React.PropsWithChildren<{
    parameter: ISearchEntity.FilterType;
    onClear: (parameter: ISearchEntity.FilterType) => void;
  }>;

const Renderer = (props: IProps) => {
  const { t, parameter, onClear } = props;
  return (
    <SChip className={cn('flex align-center fs-14 steel')}>
      <SDot />
      <div>{t(`entitySearch.type.${parameter}`) as string}</div>
      <SCancelIcon
        className={cn('ml-5 fs-12 pointer')}
        onClick={() => onClear(parameter)}
      />
    </SChip>
  );
};

const EntityFilterChip = withTranslation()(Renderer);

export default EntityFilterChip;
