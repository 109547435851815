import { showSystemMessage } from 'utils/global';
import { reformatTaskKey } from 'utils/taskSubscriber';
import { defaultVmsQuery } from 'enums';
import {
  virtualMachinesService,
  billingCyclesService,
  dedicatedServersService,
} from 'services';
import { taskManager } from './taskManager';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

const INITIAL_BILLING_INFO: IVmTypes.IBillingCircle = {
  canceledAt: '',
  discountValue: 0,
  editedAt: '',
  id: 0,
  interval: 'NONE',
  pendingInterval: 'NONE',
  periodEnd: '',
  periodStart: '',
  status: 'PENDING',
};

export const vms = new BaseMobxInstanceDecorator<IVmTypes.Vm, true>({
  instanceName: 'vms',
  requests: {
    get: async (query) => {
      const res = await virtualMachinesService.get({
        orderBy: 'name',
        orderType: 'asc',
        ...query,
        serviceGroupId: [],
        serviceGroupIsEmpty: true,
      });

      (res.data || []).forEach((vm: IVmTypes.Vm) => {
        if (vm.task) taskManager.updateById(reformatTaskKey(vm.task), vm.task);
      });

      return res;
    },
    create: async (payload) => {
      const res = await virtualMachinesService.create(payload);
      showSystemMessage('services.vm.notify.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await virtualMachinesService.update(id as number, payload);
      showSystemMessage('services.vm.notify.update.success', 'success');
      return res;
    },
    remove: async (id, deletionReason) => {
      const res = await virtualMachinesService.remove(
        id as number,
        deletionReason
      );
      showSystemMessage('services.vm.notify.delete.success', 'success');
      return res;
    },
    reload: async (id, query) => virtualMachinesService.getById(id, query),
  },
  additionalRequests: {
    importVm: async (payload: IVmTypes.IVmImportPayload) => {
      const res = await virtualMachinesService.importVm(payload);
      showSystemMessage('services.vm.notify.import.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type IVmsHandler = BaseDecorator<IVmTypes.Vm, true>;

export const vmDetails = new BaseMobxInstanceDecorator<IVmTypes.Vm, false>({
  instanceName: 'vmDetails',
  requests: {
    get: async (query) => {
      const { id, isDedicatedServer, tenantosServerId, ...q } = query;
      const vm = await virtualMachinesService.getById(id, q);

      if (vm.data?.billingCycleId) {
        const billingCycle = await billingCyclesService.getBillingCycle(
          vm.data.billingCycleId
        );
        vm.data.billingCycle = billingCycle.data;
      }

      if (isDedicatedServer && vm.data?.dedicatedServer?.tenantosServerId) {
        const tenantosServer =
          await dedicatedServersService.getTenantosServerById(
            vm.data?.dedicatedServer?.tenantosServerId as number
          );

        vm.data.dedicatedServer.tenantosServer = tenantosServer.data;
      }

      return vm;
    },
    update: async (id, payload) => {
      const res = await virtualMachinesService.update(id as number, payload);
      showSystemMessage('services.vm.notify.update.success', 'success');
      return res;
    },
    remove: async (data) => {
      const res = await virtualMachinesService.remove(
        data.id,
        data.deletionReason
      );
      showSystemMessage('services.vm.notify.delete.success', 'success');
      return res;
    },
  },
  additionalRequests: {
    switchVmType: async (id: number, data: IVmTypes.IVmSwitchVmTypePayload) => {
      const res = await virtualMachinesService.switchVmType(id, data);
      showSystemMessage('services.vm.notify.switchType.success', 'success');
      return res;
    },
  },
  initialState: {} as IVmTypes.Vm,
});

export type IVmDetailsHandler = BaseDecorator<IVmTypes.Vm, false>;

export const vmDisks = new BaseMobxInstanceDecorator<IVmTypes.VMDisk, true>({
  instanceName: 'vm-disks',
  requests: {
    get: (query) => {
      const { vmId, ...q } = query;
      return virtualMachinesService.getDisks(vmId, q);
    },
    create: async (payload) => {
      const { vmId, ..._payload } = payload;
      const res = await virtualMachinesService.createDisk(vmId, _payload);
      showSystemMessage('services.vm.disks.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await virtualMachinesService.updateDisk(
        id.vmId,
        id.diskId,
        payload
      );
      showSystemMessage('services.vm.disks.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await virtualMachinesService.deleteDisk(id.vmId, id.diskId);
      showSystemMessage('services.vm.disks.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type IVmDisksHandler = BaseDecorator<IVmTypes.VMDisk, true>;

export const vmPorts = new BaseMobxInstanceDecorator<IVmTypes.VMPort, true>({
  instanceName: 'vm-ports',
  requests: {
    get: (query) => {
      const { vmId } = query;
      return virtualMachinesService.getPorts(vmId);
    },
    create: async (payload) => {
      const { vmId, ..._payload } = payload;
      const res = await virtualMachinesService.createPort(vmId, _payload);
      showSystemMessage('services.vm.services.create.success', 'success');
      return res;
    },
    update: async (id: any, payload: IVmTypes.PortUpdatePayload) => {
      const { vmId, portId } = id;
      const res = await virtualMachinesService.updatePort(
        vmId,
        portId,
        payload
      );
      showSystemMessage('services.vm.services.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await virtualMachinesService.deletePort(id.vmId, id.portId);
      showSystemMessage('services.vm.services.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type IVmPortsHandler = BaseDecorator<IVmTypes.VMPort, true>;

export const billingInfo = new BaseMobxInstanceDecorator<
  IVmTypes.IBillingCircle,
  false
>({
  instanceName: 'vm-billing-info',
  requests: {
    get: (query) => {
      return billingCyclesService.getBillingCycle(query.id);
    },
  },
  initialState: INITIAL_BILLING_INFO,
});

export type IBillingInfoHandler = BaseDecorator<IVmTypes.IBillingCircle, false>;

export const defaultVmQuery = new BaseMobxInstanceDecorator<
  IVmTypes.AllVmQuery,
  false
>({
  instanceName: 'vm-query-params',
  requests: {},
  initialState: defaultVmsQuery,
});

export const media = new BaseMobxInstanceDecorator<IVmMedia.Media, true>({
  instanceName: 'vm-media',
  requests: {
    get: virtualMachinesService.getMedia,
  },
  additionalRequests: {
    injectMedia: virtualMachinesService.injectMedia,
    ejectMedia: virtualMachinesService.ejectMedia,
  },
  initialState: [],
});

export const vmsByNetwork = new BaseMobxInstanceDecorator<IVmTypes.Vm, true>({
  instanceName: 'vm-by-network',
  requests: {
    get: async (query) => {
      const res = await virtualMachinesService.get({
        orderBy: 'name',
        orderType: 'asc',
        ...query,
        serviceGroupId: [],
        serviceGroupIsEmpty: true,
      });

      return res;
    },
  },
  initialState: [],
});

export const vmNics = new BaseMobxInstanceDecorator<IVmTypes.Nic, true>({
  instanceName: 'vm-nics',
  requests: {
    get: (query) => {
      const { vmId, ...q } = query;
      return virtualMachinesService.getVmNics(vmId, q);
    },
    create: async (payload) => {
      const { vmId, ..._payload } = payload;
      const res = await virtualMachinesService.createNic(vmId, _payload);
      showSystemMessage(
        'services.vm.notify.attachNetwork.create.success',
        'success'
      );
      return res;
    },
    update: async (id, payload) => {
      const { vmId, nicId } = id;
      const res = (await virtualMachinesService.updateNic(
        vmId,
        nicId,
        payload
      )) as any;
      showSystemMessage(
        'services.vm.notify.attachNetwork.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await virtualMachinesService.removeNic(id.vmId, id.nicId);
      showSystemMessage(
        'services.vm.notify.attachNetwork.remove.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});
