import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useState } from 'hooks';
import { ALL_TEST_IDS } from 'enums';
import { remCalc, showSystemMessage } from 'utils';
import {
  Button,
  Row,
  Col,
  Dialog,
  DialogProps,
  Stepper,
  Step,
  StepLabel,
} from 'elements';
import FirstStep from './Step1';
import SecondStep from './Step2';
import { defineValidationSchema } from './helpers';
import { BULk_SCHEDULE_STEPS } from '../constants';
import { INITAL_VALUES } from './constants';

type Props = DialogProps<IBulkScheduleUpdateDialog.IFormValues>;

const Connector = () => null;

const BulkScheduleUpdateDialog = (props: Props) => {
  const { open, onClose, onSave } = props;
  const [state, handleState] = useState<IBulkScheduleUpdateDialog.IState>({
    activeStep: 0,
  });

  const { t } = useTranslation();

  const isLastStep = state.activeStep === 1;

  const onSubmit = React.useCallback(
    (values: IBulkScheduleUpdateDialog.IFormValues) => {
      if (state.activeStep === 0) {
        handleState({ activeStep: 1 });
      }
      if (state.activeStep === 1) {
        if (!values.applied.length)
          return showSystemMessage(
            'tenants.dialog.bulkSchedule.notify.alert.noCompanySelected',
            'error'
          ) as any;

        return onSave(values);
      }
    },
    [state.activeStep, onSave]
  );

  const form = useFormik<IBulkScheduleUpdateDialog.IFormValues>({
    initialValues: INITAL_VALUES,
    validationSchema: defineValidationSchema(state.activeStep),
    validateOnMount: false,
    onSubmit,
  });

  React.useEffect(() => {
    if (!open) {
      form.resetForm();
      handleState({ activeStep: 0 });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t(`tenants.dialog.bulkSchedule.title`)}
      PaperProps={{
        style: { maxWidth: remCalc(1320) },
      }}
      contentProps={{
        style: { minHeight: remCalc(400) },
      }}
      fullWidth
      onClose={onClose}
      handleSubmit={form.handleSubmit}
      actions={
        <Row justifyContent="space-between" columnSpacing={3} rowSpacing={2}>
          <Col>
            <Button
              variant="outlined"
              color="default"
              onClick={onClose}
              testId={ALL_TEST_IDS.tenants.dialog.back}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Row justifyContent="flex-end" columnSpacing={2}>
              {state.activeStep !== 0 && (
                <Col>
                  <Button
                    onClick={() =>
                      handleState({ activeStep: state.activeStep - 1 })
                    }
                  >
                    {t('common.previous')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button type="submit">
                  {t(isLastStep ? 'common.save' : 'common.next')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <div className="mb-30">
        <Stepper
          activeStep={state.activeStep}
          classes={{ root: '' }}
          connector={<Connector />}
        >
          {BULk_SCHEDULE_STEPS.map((label, index) => {
            return (
              <Step key={label} className="mr-50">
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {state.activeStep === 0 && <FirstStep {...form} />}
      {state.activeStep === 1 && <SecondStep {...form} />}
    </Dialog>
  );
};

export default BulkScheduleUpdateDialog;
