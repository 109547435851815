import React, { PropsWithChildren } from 'react';
import RSelect, { Props, components as SelectElements } from 'react-select';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import AsyncSelect from 'react-select/async';
import { is } from 'ramda';
import { Checkbox } from './Checkbox';
import { Row } from './Grid';
import { PALETTE, TestSuffixes } from 'enums';
import { remCalc, important } from 'utils/uxHelpers';
import { isString } from 'utils/customFunctions';
import { styled } from './utilities';
import { useTheme } from '@mui/material/styles';
import { IAutomationTestProps } from 'automation-test';
import { AnyShape, ValueType } from 'global-shapes';
import { useTranslation } from 'react-i18next';

type SelectProps = Omit<Props, 'onChange'> & {
  selectClassname?: string;
  label?: any;
  error?: boolean;
  useAsync?: boolean;
  helperText?: any;
  onLoad?: (...args: any) => Promise<any>;
  useCheckboxOption?: boolean;
  maxDisplayLabels?: number;
  defaultOptions?: boolean;
  onChange: (val: ValueType<any>) => void;
};

const MultiValueRemove = styled(CloseIcon)(() => ({
  cursor: 'pointer',
  color: '#7e8288',
  fontSize: remCalc(12),
  height: remCalc(18),
  lineHeight: remCalc(18),
  '&:hover': {
    color: important(PALETTE.error),
    backgroundColor: important('transparent'),
  },
}));

const CrossIcon = styled(CloseIcon)(() => ({
  cursor: 'pointer',
  color: '#7e8288',
  fontSize: remCalc(14),
  height: remCalc(18),
  lineHeight: remCalc(18),
  '&:hover': {
    color: important(PALETTE.error),
    backgroundColor: important('transparent'),
  },
}));

const components = {
  IndicatorSeparator: null,
  LoadingMessage: (pr: any) => {
    const { children, ...props } = pr;
    return (
      <SelectElements.LoadingMessage {...props} className="pt-15 pb-15">
        {children}
      </SelectElements.LoadingMessage>
    );
  },
  NoOptionsMessage: (pr: any) => {
    const { children, ...props } = pr;
    return (
      <SelectElements.NoOptionsMessage {...props} className="pt-15 pb-15">
        {children}
      </SelectElements.NoOptionsMessage>
    );
  },
  SingleValue: (pr: any) => {
    const { children, ...props } = pr;
    const { t } = useTranslation();
    return (
      <SelectElements.SingleValue {...props}>
        {typeof children === 'string' ? t(children) : children}
      </SelectElements.SingleValue>
    );
  },
  MultiValueLabel: (pr: any) => {
    const { children, ...props } = pr;
    const { t } = useTranslation();
    return (
      <SelectElements.MultiValueLabel {...props}>
        {t(children)}
      </SelectElements.MultiValueLabel>
    );
  },
  ClearIndicator: (pr: any) => {
    return <CrossIcon {...pr.innerProps} />;
  },
  MultiValueRemove: (pr: any) => {
    return <MultiValueRemove {...pr.innerProps} />;
  },
  DropdownIndicator: (pr: any) => (
    <ArrowDropDown {...pr.innerProps} className="fs-18 steel" />
  ),
  ValueContainer: (pr: any) => {
    const { children, ...props } = pr;

    const hasValue = props.hasValue && children[0];
    const displayMoreLabel = hasValue && children[0].length > 2;
    return (
      <SelectElements.ValueContainer {...props}>
        {props.selectProps.maxDisplayLabels
          ? displayMoreLabel
            ? [
                [
                  children[0].slice(0, props.selectProps.maxDisplayLabels),
                  <span className="ml-5 fs-12" key="show-more">
                    {children[0].length - props.selectProps.maxDisplayLabels}
                    {'+'}
                  </span>,
                ],
                children[1],
              ]
            : children
          : children}
      </SelectElements.ValueContainer>
    );
  },
  Option: (pr: any) => {
    const { selectProps, label } = pr;
    const { t } = useTranslation();
    return (
      <div
        data-test-id={`${pr.selectProps.testId}-${
          pr.selectProps.name ? `${pr.selectProps.name}-` : ''
        }${TestSuffixes.selectItem}`}
        data-test-id-value={`${pr.selectProps.testId}-${
          pr.selectProps.name ? `${pr.selectProps.name}-` : ''
        }${pr.value}`}
      >
        <SelectElements.Option {...pr}>
          {selectProps.useCheckboxOption && (
            <Checkbox
              checked={pr.isSelected}
              label={''}
              onChange={() => undefined}
            />
          )}
          {t(label)}
        </SelectElements.Option>
      </div>
    );
  },
};

const defineSelectBorder = (state: any) => {
  const primary = state.selectProps.theme.palette.primary.main;

  if (state.selectProps.error) return PALETTE.error;

  if (state.selectProps.isDisabled) return PALETTE.pageBackground;

  if (state.isFocused) return primary;

  return PALETTE.lightGrey;
};

const customStyles = {
  control: (provided: any, state: AnyShape) => {
    return {
      ...provided,
      minHeight: remCalc(48),
      padding: remCalc(0, 15),
      borderRadius: 4,
      borderColor: defineSelectBorder(state),
      borderWidth: 1,
      boxShadow: 'none',
      color: state.selectProps.isDisabled ? PALETTE.steel : provided.color,
      backgroundColor: state.selectProps.error
        ? '#FFF0EE'
        : state.selectProps.isDisabled
        ? PALETTE.pageBackground
        : state.selectProps.theme.palette.common.white,
      '&:hover': {
        borderColor: state.selectProps.theme.palette.primary.main,
      },
    };
  },
  input: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps.isDisabled ? PALETTE.steel : provided.color,
    visibility: state.selectProps.isDisabled ? 'visible' : provided.visibility,
  }),
  menu: (provided: any) => ({
    ...provided,
    minHeight: remCalc(48),
    boxShadow: '0 12px 30px 0 rgba(23, 29, 41, 0.08)',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    lineHeight: 1.4,
  }),
  multiValue: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: 'transparent',
      fontSize: remCalc(11),
      border: `1px solid ${state.selectProps.theme.palette.primary.main}`,
      margin: remCalc(2, 4),
      borderRadius: 4,
      height: remCalc(20),
      lineHeight: remCalc(13),
    };
  },
  option: (base: any, state: any) => {
    let color = '#fff';
    if (state.isFocused) color = PALETTE.paleGrey;
    if (state.isSelected)
      color = state.selectProps.useCheckboxOption
        ? PALETTE.paleGrey
        : state.selectProps.theme.palette.primary.main;
    return {
      ...base,
      padding: remCalc(0, 15),
      backgroundColor: color,
      color: state.selectProps.useCheckboxOption ? PALETTE.dark : base.color,
      lineHeight: remCalc(40),
    };
  },
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

const errorStyles = {
  fontSize: remCalc(12),
  color: PALETTE.error,
  paddingTop: remCalc(5),
  lineHeight: remCalc(15),
};

const labelStyles = {
  color: PALETTE.primaryDark,
  display: 'block',
  fontSize: remCalc(12),
  marginBottom: remCalc(8),
  lineHeight: remCalc(17.25),
};

const loadOptions =
  (onLoad?: (...args: any) => Promise<any>) => (inputValue: string) =>
    onLoad && onLoad(inputValue.toLowerCase());

const SelectHolder = (
  props: PropsWithChildren<
    IAutomationTestProps & {
      className?: string;
      name?: string;
      label?: React.ReactNode | string;
      testId?: string;
      error?: boolean;
      helperText?: string;
    }
  >
) => {
  const { className, name, label, testId, error, helperText, children } = props;
  const { t } = useTranslation();

  return (
    <div
      className={className}
      data-test-id={`${props.testId}-${name ? `${name}-` : ''}${
        TestSuffixes.select
      }-${TestSuffixes.wrapper}`}
    >
      {label && (
        <label
          htmlFor={name}
          style={labelStyles}
          data-test-id={`${testId}-${name ? `${name}-` : ''}${
            TestSuffixes.select
          }-${TestSuffixes.label}`}
        >
          {isString(props.label) ? t(props.label as string) : props.label}
        </label>
      )}
      {children}
      {error && helperText && (
        <Row justifyContent="flex-end">
          <span
            style={errorStyles}
            data-test-id={`${testId}-${name ? `${name}-` : ''}${
              TestSuffixes.select
            }-${TestSuffixes.error}`}
          >
            {t(helperText) as string}
          </span>
        </Row>
      )}
    </div>
  );
};

const Select = (props: SelectProps & IAutomationTestProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const onLoad = React.useCallback(loadOptions(props.onLoad), [props.useAsync]);

  const commonProps = {
    ...props,
    placeholder: is(String)(props.placeholder)
      ? t(props.placeholder)
      : props.placeholder,
    value: is(String)(props.value)
      ? (props.options || []).find((o: any) => o.value === props.value)
      : props.value,
    styles: customStyles,
    menuPortalTarget: document.body,
    components,
    className: props.selectClassname,
    theme,
  };

  if (props.useAsync) {
    return (
      <SelectHolder
        className={props.className}
        testId={props.testId}
        label={props.label}
        name={props.name}
        error={props.error}
        helperText={props.helperText}
      >
        {/* @ts-ignore */}
        <AsyncSelect
          {...commonProps}
          menuPosition="fixed"
          loadOptions={onLoad}
        />
      </SelectHolder>
    );
  }

  return (
    <SelectHolder
      className={props.className}
      testId={props.testId}
      label={props.label}
      name={props.name}
      error={props.error}
      helperText={props.helperText}
    >
      {/* @ts-ignore */}
      <RSelect {...commonProps} menuPosition="fixed" />
    </SelectHolder>
  );
};

Select.defaultProps = {
  useAsync: false,
  loadOptions: () => undefined,
};

export default Select;
