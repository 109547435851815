import React, { useCallback, useMemo } from 'react';
import {
  Button,
  LinearTable,
  Paper,
  Row,
  Col,
  CopyTooltip,
  MuiIcons,
  List,
  Popover,
  RdpIcon,
  IconButton,
  Input,
  styled,
} from 'elements';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import { stringify } from 'query-string';
import { useTranslation } from 'react-i18next';
import { ALL_TEST_IDS, defaultRdsNavQuery } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  useQuery,
  useTask,
  useUserHash,
  usePermissions,
  useRemoteSupport,
  useState,
} from 'hooks';
import {
  openInNewTab,
  remCalc,
  stripCoreDomain,
  debounce,
  shouldDisableWebConsoleLaunching,
} from 'utils';
import { MaintenanceWindowValidatePayload } from 'maintenances';
import cn from 'classnames';
import PopupState, {
  bindMenu,
  bindTrigger,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import ButtonList from 'components/ActionButtons/ButtonList';
import SnapshotInfo from 'components/SnapshotInfo';
import { withRdsActions, InjectedProps } from 'hocs/withRdsActions';
import EditPerformanceProfileDialog from './EditPerformanceProfileDialog';
import ProfileDisk from './ProfileDisk';
import CoreSnapshotBanner from './CoreSnapshotBanner';
import { USERS_COLUMNS } from '../constants';
import { downloadRdpFile } from '../helpers';

type IDialogType = 'performance' | null;

type IProps = InjectedProps & {
  requireAgentUpdate?: boolean;
};

type IState = {
  openedDialog: IDialogType;
  remoteSupportOpen: boolean;
};

const INITIAL_STATE = {
  openedDialog: null,
  remoteSupportOpen: false,
};

const OpenInNewTabIcon = styled(MuiIcons.OpenInNew)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const OBSERVERS = {
  rds: StateHandlers.rds,
  users: StateHandlers.rdsUsers,
  core: StateHandlers.core,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    handlers,
    actionItems,
    isRequesting,
    rds,
    users,
    core,
    currentUser,
    currentSnapshot,
    requireAgentUpdate,
  } = props;
  const { t } = useTranslation();
  const { changeQuery, queryStr, query } = useQuery();
  const [, changeUrl] = useUserHash();
  const rs = useRemoteSupport({ type: 'rds', onSuccess: () => rds.get({}) });
  const { permissions } = usePermissions('SERVICES');
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const handleDialogChange = useCallback(
    (type: IDialogType) => handleChange({ openedDialog: type }),
    []
  );

  const searchRef = React.useRef({ value: query.q || '' });

  const hasSnapshot = !!currentSnapshot;

  const innerPopupState = usePopupState({
    variant: 'popover',
    popupId: 'innerPopover',
  });

  const fetchRds = useCallback(() => rds.get({}), []);

  const task = useTask(rds.data?.task || null, {
    onComplete: (action) => {
      fetchRds();
      if (action === 'delete') {
        changeUrl('/services/all');
      }
    },
  });

  const coreDomain = useMemo(
    () => (core.data ? stripCoreDomain(core.data.domainName) : ''),
    [core.data]
  );

  const changeSearchUrl = React.useCallback(
    (q: string) => changeQuery({ ...query, q, page: 1 }),
    [queryStr, query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [queryStr, query]
  );

  const columns = useMemo(() => USERS_COLUMNS({ t, coreDomain }), []);

  const onUsersChange = useCallback(
    (payload: MaintenanceWindowValidatePayload) => {
      users.create(payload).then(() => {
        users.get(query);
        handleDialogChange(null);
      });
    },
    [queryStr]
  );

  const isOpenDialog = state.openedDialog === 'performance';

  const rdsVms =
    rds.data?.host?.hostInstances.map((el) => ({
      value: 'console',
      label: el.vm?.name,
      id: el.vm?.id,
    })) || [];

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
  }, [query.q]);

  React.useEffect(() => {
    users.get(query);
  }, [queryStr]);

  return (
    <Paper
      className={cn('p-30')}
      style={{ minHeight: `calc(100vh - ${remCalc(250)})` }}
    >
      <Row className="mb-10" alignItems="center" justifyContent="space-between">
        <Col>
          <div className="uppercase bolder fs-14">
            {t('services.rds.users.title')}
          </div>
        </Col>
        {permissions.canManage && (
          <Col>
            <Row columnSpacing={2} alignItems="center">
              <Col>
                <ButtonList actions={actionItems || []} />
              </Col>
              <Col>
                <PopupState variant="popover" popupId="rds-users-menu">
                  {(popupState) => (
                    <>
                      <IconButton size="small" {...bindTrigger(popupState)}>
                        <MuiIcons.MoreVert className="fs-20 steel pointer" />
                      </IconButton>
                      <Popover {...bindMenu(popupState)}>
                        <List>
                          {!hasSnapshot && (
                            <ListItemButton
                              data-test-id={`rds-menu-item-create-snapshot`}
                              disabled={isRequesting}
                              onClick={() => {
                                handlers.createSnapshot();
                                popupState.close();
                              }}
                            >
                              {t(`snapshots.options.create`)}
                            </ListItemButton>
                          )}

                          {hasSnapshot && (
                            <ListItemButton
                              data-test-id={`rds-menu-item-revert-snapshot`}
                              disabled={isRequesting}
                              onClick={() => {
                                handlers.revertSnapshot().then(fetchRds);
                                popupState.close();
                              }}
                            >
                              {t(`snapshots.options.revert`)}
                            </ListItemButton>
                          )}

                          {hasSnapshot && (
                            <ListItemButton
                              data-test-id={`rds-menu-item-remove-snapshot`}
                              disabled={isRequesting}
                              onClick={() => {
                                handlers.removeSnapshot().then(fetchRds);
                                popupState.close();
                              }}
                            >
                              {t(`snapshots.options.remove`)}
                            </ListItemButton>
                          )}

                          {hasSnapshot && (
                            <ListItemButton
                              data-test-id={`rds-menu-item-cancel-autoremoval-snapshot`}
                              disabled={
                                isRequesting || !currentSnapshot?.removalOn
                              }
                              onClick={() => {
                                handlers.cancelSnapshotAutoremoval();
                                popupState.close();
                              }}
                            >
                              {t(`snapshots.options.cancelAutoRemoval`)}
                            </ListItemButton>
                          )}

                          <Divider />
                          {requireAgentUpdate && (
                            <ListItemButton
                              data-test-id={`rds-menu-item-update-agent`}
                              onClick={() => {
                                popupState.close();
                                return handlers.updateAgent();
                              }}
                            >
                              {
                                t(
                                  `services.content.action.updateAgent`
                                ) as string
                              }
                            </ListItemButton>
                          )}
                          <ListItemButton
                            data-test-id={`rds-menu-item-remote-support`}
                            disabled={
                              !(task.isCompleted || task.isFailed) ||
                              rs.isRequesting ||
                              rs.isTaskActive
                            }
                            onClick={() => {
                              rs.isUsedForRds
                                ? handlers.disableRemoteSupport()
                                : handlers.enableRemoteSupport();
                              handleChange(true, 'remoteSupportOpen');
                              popupState.close();
                            }}
                          >
                            <div className="full-width test-right">
                              {t(
                                `services.content.action.remoteSupport.${
                                  rs.isUsedForRds ? 'disable' : 'enable'
                                }`
                              )}
                            </div>
                          </ListItemButton>
                          {!shouldDisableWebConsoleLaunching(
                            rds.data?.displayStatus
                          ) && (
                            <ListItemButton {...bindHover(innerPopupState)}>
                              <div className="flex justify-between align-center">
                                <MuiIcons.ChevronLeft className="fs-22 steel pointer mr-5" />
                                {t(`services.content.action.console`)}
                              </div>
                            </ListItemButton>
                          )}

                          <ListItemButton
                            data-test-id={`rds-menu-item-delete`}
                            disabled={!(task.isCompleted || task.isFailed)}
                            onClick={async () => {
                              popupState.close();
                              await handlers.delete();
                              await fetchRds();
                            }}
                          >
                            <div className="full-width test-right">
                              {t(`services.rds.navigation.remove`)}
                            </div>
                          </ListItemButton>
                        </List>
                      </Popover>

                      <HoverPopover
                        {...bindPopover(innerPopupState)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <List>
                          {/* @ts-ignore */}
                          {rdsVms.map((el: any) => {
                            const { label, disabled } = el;
                            return (
                              <ListItemButton
                                key={`${el.label}-${label}`}
                                data-test-id={`${label}-menu-item`}
                                disabled={disabled}
                                onClick={() => {
                                  handlers.console(el.id);
                                  popupState.close();
                                  innerPopupState.close();
                                }}
                              >
                                {label}
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </HoverPopover>
                    </>
                  )}
                </PopupState>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row
        className="mb-30 fs-14 full-width"
        columnSpacing={5}
        alignItems="center"
      >
        <Col>
          <div className="flex">
            <div className="mr-10">
              {t('services.rds.users.accessGateway')}:
            </div>
            <div className="steel">
              <CopyTooltip>{rds.data.externalFQDN}</CopyTooltip>
            </div>
            <OpenInNewTabIcon
              className="fs-20 ml-10 pointer"
              onClick={() => openInNewTab(`https://${rds.data.externalFQDN}`)}
            />
          </div>
        </Col>
        <Col>
          <div className="flex align-center">
            <div className="mr-10">{t('services.rds.users.downloadRdp')}:</div>
            <RdpIcon
              className={cn('fs-24 pointer radiused flex')}
              onClick={() =>
                downloadRdpFile(
                  rds.data,
                  `Remote Desktop ${
                    currentUser.data.tenant
                      ? currentUser.data.tenant.name
                      : `${currentUser.data.firstName} ${currentUser.data.lastName}`
                  }`
                )
              }
            />
          </div>
        </Col>
        {currentSnapshot && (
          <Col xs justifyContent="flex-end">
            <div className="flex justify-end full-width">
              <div>
                <SnapshotInfo snapshot={currentSnapshot} />
              </div>
            </div>
          </Col>
        )}
      </Row>
      <ProfileDisk />
      <CoreSnapshotBanner />
      <Row className="mb-15" justifyContent="space-between" alignItems="center">
        <Col>
          <div className="uppercase bolder fs-14">
            {t('services.rds.users.tableTitle')}
          </div>
        </Col>
        {permissions.canManage && (
          <Col>
            <Row columnSpacing={2}>
              <Col>
                <Button
                  size="small"
                  onClick={() => handleDialogChange('performance')}
                  testId={ALL_TEST_IDS.services.remote.enableUsers}
                >
                  {t('services.rds.users.enableUsers')}
                </Button>
              </Col>
              <Col>
                <Button
                  size="small"
                  onClick={() =>
                    changeUrl(
                      `/services/infrastructure/accounts?${stringify(
                        defaultRdsNavQuery
                      )}`
                    )
                  }
                  testId={ALL_TEST_IDS.services.remote.manageUsers}
                >
                  {t('services.rds.users.manageUsers')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <div className="mb-5">
        <Input
          placeholder={t('forms.placeholders.search')}
          ref={searchRef}
          type="search"
          inputClassName={cn('bg-white')}
          inputProps={{ style: { height: remCalc(48) } }}
          className="mt-25"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </div>
      <LinearTable
        columns={columns}
        data={users.data}
        params={users.meta || {}}
        query={query}
        isLoading={!users.dataReceived}
        onPageChange={(nq) => changeQuery({ ...defaultRdsNavQuery, ...nq })}
        testId={ALL_TEST_IDS.services.remote.users}
        noDataMessage={t('services.rds.users.table.noUsers')}
        additionalTaskWatch="rdsUser"
        usePagination
        hasSorting
        onSort={(orderBy, orderType) => changeQuery({ orderBy, orderType })}
        onUpdateTable={() => users.get(query)}
      />
      <EditPerformanceProfileDialog
        open={isOpenDialog}
        testId={ALL_TEST_IDS.services.remote.usersDialog}
        onClose={() => handleDialogChange(null)}
        onSave={onUsersChange}
        coreDomain={coreDomain}
      />
    </Paper>
  );
});

const Users = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withRdsActions(Users);
