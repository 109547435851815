export const COUNTRIES = [
  {
    label: 'Switzerland',
    value: 'CH',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    label: 'common.other',
    value: 'other',
  },
];
