import React, { useCallback } from 'react';
import { AnyFunc } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik/dist/types';
import { useState, useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { CommonContentProps } from 'services-types';
import { ALL_TEST_IDS, NETWORKS_DEFAULT_PARAMS } from 'enums';
import { debounce } from 'utils';
import { connectivityService } from 'services';
import {
  Input,
  Select,
  Tooltip,
  MuiIcons,
  Alert,
  Switch,
  SPrimarySpan,
  ConditionalTooltip,
} from 'elements';
import SliderItem from './SliderItem';
import { mapOsSystems, onFetchTemplates, checkIfVmNameExist } from './helpers';
import { MAX_VM_DETAILS_VALUES } from '../constants';

const minValuesPartial = {
  virtualCpus: 1,
  ramMb: 1,
  osDiskSizeGb: 40,
};

const fetchNetworkOptions = async (q: string) =>
  connectivityService
    .getNetworks({
      ...NETWORKS_DEFAULT_PARAMS,
      type: ['NAT_ROUTED', 'ISOLATED'],
      q,
    })
    .then((res) =>
      res.data.map((n) => ({
        value: n.id,
        label: n.name,
        type: n.type,
        isPrimary: n.isPrimary,
      }))
    );

type IProps = React.PropsWithChildren<
  CommonContentProps &
    FormikProps<IVmTypes.IVmInfoBaseValues> & {
      isNew?: boolean;
      isDisabled?: boolean;
      hasSnapshot?: boolean;
      currentVM?: IVmTypes.Vm;
      onDirtyChange?: (dirty: boolean) => void;
      vmId?: number;
    }
>;

const Renderer = observer((props: IProps) => {
  const {
    isNew,
    values,
    errors,
    handleChange,
    setFieldValue,
    submitCount,
    isDisabled,
    hasSnapshot,
    setFieldError,
    currentVM,
  } = props;

  const core = useStateHandler(StateHandlers.core);
  const { t } = useTranslation();

  const [state, handleState] = useState<any>({
    systems: [],
    osDiskLimits: {},
  });

  const vmId = currentVM?.id;

  const onSystemInfoChange = useCallback(
    (field: string): AnyFunc =>
      (ev: any, value: any) => {
        setFieldValue(field, value);
      },
    []
  );

  const onSystemTemplateChange = React.useCallback(
    (val: VmDialogTypes.IOsValueType<number>) => {
      setFieldValue('virtualCpus', val.virtualCpus);
      setFieldValue('ramMb', val.ramMb);
      setFieldValue('osDiskSizeGb', val.osDiskSizeGb);
      setFieldValue('templateId', val);
    },
    [JSON.stringify(values), JSON.stringify(state.osDiskLimits)]
  );

  const minValues = React.useMemo(() => {
    return {
      ...minValuesPartial,
      osDiskSizeGb:
        values.templateId && state.osDiskLimits[values.templateId.value]
          ? state.osDiskLimits[values.templateId.value].min
          : 40,
    };
  }, [JSON.stringify(values), JSON.stringify(state.osDiskLimits)]);

  const handleVmNameChange = React.useCallback(
    debounce(async (e: React.ChangeEvent<any>) => {
      const vmName = e.target.value || '';
      const res = await checkIfVmNameExist({
        vmName,
        osDiskSizeGb: values.osDiskSizeGb,
        vmId,
      }).catch(() => ({ exist: false, errorCode: 'error placeholder' }));
      if (res.exist) {
        setFieldError('name', res.errorCode);
      }
    }, 500),
    [vmId]
  );

  React.useEffect(() => {
    onFetchTemplates('').then((res) => {
      handleState(mapOsSystems(res.data));
    });
    fetchNetworkOptions('').then((res) => {
      const defaultNetwork = res.find((el) => el.isPrimary);
      if (defaultNetwork && !values.networkId?.value) {
        setFieldValue('networkId', defaultNetwork);
      }
    });
  }, []);

  const isWindows = values?.templateId?.familyId === 1;
  const isCoreRunning = core.data?.displayStatus === 'POWERED_ON';

  const showJoinDOmain = React.useMemo(() => {
    if (!core.data) return false;
    if (values?.isPublic) return false;
    if (!isWindows) return false;
    if (values?.networkId?.type === 'ISOLATED') return false;
    return true;
  }, [isWindows, values?.isPublic, isNew, core.data, values?.networkId?.type]);

  return (
    <div data-test-id={ALL_TEST_IDS.services.vm.details}>
      <div className="mb-20">
        <Input
          name="name"
          label="services.dialog.vms.serverName"
          testId={ALL_TEST_IDS.services.vm.details}
          value={values.name}
          onChange={(ev) => {
            handleChange(ev);
            handleVmNameChange(ev);
          }}
          placeholder="services.dialog.vms.serverName"
          error={!!submitCount && !!errors.name}
          helperText={errors.name}
          helperTextOptions={{ max: 15 }}
          aria-autocomplete="none"
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
          }}
          labelIcon={
            <Tooltip
              placement="top"
              title={
                <div>
                  {t('services.dialog.vms.name.tip')
                    .split('; ')
                    .map((s) => (
                      <div key={s}>- {s}</div>
                    ))}
                </div>
              }
            >
              <MuiIcons.HelpOutline className="fs-12 ml-5" color="primary" />
            </Tooltip>
          }
        />
      </div>
      {isNew && (
        <div className="mb-20">
          <Input
            type="password"
            name="guestOSPassword"
            label="forms.password"
            testId={ALL_TEST_IDS.services.vm.details}
            value={values.guestOSPassword}
            onChange={handleChange}
            placeholder="forms.placeholders.password"
            error={!!submitCount && !!errors.guestOSPassword}
            helperText={errors.guestOSPassword}
            helperTextOptions={{ max: 32 }}
            aria-autocomplete="none"
            autoComplete="off"
            inputProps={{
              autoComplete: 'new-password',
            }}
            labelIcon={
              <Tooltip
                placement="top"
                title={
                  <div>
                    {t('forms.sups.password')
                      .split('; ')
                      .map((s) => (
                        <div key={s}>- {s}</div>
                      ))}
                  </div>
                }
              >
                <MuiIcons.HelpOutline className="fs-12 ml-5" color="primary" />
              </Tooltip>
            }
          />
        </div>
      )}
      <div className="mb-20">
        <Input
          name="description"
          testId={ALL_TEST_IDS.services.vm.details}
          label="services.dialog.vms.description"
          value={values.description}
          multiline
          rows={3}
          onChange={handleChange}
          placeholder="services.dialog.vms.description"
          error={!!errors.description}
          helperText={errors.description}
          helperTextOptions={{ max: 100 }}
        />
      </div>
      {isNew && !values.isPublic && (
        <div className="mb-20">
          <Select
            name="networkId"
            testId={ALL_TEST_IDS.services.vm.details}
            label="forms.network"
            value={values.networkId}
            onChange={(el) => setFieldValue('networkId', el)}
            defaultOptions
            useAsync
            onLoad={fetchNetworkOptions}
          />
          {!values.networkId?.isPrimary && (
            <div className="fs-12 error lh-20">
              {t('services.dialog.vms.message.notPrimaryNetwork')}
            </div>
          )}
        </div>
      )}
      {isNew && (
        <div className="mb-20">
          <Select
            name="templateId"
            testId={ALL_TEST_IDS.services.vm.details}
            label={t('services.dialog.vms.operatingSystem') as string}
            value={values.templateId}
            // @ts-ignore
            onChange={onSystemTemplateChange}
            options={state.systems}
            placeholder={t('services.dialog.vms.operatingSystem') as string}
            error={!!submitCount && !!errors.templateId}
            helperText={errors.templateId}
          />
          {showJoinDOmain && (
            <ConditionalTooltip
              title={t('services.dialog.vms.joinCoreDomain.tip') as string}
              condition={!isCoreRunning}
              Tooltip={Tooltip}
              placement="top-start"
              arrow
            >
              <div className="pt-10">
                <Switch
                  label={
                    <div>
                      {t('forms.joinCoreDomain') as string}{' '}
                      <SPrimarySpan>{core.data.domainName}</SPrimarySpan>
                    </div>
                  }
                  checked={values.joinDomain}
                  onCheck={(checked) => setFieldValue('joinDomain', checked)}
                  disabled={!isCoreRunning}
                />
              </div>
            </ConditionalTooltip>
          )}
        </div>
      )}
      <SliderItem
        name="virtualCpus"
        value={values.virtualCpus}
        minValue={minValues.virtualCpus}
        maxValue={MAX_VM_DETAILS_VALUES.virtualCpus}
        onChange={handleChange}
        error={!!submitCount && !!errors.virtualCpus}
        isDisabled={isDisabled}
        onSystemInfoChange={onSystemInfoChange}
        sliderProps={{
          defaultValue: 1,
          step: 1,
          min: minValues.virtualCpus,
          max: 64,
          marks: [
            {
              value: minValues.virtualCpus,
              label: minValues.virtualCpus,
            },
            { value: 64, label: 64 },
          ],
        }}
        helperText={!!submitCount && errors.virtualCpus}
        className="mb-20"
      />

      <SliderItem
        name="ramMb"
        value={values.ramMb}
        minValue={minValues.ramMb}
        maxValue={MAX_VM_DETAILS_VALUES.ramMb}
        onChange={handleChange}
        error={!!submitCount && !!errors.ramMb}
        isDisabled={isDisabled}
        onSystemInfoChange={onSystemInfoChange}
        sliderProps={{
          defaultValue: 1,
          step: 1,
          max: 512,
          min: minValues.ramMb,
          marks: [
            {
              value: minValues.ramMb,
              label: minValues.ramMb,
            },
            { value: 512, label: 512 },
          ],
        }}
        helperText={!!submitCount && errors.ramMb}
        className="mb-20"
      />

      <SliderItem
        name="osDiskSizeGb"
        value={values.osDiskSizeGb}
        minValue={minValues.osDiskSizeGb}
        maxValue={MAX_VM_DETAILS_VALUES.osDiskSizeGb}
        onChange={(ev: React.ChangeEvent<any>) => {
          setFieldValue('osDiskSizeGb', +ev.target.value);
        }}
        error={!!submitCount && !!errors.osDiskSizeGb}
        isDisabled={isDisabled || hasSnapshot}
        onSystemInfoChange={onSystemInfoChange}
        sliderProps={{
          step: 10,
          max: 10000,
          min: minValues.osDiskSizeGb,
          marks: [
            {
              value: minValues.osDiskSizeGb,
              label: minValues.osDiskSizeGb,
            },
            { value: 10000, label: "10'000" },
          ],
        }}
        helperText={
          (!!submitCount && errors.osDiskSizeGb) ||
          (isNew && 'services.dialog.vms.diskSizeTip')
        }
        className="mb-5"
      />
      {hasSnapshot && (
        <Alert severity="info" className="mb-15">
          {
            t(
              'services.dialog.vms.alerts.storageDisabledWithSnappshot'
            ) as string
          }
        </Alert>
      )}
    </div>
  );
});

export default Renderer;
