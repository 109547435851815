import * as React from 'react';
import {
  useFormik,
  FormikState,
  FormikHelpers,
  FormikHandlers,
  FormikComputedProps,
} from 'formik';
import { Col, Input, MuiIcons, Row, Tooltip, AddIcon } from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { validateCallback, validator } from 'utils';
import {
  STATIC_PUBLIC_SERVICES,
  PORT_TYPES_OPTIONS,
  ICMP_TYPE_NAME_OPTION,
} from 'pages/services/constants';
import { ejectPubServiceName } from 'pages/services/helpers';
import { publicServiceValidationSchema } from './constants';
import { PublicServiceSelect } from './Styled';

const INITIAL: IVmTypes.NewVMPortFormValues = {
  name: '',
  port: 1,
  type: PORT_TYPES_OPTIONS[0],
};

type IProps = React.PropsWithChildren<
  WithT & {
    useAddButton: boolean;
    formConfig?: FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any>;
    onSave?: (values: IVmTypes.NewVMPorts) => void;
  }
>;

const Renderer = (props: IProps) => {
  const { t, onSave, useAddButton, formConfig } = props;

  const onAdd = (
    { name, port, type }: IVmTypes.NewVMPortFormValues,
    config: any
  ) => {
    onSave &&
      onSave({
        name: ejectPubServiceName(name),
        port: +port,
        type: type.value,
      });
    return config.resetForm();
  };

  const localConfig = useFormik({
    initialValues: INITIAL,
    validationSchema: publicServiceValidationSchema,
    onSubmit: onAdd,
    validateOnMount: false,
  });

  const config = formConfig || localConfig;

  const { values, setFieldValue, errors, submitCount, isSubmitting } = config;

  const onInputPortNameChange = React.useCallback(
    validateCallback({
      max: 30,
      restrict: validator.portName,
    })((val: any, meta: any) => {
      if (meta.action === 'input-change') {
        setFieldValue('name', val);
      }
    }),
    []
  );

  const isIcmpType = values.type?.value === 'icmp';

  const portNameOptions = isIcmpType
    ? [...STATIC_PUBLIC_SERVICES, ICMP_TYPE_NAME_OPTION]
    : STATIC_PUBLIC_SERVICES;

  return (
    <Row
      alignItems="flex-start"
      justifyContent="space-between"
      columnSpacing={2}
      className="mb-25"
    >
      <Col xs>
        <Row columnSpacing={2} alignItems="flex-start" className="full-width">
          <Col xs={5}>
            <PublicServiceSelect
              label={t('services.dialog.vms.table.head.portName') as string}
              placeholder="FTPS-Control"
              name="name"
              options={portNameOptions}
              inputValue={
                values.name ? ejectPubServiceName(values.name) : undefined
              }
              value={values.name}
              getOptionLabel={(o: any) => ejectPubServiceName(o.label)}
              onChange={(val: any) => {
                setFieldValue('name', val.value);
                setFieldValue('port', val.value.split(', ')[1]);
              }}
              onInputChange={onInputPortNameChange}
              error={!!(submitCount && errors.name)}
              helperText={errors.name}
              isDisabled={isSubmitting || isIcmpType}
            />
          </Col>
          <Col xs={3}>
            <Input
              label="services.dialog.vms.table.head.port"
              placeholder={'3389'}
              name="port"
              value={values.port}
              onChange={validateCallback({
                restrict: validator.port,
              })((ev: any) => {
                const value = ev.target.value;
                setFieldValue('port', value);
                if (+value === 161) {
                  setFieldValue('type', PORT_TYPES_OPTIONS[1]);
                }

                if (+value === 49573) {
                  setFieldValue('type', PORT_TYPES_OPTIONS[2]);
                }
              })}
              error={!!(submitCount && errors.port)}
              helperText={errors.port}
              disabled={isSubmitting || isIcmpType}
              labelIcon={
                <Tooltip
                  placement="top"
                  title={
                    <div>
                      {t('services.vm.services.dialog.portTip') as string}
                    </div>
                  }
                >
                  <MuiIcons.HelpOutline
                    className="fs-12 ml-5"
                    color="primary"
                  />
                </Tooltip>
              }
            />
          </Col>
          <Col xs={4}>
            <PublicServiceSelect
              name="type"
              label={t('forms.type') as string}
              placeholder={'TCP'}
              options={PORT_TYPES_OPTIONS}
              value={values.type}
              onChange={(ev: IVmTypes.IPortTypeValue) => {
                setFieldValue('type', ev);
                if (ev.value === 'icmp') {
                  setFieldValue('port', '49573');
                  setFieldValue('name', ICMP_TYPE_NAME_OPTION.value);
                }
              }}
              error={!!(submitCount && errors.type)}
              helperText={errors.type}
              isDisabled={isSubmitting}
            />
          </Col>
        </Row>
      </Col>
      {useAddButton && (
        <Col>
          <AddIcon
            size="medium"
            className="mt-30"
            disabled={isSubmitting}
            onClick={() => config.submitForm()}
          />
        </Col>
      )}
    </Row>
  );
};

const AddPublicServiceForm = withTranslation()(Renderer);

export default AddPublicServiceForm;
