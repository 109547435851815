import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useStateHandler } from 'hooks';
import { Row, Col, Loader, Select, Tooltip } from 'elements';
import { observer } from 'mobx-react-lite';
import { remCalc } from 'utils';
import * as StateHandlers from 'states';
import * as SERVICES from 'services';
import SystemInfo from './SystemInfo';
import CostBreakdown from './CostBreakdown';
import AdditionalDisks from './AdditionalDisks';
import * as TYPES from './types';
import { FormSwitcher, InfoLabelSup } from '../Styled';

type IProps = FormikProps<TYPES.IFormValues>;

const SecondStep = observer((props: IProps) => {
  const { t } = useTranslation();
  const vmSpec = useStateHandler(StateHandlers.vmSpec);

  const fetchSpec = React.useCallback(() => {
    return vmSpec.get({
      vmId: props.values.vm?.id,
    });
  }, [props.values.vm?.id]);

  React.useEffect(() => {
    if (props.values.vm?.id) {
      fetchSpec();
    } else {
      vmSpec.reset();
    }
  }, [props.values.vm?.id]);

  React.useEffect(() => {
    return () => {
      vmSpec.reset();
    };
  }, []);

  if (!vmSpec.dataReceived) {
    return <Loader />;
  }

  return (
    <Row columnSpacing={2}>
      <Col xs>
        <div className="mb-25">
          <strong>{props.values.vm?.name}</strong>
        </div>
        <div className="mb-20">
          <FormSwitcher
            label={
              <div className="flex align-center ml-10">
                {t('services.dialog.vms.switchers.publicVm')}
                <Tooltip
                  placement="top"
                  title={t('services.dialog.vms.switchers.publicVm.tooltip')}
                >
                  <sup className="ml-5">
                    <InfoLabelSup />
                  </sup>
                </Tooltip>
              </div>
            }
            checked={props.values.isPublic}
            onCheck={(isPublic: boolean) =>
              props.setFieldValue('isPublic', isPublic)
            }
          />
        </div>
        <div className="mb-25">
          <Select
            name="templateId"
            label="services.dialog.vms.operatingSystem"
            value={props.values.templateId}
            defaultOptions
            useAsync
            onLoad={(q) =>
              SERVICES.virtualMachinesService
                .getTemplates({
                  q,
                  limit: 1000,
                  offset: 0,
                  orderBy: 'name',
                  orderType: 'asc',
                })
                .then((res) =>
                  res.data.map((s) => ({
                    value: s.id,
                    label: s.name,
                    osFamilyId: s.os.osFamilyId,
                  }))
                )
            }
            onChange={(val) => props.setFieldValue('templateId', val)}
            error={!!props.errors.templateId}
            helperText={!!props.submitCount && props.errors.templateId}
          />
        </div>
        <SystemInfo {...vmSpec.data} className="mb-25" />
        <AdditionalDisks spec={vmSpec.data} />
      </Col>
      <Col style={{ width: remCalc(370) }}>
        <CostBreakdown {...props} spec={vmSpec.data} />
      </Col>
    </Row>
  );
});

export default SecondStep;
