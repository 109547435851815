import { showSystemMessage } from 'utils/global';
import { connectivityService } from 'services';
import { IIpSecVpnTunnel } from 'ipsec-vpn-tunnels';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const connectivity = new BaseMobxInstanceDecorator<
  IConnectivityTypes.Connectivity,
  false
>({
  instanceName: 'connectivity',
  requests: {
    get: connectivityService.get,
    create: async (payload) => {
      const res = await connectivityService.create(payload);
      showSystemMessage('services.connectivity.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await connectivityService.update(payload);
      showSystemMessage('services.connectivity.update.success', 'success');
      return res;
    },
    remove: async () => {
      const res = await connectivityService.remove();
      showSystemMessage('services.connectivity.delete.success', 'success');
      return res as any;
    },
  },
  additionalRequests: {
    setConRateLimit: connectivityService.setConRateLimit,
  },
  // @ts-ignore
  initialState: null,
});

export type IConnectivityHandler = BaseDecorator<
  IConnectivityTypes.Connectivity,
  false
>;

export const ipSecVpnTunnels = new BaseMobxInstanceDecorator<
  IIpSecVpnTunnel,
  true
>({
  instanceName: 'psSecVpnTunnels',
  requests: {
    get: (query) => {
      const { id, ...q } = query;
      return connectivityService.getTunnels(id, q);
    },
    create: async (payload) => {
      const res = await connectivityService.createTunnel(
        payload.connectivityId,
        payload
      );
      showSystemMessage(
        'services.connectivity.ipsecVpnTunnels.create.success',
        'success'
      );
      return res;
    },
    update: async (id, payload) => {
      const res = await connectivityService.updateTunnel(
        id.connectivityId,
        id.tunnelId,
        payload
      );
      showSystemMessage(
        'services.connectivity.ipsecVpnTunnels.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = (await connectivityService.deleteTunnel(
        id.id,
        id.connectivityId
      )) as any;
      showSystemMessage(
        'services.connectivity.ipsecVpnTunnels.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});

export type IIpSecVpnTunnelsHandler = BaseDecorator<IIpSecVpnTunnel, true>;

function gatherFirewallRules(
  collection?: IConnectivityTypes.FirewallRulesCollection
): IConnectivityTypes.IFirewallRule[] {
  if (!collection) return [];
  let ind = 0;
  return [
    ...collection.systemRules.map((r) => ({ ...r, isSystem: true })),
    ...collection.userDefinedRules.map((r) => ({
      ...r,
      isUserDefined: true,
      index: ind++ + 1,
    })),
    ...collection.defaultRules.map((r) => ({
      ...r,
      isDefault: true,
      index: ind++ + 1,
    })),
  ];
}

export const firewallRules = new BaseMobxInstanceDecorator<
  IConnectivityTypes.IFirewallRule,
  true
>({
  instanceName: 'firewall-rules',
  requests: {
    get: async (params: AnyShape) => {
      const { connectivityId, ...query } = params;
      const res = await connectivityService.getFirewallRules(
        connectivityId,
        query
      );

      return {
        ...res,
        data: gatherFirewallRules(res.data),
      };
    },
    create: async (payload) => {
      const { connectivityId, ...data } = payload;
      const res = await connectivityService.createFirewallRule(
        connectivityId,
        data
      );
      showSystemMessage(
        'services.connectivity.firewallRules.notify.create.success',
        'success'
      );
      return res;
    },
    update: async (_id, payload) => {
      const { connectivityId, id } = _id;
      const res = await connectivityService.updateFirewallRule(
        connectivityId,
        id,
        payload
      );
      showSystemMessage(
        'services.connectivity.firewallRules.notify.update.success',
        'success'
      );
      return res;
    },

    remove: async (_id) => {
      const { connectivityId, id } = _id;

      const res = await connectivityService.removeFirewallRule(
        connectivityId,
        id
      );

      showSystemMessage(
        'services.connectivity.firewallRules.notify.remove.success',
        'success'
      );
      return res;
    },
  },

  initialState: [],
});

export const natRules = new BaseMobxInstanceDecorator<
  IConnectivityTypes.INatRule,
  true
>({
  instanceName: 'nat-rules',
  requests: {
    get: (params: AnyShape) => {
      const { connectivityId, ...query } = params;
      return connectivityService.getNatRules(connectivityId, query);
    },
  },
  initialState: [],
});

export const connectivityNetworks = new BaseMobxInstanceDecorator<
  IConnectivityTypes.INetwork,
  true
>({
  instanceName: 'connectivity-networks',
  requests: {
    get: connectivityService.getNetworks,
    create: async (payload) => {
      const res = await connectivityService.createNetwork(payload);
      showSystemMessage('services.connectivity.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await connectivityService.updateNetwork(id, payload);
      showSystemMessage('services.connectivity.update.success', 'success');
      return res;
    },
    remove: async (id: number) => {
      const res = await connectivityService.removeNetwork(id);
      showSystemMessage('services.connectivity.delete.success', 'success');
      return res as any;
    },
  },
  initialState: [],
});
