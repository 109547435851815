import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import isFQDN from 'validator/lib/isFQDN';
import isFloat from 'validator/lib/isFloat';
import { ValueType } from 'global-shapes';
import { CoreInfrastructureFormValues } from 'services-shapes';
import * as Yup from 'yup';
import {
  Dialog,
  Button,
  Row,
  Col,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Alert,
  MuiIcons,
  DialogProps,
  PrimaryTextSpan,
  PrimaryTextH5,
  CustomListUl,
} from 'elements';
import { usePermissions, usePrice, useTask } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { numberToCurrency, remCalc, round } from 'utils';
import { ICoreCreatePayload, ICoreInfrastructure } from 'core-infrastructure';
import { coreInfService } from 'services';
import {
  CORE_DEV_AVAILABLE_DOMAIN_OPTIONS,
  CORE_DEV_AVAILABLE_DOMAINS,
  CORE_PROD_AVAILABLE_DOMAIN_OPTIONS,
  CORE_PROD_DOMAIN,
  defaultDnsZonesQuery,
  CostResourceIds,
} from 'enums';
import { FormikErrors } from 'formik/dist/types';

type IProps = DialogProps<ICoreCreatePayload> & {
  coreData: ICoreInfrastructure | null;
  isRequesting: boolean;
};

const isProd = process.env.REACT_APP_ENV === 'production';

const domainSuffix = (): string => {
  if (isProd) return CORE_PROD_DOMAIN;
  return CORE_DEV_AVAILABLE_DOMAINS[0];
};

const INITIAL_STATE: CoreInfrastructureFormValues = {
  domainName: '',
  dnsZone: domainSuffix(),
};

const OBSERVERS = {
  zones: StateHandlers.dnsZones,
};

type IViewProps = typeof OBSERVERS;

const domainValidationSchema = Yup.string()
  .trim()
  .min(2, 'forms.invalid.ciDomain')
  .max(15, 'forms.invalid.max')
  .required('forms.required')
  .test('domainName', 'forms.invalid.ciDomain', (_val) => {
    _val = _val || '';
    return !isFloat(_val) && isFQDN(_val && _val + `.${domainSuffix()}`);
  });

const displayPrice = (price: number) =>
  numberToCurrency(round(price, 2), false);

const View = observer((props: IProps & IViewProps) => {
  const { t } = useTranslation();
  const { onSave, coreData, isRequesting, zones, ...restProps } = props;
  const { isEvaluation } = usePermissions();
  const prices = usePrice([
    CostResourceIds.coreBase,
    CostResourceIds.coreGBStorage,
  ]);
  const ciTask = useTask(coreData ? coreData.task : null);

  const baseFee = prices[CostResourceIds.coreBase]?.monthly;
  const storagePrice = prices[CostResourceIds.coreGBStorage]?.monthly * 10;

  const getZones = React.useCallback(
    () =>
      zones.get({
        ...defaultDnsZonesQuery,
        perPage: 1000,
      }),
    []
  );

  const staticDomains = isProd
    ? CORE_PROD_AVAILABLE_DOMAIN_OPTIONS
    : CORE_DEV_AVAILABLE_DOMAIN_OPTIONS;

  const allDomains: ValueType<string | number>[] = [
    ...staticDomains,
    ...zones.data.map((zone) => ({
      value: zone.domainName,
      label: zone.domainName,
    })),
  ];

  const showDropdown = allDomains.length > 1;

  const validate = React.useCallback(
    async (payload: CoreInfrastructureFormValues) => {
      const errors: FormikErrors<CoreInfrastructureFormValues> = {};

      if (payload.domainName) {
        const res = await coreInfService.validateDomainName({
          dnsZone: payload.dnsZone || '',
          subDomain: payload.domainName,
        });

        if (!res.data.isValid) {
          errors.domainName = res.data.errorMessage;
        }
      }

      await domainValidationSchema.validate(payload.domainName).catch((err) => {
        errors.domainName = err.errors[0];
      });

      return errors;
    },
    []
  );

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    resetForm,
    submitForm,
    isValid,
    submitCount,
    setFieldValue,
  } = useFormik({
    initialValues: INITIAL_STATE,
    validate,
    onSubmit: (val) =>
      Promise.resolve(
        onSave({
          dnsZone: val.dnsZone,
          subDomain: val.domainName.trim(),
        })
      ),
  });

  React.useEffect(() => {
    if (!restProps.open) {
      resetForm();
    } else {
      getZones();
    }
  }, [restProps.open]);

  return (
    <Dialog
      {...restProps}
      maxWidth="md"
      fullWidth
      title={t('services.dialog.core.title')}
      handleSubmit={handleSubmit}
      actions={
        <div className="full-width flex justify-between">
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isRequesting}
          >
            {t('common.cancel')}
          </Button>
          <Button
            disabled={
              isRequesting ||
              (!!submitCount && (!isValid || ciTask.isTaskActive))
            }
            onClick={submitForm}
          >
            {t('common.deploy')}
          </Button>
        </div>
      }
    >
      <Row columnSpacing={2}>
        <Col md={7} xs={12} className="pr-50">
          <div className="steel fs-14 mb-25">
            {t('services.dialog.core.createCore.content')}
          </div>

          <Input
            name="domainName"
            className="mb-25"
            label="services.dialog.core.createCore.form.name"
            value={values.domainName}
            onChange={handleChange}
            placeholder="services.dialog.core.createCore.form.name.placeholder"
            error={!!errors.domainName}
            helperText={errors.domainName}
            helperTextOptions={{ max: 15 }}
            endAdornment={
              <InputAdornment
                position="end"
                className="pr-10 justify-end"
                style={{ width: remCalc(270) }}
              >
                <PopupState variant="popover">
                  {(popupState) => (
                    <>
                      <PrimaryTextSpan
                        className={cn('pointer flex align-center')}
                        {...bindTrigger(popupState)}
                      >
                        <PrimaryTextSpan>.{values.dnsZone}</PrimaryTextSpan>
                        {showDropdown && (
                          <MuiIcons.ArrowDropDown
                            className="fs-20 lh-1"
                            height={10}
                          />
                        )}
                      </PrimaryTextSpan>
                      <Menu {...bindMenu(popupState)}>
                        {allDomains.map((domain) => (
                          <MenuItem
                            key={domain.value}
                            onClick={async () => {
                              setFieldValue('dnsZone', domain.value);
                              popupState.close();
                            }}
                          >
                            {domain.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </InputAdornment>
            }
          />
        </Col>

        <Col md={5} xs={12}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(400) }}
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={8}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={4} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.coreInfrastructure')}</h5>
              <div className="custom-list">
                <CustomListUl>
                  <li className="mb-15">
                    <div className="flex justify-between">
                      <span>{t('costsInfo.baseFee')}</span>
                      <span className="pl-10 steel">
                        {displayPrice(baseFee)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>{t('costsInfo.fileStorage10gb')}</span>
                      <span className="pl-10 steel">
                        {displayPrice(storagePrice)}
                      </span>
                    </div>
                  </li>
                </CustomListUl>
              </div>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center mb-10">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {displayPrice(baseFee + storagePrice)}
                </PrimaryTextSpan>
              </div>
              <div className="steel fs-12 mb-10">
                {t('costsInfo.inChfExclVat')}
              </div>
              {isEvaluation && (
                <Alert className="fs-12" severity="info">
                  {t('costsInfo.evalAccount.notify')}
                </Alert>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

const CreateCoreInfrastructureDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default CreateCoreInfrastructureDialog;
