import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import { Row, Col, MuiIcons, Loader, Tooltip, styled } from 'elements';
import { numberToCurrency, round } from 'utils';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { UsageInfoBox } from '../UsageInfoBox';
import { SYSTEM_INFO } from './constants';
import { calculateTotals, defineLimitColor } from './helpers';
import { IQuotas } from './types';

const StyledLoader = styled('div')(() => ({
  position: 'relative',
  '& > div': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginTop: '-10px',
  },
}));

const StyledCostHolder = styled(Row)(({ theme }) => ({
  position: 'relative',
  minWidth: 105,
  transition: `transform 0.25s ${theme.transitions.easing.easeInOut}`,
  transform: 'scale(1) translateX(0%)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1) translateX(-5%)',
  },
}));

const AttachMoney = styled(MuiIcons.AttachMoney)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
  quotas: StateHandlers.costBreakdown,
  usage: StateHandlers.usageSelected,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IQuotas.IProps & IViewProps) => {
  const { quotas, usage, currentUser } = props;
  const { t } = useTranslation();
  const { permissions } = usePermissions('USAGE');
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'quotas-popover' : undefined;

  const { total, used } = React.useMemo(
    () => calculateTotals(quotas.data),
    [JSON.stringify(quotas.data)]
  );

  if (!props.portal) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Row justifyContent="flex-end" alignItems="center" className="fs-12">
        {SYSTEM_INFO.map(({ Icon, key }) => {
          const current = used[key];
          const totalUsage = total[key];

          const color = defineLimitColor(current, totalUsage);
          return (
            <Col key={key} className="pr-15">
              <Tooltip
                title={t(`quotaInfo.tooltips.${key}`)}
                placement="bottom"
                arrow
              >
                <Row alignItems="center" className="pointer">
                  <Col className="pr-10">
                    <Icon size={32} />
                  </Col>
                  <Col className="bold">
                    <span className={cn(color)}>{round(current, 1, 2)}</span>/
                    {totalUsage}
                  </Col>
                </Row>
              </Tooltip>
            </Col>
          );
        })}
        {permissions.canView && (
          <Col>
            <StyledCostHolder
              columnSpacing={1}
              alignItems="center"
              onClick={handleClick}
            >
              <Col>
                <AttachMoney className="primary" />
              </Col>
              <Col className="fs-12" id="cost-breakdown-button">
                {usage.isLoading ? (
                  <StyledLoader>
                    <div>
                      <Loader size={16} className="p-0" />
                    </div>
                  </StyledLoader>
                ) : (
                  <>
                    <div className="steel mb-5">{t('quotaInfo.total')}</div>
                    <div className="bold">
                      {numberToCurrency(usage.data?.cost?.total, false)}
                    </div>
                  </>
                )}
              </Col>
            </StyledCostHolder>
          </Col>
        )}
      </Row>
      <UsageInfoBox
        id={id}
        accountId={currentUser.data?.tenant?.id}
        open={open}
        showTabs
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>,

    props.portal
  );
});

export const QuotasInfo = (props: IQuotas.IProps) => (
  <View {...props} {...OBSERVERS} />
);
