import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import {
  Col,
  Row,
  Button,
  Loader,
  Alert,
  TablePagination,
  Input,
} from 'elements';
import {
  noop,
  confirm,
  dayjs,
  utcDayjs,
  debounce,
  showSystemMessage,
} from 'utils';
import { InjectedPermissionsProps, withPermissions } from 'hocs';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { rdsService } from 'services';
import {
  useForceUpdate,
  useQuery,
  useState,
  useAsync,
  useTask,
  useUserHash,
} from 'hooks';
import { SoftwarePackageItem, PlanRebootDialog } from 'components';
import {
  ISoftwarePackage,
  ISoftwarePackageMerged,
  IInstallSoftwarePackageValues,
} from 'app-store';
import { AnyFunc, AnyShape } from 'global-shapes';
import { ICreateSoftwarePackageValues } from 'app-store';
import { reformatPackageValuesForForm } from './helpers';
import Filters from './Filters';
import AppStoreDetailsDialog from './AppStoreDetailsDialog';
import SoftwarePackageInfoDialog from './SoftwarePackageInfoDialog';
import InstallPackageDialog from './InstallPackageDialog';
import { resetTimeValue } from 'components/RebootSchedules/helpers';
import { NoInstallAlert } from './Styled';

type IState = {
  openedDialog: IDialogType;
  values: any;
  currentPackage: ISoftwarePackage | null;
};

const INITIAL_STATE: IState = {
  openedDialog: null,
  values: null,
  currentPackage: null,
};

const OBSERVERS = {
  softwarePackages: StateHandlers.softwarePackages,
  tenantPackages: StateHandlers.vmPackages,
  rds: StateHandlers.rds,
  taskManager: StateHandlers.taskManager,
  activeRdsUsers: StateHandlers.activeRdsUsers,
};

type Props = {
  vmId: number;
  isTenantView?: boolean;
  ableToInstall: boolean;
  isEditable?: boolean;
};

type IDialogType = 'details' | 'info' | 'install' | 'maintenance' | null;
type IViewProps = typeof OBSERVERS;

export const View = observer(
  (props: Props & InjectedPermissionsProps & IViewProps) => {
    const {
      vmId,
      isCurrentProvider,
      isCurrentTenant,
      permissions,
      ableToInstall,
      isEditable,
      isTenantView,
      softwarePackages,
      tenantPackages,
      rds,
      taskManager,
      activeRdsUsers,
    } = props;
    const [state, handleStateChange] = useState<IState>(INITIAL_STATE);
    const { t } = useTranslation();
    const { query, queryStr, changeQuery } = useQuery();
    const searchRef = React.useRef({ value: query.q || '' });
    const forceUpdate = useForceUpdate();
    const [, navigate] = useUserHash();
    const rdsTask = useTask(rds.data?.task, { onComplete: () => rds.get() });
    const { execute: upgradeApps, isPending } = useAsync(
      rdsService.upgradeApps
    );

    const avaliableRdsUsersCount = activeRdsUsers.meta?.totalCount || 0;

    const hasPlannedReboot = !!rds.data?.maintenanceWindows?.find(
      (w) => w.taskOperationName === 'RdsHostAppsUpgrade'
    );

    const data = softwarePackages.data.map((p: ISoftwarePackage) => {
      return {
        ...p,
        task: p.installation?.task,
        licenseKey: p.installation?.licenseKey,
        providersLicenseUnits: p.installation?.providersLicenseUnits,
        isInstalled: p.installation && !isEmpty(p.installation),
      };
    }) as ISoftwarePackageMerged[];

    const isNew = state.values === null;

    const fetchSoftwarePackages = useCallback(
      (q: AnyShape) =>
        softwarePackages.get({
          ...q,
          orderBy: 'name',
          vmId: isTenantView ? vmId : undefined,
          isInstalled:
            query.isInstalled !== 'none' ? query.isInstalled : undefined,
          licensePricingType:
            query.licensePricingType !== 'all'
              ? query.licensePricingType
              : undefined,
        }),
      [isTenantView, vmId, permissions.canView, queryStr]
    );

    const createSoftwarePackage = useCallback(
      (payload: ICreateSoftwarePackageValues) => {
        isNew
          ? softwarePackages.create(payload).then(() => {
              fetchSoftwarePackages(query);
              handleStateChange(INITIAL_STATE);
            })
          : softwarePackages.update(state.values.id, payload).then(() => {
              fetchSoftwarePackages(query);
              handleStateChange(INITIAL_STATE);
            });
      },
      [isNew, state.values, queryStr]
    );

    const deleteSoftwarePackage = useCallback(
      (pac: ISoftwarePackage) => {
        if (pac.activeInstallations) {
          return showSystemMessage(
            'softwarePackages.notify.delete.hasInstalations',
            'error'
          );
        }

        return confirm({
          title: 'softwarePackages.dialog.confirm.delete.title',
          content: 'softwarePackages.dialog.confirm.delete.content',
          onSuccess: () => {
            softwarePackages.remove(pac.id).then(() => {
              handleStateChange(INITIAL_STATE);
              return fetchSoftwarePackages(query);
            });
          },
          onCancel: () => undefined,
        });
      },
      [queryStr]
    );

    const onInstallPackage = useCallback(
      (install: boolean): AnyFunc =>
        (values: Partial<IInstallSoftwarePackageValues>, pac: any) => {
          tenantPackages
            .create({
              install,
              vmId,
              packageId: pac.id,
              values,
            })
            .then(() => {
              handleStateChange(INITIAL_STATE);
              return fetchSoftwarePackages(query);
            })
            .catch((err) => showSystemMessage(err.message, 'error'));
        },
      [vmId, queryStr]
    );

    const onMaintenanceSave = React.useCallback(
      async (values: IRebootDialogTypes.Values) => {
        const isForced = values.configureType === 'now';
        const startDate = resetTimeValue(dayjs(values.startDate));

        await upgradeApps({
          message: '',
          notificationEnabled: values.notificationEnabled || false,
          isForced,
          startAt: utcDayjs(startDate).toISOString(),
          recurringInterval:
            values.recurringInterval?.value === 'SINGLE' // as backend doesnt have SINGLE interval value, we need pass undefined instead
              ? undefined
              : values.recurringInterval?.value,
        });
        handleStateChange(INITIAL_STATE);
        await rds.get();
        await fetchSoftwarePackages(query);
        return taskManager.get();
      },
      [queryStr]
    );

    const onUpgradeClick = React.useCallback(() => {
      hasPlannedReboot
        ? navigate('/services/rds/reboot')
        : handleStateChange({ openedDialog: 'maintenance' });
    }, [hasPlannedReboot]);

    React.useEffect(() => {
      fetchSoftwarePackages(query);
    }, [queryStr]);

    React.useEffect(() => {
      if (softwarePackages.dataReceived) {
        searchRef.current.value = query.q || '';
        forceUpdate();
      }
    }, [softwarePackages.dataReceived]);

    React.useEffect(() => {
      if (!activeRdsUsers.dataReceived) {
        activeRdsUsers.get();
      }
    }, [activeRdsUsers.dataReceived]);

    const changeSearchUrl = React.useCallback(
      (q: string) => changeQuery({ ...query, q, page: 1 }),
      [queryStr, query]
    );

    const onItemSearch = React.useCallback(
      debounce(
        (ev: React.ChangeEvent<HTMLInputElement>) =>
          changeSearchUrl(ev.target.value),
        1000
      ),
      [queryStr, query]
    );

    const isUpgredable = isTenantView && !!data.length;

    if (!softwarePackages.dataReceived) {
      return <Loader />;
    }

    if (softwarePackages.errors.get) {
      return <Alert severity="error">{softwarePackages.errors.get}</Alert>;
    }

    if (isCurrentTenant && !vmId) {
      return <Alert severity="error">{t('softwarePackages.norRdsVm')}</Alert>;
    }

    const isUpgrading =
      isPending || softwarePackages.isRequesting || rdsTask.isTaskActive;

    return (
      <div
        className={cn('pt-10 full-width', {
          disabled: isUpgrading,
        })}
      >
        <Row
          justifyContent="space-between"
          alignItems="center"
          className="pb-25"
        >
          <h3>{t('softwarePackages.title')}</h3>
          {isCurrentProvider && permissions.canManage && (
            <Button
              onClick={() => handleStateChange({ openedDialog: 'details' })}
            >
              {t('softwarePackages.buttons.addApp')}
            </Button>
          )}
          {isUpgredable && (
            <Button onClick={onUpgradeClick} disabled={isUpgrading}>
              {t('softwarePackages.buttons.upgradeAll')}
            </Button>
          )}
        </Row>
        {!ableToInstall && (
          <NoInstallAlert severity="info" className="mb-20">
            {t('softwarePackages.alerts.isPoweredOff')}
          </NoInstallAlert>
        )}
        <Input
          ref={searchRef}
          type="search"
          placeholder={`softwarePackages.inputs.search.placeholder`}
          inputClassName={cn('bg-white')}
          className="mb-25"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
        {isTenantView && <Filters />}

        {data.length ? (
          <>
            <Row columnSpacing={2} rowSpacing={2} className="full-width">
              {data.map((_package) => {
                return (
                  <Col xs={12} md={6} key={_package.id}>
                    <SoftwarePackageItem
                      {..._package}
                      ableToInstall={ableToInstall}
                      ableToUninstall={_package.isUninstallable}
                      permissions={permissions}
                      isEditable={isEditable}
                      onEdit={() =>
                        handleStateChange({
                          openedDialog: 'details',
                          values: {
                            ...reformatPackageValuesForForm(_package),
                            id: _package.id,
                          },
                        })
                      }
                      onInstall={() =>
                        handleStateChange({
                          openedDialog: 'info',
                          currentPackage: _package,
                        })
                      }
                      showDetails={() =>
                        handleStateChange({
                          openedDialog: 'info',
                          currentPackage: _package,
                        })
                      }
                      onUninstall={() =>
                        confirm({
                          title: t(
                            'softwarePackages.dialog.confirm.uninstall.title',
                            { title: _package.name }
                          ),
                          content: t(
                            'softwarePackages.dialog.confirm.uninstall.content',
                            { title: _package.name }
                          ),
                          onSuccess: () =>
                            onInstallPackage(false)({}, _package),
                          onCancel: noop,
                        })
                      }
                      onEnableSuccess={() => fetchSoftwarePackages(query)}
                      onTaskCompleted={() => fetchSoftwarePackages(query)}
                      onDelete={() => deleteSoftwarePackage(_package)}
                    />
                  </Col>
                );
              })}
            </Row>
            <TablePagination
              shouldUseQuery
              perPage={query.perPage}
              page={query.page}
              totalCount={softwarePackages.meta?.totalCount}
              onChange={({ page }) => {
                changeQuery({ page });
              }}
            />
          </>
        ) : (
          <div className="text-center p-30 steel fs-14">
            {t('softwarePackages.noData')}
          </div>
        )}
        <AppStoreDetailsDialog
          onSave={createSoftwarePackage}
          title={t(
            `softwarePackages.dialog.title.${isNew ? 'create' : 'edit'}`
          )}
          open={state.openedDialog === 'details'}
          initialValues={state.values}
          isNew={isNew}
          onClose={() => handleStateChange(INITIAL_STATE)}
        />

        <SoftwarePackageInfoDialog
          open={state.openedDialog === 'info'}
          currentPackage={state.currentPackage as ISoftwarePackageMerged}
          permissions={permissions}
          isTenant={isCurrentTenant}
          onInstall={(pac) => {
            if (!pac.licenseSettings) {
              return confirm({
                title: t('softwarePackages.dialog.confirm.install.title', {
                  title: state.currentPackage?.name,
                }),
                content: 'softwarePackages.dialog.confirm.install.content',
                onSuccess: () => onInstallPackage(true)({}, pac),
                onCancel: noop,
              });
            }
            return handleStateChange({
              openedDialog: 'install',
              currentPackage: pac,
            });
          }}
          onClose={() => handleStateChange(INITIAL_STATE)}
          onSave={() => undefined}
        />

        <InstallPackageDialog
          open={state.openedDialog === 'install'}
          currentPackage={state.currentPackage as ISoftwarePackage}
          avaliableRdsUsersCount={avaliableRdsUsersCount}
          onSave={onInstallPackage(true)}
          onClose={() => handleStateChange(INITIAL_STATE)}
        />

        <PlanRebootDialog
          title={t('services.rds.packages.maintenance.dialog.title')}
          subtitle={t('services.rds.packages.maintenance.dialog.subtitle')}
          open={state.openedDialog === 'maintenance'}
          onSave={onMaintenanceSave}
          onClose={() => handleStateChange(INITIAL_STATE)}
        />
      </div>
    );
  }
);

export const AppStoreComponent = (props: Props & InjectedPermissionsProps) => (
  <View {...props} {...OBSERVERS} />
);

const AppStore = withPermissions<Props>(AppStoreComponent, 'SERVICES');

AppStore.defaultProps = {
  ableToInstall: true,
  isEditable: true,
};

export default AppStore;
