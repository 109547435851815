import React, { useCallback, useMemo } from 'react';
import {
  Col,
  MuiIcons,
  Row,
  Tooltip,
  List,
  ListItemButton,
  IconButton,
  Popover,
} from 'elements';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import cn from 'classnames';
import ImagePlaceholder from '../ImagePlaceholder';
import { ISoftwarePackageMerged } from 'app-store';
import { compact, confirm, noop, remCalc } from 'utils';
import { AnyFunc } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { softwarePackagesService } from 'services';
import { useAsync, usePermissions, useTask } from 'hooks';
import { DESCRIPTION_LETTERS_LIMIT } from './constants';
import { stripHtml } from './helpers';
import { Permission } from 'auth-shapes';
import LicenseListDisplay from './LicenseListDisplay';
import { ItemPaper, ItemContent, Description } from './Styled';

type Props = ISoftwarePackageMerged & {
  onEnableSuccess?: AnyFunc;
  showDetails: AnyFunc;
  ableToInstall: boolean;
  isEditable?: boolean;
  onEdit: AnyFunc;
  onInstall: AnyFunc;
  onUninstall: AnyFunc;
  onTaskCompleted: AnyFunc;
  onDelete: AnyFunc;
  permissions: Permission;
};

const SoftwarePackageItem = (props: Props) => {
  const {
    ableToUninstall,
    isInstalled,
    permissions,
    onTaskCompleted,
    ableToInstall,
    isEditable,
    installation,
    onDelete,
  } = props;
  const { t } = useTranslation();
  const { isCurrentProvider, isCurrentTenant } = usePermissions();
  const hasInstalations = !!installation;
  const task = useTask(props.task, { onComplete: onTaskCompleted });

  // TODO refactor to use reducer later
  const onEnableRequest = useCallback(async (enabled: boolean) => {
    const res = !enabled
      ? await softwarePackagesService.enableSoftwarePackage(props.id)
      : await softwarePackagesService.disableSoftwarePackage(props.id);

    props.onEnableSuccess && props.onEnableSuccess(res);
    return res;
  }, []);

  const { execute: onEnable, isPending: isEnabling } =
    useAsync(onEnableRequest);

  const licenses = props.licenseSettings
    ? compact(
        Object.keys(props.licenseSettings).map((key: string) =>
          // @ts-ignore
          props.licenseSettings[key] ? key : undefined
        )
      )
    : [];

  const onConfirmEnable = useCallback(
    () =>
      confirm({
        title: t('softwarePackages.dialog.confirm.enable.title'),
        content: t('softwarePackages.dialog.confirm.enable.content'),
        onSuccess: () => onEnable(props.isEnabled),
        onCancel: noop,
      }),
    [props.isEnabled]
  );

  const onConfirmDisable = useCallback(
    () =>
      confirm({
        title: t('softwarePackages.dialog.confirm.disable.title'),
        content: t('softwarePackages.dialog.confirm.disable.content'),
        onSuccess: () => onEnable(props.isEnabled),
        onCancel: noop,
      }),
    [props.isEnabled]
  );

  const description = useMemo(
    () => stripHtml(props.description),
    [props.description]
  );

  const hasViewMore = useMemo(
    () => description.length > DESCRIPTION_LETTERS_LIMIT,
    [description]
  );

  const _ableToUninstall = useMemo(() => {
    if (!ableToUninstall) {
      return false;
    }

    if (!props.isEnabled && !hasInstalations) {
      return true;
    }

    return ableToUninstall;
  }, [ableToUninstall, props.isEnabled, hasInstalations]);

  return (
    <ItemPaper
      className={cn('pt-30 pr-20 pb-25 pl-30 full-height', {
        disabled: isEnabling || task.isTaskActive,
      })}
    >
      {isCurrentProvider && (
        <>
          {!props.isEnabled && <div className="_disable-layer" />}
          <Row
            direction="column"
            justifyContent="space-between"
            className="full-height"
          >
            <Col xs>
              <Row
                justifyContent="space-between"
                className="mb-20"
                columnSpacing={1}
              >
                <ItemContent xs className="relative">
                  <h4 className="break-line-all mb-10 pr-15">{props.name}</h4>
                  <Description
                    hasViewMore={hasViewMore}
                    className={cn('mb-10')}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: props.description }}
                      className="_html"
                    />
                    {hasViewMore && (
                      <span
                        onClick={() => props.showDetails()}
                        className="_viewMore"
                      >
                        <span className="primary pointer">
                          {t('common.viewMore')}
                        </span>
                      </span>
                    )}
                  </Description>
                  <LicenseListDisplay {...props} />
                </ItemContent>
                <Col style={{ width: remCalc(86) }}>
                  <ImagePlaceholder
                    src={props.icon && props.icon.link}
                    size={86}
                  />
                </Col>
                {permissions.canManage && (
                  <Col className="pl-15">
                    <PopupState variant="popover">
                      {(popupState) => (
                        <>
                          <IconButton
                            size="small"
                            className="relative"
                            style={{ zIndex: 2 }}
                            {...bindTrigger(popupState)}
                          >
                            <MuiIcons.MoreVert className="fs-20 steel pointer" />
                          </IconButton>
                          <Popover {...bindMenu(popupState)}>
                            <List>
                              {props.isEnabled ? (
                                <ListItemButton
                                  onClick={() => {
                                    popupState.close();
                                    onConfirmDisable();
                                  }}
                                >
                                  {t('common.disable')}
                                </ListItemButton>
                              ) : (
                                <ListItemButton
                                  onClick={() => {
                                    popupState.close();
                                    onConfirmEnable();
                                  }}
                                >
                                  {t('common.enable')}
                                </ListItemButton>
                              )}

                              <ListItemButton
                                onClick={() => {
                                  popupState.close();
                                  onDelete();
                                }}
                              >
                                {t('common.delete')}
                              </ListItemButton>

                              <ListItemButton
                                onClick={() => {
                                  popupState.close();
                                  props.onEdit();
                                }}
                              >
                                {t('common.edit')}
                              </ListItemButton>
                            </List>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Col>
                )}
              </Row>
            </Col>
            <Col>
              <Row alignItems="center" columnSpacing={4}>
                <Col>
                  <small>
                    <span className="steel">
                      {t('softwarePackages.installations')}:{' '}
                    </span>
                    <span>{props.activeInstallations || 0}</span>
                  </small>
                </Col>
                <Col>
                  <small>
                    <span className="steel">
                      {t('softwarePackages.users')}:{' '}
                    </span>
                    <span>{props.licensedUsers || 0}</span>
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {isCurrentTenant && (
        <>
          <Row className="mb-10" columnSpacing={2}>
            <Col>
              <div style={{ width: remCalc(86) }}>
                <ImagePlaceholder
                  src={props.icon && props.icon.link}
                  size={86}
                />
              </div>
            </Col>
            <Col xs>
              <h4 className="break-line-all">{props.name}</h4>
              <small className="steel">
                {licenses
                  .map((license) => t(`softwarePackages.license.${license}`))
                  .join(', ')}
              </small>
            </Col>

            {isEditable && permissions.canManage && (
              <Col>
                <Row alignItems="center">
                  {task.isFailed && (
                    <Col>
                      <Tooltip
                        title={task.message || task.error?.message}
                        placement="top"
                        arrow
                      >
                        <MuiIcons.Cancel
                          color="error"
                          className="fs-18 mr-10 mt-5 pointer"
                        />
                      </Tooltip>
                    </Col>
                  )}
                  <Col>
                    <PopupState variant="popover">
                      {(popupState) => (
                        <>
                          <IconButton size="small" {...bindTrigger(popupState)}>
                            <MuiIcons.MoreVert className="fs-20 steel pointer" />
                          </IconButton>
                          <Popover {...bindMenu(popupState)}>
                            <List>
                              {!isInstalled ? (
                                <ListItemButton
                                  onClick={() => {
                                    popupState.close();
                                    props.showDetails();
                                  }}
                                  disabled={!ableToInstall}
                                >
                                  {t('common.install')}
                                </ListItemButton>
                              ) : (
                                _ableToUninstall && (
                                  <ListItemButton
                                    onClick={() => {
                                      popupState.close();
                                      props.onUninstall();
                                    }}
                                    disabled={!ableToInstall}
                                  >
                                    {t('common.uninstall')}
                                  </ListItemButton>
                                )
                              )}
                            </List>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Description hasViewMore={hasViewMore}>
            <div
              dangerouslySetInnerHTML={{ __html: props.description }}
              className="_html"
            />
            {hasViewMore && (
              <span onClick={() => props.showDetails()} className="_viewMore">
                <span className="primary pointer">{t('common.viewMore')}</span>
              </span>
            )}
          </Description>
        </>
      )}
    </ItemPaper>
  );
};

export default SoftwarePackageItem;
