import * as React from 'react';
import {
  Button,
  Input,
  InputPhone,
  MuiIcons,
  Tooltip,
  Dialog,
  DialogProps,
  Select,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useStateHandler, useState } from 'hooks';
import * as StateHandlers from 'states';
import { COUNTRIES } from './constants';
import {
  customerInvoiceValidationSchema,
  customerInvoiceValidationSchemaForOthers,
} from '../../../constants';
import { parseCustomerInvoiceSettingsForForm } from '../../../helpers';

type IProps = DialogProps<IPartnerTypes.ICustomerInvoiceValues> & {
  isNew: boolean;
};

type ISchema = {
  schema: any;
};

const CustomerInvoice = observer((props: IProps) => {
  const { open, onClose, onSave, isNew } = props;
  const { t } = useTranslation();
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const [state, handleState] = useState<ISchema>({
    schema: customerInvoiceValidationSchema,
  });

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    submitCount,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: parseCustomerInvoiceSettingsForForm(
      currentUser.data?.partner as IPartnerTypes.Partner
    ),
    validationSchema: state.schema,
    onSubmit: onSave,
  });

  const isOther = values.countryCode?.value === 'other';

  React.useEffect(() => {
    handleState({
      schema: isOther
        ? customerInvoiceValidationSchemaForOthers
        : customerInvoiceValidationSchema,
    });
  }, [isOther]);

  return (
    <Dialog
      title={t(
        `settings.page.customer-invoice.dialog.${isNew ? 'add' : 'edit'}.title`
      )}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="outlined" color="default">
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="steel mb-30">
        {t('settings.page.customer-invoice.dialog.edit.content') as string}
      </div>
      <Select
        label="forms.country"
        value={values.countryCode}
        options={COUNTRIES}
        onChange={(countryCode) => setFieldValue('countryCode', countryCode)}
        className="mb-25"
      />
      <Input
        label={t('forms.email')}
        value={values.email}
        name="email"
        placeholder={t('forms.placeholders.email')}
        className="mb-25"
        onChange={handleChange}
        helperText={errors.email}
        helperTextOptions={{ max: 60 }}
        error={!!submitCount && !!errors.email}
      />
      <InputPhone
        label={t('forms.phone')}
        placeholder="+0000000000"
        value={values.phone}
        name="phone"
        className="mb-25"
        onChange={(val) => {
          setFieldValue('phone', val);
        }}
        helperText={errors.phone}
        error={!!submitCount && !!errors.phone}
      />

      {!isOther ? (
        <>
          <Input
            label={t('forms.vatId')}
            placeholder="CHE-123.456.789 MWST"
            value={values.vatId}
            name="vatId"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.vatId}
            error={!!submitCount && !!errors.vatId}
          />
          <Input
            label={t('forms.ibanQR')}
            placeholder="CH00 0000 0000 0000 0000 0"
            labelIcon={
              <Tooltip
                title={
                  t('settings.page.customer-invoice.tooltip.iban') as string
                }
                placement="top"
              >
                <sup>
                  <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                </sup>
              </Tooltip>
            }
            value={values.iban}
            name="iban"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.iban}
            error={!!submitCount && !!errors.iban}
          />
        </>
      ) : (
        <>
          <Input
            label={t('forms.iban')}
            placeholder="CH00 0000 0000 0000 0000 0"
            labelIcon={
              <Tooltip
                title={
                  t('settings.page.customer-invoice.tooltip.iban') as string
                }
                placement="top"
              >
                <sup>
                  <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                </sup>
              </Tooltip>
            }
            value={values.iban}
            name="iban"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.iban}
            error={!!submitCount && !!errors.iban}
          />

          <Input
            label={t('forms.bankAddress')}
            placeholder="forms.placeholders.bankAddress"
            multiline
            value={values.bankDetails}
            name="bankDetails"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.bankDetails}
            error={!!submitCount && !!errors.bankDetails}
            helperTextOptions={{
              max: 200,
            }}
          />

          <Input
            label={t('forms.bankAccountNumber')}
            placeholder="forms.placeholders.bankAccountNumber"
            multiline
            value={values.bankAccount}
            name="bankAccount"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.bankAccount}
            error={!!submitCount && !!errors.bankAccount}
            helperTextOptions={{
              max: 30,
            }}
          />

          <Input
            label={t('forms.swift.bic')}
            placeholder="forms.placeholders.swift.bic"
            value={values.bankIdentificationCode}
            name="bankIdentificationCode"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.bankIdentificationCode}
            error={!!submitCount && !!errors.bankIdentificationCode}
            helperTextOptions={{
              max: 11,
              min: 8,
            }}
          />
        </>
      )}
    </Dialog>
  );
});

export default CustomerInvoice;
