import React from 'react';
import {
  styled,
  Dialog,
  DialogProps,
  Input,
  Button,
  Switch,
  Tooltip,
  DateTimePicker,
  MuiIcons,
} from 'elements';
import { ISnapshotEntityType } from 'snapshots';
import { useFormik } from 'formik';
import { dayjs, findDisabledHoursMin } from 'utils';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type ISnapshotAction = 'create' | 'revert' | 'remove';

type Props = DialogProps<{ description: string }> & {
  serviceEntityName: ISnapshotEntityType;
  action: ISnapshotAction;
};

export const snapshotInputValidationSchema = Yup.object({
  description: Yup.string().trim().max(100, 'forms.invalid.max'),
});

export const SPickerWrapper = styled('div')(() => ({
  maxWidth: 210,
}));

const SnapshotDialog = (props: Props) => {
  const { onSave, serviceEntityName, open, action, ...rest } = props;
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      description: '',
      removalEnabled: true,
      removalOn: dayjs().add(7, 'days').add(1, 'hours').set('minutes', 0),
    },
    validationSchema: snapshotInputValidationSchema,
    validateOnMount: false,
    onSubmit: (val) => {
      const payload = val.removalEnabled
        ? {
            description: val.description,
            removalOn: val.removalOn,
          }
        : { description: val.description };
      return Promise.resolve(onSave && onSave(payload));
    },
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      title={t(`snapshots.confirm.${serviceEntityName}.${action}.title`)}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit">{t('common.save')}</Button>
        </>
      }
    >
      <div className="mb-20 steel">
        {t(`snapshots.confirm.${serviceEntityName}.${action}.content`)}
      </div>
      <div className="mb-25">
        <Input
          label={t('forms.snapshotNotes')}
          name="description"
          placeholder="forms.placeholders.snapshotNotes"
          aria-autocomplete="none"
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
          }}
          value={values.description}
          onChange={handleChange}
          error={!!errors.description}
          helperText={errors.description}
          helperTextOptions={{ max: 100 }}
        />
      </div>
      <div className="mb-25">
        <Switch
          label={
            <div className="flex flex-center">
              {t('forms.snapshotsAutoRemoval')}{' '}
              <sup className="ml-10">
                <Tooltip
                  title={t('snapshots.tooltips.autoRemoval')}
                  placement="top"
                  arrow
                >
                  <MuiIcons.InfoOutlined className="fs-14 primary" />
                </Tooltip>
              </sup>
            </div>
          }
          checked={values.removalEnabled}
          onCheck={(removalEnabled) =>
            setFieldValue('removalEnabled', removalEnabled)
          }
        />
      </div>
      {values.removalEnabled && (
        <SPickerWrapper>
          <DateTimePicker
            label={t('forms.startDate')}
            minimumDate={dayjs().toDate()}
            maximumDate={dayjs().add(14, 'days').toDate()}
            value={values.removalOn}
            onChange={(val) => {
              setFieldValue('removalOn', val);
            }}
            closeOnSelect
            minuteStep={60}
            showTime={false}
            showMinute={false}
            disabledTime={() => {
              return {
                disabledHours: findDisabledHoursMin(
                  values.removalOn.add(1, 'hours')
                ),
              };
            }}
          />
        </SPickerWrapper>
      )}
    </Dialog>
  );
};

export default SnapshotDialog;
