import React from 'react';
import { useFormik } from 'formik';
import { IInvoice } from 'invoices';
import { useTranslation } from 'react-i18next';
import { ICurrentUser } from 'auth-shapes';
import {
  Dialog,
  DialogProps,
  Button,
  Select,
  Switch,
  Row,
  Col,
  DatePicker,
} from 'elements';
import { dayjs } from 'utils';
import { invoiceSchema } from './constants';
import {
  onPartnersLoad,
  onTenantsLoad,
  parseInvoiceForEditValues,
} from './helpers';
import { IInvoiceTypes } from './types';

type Props = DialogProps<IInvoiceTypes.NewInvoice> & {
  currentAccessLevel: 'provider' | 'partner' | 'tenant';
  isRequesting: boolean;
  isNew: boolean;
  initialValues?: IInvoice | null;
  account?: ICurrentUser;
};

const AddInvoiceDialog = (props: Props) => {
  const {
    isRequesting,
    currentAccessLevel,
    isNew,
    initialValues,
    open,
    account,
  } = props;
  const { t } = useTranslation();

  const type = isNew ? 'create' : 'edit';

  const initials: IInvoiceTypes.NewInvoice = {
    userId: undefined,
    date: dayjs(),
    dueDate: dayjs().add(14, 'day'),
    autoSend: true,
  };

  const userLabelName = React.useMemo(() => {
    return currentAccessLevel === 'provider' ? 'partner' : 'tenant';
  }, [currentAccessLevel]);

  const onUsersLoad = React.useMemo(() => {
    return currentAccessLevel === 'provider' ? onPartnersLoad : onTenantsLoad;
  }, [currentAccessLevel]);

  const isTenant = currentAccessLevel === 'tenant';

  const {
    handleSubmit,
    setFieldValue,
    submitCount,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initials,
    validateOnMount: false,
    validationSchema: invoiceSchema,
    onSubmit: props.onSave,
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    } else {
      setValues(parseInvoiceForEditValues(initialValues, isTenant, account));
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={props.onClose}
      fullWidth
      title={t(`invoices.${type}Invoice.dialog.title`)}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isRequesting}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="steel mb-25">
        {t(`invoices.${type}Invoice.dialog.content`)}
      </div>
      {!isTenant && (
        <div className="mb-25">
          <Select
            useAsync
            defaultOptions
            isDisabled={!isNew}
            label={t(`forms.${userLabelName}`)}
            value={values.userId}
            onLoad={onUsersLoad}
            onChange={(val) => {
              if (val.dueDate) {
                setFieldValue(
                  'dueDate',
                  values.date.startOf('day').add(val.dueDate, 'day')
                );
              }
              setFieldValue('userId', val);
            }}
            error={!!submitCount && !!errors.userId}
            helperText={errors.userId as string}
          />
        </div>
      )}

      <Row columnSpacing={2} className="mb-30">
        <Col xs={6}>
          <DatePicker
            closeOnSelect
            label={t('forms.invoiceDate')}
            onChange={(date) => {
              const newDate = date.startOf('day');
              const isLater = +newDate > +values.dueDate;
              if (isLater) {
                setFieldValue(
                  'dueDate',
                  date.startOf('day').add(values.userId?.dueDate || 14, 'day')
                );
              }
              return setFieldValue('date', date.startOf('day'));
            }}
            disabled={!isNew}
            minimumDate={dayjs().startOf('day').toDate()}
            value={values.date}
            error={!!submitCount && !!errors.date}
            helperText={errors.date as string}
          />
        </Col>
        <Col xs={6}>
          <DatePicker
            label={t('forms.dueDate')}
            onChange={(date) => setFieldValue('dueDate', date.endOf('day'))}
            minimumDate={dayjs().startOf('day').toDate()}
            maximumDate={values.date.add(90, 'day').endOf('day').toDate()}
            value={values.dueDate}
            error={!!submitCount && !!errors.dueDate}
            helperText={errors.dueDate as string}
          />
        </Col>
      </Row>
      {isNew && (
        <Switch
          label={t('forms.invoiceAutoSend')}
          checked={values.autoSend}
          onCheck={(autoSend) => setFieldValue('autoSend', autoSend)}
        />
      )}
    </Dialog>
  );
};

export default AddInvoiceDialog;
