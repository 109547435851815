import { remCalc } from 'utils';
import { styled, Switch, Col, Dialog, MuiIcons } from 'elements';
import BaseLinearStepper from 'pages/services/components/Stepper';

export const CreateVmDialog = styled(Dialog)(() => ({
  '& ._dialog-paper': {
    minHeight: remCalc(945),
  },
}));

export const FormSwitcher = styled(Switch)(() => ({
  height: remCalc(25),
}));

export const StepperContent = styled(Col)(() => ({
  minHeight: remCalc(695),
}));

export const LinearStepper = styled(BaseLinearStepper)(() => ({
  justifyContent: 'flex-start',
  '& .MuiStep-root': {
    marginRight: remCalc(50),
  },
}));

export const InfoLabelSup = styled(MuiIcons.HelpOutline)(({ theme }) => ({
  fontSize: remCalc(12),
  position: 'relative',
  top: remCalc(-3),
  color: theme.palette.primary.main,
}));
