import { remCalc } from 'utils';
import { EditableText } from 'components';
import { styled, Paper, Alert } from 'elements';

export const MaintenanceTabContent = styled('div')(() => ({
  maxWidth: remCalc(600),
}));

export const MonitoringHolder = styled(Paper)(() => ({
  minHeight: `calc(100vh - 310px)`,
}));

export const PatchingHolder = styled(Paper)(() => ({
  minHeight: `calc(100vh - 310px)`,
}));

export const DiskSizeHolder = styled('div')(() => ({
  height: remCalc(35),
  display: 'flex',
  alignItems: 'center',
}));

export const SEditableText = styled(EditableText)(({ theme }) => ({
  '& ._displayText': {
    color: theme.palette.primary.main,
  },
}));

export const SnapshotAlert = styled(Alert)(() => ({
  '& ._text': {
    position: 'relative',
    paddingRight: remCalc(180),
  },
  '& ._button': {
    position: 'absolute',
    right: remCalc(10),
  },
}));
