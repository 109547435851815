import React from 'react';
import { Button, Dialog, DialogProps, Input, Row } from 'elements';
import { useFormik } from 'formik';
import { validator } from 'utils';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { BlacklistDomainCreatePayload } from 'blacklist';

type Props = DialogProps<BlacklistDomainCreatePayload> & {
  testId: string;
  isSaving: boolean;
};

const validationSchema = Yup.object().shape({
  domainName: Yup.string()
    .required('forms.required')
    .test((val) => validator.isFQDN(val || '')),
});

const INITIALS = {
  domainName: '',
};

const AddDomainDialog = (props: Props) => {
  const { onSave, testId, isSaving, ...rest } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    values,
    errors,
    resetForm,
    handleChange,
    submitCount,
  } = useFormik({
    initialValues: INITIALS,
    validateOnMount: false,
    validationSchema,
    onSubmit: (val) => Promise.resolve(onSave(val)),
  });

  React.useEffect(() => {
    if (!rest.open) {
      resetForm();
    }
  }, [rest.open]);

  return (
    <Dialog
      {...rest}
      testId={testId}
      title={t('settings.page.blacklist.dialog.title')}
      handleSubmit={handleSubmit}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={rest.onClose}
            testId={`${testId}-cancel`}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" testId={`${testId}`} disabled={isSaving}>
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <div className="steel fs-14 mb-20">
        {t('settings.page.blacklist.dialog.subtitle')}
      </div>
      <Input
        id="domainName"
        value={values.domainName}
        name="domainName"
        label="forms.domainName"
        placeholder="forms.placeholders.domainName"
        onChange={handleChange}
        error={!!submitCount && !!errors.domainName}
        helperText={errors.domainName}
      />
    </Dialog>
  );
};

export default AddDomainDialog;
