import { remCalc } from 'utils';
import { styled, Stepper as MStepper } from 'elements';
import { ColorAccumulator } from 'components/ColorAccumulator';

export const Stepper = styled(MStepper)(() => ({
  '&._paper': {
    padding: 0,
    margin: remCalc(0, -8, 40),
  },
}));

export const CostInfoList = styled('div')(({ theme }) => ({
  '& li:before': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const VmIconWrapper = styled('div')(() => ({
  position: 'relative',
  lineHeight: remCalc(12),
  marginRight: remCalc(8),
  marginLeft: remCalc(15),
  '& ._rs-icon': {
    position: 'absolute',
    left: remCalc(9),
    top: remCalc(7),
    zIndex: 1,
    width: remCalc(12),
  },
}));

export const StatusChip = styled(ColorAccumulator)(() => ({
  lineHeight: remCalc(24),
  padding: remCalc(0, 10),
  borderRadius: remCalc(4),
}));
