import * as React from 'react';
import { styled, Col, Paper, Alert } from 'elements';
import { remCalc } from 'utils';

export const ItemPaper = styled(Paper)(() => ({
  lineHeight: 1.5,
  minHeight: remCalc(200),
  position: 'relative',
  '& ._disable-layer': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    // opacity: 0.3,
  },
}));

export const ItemContent = styled(Col)(() => ({
  maxWidth: `calc(100% - ${remCalc(86)})`,
  paddingRight: remCalc(10),
}));

export const Description = styled(
  (props: React.ComponentProps<'div'> & { hasViewMore: boolean }) => {
    const { hasViewMore, ...rest } = props;
    return <div {...rest} />;
  }
)(({ hasViewMore }) => {
  const hasMoreStyle = hasViewMore
    ? {
        minHeight: remCalc(80),
        maxHeight: remCalc(135),
        paddingBottom: remCalc(20),

        '&:after': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: remCalc(75),
          margin: 0,
          backgroundImage:
            'linear-gradient(0deg; rgba(255;255;255;1) 20%; rgba(255;255;255;0) 100%)',
        },
      }
    : {};

  return {
    position: 'relative',
    overflow: 'hidden',
    ...hasMoreStyle,

    '& ._html': {
      overflow: 'hidden',
      maxHeight: remCalc(115),
      '& *': {
        wordBreak: 'break-word',
      },
    },

    '& ._viewMore': {
      position: 'absolute',
      bottom: remCalc(0),
      fontSize: remCalc(14),
      left: 0,
      zIndex: 2,
      lineHeight: 1,
    },
  };
});

export const NoInstallAlert = styled(Alert)(() => ({
  maxWidth: remCalc(500),
}));
