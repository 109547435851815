import * as React from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogProps,
  Row,
  Col,
  Paper,
  Tooltip,
  Checkbox,
  MuiIcons,
} from 'elements';
import { useFormik } from 'formik';
import { useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import SliderWithInputController from 'components/SliderWithInputController';
import { ServiceBulkUpdateBlock } from './ServiceView';
import { selectAllPrice } from './helper';
import { SCheckboxWrapperAbsolute, SInfoLabel, SSliderWrapper } from './Styled';
import { IBulkUpdateProps, IBulkUpdatePayload } from './types';

type IProps = DialogProps<IBulkUpdatePayload> & IBulkUpdateProps;

const validationSchema = Yup.object({
  // @ts-ignore
  percentage: Yup.string()
    .trim()
    .required('forms.required')
    // @ts-ignore
    .test({
      message: 'forms.invalid',
      test: (val: number) => {
        return !!val && !(+val < 0 || +val > 100);
      },
    })
    .max(30, 'forms.invalid.max'),
});

const BulkUpdate = observer((props: IProps) => {
  const { hasParentPrice, data, parentPricing, defaultPrice, onSave } = props;
  const { t } = useTranslation();
  const currentUser = useStateHandler(StateHandlers.currentUser);

  const initials: IBulkUpdatePayload = data.reduce(
    (res, price) => {
      // @ts-ignore
      res[price.id] = true;
      return res;
    },
    { percentage: 0 }
  );

  const onSubmit = React.useCallback(
    (payload: IBulkUpdatePayload) => Promise.resolve(onSave(payload)),
    [onSave]
  );

  const {
    values: _values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resetForm,
    setValues,
    errors,
    submitCount,
  } = useFormik({
    initialValues: initials,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });

  const { percentage, ...values } = _values;

  const isAllPriceSelected = React.useMemo(() => {
    // @ts-ignore
    return Object.keys(values).every((key) => !!values[key]);
  }, [JSON.stringify(values)]);

  const hasNoSelectedPrice = React.useMemo(() => {
    // @ts-ignore
    return Object.keys(values).every((key) => !values[key]);
  }, [JSON.stringify(values)]);

  const isRequesting = isSubmitting || currentUser.isRequesting;

  React.useEffect(() => {
    if (!props.open) {
      resetForm();
    } else {
      setValues(initials);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t('settings.page.pricing.bulk.dialog.title')}
      handleSubmit={handleSubmit}
      showCloseIcon
      fullScreen
      contentProps={{
        style: {
          overflowX: 'visible',
          overflowY: 'inherit',
        },
      }}
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isSubmitting}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting || hasNoSelectedPrice}>
            {t('settings.page.partnerInfo.section.company.buttons.save')}
          </Button>
        </>
      }
    >
      <div className="steel mb-20">
        {t('settings.page.pricing.bulk.dialog.subtitle')}
      </div>
      <SSliderWrapper>
        <SliderWithInputController
          value={+percentage}
          label={`${t(
            'settings.page.pricing.bulk.slider.percentage.label'
          )}, %`}
          labelClassName="bold"
          name="percentage"
          sliderProps={{
            min: 0,
            max: 100,
            defaultValue: 0,
            className: 'mb-0',
            step: 1,
            marks: [
              {
                value: 0,
                label: 0,
              },
              { value: 100, label: 100 },
            ],
          }}
          error={!!submitCount && !!errors.percentage}
          helperText={
            //@ts-ignore
            submitCount ? t(errors.percentage, { max: 100, min: 0 }) : ''
          }
          onInputChange={(ev) => setFieldValue('percentage', ev.target.value)}
          onSliderChange={(ev) =>
            // @ts-ignore
            setFieldValue('percentage', ev.target?.value || 0)
          }
          tooltip={
            <SInfoLabel>
              <Tooltip
                title={t(
                  'settings.page.pricing.bulk.slider.percentage.tooltip'
                )}
                placement="top"
                arrow
              >
                <MuiIcons.HelpOutline />
              </Tooltip>
            </SInfoLabel>
          }
        />
      </SSliderWrapper>

      <Paper className={cn('p-20 mb-15 mt-5')}>
        <Row alignItems="center">
          {hasParentPrice ? (
            <>
              <Col xs={5} className="bold">
                {t('settings.page.pricing.head.name')}
              </Col>
              <Col xs={6}>
                <Row>
                  <Col xs={5}>
                    <div className="bold mb-10">
                      {t('settings.page.pricing.head.yourRetail')}
                    </div>
                    <Row className="steel">
                      <Col xs={6}>
                        {t('settings.page.pricing.head.monthly')}
                      </Col>
                      <Col xs={6}>{t('settings.page.pricing.head.daily')}</Col>
                    </Row>
                  </Col>
                  <Col xs={7}>
                    <div className="bold mb-10">
                      {t('settings.page.pricing.head.customerRetail')}
                    </div>
                    <Row className="steel">
                      <Col xs={5}>
                        {t('settings.page.pricing.head.monthly')}
                      </Col>
                      <Col xs={5}>{t('settings.page.pricing.head.daily')}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={1} className="relative pb-25">
                <div className="bold">
                  {t('settings.page.pricing.head.updatePrice')}
                </div>
                <SCheckboxWrapperAbsolute className="steel">
                  <Checkbox
                    label={t('settings.page.pricing.selectAll')}
                    checked={isAllPriceSelected}
                    onCheck={(on) => setValues(selectAllPrice(_values, on))}
                  />
                </SCheckboxWrapperAbsolute>
              </Col>
            </>
          ) : (
            <>
              <Col xs={5} className="bold">
                {t('settings.page.pricing.head.name')}
              </Col>
              <Col xs={5}>
                <div className="bold mb-10">
                  {t('settings.page.pricing.head.yourRetail')}
                </div>
                <Row className="steel">
                  <Col xs={5}>{t('settings.page.pricing.head.monthly')}</Col>
                  <Col xs={4}>{t('settings.page.pricing.head.daily')}</Col>
                </Row>
              </Col>
              <Col xs={2} className="relative pb-30">
                <div className="bold mb-10">{t('Update price')}</div>
                <SCheckboxWrapperAbsolute className="steel">
                  <Checkbox
                    label="Select all"
                    checked={isAllPriceSelected}
                    onCheck={(on) => setValues(selectAllPrice(_values, on))}
                  />
                </SCheckboxWrapperAbsolute>
              </Col>
            </>
          )}
        </Row>
      </Paper>
      <ServiceBulkUpdateBlock
        data={data}
        parentPricing={parentPricing}
        defaultPrice={defaultPrice}
        hasParentPrice={hasParentPrice}
        checked={values}
        onCheck={(id, on) => {
          setFieldValue(id.toString(), on);
        }}
        percentage={+percentage}
      />
    </Dialog>
  );
});

export default BulkUpdate;
