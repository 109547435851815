import React from 'react';
import {
  Button,
  Dialog,
  DialogProps,
  Row,
  Col,
  Checkbox,
  Alert,
  Switch,
} from 'elements';
import { useFormik } from 'formik';
import { IBackupVersion, ResoreVersionPayload } from 'backup';
import * as Yup from 'yup';
import * as UTILS from 'utils';
import { useTranslation } from 'react-i18next';

type Props = DialogProps<ResoreVersionPayload> & {
  selected?: IBackupVersion | null;
  vms?: IVmTypes.Vm[];
};

const validationSchema = Yup.object().shape({
  vmIds: Yup.array(Yup.number()).min(
    1,
    'services.backup.dialog.target.errors.min'
  ),
});

const INITIALS: ResoreVersionPayload = {
  vmIds: [],
  powerOnAfterRestore: false,
};

const VmToRestoreDialog = (props: Props) => {
  const { onSave, open, onClose, selected, vms, ...rest } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    values,
    errors,
    resetForm,
    setFieldValue,
    submitCount,
    isSubmitting,
  } = useFormik({
    initialValues: INITIALS,
    validateOnMount: false,
    validationSchema,
    onSubmit: async (val) => onSave(val),
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      title={t('services.backup.dialog.target.title')}
      handleSubmit={handleSubmit}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('services.backup.dialog.target.buttons.restore')}
          </Button>
        </Row>
      }
    >
      <div className="steel mb-25">
        {t('services.backup.dialog.target.content')}
      </div>
      <Row columnSpacing={2} rowSpacing={4} className="mb-25">
        {vms?.map((vm) => {
          return (
            <Col key={vm.id} xs={6}>
              <Checkbox
                checked={values.vmIds.includes(vm.id)}
                label={vm.name}
                onCheck={() =>
                  setFieldValue(
                    'vmIds',
                    UTILS.toggleArrayState(values.vmIds, vm.id)
                  )
                }
              />
            </Col>
          );
        })}
      </Row>
      <Switch
        className="mb-20"
        label={t('services.backup.dialog.target.switchers.powerOn')}
        checked={values.powerOnAfterRestore}
        onCheck={(powerOnAfterRestore) =>
          setFieldValue('powerOnAfterRestore', powerOnAfterRestore)
        }
      />
      {!!errors.vmIds && !!submitCount && (
        <Alert severity="error">{t(errors.vmIds)}</Alert>
      )}
    </Dialog>
  );
};

export default VmToRestoreDialog;
