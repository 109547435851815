import * as React from 'react';
import {
  Row,
  Select,
  DateTimePicker,
  Switch,
  Alert,
  Dialog,
  Button,
  DialogProps,
} from 'elements';
import { dayjs } from 'utils';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { BaseDecorator } from 'states/types';
import { MINUTE_STEP } from './constants';
import {
  findDisabledHours,
  findDisabledMinutes,
  parseNextRebootField,
} from './helpers';
import {
  buildInitialState,
  startDateValidationShecma,
  REBOOT_INTERVAL_OPTIONS,
} from './constants';
import { RebootDialogInputsWrapper } from './Styled';

type IProps = DialogProps<IRebootDialogTypes.Values> & {
  subtitle?: string;
  instance: BaseDecorator<IMaintenanceWindow, true>;
  isEnabledMode?: boolean;
  operationName?: IMaintenanceWindow['taskOperationName'];
  initialValues?: IRebootDialogTypes.Values;
};

const RebootDialog = (props: IProps) => {
  const {
    isEnabledMode,
    onSave,
    open,
    onClose,
    subtitle,
    initialValues,
    instance,
    operationName,
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    handleSubmit,
    setFieldValue,
    values,
    resetForm,
    errors,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: buildInitialState(undefined, 'plan'),
    validationSchema: startDateValidationShecma,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: onSave,
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }

    if (open && initialValues) {
      setValues(initialValues);
    }
  }, [open, initialValues]);

  const startDate = values.startDate ? dayjs(values.startDate) : dayjs();

  return (
    <Dialog
      {...rest}
      title={t('services.rds.reboot.dialogs.addReboot.title')}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            disabled={isSubmitting || instance.isRequesting}
          >
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      {subtitle && <div className="steel mb-40">{subtitle}</div>}
      {isEnabledMode && (
        <Alert severity="info" className="mb-25">
          {t('services.rds.maintenance.tab.alerts.modeEnabled')}
        </Alert>
      )}
      <RebootDialogInputsWrapper>
        <div className="mb-15">
          <Select
            label={t('forms.recurring')}
            value={values.recurringInterval}
            onChange={(recurringInterval) =>
              setFieldValue('recurringInterval', recurringInterval)
            }
            options={REBOOT_INTERVAL_OPTIONS}
            isDisabled={operationName === 'RdsHostReconfigure'}
          />
        </div>
        <div className="mb-35">
          <DateTimePicker
            label={t('services.rds.maintenance.select.startDate')}
            maximumDate={dayjs().add(31, 'days').toDate()}
            minimumDate={dayjs().toDate()}
            value={startDate}
            onChange={(val) => {
              setFieldValue('startDate', val);
            }}
            closeOnSelect
            minuteStep={MINUTE_STEP}
            disabled={isEnabledMode}
            disabledTime={() => {
              return {
                disabledHours: findDisabledHours(startDate),
                disabledMinutes: findDisabledMinutes(startDate),
              };
            }}
          />
          {errors.startDate && (
            <div className="error fs-12 pt-5 ">
              {t(errors.startDate as string)}
            </div>
          )}
        </div>
      </RebootDialogInputsWrapper>

      <div className="mb-30">
        <Switch
          name="notificationEnabled"
          checked={values.notificationEnabled}
          onCheck={(notificationEnabled) =>
            setFieldValue('notificationEnabled', notificationEnabled)
          }
          disabled={isEnabledMode}
          label={t(
            'services.rds.reboot.dialogs.addReboot.checkbox.notifyUsers'
          )}
        />
      </div>

      <div>
        {t('services.rds.reboot.dialogs.addReboot.nextReboot')}:{' '}
        {parseNextRebootField(
          values.startDate,
          values.recurringInterval?.value || 'SINGLE'
        )}
      </div>
    </Dialog>
  );
};

export default RebootDialog;
