import React from 'react';
import cn from 'classnames';
import MuiDialog, { DialogProps as MiuDialogProps } from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent, {
  DialogContentProps,
} from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { TestSuffixes } from 'enums';
import { IAutomationTestProps } from 'automation-test';
import { remCalc } from 'utils';
import { FormikHandlers } from 'formik/dist/types';

export type DialogProps<T = unknown, O = any> = MiuDialogProps & {
  actions?: any;
  formClassName?: string;
  onClose: (...args: any[]) => void;
  handleSubmit?: FormikHandlers['handleSubmit'];
  contentProps?: DialogContentProps;
  title?: any;
  showCloseIcon?: boolean;
  onSave: (values: T, other?: O) => any;
};

export const Dialog = (props: DialogProps & IAutomationTestProps) => {
  const {
    title,
    actions,
    children,
    handleSubmit,
    contentProps,
    testId,
    PaperProps,
    formClassName,
    showCloseIcon,
    onSubmit,
    open,
    onSave,
    ...rest
  } = props;
  return (
    <MuiDialog
      scroll="body"
      {...rest}
      open={Boolean(open)}
      PaperProps={{
        ...PaperProps,
      }}
      data-test-id={`${testId}-${TestSuffixes.dialog}`}
    >
      {showCloseIcon && (
        <CloseIcon
          className={cn('steel fs-20 pointer')}
          onClick={rest.onClose}
          style={{
            position: 'absolute',
            top: remCalc(50),
            right: remCalc(42),
          }}
        />
      )}
      {title && <MuiDialogTitle>{title}</MuiDialogTitle>}
      {handleSubmit ? (
        <form
          onSubmit={handleSubmit}
          data-test-id={`${testId}-${TestSuffixes.form}`}
          className={cn(formClassName)}
          autoComplete="off"
        >
          {children && (
            <MuiDialogContent {...contentProps}>{children}</MuiDialogContent>
          )}
          {actions && <MuiDialogActions>{actions}</MuiDialogActions>}
        </form>
      ) : (
        <>
          {children && (
            <MuiDialogContent {...contentProps}>{children}</MuiDialogContent>
          )}
          {actions && <MuiDialogActions>{actions}</MuiDialogActions>}
        </>
      )}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  onSave: () => undefined,
};
