import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import { Paper, MuiIcons, Row, Col, styled } from 'elements';

export const RoleItemHolder = styled(Paper)(() => ({
  padding: remCalc(30),
  minHeight: remCalc(250),
}));

export const RoleItemHolderContent = styled('div')(() => ({
  minHeight: remCalc(75),
  marginBottom: remCalc(15),
}));

export const PermissionsHeader = styled('div')(() => ({
  fontSize: remCalc(10),
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.6,
  color: PALETTE.steel,
  marginBottom: remCalc(5),
}));

export const GroupIcon = styled(MuiIcons.Group)(() => ({
  color: PALETTE.lightGrey,
  marginLeft: remCalc(10),
}));

export const SRow = styled(Row)(() => ({
  borderBottom: `1px solid ${PALETTE.pageBackground}`,
  minHeight: remCalc(48),
}));

export const RowRightCol = styled(Col)(() => ({
  width: remCalc(125),
}));
