import * as React from 'react';
import { remCalc, Dayjs } from 'utils';
import de from 'rc-picker/lib/locale/de_DE';
import en from 'rc-picker/lib/locale/en_US';
import GenerateConfig from 'rc-picker/lib/generate/dayjs';
import { useLocale } from 'hooks';
import { PickerPanel, PickerPanelProps } from 'rc-picker';
import { styled } from './utilities';

const LOCALES = {
  de,
  en,
};

type Props = PickerPanelProps<Dayjs> & {
  label?: string;
  error?: boolean;
  helperText?: string;
  helperTextOptions?: Record<string, any>;
};

export function STimePicker(props: Props) {
  const {
    value,
    onChange,
    label,
    error,
    helperText,
    helperTextOptions,
    className,
    ...rest
  } = props;
  const { locale } = useLocale();

  // @ts-ignore
  const sharedProps: PickerPanelProps<Dayjs> = {
    ...rest,
    picker: 'time',
    onSelect: onChange,
    locale: LOCALES[locale],
    generateConfig: GenerateConfig,
    value,
    showSecond: false,
    use12Hours: false,
    format: 'HH:mm',
    className,
  };
  return <PickerPanel {...sharedProps} />;
}

STimePicker.defaultProps = {
  showSecond: false,
  showMinute: true,
};

export const TimePicker = styled(STimePicker)(({ theme }) => {
  return {
    width: '100%',
    '& input': {
      height: remCalc(48),
      padding: remCalc(5, 5, 5, 20),
      borderRadius: remCalc(6),
      backgroundColor: theme.palette.common.white,
      minWidth: remCalc(230),
      fontSize: remCalc(16),
      color: theme.palette.common.black,
    },
    '& label': {
      display: 'flex',
      width: '100%',
      fontSize: remCalc(12),
      lineHeight: remCalc(17),
      marginBottom: remCalc(8),
    },
    '& ._clear-icon': {
      position: 'absolute',
      top: `calc(50% - ${remCalc(8)})`,
      right: remCalc(15),
      cursor: 'pointer',
    },

    '& .rc-picker-footer': {
      display: 'none',
    },
    '& .rc-picker-time-panel-cell': {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& .rc-picker-time-panel-cell-inner': {
          color: theme.palette.common.white,
        },
      },
    },
    '& .rc-picker-time-panel-cell-selected': {
      backgroundColor: theme.palette.primary.main,
      '& .rc-picker-time-panel-cell-inner': {
        color: theme.palette.common.white,
      },
    },
  };
});
