import * as React from 'react';
import cn from 'classnames';
import { generateSortingIndicator, styled } from 'elements';
import { remCalc } from 'utils';

type IProps = React.PropsWithChildren<{
  size?: number;
  propName: string;
  className?: string;
  query: any;
  onSort: (name: string, order: 'asc' | 'desc') => void;
}>;

export const SortIcon = styled((props: IProps) => {
  const { propName, query, onSort, size, className } = props;
  const _size = size || 20;
  return (
    <div
      style={{ width: remCalc(_size), height: remCalc(_size), lineHeight: 1 }}
      className={cn('pointer', className)}
      onClick={() =>
        onSort(propName, query.orderType === 'desc' ? 'asc' : 'desc')
      }
    >
      {generateSortingIndicator(propName, query, _size)}
    </div>
  );
})(() => ({
  '& img': {
    display: 'flex',
  },
}));
