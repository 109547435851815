import * as React from 'react';
import { Dayjs } from 'dayjs';
import { remCalc, dayjs } from 'utils';
import { styled } from './utilities';
import { useState } from 'hooks';
import InputMask from 'react-input-mask';
import { GLOBAL_DATE_FORMAT_MASK, GLOBAL_DATE_FORMAT } from 'enums';

type ICustomProps = {
  onSelect: (date: Dayjs) => void;
  value: string | number;
  className?: string;
};

type IState = {
  value: string;
};

const InputComponent = (props: ICustomProps) => {
  const [state, handleState] = useState<IState>({
    value: (props.value as string) || '',
  });

  React.useEffect(() => {
    const newValue = dayjs(state.value, GLOBAL_DATE_FORMAT);
    if (state.value && state.value !== props.value && newValue.isValid()) {
      props.onSelect(newValue);
    }
  }, [state.value]);

  React.useEffect(() => {
    const newValue = dayjs(props.value as string, GLOBAL_DATE_FORMAT);
    if (state.value !== props.value && newValue.isValid()) {
      handleState({ value: newValue.format(GLOBAL_DATE_FORMAT) });
    }
  }, [props.value]);

  return (
    <InputMask
      className={props.className}
      value={state.value}
      mask={GLOBAL_DATE_FORMAT_MASK}
      placeholder={'DD.MM.YYYY'}
      onChange={(ev) => handleState({ value: ev.target.value })}
    />
  );
};

export const DateInput = styled(InputComponent)(() => {
  return {
    border: 'none',
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    lineHeight: remCalc(48),
    padding: 0,
  };
});
