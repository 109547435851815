import * as React from 'react';
import { Input, InputProps } from './Input';
import { styled } from './utilities';
import Popover from './Popover';
import { ChromePicker, ColorChangeHandler, Color } from 'react-color';
import { remCalc, important } from 'utils';

type IColorPickerProps = React.PropsWithChildren<
  Omit<InputProps, 'onChange'> & {
    value: Color;
    onChange: (color: string) => void;
  }
>;

const ColorDisplay = styled('div')(() => ({
  width: remCalc(18),
  minWidth: remCalc(18),
  height: remCalc(18),
  borderRadius: remCalc(4),
  overflow: 'hidden',
  display: 'block',
  margin: remCalc(0, 10),
}));

const PickerHolder = styled(Popover)(() => ({
  '&._picher_holder': {
    '& .flexbox-fix:nth-of-type(2)': {
      display: important('none'),
    },
  },
}));

const ColorPicker = (props: IColorPickerProps) => {
  const { value, onChange, className, error, ...restProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? `color-picker-${restProps.name}-popover` : undefined;

  const changeColor = React.useCallback<ColorChangeHandler>(
    (color) => onChange(color.hex),
    []
  );

  return (
    <div className={className} aria-describedby={id}>
      <Input
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        {...restProps}
        error={error}
        endAdornment={
          <ColorDisplay
            onClick={handleClick}
            style={{ backgroundColor: error ? '#fff' : (value as string) }}
          />
        }
      />
      <PickerHolder
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ className: '_picher_holder' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker color={value} disableAlpha onChange={changeColor} />
      </PickerHolder>
    </div>
  );
};

export default ColorPicker;
