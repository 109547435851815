import { EditableText } from 'components';
import { styled, Paper, Dialog, InputAdornment, Input } from 'elements';
import { remCalc, important } from 'utils';

export const ConnectivityMainWrapper = styled(Paper)(() => ({
  minHeight: remCalc(600),
}));

export const ConnectivityAdornment = styled('span')(() => ({
  lineHeight: remCalc(32),
}));

export const TunnelTable = styled('div')(() => ({
  minHeight: remCalc(100),
}));

export const SEditableText = styled(EditableText)(() => ({
  '& .jbbf-input': { width: remCalc(180) },
  '& input': { paddingLeft: 0, paddingRight: remCalc(5) },
}));

export const SDnsEditableText = styled(EditableText)(() => ({
  '& .jbbf-input': { width: remCalc(180) },
  '& input': { paddingRight: remCalc(5) },
}));

export const CreateConnectivityInput = styled(Input)(() => ({
  width: remCalc(200),
  '& input': {
    textAlign: 'right',
    padding: remCalc(0, 0, 0, 10),
    lineHeight: remCalc(48),
  },
}));

export const CreateConnectivityIpPoolInput = styled(Input)(() => ({
  width: remCalc(200),
  '& input': {
    textAlign: 'left',
    padding: remCalc(0, 10, 0, 0),
    lineHeight: remCalc(48),
  },
}));

export const CreateConnectivityFixedValue = styled(InputAdornment)(
  ({ theme }) => ({
    margin: important(0),
    color: theme.palette.primary.main,
    height: remCalc(48),
    maxHeight: remCalc(48),
    lineHeight: remCalc(48),
    '& p': {
      color: theme.palette.primary.main,
    },
  })
);

export const IpsecVpnDialog = styled(Dialog)(() => ({
  '& ._dialog': {
    maxWidth: remCalc(1200),
  },
}));

export const IpsecVpnSwitcherWrapper = styled('div')(() => ({
  height: remCalc(55),
  marginBottom: remCalc(23),
}));

export const CostInfoList = styled('div')(({ theme }) => ({
  '& li:before': {
    backgroundColor: theme.palette.primary.main,
  },
}));
