import { ConnectivityFormValues } from 'services-shapes';
import { GET, POST, DELETE, PUT, compact, buildListParams } from 'utils';
import { ICommonListParams } from 'global-shapes';
import { IIpSecVpnTunnel, IIpSecVpnTunnelEmpty } from 'ipsec-vpn-tunnels';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';
import { parseConFormValuesToPayload } from 'pages/connectivity/helpers';

export const get = (): AxiosPromise<IConnectivityTypes.Connectivity> =>
  GET('/services/connectivity', {}).then((res) => {
    if (res.data) {
      res.data.qosProfileName = Number(
        res.data.qosProfileName.split(' ')[0] || 100
      );
    }
    return res;
  });

export const remove =
  (): AxiosPromise<IConnectivityTypes.Connectivity | null> =>
    DELETE('/services/connectivity');

export const update = (
  payload: ConnectivityFormValues
): AxiosPromise<IConnectivityTypes.Connectivity> =>
  PUT(
    '/services/connectivity',
    {},
    parseConFormValuesToPayload(payload, false)
  );

export const create = (
  payload: ConnectivityFormValues
): AxiosPromise<IConnectivityTypes.Connectivity> => {
  return POST(
    '/services/connectivity',
    {},
    parseConFormValuesToPayload(payload, true)
  );
};

export const setConRateLimit = (
  id: number,
  payload: any
): AxiosPromise<IConnectivityTypes.Connectivity> => {
  return PUT(`/services/connectivity/${id}/qos`, {}, payload);
};

export const getTunnels = (
  id: number,
  params: ICommonListParams
): AxiosPromise<IIpSecVpnTunnel[]> =>
  GET(`/services/connectivity/${id}/ipsec-vpn-tunnels`, params);

export const getTunnel = (
  connectivityId: number,
  id: number
): AxiosPromise<IIpSecVpnTunnel> =>
  GET(`/services/connectivity/${connectivityId}/ipsec-vpn-tunnels/${id}`);

export const refreshTunnels = (id: number): AxiosPromise<IIpSecVpnTunnel[]> =>
  POST(`/services/connectivity/${id}/ipsec-vpn-tunnels/refresh`);

export const deleteTunnel = (
  id: number,
  connectivityId: number
): AxiosPromise<IIpSecVpnTunnel | null> =>
  DELETE(`/services/connectivity/${connectivityId}/ipsec-vpn-tunnels/${id}`);

export const createTunnel = (
  id: number,
  payload: IIpSecVpnTunnelEmpty
): AxiosPromise<IIpSecVpnTunnel> =>
  POST(
    `/services/connectivity/${id}/ipsec-vpn-tunnels`,
    {},
    {
      ...payload,
      remoteSubnets: compact(
        payload.remoteSubnets.split(',').map((str) => str.trim())
      ),
      diffieHellmanGroup: payload.diffieHellmanGroup.value,
      encryptionAlgorithm: payload.encryptionAlgorithm.value,
      digestAlgorithm: payload.digestAlgorithm.value,
      ikeVersion: payload.ikeVersion.value,
      isEnabled: payload.isEnabled,
    }
  );

export const updateTunnel = (
  connectivityId: number,
  tunnelId: number,
  payload: IIpSecVpnTunnelEmpty
): AxiosPromise<IIpSecVpnTunnel> =>
  PUT(
    `/services/connectivity/${connectivityId}/ipsec-vpn-tunnels/${tunnelId}`,
    {},
    {
      name: payload.name,
      remoteEndpoint: payload.remoteEndpoint,
      preSharedKey: payload.preSharedKey,
      isEnabled: payload.isEnabled,
      remoteSubnets: compact(
        payload.remoteSubnets.split(',').map((str) => str.trim())
      ),
      ikeVersion: payload.ikeVersion.value,
      perfectForwardSecrecy: payload.perfectForwardSecrecy,
      encryptionAlgorithm: payload.encryptionAlgorithm.value,
      diffieHellmanGroup: payload.diffieHellmanGroup.value,
      digestAlgorithm: payload.digestAlgorithm.value,
    }
  );

export const getFirewallRules = (
  connectivityId: number,
  params: ICommonListParams
): IAxiosPromise<IConnectivityTypes.FirewallRulesCollection> =>
  GET(
    `/services/connectivity/${connectivityId}/firewall-rules`,
    buildListParams(params, ['orderType', 'orderBy', 'type', 'q'])
  );

export const createFirewallRule = (
  connectivityId: number,
  payload: IConnectivityTypes.IFirewallRulePayload
): IAxiosPromise<IConnectivityTypes.IFirewallRule> =>
  POST(`/services/connectivity/${connectivityId}/firewall-rules`, {}, payload);

export const updateFirewallRule = (
  connectivityId: number,
  id: number,
  payload: IConnectivityTypes.IFirewallRulePayload
): IAxiosPromise<IConnectivityTypes.IFirewallRule> =>
  PUT(
    `/services/connectivity/${connectivityId}/firewall-rules/${id}`,
    {},
    payload
  );

export const removeFirewallRule = (
  connectivityId: number,
  id: number
): IAxiosPromise<IConnectivityTypes.IFirewallRule> =>
  DELETE(`/services/connectivity/${connectivityId}/firewall-rules/${id}`);

export const getNatRules = (
  connectivityId: number,
  params: ICommonListParams
): IAxiosPromise<IConnectivityTypes.INatRule[]> =>
  GET(
    `/services/connectivity/${connectivityId}/nat-rules`,
    buildListParams(params, ['orderType', 'orderBy', 'type', 'q'])
  );

export const getNetworks = (
  params: AnyShape
): IAxiosPromise<IConnectivityTypes.INetwork[]> =>
  GET(
    `/networks`,
    buildListParams(params, ['orderType', 'orderBy', 'type', 'q'])
  );

export const createNetwork = (
  payload: IConnectivityTypes.INetworkPayload
): AxiosPromise<IConnectivityTypes.INetwork> => POST(`/networks`, {}, payload);

export const updateNetwork = (
  id: number,
  payload: IConnectivityTypes.INetworkPayload
): AxiosPromise<IConnectivityTypes.INetwork> =>
  PUT(
    `/networks/${id}`,
    {},
    {
      ipPoolStart: payload.ipPoolStart,
      ipPoolEnd: payload.ipPoolEnd,
      name: payload.name,
    }
  );

export const removeNetwork = (
  id: number
): AxiosPromise<IConnectivityTypes.INetwork> => DELETE(`/networks/${id}`);
