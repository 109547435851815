import React, { useCallback } from 'react';
import {
  Dialog,
  DialogProps,
  Checkbox,
  Row,
  Col,
  Button,
  Alert,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { ILanguage } from 'global-shapes';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { openInNewTab } from '../../utils';

const INITIALS = {
  termsPrivacy: false,
  serviceUse: false,
  charge: false,
};

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

type IProps = DialogProps<typeof INITIALS>;

const validationSchema = Yup.object().shape({
  termsPrivacy: Yup.boolean().equals([true]).required(),
  serviceUse: Yup.boolean().equals([true]).required(),
  charge: Yup.boolean().equals([true]).required(),
});

const LINKS = {
  en: {
    termsPrivacy: 'https://awesome.cloud/privacy-policy-en',
    serviceUse: 'https://awesome.cloud/terms-of-service-en',
    charge: 'https://awesome.cloud/pricing-en',
  },
  de: {
    termsPrivacy: 'https://awesome.cloud/privacy-policy-de',
    serviceUse: 'https://awesome.cloud/terms-of-service-de',
    charge: 'https://awesome.cloud/pricing-de',
  },
};

const View = observer((props: IProps & IViewProps) => {
  const { onSave, currentUser } = props;
  const { t } = useTranslation();
  const currentLanguage: ILanguage = currentUser.data?.lang;
  const {
    handleSubmit,
    values,
    isValid,
    setFieldValue,
    resetForm,
    submitCount,
  } = useFormik({
    initialValues: INITIALS,
    validateOnMount: false,
    validationSchema,
    onSubmit: (val) => Promise.resolve(onSave(val)),
  });

  const onChange = useCallback(
    (name: string) => (isChecked: boolean) => setFieldValue(name, isChecked),
    []
  );

  React.useEffect(() => {
    if (!props.open) {
      resetForm();
    }
  }, [props.open]);

  return (
    <Dialog
      title={props.title}
      open={props.open}
      onClose={props.onClose}
      handleSubmit={handleSubmit}
      actions={
        <Row columnSpacing={3} justifyContent="flex-end">
          <Col>
            <Button color="default" variant="outlined" onClick={props.onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="submit">
              {t('app.evaluationBar.confirm.switchToPaidAccount')}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className="mb-20">{t('app.evaluationBar.confirm.content')}</div>
      {!!submitCount && !isValid && (
        <Alert severity="error" className="mb-10">
          {t('app.evaluationBar.confirm.invalidForm')}
        </Alert>
      )}
      <div className="flex align-center mb-10">
        <Checkbox
          checked={values.termsPrivacy}
          name="termsPrivacy"
          wrapperClassName="mr-0"
          onCheck={onChange('termsPrivacy')}
        />
        <div
          className="fs-14 pointer"
          onClick={() => openInNewTab(LINKS[currentLanguage].termsPrivacy)}
        >
          {t('app.evaluationBar.confirm.condition.1')}
        </div>
      </div>
      <div className="flex align-center mb-10">
        <Checkbox
          wrapperClassName="mr-0"
          checked={values.serviceUse}
          name="serviceUse"
          onCheck={onChange('serviceUse')}
        />
        <div
          className="fs-14 pointer"
          onClick={() => openInNewTab(LINKS[currentLanguage].serviceUse)}
        >
          {t('app.evaluationBar.confirm.condition.2')}
        </div>
      </div>
      <div className="flex align-center">
        <Checkbox
          wrapperClassName="mr-0"
          checked={values.charge}
          name="charge"
          onCheck={onChange('charge')}
        />
        <div
          className="fs-14 pointer"
          onClick={() => openInNewTab(LINKS[currentLanguage].charge)}
        >
          {t('app.evaluationBar.confirm.condition.3')}
        </div>
      </div>
    </Dialog>
  );
});

const ConfirmationProdStartDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default ConfirmationProdStartDialog;
