import * as React from 'react';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';
import { styled, MuiIcons, Paper } from 'elements';

const LINE_COLOR = '#81858B';

export const AllServicePage = styled('div')(() => ({
  marginBottom: remCalc(20),
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  minWidth: remCalc(1360),
  maxWidth: remCalc(1390),
  minHeight: `calc(100vh - ${remCalc(64 + 48 + 30 + 120)})`,
}));

export const AllServiceGlobalIconWrapper = styled('div')({
  position: 'relative',
  minHeight: remCalc(55),
  '&::after': {
    position: 'absolute',
    left: 'calc(50% - 1px)',
    top: remCalc(38),
    width: 0,
    borderLeft: `2px dashed ${LINE_COLOR}`,
    height: remCalc(18),
    content: "''",
  },
});

export const AllServiceVmsWrapper = styled(Paper)(() => ({
  padding: remCalc(20, 20, 0),
  overflow: 'hidden',
}));

export const AllServiceGlobalIcon = styled(MuiIcons.Public)(() => ({
  '&.MuiSvgIcon-root': {
    fontSize: remCalc(40),
    color: LINE_COLOR,
  },
}));

export const AllServiceStatusIndicator = styled(
  (props: any & { connectivitySetupCompleted: boolean }) => {
    const { connectivitySetupCompleted, ...rest } = props;
    const Icon = props.connectivitySetupCompleted
      ? MuiIcons.Done
      : MuiIcons.Close;
    return <Icon {...rest} />;
  }
)(({ connectivitySetupCompleted }) => ({
  backgroundColor: connectivitySetupCompleted ? PALETTE.success : PALETTE.error,
  '&.MuiSvgIcon-root': {
    position: 'absolute',
    right: 0,
    top: 0,
    borderRadius: '50%',
    color: PALETTE.white,
    lineHeight: 1,
    fontSize: remCalc(17),
    padding: remCalc(2),
    border: `2px solid ${PALETTE.white}`,
    fontWeight: 600,
  },
}));
