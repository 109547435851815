import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  Row,
  Col,
  Button,
  Dialog,
  Input,
  Alert,
  DialogProps,
  SCustomList,
  PrimaryTextSpan,
  PrimaryTextH5,
} from 'elements';
import { validateCallback, validator, numberToCurrency, remCalc } from 'utils';
import {
  usePrice,
  useStateHandler,
  calculatePrices,
  findVmPricing,
} from 'hooks';
import { pick, sum, values as RValues } from 'ramda';
import { CostResourceIds } from 'enums';
import { getAdditionalDiskValidSchema } from './constants';

const INIT_STATE: AdditinalDiskTypes.IStateValues = {
  name: '',
  sizeGb: '',
};

interface IProps extends DialogProps<AdditinalDiskTypes.IStateValues> {
  isNew: boolean;
  currentDisk: IVmTypes.VMDisk | null;
}

const AdditionalDisks = observer((props: IProps) => {
  const { onSave, isNew, currentDisk } = props;
  const { t } = useTranslation();
  const prices = usePrice([
    CostResourceIds.virtualServerBase,
    CostResourceIds.virtualServerGBStorage,
  ]);
  const tenantPricing = useStateHandler(StateHandlers.tenantPricing);
  const disksState = useStateHandler(StateHandlers.vmDisks);
  const disks = disksState.data.filter((d) => d.id !== currentDisk?.id);
  const currentVM = useStateHandler(StateHandlers.vmDetails);
  const costBreakdownState = useStateHandler(StateHandlers.costBreakdown);
  const vm = currentVM.data;
  const isRequesting = currentVM.isRequesting || disksState.isRequesting;

  const {
    values,
    handleSubmit,
    isValid,
    handleChange,
    setValues,
    errors,
    submitCount,
    resetForm,
  } = useFormik({
    initialValues: INIT_STATE,
    validationSchema: getAdditionalDiskValidSchema(currentDisk),
    onSubmit: async (val) => onSave({ ...val, sizeGb: +val.sizeGb }),
    validateOnMount: true,
  });

  const baseFee = prices[CostResourceIds.virtualServerBase]?.monthly;
  const perGBStorage = prices[CostResourceIds.virtualServerGBStorage]?.monthly;

  const isWindows = vm.os.osFamilyId === 1;

  const calculatedPrices = useMemo(() => {
    const namespaces = ['virtualCpus', 'ramMb', 'osDiskSizeGb'];
    if (isWindows) namespaces.push('winLicense');
    const VM = {
      ...vm,
      ramMb: vm.ramMb / 1024,
    };

    const base: any = calculatePrices(
      // @ts-ignore
      pick(namespaces)({ ...VM, winLicense: 1 }),
      findVmPricing(tenantPricing.data)
    );
    base.baseFee = baseFee;

    return {
      base,
      disks: sum(disks.map((d: IVmTypes.VMDisk) => d.sizeGb * perGBStorage)),
      currentDisks: sum(
        disksState.data.map((d: IVmTypes.VMDisk) => d.sizeGb * perGBStorage)
      ),
    };
  }, [
    currentVM.isLoading,
    disks,
    isWindows,
    vm,
    tenantPricing.data,
    props.currentDisk,
  ]);

  const isOverusedStorage = useMemo(() => {
    const current = isValid ? +values.sizeGb : 0;
    const remaining = +costBreakdownState.data?.remaining.diskSizeGb;
    return current > remaining;
  }, [
    isValid,
    values.sizeGb,
    costBreakdownState.data?.remaining.diskSizeGb,
    vm,
  ]);

  React.useEffect(() => {
    if (props.open) {
      setValues({
        name: props.currentDisk?.name || '',
        sizeGb: props.currentDisk?.sizeGb || '',
      });
    } else {
      resetForm();
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      title={t(
        isNew
          ? 'services.vm.disks.dialog.title'
          : 'services.vm.disks.dialog.edit.title'
      )}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      handleSubmit={handleSubmit}
      actions={
        <Row justifyContent="flex-end" columnSpacing={2}>
          <Col>
            <Button variant="outlined" color="default" onClick={props.onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button disabled={isOverusedStorage || isRequesting} type="submit">
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Row justifyContent="space-between" columnSpacing={2}>
        <Col xs={7}>
          <div className="steel mb-25">
            {t(
              isNew
                ? 'services.vm.disks.dialog.subtitle'
                : 'services.vm.disks.dialog.edit.subtitle'
            )}
          </div>
          <Row
            columnSpacing={2}
            alignItems="flex-start"
            className="full-width mb-25"
          >
            <Col xs>
              <Input
                name="name"
                label="services.dialog.vms.table.head.diskName"
                placeholder={t(
                  'services.dialog.vms.table.head.placeholder.diskName'
                )}
                value={values.name}
                onChange={handleChange}
                error={!!(submitCount && errors.name)}
                helperText={errors.name}
                helperTextOptions={{ max: 30 }}
              />
            </Col>
            <Col xs={3}>
              <Input
                name="sizeGb"
                label="services.dialog.vms.table.head.diskSize"
                value={values.sizeGb}
                onChange={validateCallback({
                  restrict: validator.onlyNumbers,
                })(handleChange)}
                error={!!(submitCount && errors.sizeGb)}
                helperText={errors.sizeGb}
              />
            </Col>
          </Row>
          {!isNew && (
            <Alert severity="info" className="mb-25">
              {t('services.vm.disks.dialog.alerts.onlyIncrease')}
            </Alert>
          )}
          {isOverusedStorage && (
            <Alert severity="error">
              {t('costBreakdown.storage.limitReached')}
            </Alert>
          )}
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(360) }}
          >
            <Col>
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={8}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={4} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.virtualServer')}</h5>
              <SCustomList className="custom-list mb-10">
                <ul>
                  <li>
                    <div className="flex justify-between">
                      <span>{t('costsInfo.baseFee')}</span>
                      <span className="pl-10 steel">
                        {numberToCurrency(baseFee, false)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {t('costsInfo.virtualCpus', {
                          value: vm.virtualCpus,
                        })}
                      </span>
                      <span className="pl-10 steel">
                        {numberToCurrency(
                          calculatedPrices.base.virtualCpus,
                          false
                        )}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {t('costsInfo.ramMb', {
                          value: vm.ramMb / 1024,
                        })}
                      </span>
                      <span className="pl-10 steel">
                        {numberToCurrency(calculatedPrices.base.ramMb, false)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {t('costsInfo.osDiskSizeGb', {
                          value: vm.osDiskSizeGb,
                        })}
                      </span>
                      <span className="pl-10 steel">
                        {numberToCurrency(
                          calculatedPrices.base.osDiskSizeGb,
                          false
                        )}
                      </span>
                    </div>
                  </li>
                  {isWindows && (
                    <li>
                      <div className="flex justify-between">
                        <span>{t('costsInfo.winLicense')}</span>
                        <span className="pl-10 steel">
                          {numberToCurrency(
                            calculatedPrices.base.winLicense,
                            false
                          )}
                        </span>
                      </div>
                    </li>
                  )}
                  {disks.map((d: IVmTypes.VMDisk) => (
                    <li key={d.id}>
                      <div className="flex justify-between full-width">
                        <div className="flex">
                          <div
                            className="ellipsis pr-10"
                            style={{ maxWidth: remCalc(200) }}
                          >
                            {d.name}
                          </div>
                          {d.sizeGb} GB
                        </div>

                        <span className="pl-5 steel">
                          {numberToCurrency(d.sizeGb * perGBStorage, false)}
                        </span>
                      </div>
                    </li>
                  ))}
                  {isValid && (
                    <li>
                      <div className="flex justify-between full-width">
                        <div className="flex">
                          <span
                            className="ellipsis pr-10"
                            style={{ maxWidth: remCalc(200) }}
                          >
                            {values.name}
                          </span>
                          {values.sizeGb} GB
                        </div>
                        <span className="pl-5 steel">
                          {numberToCurrency(
                            +values.sizeGb * perGBStorage,
                            false
                          )}
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </SCustomList>
            </Col>
            <Col>
              <div className="flex justify-between align-center">
                <div className="fs-14">
                  {t('costsInfo.currentTotalMonthly')}
                </div>
                <span className="fs-16 bold">
                  {numberToCurrency(
                    sum(
                      RValues([
                        ...RValues(calculatedPrices.base),
                        calculatedPrices.currentDisks,
                      ])
                    ),
                    false
                  )}
                </span>
              </div>
              <div className="flex justify-between align-center">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(
                    sum([
                      ...RValues(calculatedPrices.base),
                      calculatedPrices.disks,
                      isValid ? +values.sizeGb * perGBStorage : 0,
                    ]),
                    false
                  )}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel mb-10">
                {t('costsInfo.chfExclVat') as string}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

// @ts-ignore
AdditionalDisks.defaultProps = {
  shouldRenderForm: true,
};

export default AdditionalDisks;
