import React, { useCallback } from 'react';
import cn from 'classnames';
import { pick } from 'ramda';
import { Row, Col, Button, Loader, Alert } from 'elements';
import { ALL_TEST_IDS } from 'enums';
import { confirm, noop, showSystemMessage, remCalc } from 'utils';
import { useTranslation } from 'react-i18next';
import { IBackupEmail, IBackupEntity, IBackupNotificationValues } from 'backup';
import { useTask, usePermissions, useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import CreatePlanDialog from './PlanDialog';
import CreateUserDialog from './CreateUserDialog';
import BackupVersions from './Versions';
import BackupUsers from './BackupUsers';
import BackupPlansInfo from './BackupPlansInfo';
import BackupNotifications from './BackupNotifications';
import {
  hasDeletedOption,
  parseBackupStateToForm,
  parseBackupToForm,
  parseBackupUpdateStateToForm,
  showNoBillingAlertPopup,
} from './helpers';
import { backupNamespaces } from 'enums';

type IProps = BackupTypes.ICommonPlanDialogProps & {
  entityId: number;
  entityName: IBackupEntity;
  vmDiskSize: number;
  isUsageEnabled: boolean;
};

const INITIAL_STATE = {
  isPlanDialogOpen: false,
  isUserDialogOpen: false,
};

const OBSERVERS = {
  backup: StateHandlers.backup,
  backupNotifications: StateHandlers.backupNotifications,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    entityId,
    vmDiskSize,
    entityName,
    isUsageEnabled,
    backup,
    backupNotifications,
    instance,
  } = props;
  const [state, handleChange] = useState(INITIAL_STATE);
  const {
    permissions: { canManage: isEditable },
  } = usePermissions('BACKUP');
  const { t } = useTranslation();

  const fetchBackup = useCallback(
    () => backup.get({ id: entityId, entityName }),
    [entityId, entityName]
  );

  const backupData = backup.data;
  const backupId = backupData ? backupData.id : null;

  const task = useTask(backupData ? backupData.task : null, {
    onComplete: fetchBackup,
  });

  const userEmails = backupNotifications.data.notificationEmails || [];

  const createBackup = useCallback(
    (values: BackupTypes.State) => {
      const noSelectedPlan = backupNamespaces.every((key) => !values[key]);

      if (noSelectedPlan) {
        return showSystemMessage(
          'services.backup.notify.create.noPlanSelected',
          'info'
        );
      }

      return backup
        .create(
          parseBackupStateToForm({
            entityId: +entityId,
            entityName,
            ...values,
          })
        )
        .then(() => {
          handleChange(false, 'isPlanDialogOpen');
          fetchBackup();
          confirm({
            title: 'services.backup.notify.create.title',
            content: t('services.backup.notify.create.content')
              .split('\n')
              .map((s) => <p className="mb-15 fs-14 steel">{s}</p>),
            onCancel: noop,
            cancelLabel: 'common.ok',
          });
        });
    },
    [entityId, entityName, backupId]
  );

  const updateBackupRequest = React.useCallback(
    (values: BackupTypes.State) =>
      backup.update(backupId, parseBackupUpdateStateToForm(values)).then(() => {
        handleChange(false, 'isPlanDialogOpen');
        fetchBackup();
      }),
    [backupId]
  );

  const updateBackup = useCallback(
    (values: BackupTypes.State) => {
      const isDeletingWholeBackup = backupNamespaces.every(
        (key) => !values[key]
      );

      const hasDeletedItem = hasDeletedOption(
        parseBackupToForm(backupData),
        values
      );

      if (isDeletingWholeBackup) {
        return onConfirmDelete();
      }

      if (hasDeletedItem) {
        return confirm({
          title: t('services.backup.confirm.delete.hasRemovedFlag.title'),
          content: t('services.backup.confirm.delete.hasRemovedFlag.content'),
          onCancel: noop,
          onSuccess: () => updateBackupRequest(values),
        });
      }

      return updateBackupRequest(values);
    },
    [entityId, entityName, backupId, JSON.stringify(backupData)]
  );

  const deleteBackup = useCallback(
    () =>
      backup.remove(backupId).then(() => {
        handleChange(false, 'isPlanDialogOpen');
        fetchBackup();
      }),
    [backupId]
  );

  const handlePlanDialogOpen = useCallback(() => {
    if (!isUsageEnabled) {
      return showNoBillingAlertPopup();
    }
    handleChange(true, 'isPlanDialogOpen');
  }, [backupId, isUsageEnabled]);

  const onConfirmDelete = useCallback(
    () =>
      confirm({
        title: 'services.backup.confirm.delete.title',
        content: t('services.backup.confirm.delete.content'),
        onSuccess: deleteBackup,
        onCancel: noop,
      }),
    [backupId]
  );

  const onUpdateNotifications = useCallback(
    (backupId: number | null, payload: IBackupNotificationValues) => {
      return backupNotifications.update(backupId, payload).then(() => {
        backupNotifications.get({ backupId });
        handleChange(false, 'isUserDialogOpen');
      });
    },
    []
  );

  const onCreateNotificationEmail = useCallback(
    (data: BackupTypes.CreateNotificationEmail) => {
      return backupNotifications.create({ backupId, ...data }).then(() => {
        backupNotifications.get({ backupId });
        handleChange(false, 'isUserDialogOpen');
      });
    },
    [backupId]
  );

  const onDeleteNotificationEmail = useCallback(
    (data: IBackupEmail) => {
      confirm({
        title: t('services.backup.confirm.delete.email.title'),
        content: t('services.backup.confirm.delete.email.content', {
          email: data.email,
        }),
        onSuccess: () =>
          backupNotifications
            .remove({ backupId, emailId: data.id })
            .then(() => {
              if (userEmails.length <= 1) {
                onUpdateNotifications(backupId, {
                  isFailedNotification: false,
                  isSuccessNotification: false,
                  isWarningNotification: false,
                });
              }
            }),
        onCancel: noop,
      });
    },
    [backupId, userEmails.length]
  );

  React.useEffect(() => {
    fetchBackup();
    return () => {
      backup.reset();
    };
  }, []);

  if (!backup.dataReceived) return <Loader />;

  const hasBackup = !!backupData;

  if (!hasBackup) {
    return (
      <div data-test-id={ALL_TEST_IDS.services.backup.main}>
        <Row
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={cn({ disabled: task.isTaskActive })}
          style={{ minHeight: `calc(100vh - ${remCalc(300)})` }}
        >
          <Col className="text-center">
            <div className="text-center mb-20">
              {t('services.backup.noBackup')}
            </div>
            {isEditable && (
              <Button onClick={handlePlanDialogOpen}>
                {t('services.backup.buttonCreate')}
              </Button>
            )}
          </Col>
        </Row>
        <CreatePlanDialog
          vmDiskSize={vmDiskSize}
          disabledActions={backup.isRequesting}
          onSave={createBackup}
          open={state.isPlanDialogOpen}
          onClose={() => handleChange(false, 'isPlanDialogOpen')}
          instance={instance}
        />
      </div>
    );
  }

  return (
    <div
      className={cn({ disabled: task.isTaskActive || backup.isRequesting })}
      data-test-id={ALL_TEST_IDS.services.backup.main}
    >
      <div className="flex justify-between align-center full-width mb-20">
        <div className="uppercase fs-14 bolder">
          {t('services.backup.title')}
        </div>
        {isEditable && (
          <div>
            <Button
              testId={ALL_TEST_IDS.services.backup.edit}
              size="small"
              onClick={handlePlanDialogOpen}
            >
              {t('common.edit')}
            </Button>
          </div>
        )}
      </div>

      {backupData?.hasInactiveJobs && (
        <Alert
          testId={ALL_TEST_IDS.services.backup.main}
          severity="warning"
          className="mb-15"
        >
          {t('services.backup.errors.hasActiveTasks')}
        </Alert>
      )}

      <div className="mb-40 fs-16">
        <BackupPlansInfo backup={backupData} />
      </div>

      <div className="uppercase fs-14 bolder mb-10">
        {t('services.backup.restorePointsList')}
      </div>

      <BackupVersions
        entityId={entityId}
        backupId={backupId}
        entityName={entityName}
        isEditable={isEditable}
      />
      <BackupNotifications
        entityId={entityId}
        backupId={backupId}
        testId={ALL_TEST_IDS.services.backup.notifications.type}
        userCount={userEmails.length}
        onAddUser={() => handleChange(true, 'isUserDialogOpen')}
        onSave={onUpdateNotifications}
        isEditable={isEditable}
        disabled={backupNotifications.isRequesting}
        initialValues={pick([
          'isFailedNotification',
          'isWarningNotification',
          'isSuccessNotification',
        ])(backupNotifications.data)}
      />
      <BackupUsers
        onDelete={onDeleteNotificationEmail}
        disabled={backupNotifications.isRequesting}
        testId={ALL_TEST_IDS.services.backup.notifications.users}
        users={userEmails}
        isEditable={isEditable}
      />
      <CreateUserDialog
        testId={ALL_TEST_IDS.services.backup.notifications.addUser}
        onSave={onCreateNotificationEmail}
        open={state.isUserDialogOpen}
        onClose={() => handleChange(false, 'isUserDialogOpen')}
        disabled={backupNotifications.isRequesting}
      />
      <CreatePlanDialog
        vmDiskSize={vmDiskSize}
        disabledActions={backup.isRequesting}
        onSave={updateBackup}
        open={state.isPlanDialogOpen}
        initialValues={backupData || undefined}
        instance={instance}
        onClose={() => handleChange(false, 'isPlanDialogOpen')}
      />
    </div>
  );
});

const Backup = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default Backup;
