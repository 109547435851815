import axios, { AxiosPromise } from 'axios';
import { IUserContext } from 'users-shapes';
import { compactObject } from '../customFunctions';
import { findShortNamesInUrl } from '../userInfo';
import { ISocketContextParams } from '../types';
import { apiPrefix, defaultHeaders } from '../constants';


export function resolveContext(config?: any): AxiosPromise<IUserContext> {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };

  return axios.get(`${apiPrefix}/users/resolve-context`, {
    params: undefined,
    ..._config,
    headers: compactObject({ ...defaultHeaders(), ..._config.headers }),
  });
}

type IUserContextType = 'tenant' | 'partner' | 'none';

type IUserContextResolve = {
  userType: IUserContextType;
  user?: ISocketContextParams;
};

export async function finUserContextOnTheFly(): Promise<IUserContextResolve> {
  const { psn, tsn } = findShortNamesInUrl(window.location);
  const res: IUserContextResolve = {
    userType: 'none',
    user: undefined,
  };
  if (psn && !tsn) {
    const partnerContext = await resolveContext({
      headers: {
        'X-JBBF-PARTNER-SHORTNAME': psn,
      },
    });

    res.userType = 'partner';
    res.user = {
      partnerKey: partnerContext.data.partnerKey,
      partnerId: partnerContext.data.partner?.id,
    };
  }

  if (tsn) {
    const tenantContext = await resolveContext({
      headers: {
        'X-JBBF-TENANT-SHORTNAME': tsn,
      },
    });

    res.userType = 'tenant';
    res.user = {
      tenantKey: tenantContext.data.tenantKey,
      tenantId: tenantContext.data.tenant?.id,
    };
  }

  return res;
}
