import { HOURS_ARRAY, MINUTES_ARRAY } from 'enums';
import * as R from 'ramda';
import { dayjs, Dayjs } from './dayjsExtended';

export function resetTimeValue(startDate: string | Dayjs): Dayjs {
  return dayjs(startDate).set('seconds', 0);
}

export function findDisabledMinutesMin(
  startDate: string | Dayjs,
  min?: string | Dayjs
): (_selectedHour: number) => number[] {
  return (_selectedHour) => {
    const start = resetTimeValue(startDate);

    const startForMinutes = _selectedHour
      ? dayjs(start).set('hours', _selectedHour)
      : dayjs(start);

    return R.filter<number>((minute) => {
      const limitDate = resetTimeValue(min || dayjs());
      return (
        +startForMinutes.set('minutes', minute) < +limitDate.add(15, 'minutes')
      );
    })(MINUTES_ARRAY);
  };
}

export function findDisabledMinutesMax(
  max: string | Dayjs
): (_selectedHour: number) => number[] {
  return (_selectedHour) => {
    const maxDate = resetTimeValue(max);

    const startForMinutes = _selectedHour
      ? dayjs(maxDate).set('hours', _selectedHour)
      : dayjs(maxDate);

    return R.filter<number>((minute) => {
      const limitDate = resetTimeValue(startForMinutes || dayjs());
      return +limitDate.set('minutes', minute) > +maxDate;
    })(MINUTES_ARRAY);
  };
}

export function findDisabledHoursMin(
  startDate: Dayjs,
  min?: Dayjs
): () => number[] {
  return () => {
    const start = resetTimeValue(startDate);
    const limitTime = resetTimeValue(min || dayjs());

    return R.filter<number>(
      (hour) => +dayjs(start).set('hours', hour) < +limitTime
    )(HOURS_ARRAY);
  };
}

export function findDisabledHoursMax(max?: Dayjs): () => number[] {
  return () => {
    const limitTime = resetTimeValue(max || dayjs());

    return R.filter<number>((hour) => {
      return +dayjs(limitTime).set('hours', hour) > +limitTime;
    })(HOURS_ARRAY);
  };
}

type IDisabledTimeResponse = {
  minutes: (hour: number) => number[];
  hours: () => number[];
};

export function findDisabledMinTime(
  startDate: Dayjs,
  min?: Dayjs
): IDisabledTimeResponse {
  const minutes = findDisabledMinutesMin(startDate);
  const hours = findDisabledHoursMin(startDate, min);

  return {
    minutes,
    hours,
  };
}

export function findDisabledMaxTime(max: Dayjs): IDisabledTimeResponse {
  const minutes = findDisabledMinutesMax(max);
  const hours = findDisabledHoursMax(max);

  return {
    minutes,
    hours,
  };
}
