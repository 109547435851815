import * as React from 'react';
import * as Translations from 'i18next';
import cn from 'classnames';
import { Loader, TablePagination, IOnChangeTableProps, styled } from 'elements';
import { withTranslation } from 'react-i18next';
import { ISearchEntity } from 'types/SearchEntity';
import { remCalc, findShortNamesInUrl } from 'utils';
import { generateEntityLink, sortEntities } from '../helpers';

type IProps = Translations.WithT & {
  isLoading: boolean;
  query: ISearchEntity.Query;
  params: ISearchEntity.PaginationParams;
  entities: ISearchEntity.EntityState;
  onPageChange: (query: IOnChangeTableProps) => void;
};

const Link = styled('div')(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Renderer = (props: IProps) => {
  const { t, isLoading, entities, query, onPageChange, params } = props;

  const handleEntityClick = React.useCallback(
    (link: string) => () => {
      const { psn, tsn } = findShortNamesInUrl({
        pathname: link.split('?')[0],
      });

      if (!psn) sessionStorage.removeItem('psn');
      if (!tsn) sessionStorage.removeItem('tsn');

      window.location.href = window.location.origin + link;
    },
    []
  );

  if (isLoading) return <Loader />;

  if (!Object.keys(entities).length) {
    return (
      <div className="steel">
        {t('entitySearch.content.noResult') as string}
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ height: `calc(100vh - ${remCalc(290)})`, overflowY: 'auto' }}
      >
        {/* @ts-ignore */}
        {sortEntities(Object.keys(entities)).map((key: string) => {
          return (
            <div key={key} className="mb-20">
              <div className="bolder">
                {t(`entitySearch.type.${key}`) as string}
              </div>
              {/* @ts-ignore */}
              {entities[key].map((ent) => {
                const { link, name } = generateEntityLink(ent);
                return (
                  <Link
                    key={ent.entityId}
                    className={cn('steel pointer')}
                    onClick={handleEntityClick(link)}
                  >
                    {name}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
      {query.perPage < params.totalCount && (
        <TablePagination
          shouldUseQuery={false}
          page={query.page}
          perPage={query.perPage}
          totalCount={params.totalCount}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};

const SearchResults = withTranslation()(Renderer);

export default SearchResults;
