import * as React from 'react';
import { Paper, Row, Col, IconButton, MuiIcons, Button } from 'elements';
import { useTranslation } from 'react-i18next';
import { showSystemMessage } from 'utils';
import { useState, useStateHandler, usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useAsync } from 'hooks';
import { partnersService } from 'services';
import CustomerInvoiceDialog from './CustomerInvoiceDialog';
import StripeConnection from './StripeConnection';
import { InvoiceHolder, InvoiceSettingsEmptyWrapper } from '../Styled';

const Main = observer(() => {
  const { t } = useTranslation();
  const [state, handleState] = useState({ dialogOpen: false });
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const countries = useStateHandler(StateHandlers.countries);
  const { permissions } = usePermissions('CUSTOMER_BILLING');
  const partnerId = currentUser.data?.partner?.id;
  const invoiceSettings = currentUser.data?.partner?.invoiceSettings;
  const stripePaymentsEnabled = !!invoiceSettings?.stripePaymentsEnabled;

  const countryCode = currentUser.data?.partner?.invoiceSettings?.countryCode;
  const country = countries.data.find((c) => c.value === countryCode);
  const isOther = !['LI', 'CH'].includes(country?.value || '');
  const countryName = country?.label || '';

  const { execute: updateInvoiceSettings } = useAsync(
    partnersService.updateInvoiceSettings
  );

  const onUpdateInfo = React.useCallback(
    async (data: IPartnerTypes.ICustomerInvoiceValues) => {
      try {
        const res = await updateInvoiceSettings(partnerId, {
          ...data,
          stripePaymentsEnabled,
        });
        showSystemMessage(
          'settings.page.customer-invoice.notify.update.success',
          'success'
        );
        StateHandlers.fetchAllAccountData({ shouldApplyTheme: false });
        handleState({ dialogOpen: false });
        return res;
      } catch (err: any) {
        return showSystemMessage(err.message, 'error');
      }
    },
    [partnerId, stripePaymentsEnabled]
  );

  if (!invoiceSettings) {
    return (
      <Paper>
        <InvoiceHolder className="flex justify-center align-center">
          <InvoiceSettingsEmptyWrapper className="text-center">
            <div className="mb-25 steel">
              {t('settings.page.customer-invoice.empty.description')}
            </div>
            {permissions.canManage && (
              <Button onClick={() => handleState({ dialogOpen: true })}>
                {t('settings.page.customer-invoice.empty.button')}
              </Button>
            )}
          </InvoiceSettingsEmptyWrapper>
        </InvoiceHolder>
        <CustomerInvoiceDialog
          isNew
          open={state.dialogOpen}
          onClose={() => handleState({ dialogOpen: false })}
          onSave={onUpdateInfo}
        />
      </Paper>
    );
  }

  return (
    <Paper>
      <InvoiceHolder>
        <Row alignItems="center" justifyContent="space-between">
          <Col>
            <h3 className="mb-20">
              {t('settings.page.customer-invoice.title') as string}
            </h3>
          </Col>
          {permissions.canManage && (
            <Col>
              <IconButton onClick={() => handleState({ dialogOpen: true })}>
                <MuiIcons.Edit />
              </IconButton>
            </Col>
          )}
        </Row>
        {isOther ? (
          <Row columnSpacing={2} rowSpacing={3} className="mb-50">
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.country')}</div>
              <div>{countryName}</div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.phone')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.phone || '-'}
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.email')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.email || '-'}
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.iban')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.iban || '-'}
              </div>
            </Col>

            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.swift.bic')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings
                  ?.bankIdentificationCode || '-'}
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">
                {t('forms.bankAccountNumber')}
              </div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.bankAccount || '-'}
              </div>
            </Col>

            <Col xs={12}>
              <div className="steel fs-12 mb-10">{t('forms.bankAddress')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.bankDetails || '-'}
              </div>
            </Col>
          </Row>
        ) : (
          <Row columnSpacing={2} rowSpacing={2} className="mb-50">
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.country')}</div>
              <div>{countryName}</div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.email')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.email || '-'}
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.phone')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.phone || '-'}
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.vatId')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.vatId || '-'}
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="steel fs-12 mb-10">{t('forms.ibanQR')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.iban || '-'}
              </div>
            </Col>
          </Row>
        )}

        <StripeConnection permissions={permissions} />
      </InvoiceHolder>

      <CustomerInvoiceDialog
        isNew={false}
        open={state.dialogOpen}
        onClose={() => handleState({ dialogOpen: false })}
        onSave={onUpdateInfo}
      />
    </Paper>
  );
});

export default Main;
