import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ISnapshot } from 'snapshots';
import { MuiIcons, PrimaryElement, Alert } from 'elements';
import * as R from 'ramda';
import { ValueType } from 'global-shapes';
import { IActualTaskState } from 'task-manager-service';
import {
  showSystemMessage,
  noop,
  confirm,
  compact,
  confirmDeleteWithReasons,
  parseConfirmDeleteWithReasonResponse,
  IConfirmDeleteReasonsRes,
  Emitter,
} from 'utils';
import { openWebConsoleWindow } from 'utils/openWebConsole';
import { Attributes } from 'forwarded-ports';
import { useVmMenuItems } from 'hooks/useInstanceMenuItems';
import { IActionHandlers, ActionOption } from 'components/ActionButtons/types';
import {
  useTask,
  useUserHash,
  useSnapshotRequest,
  useRemoteSupport,
  useStateHandler,
  useAccount,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { virtualMachinesService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { CustomEvents, BILLING_CIRCLE_OPTIONS } from 'enums';

type IRsOpenHandler = (attrs: Attributes | null) => void;

export type InjectedProps = {
  handleDetailsOpen: (open: boolean) => void;
  handleRsOpen: IRsOpenHandler;
  isRequesting: boolean;
  snapshotTask?: IActualTaskState;
  isInFolder: boolean;
  fetchVm: any;
  fetchBillingInfo: any;
  onEdit: any;
  onDelete: any;
  notifyOfCreatedSnapshot: any;
  confirmSnapshotRequest: any;
  currentSnapshot?: ISnapshot;
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  handlers: IActionHandlers;
  injectedMedia?: string;
  assignedNetworks: number[];
};

const USED_PRICE_LIST = ['virtualCpus', 'ramMb', 'osDiskSizeGb'];
const STATIC_SERVICE_COUNT = { baseFee: 1 };

const showError = (err: any) => showSystemMessage(err.message, 'error');

const parseUpdateData = (
  vm: IVmTypes.Vm,
  billingInfo: IVmTypes.IBillingCircle
): {
  data: IVmTypes.Vm;
  billingCycleInterval: ValueType<IVmTypes.IBillingCircleInterval>;
} => {
  const billingCycleInterval =
    (!!vm.billingCycleId &&
      R.find(
        R.propEq('value', billingInfo?.pendingInterval || billingInfo?.interval)
      )(BILLING_CIRCLE_OPTIONS)) ||
    BILLING_CIRCLE_OPTIONS[0];

  return {
    data: {
      ...vm,
      ramMb: vm.ramMb / 1024,
    },
    // @ts-ignore
    billingCycleInterval,
  };
};

export function withVmActions(
  Component: React.ComponentType<any>,
  place: 'list' | 'single'
) {
  return observer((props: any) => {
    const { ...passedVmProps } = props;
    const isDetailsPage = place === 'single';
    const { t } = useTranslation();
    const [, changeQuery] = useUserHash();
    const location = useLocation();
    const [account] = useAccount();
    const snapshots = useStateHandler(StateHandlers.snapshots);
    const vm = useStateHandler(StateHandlers.vmDetails);
    const vms = useStateHandler(StateHandlers.vms);
    const tasks = useStateHandler(StateHandlers.taskManager);
    const billingInfo = useStateHandler(StateHandlers.billingInfo);
    const tenantPricing = useStateHandler(StateHandlers.tenantPricing);
    const costBreakdown = useStateHandler(StateHandlers.costBreakdown);
    const folders = useStateHandler(StateHandlers.folders);
    const dnd = useStateHandler(StateHandlers.dnd);
    const media = useStateHandler(StateHandlers.media);
    const nics = useStateHandler(StateHandlers.vmNics);
    const assignedNetworks = compact(
      R.uniq(nics.data.map((el) => el.network?.id))
    );

    const vmId = isDetailsPage ? vm.data?.id : props.id;
    const currentVm = (isDetailsPage ? vm.data : passedVmProps) as IVmTypes.Vm;

    const isInFolder = !!currentVm.serviceGroupId;

    const currentSnapshot = isDetailsPage
      ? vm.data.serviceSnapshot
      : props.serviceSnapshot;

    const reloadCurrentVm = React.useCallback(() => {
      if (isDetailsPage) {
        return vm.get({
          id: vmId,
          include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
        });
      }
      return vms.reload(vmId, {
        include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
      });
    }, [isDetailsPage, vmId]);

    const vmCompleteCallback = React.useCallback(
      (action: string) => {
        tenantPricing.get();
        costBreakdown.get();

        if (action === 'delete') {
          return vms.get({
            include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
          });
        }
        reloadCurrentVm();
      },
      [vmId, reloadCurrentVm, isDetailsPage]
    );

    const snapshotCompleteCallback = React.useCallback(async () => {
      await snapshots.get();
      return reloadCurrentVm();
    }, [vmId, reloadCurrentVm]);

    const vmTask = useTask(currentVm.task, {
      onComplete: vmCompleteCallback,
    });
    const snapshotTask = useTask(currentSnapshot?.task, {
      onComplete: snapshotCompleteCallback,
    });

    const remoteSupport = useRemoteSupport({
      type: 'vm',
      vmId,
      onSuccess: reloadCurrentVm,
    });

    const handleState = React.useCallback(
      (values: AnyShape) => {
        const localState: AnyShape = {
          detailsDialogOpen: values.detailsDialogOpen,
          mediaDialogOpen: values.mediaDialogOpen,
          attachDialogOpen: values.attachDialogOpen,
          manageExternalIpDialogOpen: values.manageExternalIpDialogOpen,
          manageIpDialogOpen: values.manageIpDialogOpen,
          snapshotDialogOpen: values.snapshotDialogOpen,
          rsConfig: values.rsConfig,
        };

        const isDialogOpen = Object.keys(localState).some(
          (key) => !!localState[key]
        );

        dnd.merge({ isDialogOpen });

        Emitter.emit(CustomEvents.vmState, {
          currentVm,
          vmId,
          isDetailsPage,
          ...values,
        });
      },
      [vmId]
    );

    const { execute: confirmSnapshotRequest, isRequesting: isPendingSnapshot } =
      useSnapshotRequest(
        { serviceEntityId: vmId, serviceEntityName: 'Vm' },
        currentSnapshot?.id
      );

    const withBillingCycle = !!vm.data?.billingCycleId;
    const isRequesting =
      isPendingSnapshot ||
      billingInfo.isRequesting ||
      folders.isRequesting ||
      vm.isRequesting ||
      media.isRequesting ||
      vmTask.isTaskActive ||
      snapshotTask.isTaskActive;

    const handleDialogOpen = React.useCallback(
      (value: Record<string, any>) => () => {
        handleState(value);
        // dnd.merge({ isDialogOpen: !!Object.entries(value)[0][1] });
      },
      []
    );

    const handleRsOpen = React.useCallback<IRsOpenHandler>((rsConfig) => {
      return handleState({ rsConfig });
    }, []);

    const onEdit = React.useCallback(
      async (vmId?: number) => {
        const id = props.id || vmId;
        let _vm: IVmTypes.Vm = vm.data || undefined;
        let _billingInfo: IVmTypes.IBillingCircle =
          billingInfo.data || undefined;

        if (!isDetailsPage) {
          // @ts-ignore
          _vm = await vm.get({ id });
        }

        if (_vm?.billingCycleId) {
          // @ts-ignore
          _billingInfo = await billingInfo.get({ id: _vm?.billingCycleId });
        }

        const { data, billingCycleInterval } = parseUpdateData(
          _vm,
          _billingInfo
        );

        const isWindows = data.os.osFamilyId === 1;

        const otherPrices: Record<string, number> = {
          ...STATIC_SERVICE_COUNT,
        };

        if (isWindows) otherPrices.winLicense = 1;

        handleState({
          detailsDialogOpen: true,
          initialValues: data,
          billingCycleInterval,
          prices: {
            ...R.pick(USED_PRICE_LIST)(data),
            ...otherPrices,
          },
        });
      },
      [
        billingInfo.data,
        isDetailsPage,
        vmId,
        JSON.stringify(vm),
        JSON.stringify(props),
        JSON.stringify(dnd.data),
      ]
    );

    const onEditNic = React.useCallback((nic: IVmTypes.Nic) => {
      handleDialogOpen({
        manageIpDialogOpen: nic,
        selectedNetwork: nic.network,
      })();
    }, []);

    const onDeleteVm = React.useCallback(
      () =>
        confirmDeleteWithReasons({
          title: `${t('services.dialog.vms.confirm.delete.title')} ${
            vm.data?.name
          }`,
          content: withBillingCycle
            ? t('services.dialog.vms.confirm.delete.withBillingCycle.subtitle')
            : t('services.dialog.vms.confirm.delete.subtitle'),
          onSuccess: (val: IConfirmDeleteReasonsRes) =>
            vm
              .remove({
                id: vm.data.id,
                deletionReason: parseConfirmDeleteWithReasonResponse(val),
              })
              .then((res) => {
                if (!location.pathname.includes('/services/all')) {
                  changeQuery('/services/all');
                }

                return res;
              }),
          onCancel: () => undefined,
          successLabel: 'common.remove',
          cancelLabel: 'common.cancel',
        }),
      [vm.data?.id, withBillingCycle, vm.data?.name, location.pathname]
    );

    const onConfirmDetach = React.useCallback(
      (nic: IVmTypes.Nic) => {
        return confirm({
          title: t('services.vm.confirm.detachNetwork.title'),
          content: t('services.vm.confirm.detachNetwork.content'),
          onSuccess: async () => {
            try {
              await nics.remove({ vmId, nicId: nic.id });
              reloadCurrentVm();
              handleDialogOpen({
                manageIpDialogOpen: false,
                attachDialogOpen: false,
                selectedNetwork: undefined,
              })();
            } catch (error: any) {
              showSystemMessage(error.message, 'error');
            }
          },
          onCancel: () => undefined,
        });
      },
      [vmId]
    );

    const fetchFolders = React.useCallback(async () => {
      if (isDetailsPage)
        return folders.get({
          tenantId: account?.tenant?.id,
          type: 'VIRTUAL_SERVER',
        });

      return Emitter.emit(CustomEvents.fetchFolderList, {});
    }, [account?.tenant?.id, isDetailsPage]);

    const moveToFolder = React.useCallback(
      (f: IServiceGroups.Group) =>
        confirm({
          title: 'folders.confirm.moveToFolder.title',
          content: 'folders.confirm.moveToFolder.content',
          onSuccess: () =>
            folders
              .executeRequest('moveToFolder')(account?.tenant?.id, f.id, {
                appEntityId: currentVm.appEntityId,
              })
              .then(() => {
                reloadCurrentVm();
                Emitter.emit(CustomEvents.fetchVmList, {});
                return fetchFolders();
              })
              .catch((er) => {
                showSystemMessage(er.message, 'error');
              }),
          onCancel: () => undefined,
        }),
      [account?.tenant?.id, currentVm.appEntityId, fetchFolders, isDetailsPage]
    );

    const removeFromFolder = React.useCallback(
      () =>
        confirm({
          title: 'folders.confirm.removeFromFolder.title',
          content: 'folders.confirm.removeFromFolder.content',
          onSuccess: () =>
            folders
              .executeRequest('removeFromFolder')(
                account?.tenant?.id,
                currentVm.serviceGroupId,
                {
                  appEntityId: currentVm.appEntityId,
                }
              )
              .then(() => {
                reloadCurrentVm();
                Emitter.emit(CustomEvents.fetchVmList, {});
                return fetchFolders();
              })
              .catch((er) => {
                showSystemMessage(er.message, 'error');
              }),
          onCancel: () => undefined,
        }),
      [
        account?.tenant?.id,
        currentVm.appEntityId,
        currentVm.serviceGroupId,
        fetchFolders,
      ]
    );

    const switchVmNetworkType = React.useCallback(() => {
      const nextNetType =
        currentVm.networkType === 'PRIVATE' ? 'PUBLIC' : 'PRIVATE';
      confirm({
        title: 'services.vm.confirm.switchType.title',
        content: (
          <div>
            <div className="mb-30 steel fs-14">
              {t('services.vm.confirm.switchType.content')}
            </div>
            <div className="flex align-center mb-15">
              <div className="mr-20">
                {t(
                  `services.vm.confirm.switchType.content.type.${currentVm.networkType}`
                )}
              </div>
              <PrimaryElement
                component={MuiIcons.ArrowForward}
                className="fs-18"
              />
              <div className="ml-20">
                {t(
                  `services.vm.confirm.switchType.content.type.${nextNetType}`
                )}
              </div>
            </div>
            <Alert severity="warning" className="mb-20 fs-14">
              {t('services.vm.confirm.switchType.content.warning')}
            </Alert>
          </div>
        ),
        successLabel: 'common.confirm',
        cancelLabel: 'common.cancel',
        onSuccess: () =>
          vm
            .executeRequest('switchVmType')(vmId, { networkType: nextNetType })
            .then(reloadCurrentVm)
            .catch((er) => {
              showSystemMessage(er.message, 'error');
            }),
        onCancel: () => undefined,
      });
    }, [vmId, currentVm.networkType]);

    const ejectMedia = React.useCallback(
      () =>
        confirm({
          title: 'media.confirm.eject.title',
          content: 'media.confirm.eject.content',
          onSuccess: () =>
            media
              .executeRequest('ejectMedia')(vmId)
              .then(() => {
                showSystemMessage('media.notify.eject.success', 'success');
                return reloadCurrentVm();
              })
              .catch((er) => {
                showSystemMessage(er.message, 'error');
              }),
          onCancel: () => undefined,
        }),
      [vmId, reloadCurrentVm]
    );

    const onActionSuccess = React.useCallback(() => {
      vms.reload(vmId, {
        include: ['serviceMonitoring', 'serviceSnapshot'],
      });
      tasks.get();
    }, [vmId]);

    const onOpenDetails = React.useCallback(
      () => changeQuery(`/services/vm/info`, { id: vmId }),
      [vmId]
    );

    const handlers = React.useMemo(() => {
      return {
        powerOn: () => {
          return virtualMachinesService
            .powerOn(vmId)
            .then(onActionSuccess)
            .catch(showError);
        },
        powerOff: () => {
          return confirm({
            title: t(`services.vm.confirm.powerOff.title`, {
              title: currentVm.name,
            }),
            content: t(`services.vm.confirm.powerOff.subtitle`, {
              title: currentVm.name,
            }),
            onSuccess: () =>
              virtualMachinesService
                .powerOff(vmId)
                .then(onActionSuccess)
                .catch(showError),
            onCancel: noop,
          });
        },
        reset: () => {
          return confirm({
            title: t(`services.vm.confirm.reset.title`, {
              title: currentVm.name,
            }),
            content: t(`services.vm.confirm.reset.subtitle`, {
              title: currentVm.name,
            }),
            onSuccess: () =>
              virtualMachinesService
                .reset(vmId)
                .then(onActionSuccess)
                .catch(showError),
            onCancel: noop,
          });
        },
        delete: onDeleteVm,
        shutDown: () => {
          return confirm({
            title: t(`services.vm.confirm.shutDown.title`, {
              title: currentVm.name,
            }),
            content: t(`services.vm.confirm.shutDown.subtitle`, {
              title: currentVm.name,
            }),
            onSuccess: () =>
              virtualMachinesService
                .shutDown(vmId)
                .then(onActionSuccess)
                .catch(showError),
            onCancel: noop,
          });
        },
        openDetails: onOpenDetails,
        console: () => openWebConsoleWindow(vmId),
        manageExternalIp: handleDialogOpen({
          manageExternalIpDialogOpen: true,
        }),
        assignBadges: () => Emitter.emit(CustomEvents.assignDialog, currentVm),
        switchVmNetworkType,
        enableRemoteSupport: () => {
          handleRsOpen({
            actionType: 'enable',
            vmId: currentVm.id,
            osType: currentVm.os.osFamilyId === 1 ? 'win' : 'linux',
          });
        },
        disableRemoteSupport: () => {
          handleRsOpen({
            actionType: 'disable',
            vmId: currentVm.id,
            osType: currentVm.os.osFamilyId === 1 ? 'win' : 'linux',
          });
        },
        edit: onEdit,
        editNic: onEditNic,
        moveToFolder,
        removeFromFolder,
        insertMedia: handleDialogOpen({ mediaDialogOpen: true }),
        attachNetwork: handleDialogOpen({ attachDialogOpen: true }),
        detachNetwork: onConfirmDetach,
        ejectMedia,
        createSnapshot: () => {
          dnd.merge({ isDialogOpen: true });
          handleState({ snapshotDialogOpen: true });
        },
        revertSnapshot: () =>
          confirmSnapshotRequest('revert').then(reloadCurrentVm),
        removeSnapshot: () =>
          confirmSnapshotRequest('remove').then(reloadCurrentVm),
        cancelSnapshotAutoremoval: () =>
          confirmSnapshotRequest('cancelAutoremoval').then(reloadCurrentVm),
      };
    }, [
      JSON.stringify(currentVm),
      vmId,
      onActionSuccess,
      onDeleteVm,
      moveToFolder,
      removeFromFolder,
      onConfirmDetach,
      snapshotCompleteCallback,
      JSON.stringify(dnd.data),
    ]) as IActionHandlers;

    const { menuItems, actionItems } = useVmMenuItems({
      instance: currentVm,
      isRequesting,
      remoteSupport,
      handlers,
      folders: folders.data.filter((f) => f.type === 'VIRTUAL_SERVER'),
      isInFolder: isInFolder,
    });

    return (
      <>
        <Component
          {...props}
          fetchVm={vm.get}
          fetchBillingInfo={billingInfo.get}
          isRequesting={
            vm.isRequesting ||
            billingInfo.isRequesting ||
            nics.isRequesting ||
            isPendingSnapshot
          }
          isInFolder={isInFolder}
          onEdit={onEdit}
          handlers={handlers}
          onDelete={onDeleteVm}
          handleRsOpen={handleRsOpen}
          confirmSnapshotRequest={confirmSnapshotRequest}
          currentSnapshot={currentSnapshot}
          menuItems={menuItems}
          actionItems={actionItems}
          snapshotTask={snapshotTask}
          injectedMedia={currentVm.vcdMediaName}
          assignedNetworks={assignedNetworks}
        />
      </>
    );
  });
}
