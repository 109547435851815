import * as React from 'react';
import * as M from 'mathjs';
import cn from 'classnames';
import { AccessLevelLow } from 'global-shapes';
import {
  Row,
  Col,
  Chip,
  Button,
  MuiIcons,
  IconButton,
  CopyTooltip,
  LightTooltip,
  LinearTable,
  OpenUserIcon,
  AccordionSummary,
  AccordionDetails,
  IconOpenAsPdf,
} from 'elements';
import { IInvoice, IInvoiceDoc, IInvoicePosition } from 'invoices';
import { useTranslation } from 'react-i18next';
import { numberToCurrency, openInNewTab, round, dayjs, remCalc } from 'utils';
import { ListItemMenu } from './ListItemMenu';
import { IInvoiceTypes } from './types';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import Positions from './Positions/Positions';
import { SAccordion, ExpandIcon, TableTotalBlock } from './Styled';
import { definePaymentsColumns, chipStatusMap } from './constants';

import { ITotalPositionPricesShape, sumAllPositionTotalPrice } from './helpers';

type Props = IInvoice & {
  expanded: boolean;
  isEditable: boolean;
  isRequesting: boolean;
  stripeEnabled: boolean;
  userSource: 'tenant' | 'partner';
  pretendedLevel: AccessLevelLow;
  accessLevel: AccessLevelLow;
  currentAccessLevel: AccessLevelLow;
  reloadInvoice: (id: number) => void;
  onOpenDetails: (id: number) => void;
  onEdit: (invoice: IInvoice) => void;
  onMarkAsPaid: (invoice: IInvoice) => void;
  onResetStatus: (invoice: IInvoice) => void;
  onCancelInvoice: (isCancel: boolean, invoiceId: number) => void;
  onAddPosition: (id: number) => void;
  onAddPayment: (id: number) => void;
  onDelete: (id: number) => void;
  onPayWithStripe: (id: number) => void;
  openUserInNewTab: IInvoiceTypes.IOpenUserInNewTab;
  onDeletePosition: (pos: IInvoicePosition, invoiceId: number) => void;
  onEditPosition: (invoiceId: number, position: IInvoicePosition) => void;
  onDeletePayment: (positionId: number, invoiceId: number) => void;
};

const View = observer(
  (
    props: Props & {
      positions: StateHandlers.IInvoicePositionsHandler;
      payments: StateHandlers.IInvoicePaymentsHandler;
    }
  ) => {
    const {
      expanded,
      isEditable,
      isRequesting,
      userSource,
      accessLevel,
      reloadInvoice,
      onOpenDetails,
      onEdit,
      onMarkAsPaid,
      onResetStatus,
      onCancelInvoice,
      onAddPosition,
      onAddPayment,
      openUserInNewTab,
      onDeletePosition,
      onEditPosition,
      onDeletePayment,
      onDelete,
      onPayWithStripe,
      stripeEnabled,
      currentAccessLevel,
      pretendedLevel,
      ...invoice
    } = props;
    const {
      id,
      invoiceNumberString,
      invoiceDate,
      dueDate,
      paidDate,
      doc,
      total,
      status,
      totalRoundDiff,
      positions,
      payments,
      paidAmount,
      aggregatedTaxes,
      manualStatus,
      autoSend,
    } = invoice;

    const { t } = useTranslation();

    const docTimer = React.useRef<ReturnType<typeof setInterval>>();

    const name = props[userSource]?.name;

    const isPaid = status === 'PAID';
    const isCancelEnabled = ['CANCELED', 'UNPAID', 'OVERDUE'].includes(status);
    const isOwnInvoices =
      currentAccessLevel === 'partner' && pretendedLevel === 'tenant';

    const showActions = React.useMemo(() => {
      if (isOwnInvoices) {
        return false;
      }

      if (accessLevel === 'tenant') {
        return false;
      }

      return isEditable;
    }, [isOwnInvoices, accessLevel, isEditable]);

    const isResendEnabled = !isOwnInvoices
      ? ['UNPAID', 'OVERDUE', 'PARTIALLY_PAID'].includes(status)
      : false;

    const showStripeButton = React.useMemo(() => {
      let showStripeByLevel = false;
      const showStripeByStatus = [
        'UNPAID',
        'PARTIALLY_PAID',
        'OVERDUE',
      ].includes(status);

      if (currentAccessLevel === 'partner') {
        showStripeByLevel = pretendedLevel === 'tenant';
      }

      if (currentAccessLevel === 'tenant') {
        showStripeByLevel = stripeEnabled;
      }

      return showStripeByLevel && showStripeByStatus;
    }, [pretendedLevel, currentAccessLevel, stripeEnabled]);

    const paymentsColumns = React.useMemo(
      () =>
        definePaymentsColumns({
          t,
          isEditable,
          onDelete: (paymentId) => onDeletePayment(paymentId, id),
        }),
      [onDeletePayment, isEditable]
    );

    const positionTotals: ITotalPositionPricesShape = React.useMemo(
      () =>
        sumAllPositionTotalPrice(
          positions.data,
          totalRoundDiff,
          aggregatedTaxes?.map((tax) => tax.taxValue) || []
        ),
      [JSON.stringify(positions.data), totalRoundDiff, aggregatedTaxes]
    );

    const balance: number = M.add(-round(positionTotals.total, 4), +paidAmount);

    const isNegativeBalance = React.useMemo(
      () => round(balance, 2) < 0,
      [balance]
    );

    const initDocRealtime = React.useCallback(
      (state?: IInvoiceDoc['state']) => {
        clearInterval(docTimer.current);
        if (state === 'INPROGRESS') {
          docTimer.current = setInterval(() => {
            reloadInvoice(id);
          }, 5000);
        }

        return () => {
          clearInterval(docTimer.current);
        };
      },
      [id]
    );

    const openUserPortal = React.useCallback(
      (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        openUserInNewTab(props[userSource] as any);
      },
      [id]
    );

    React.useEffect(() => {
      const stopRefetchingDoc = initDocRealtime(doc?.state);

      return stopRefetchingDoc;
    }, [doc?.state]);

    return (
      <SAccordion
        elevation={0}
        expanded={expanded}
        onChange={() => onOpenDetails(id)}
        className={cn('mb-5')}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls={`${id}-content`}
          id={`${id}-header`}
          className="m-0 pl-0 pr-0 lh-1"
          classes={{ content: 'm-0' }}
        >
          <Row alignItems="center">
            <Col style={{ width: remCalc(50), textAlign: 'center' }}>
              <ExpandIcon expanded={expanded} className={cn('pointer fs-20')} />
            </Col>
            <Col xs>
              <Row justifyContent="space-between" alignItems="center">
                {pretendedLevel !== 'tenant' && (
                  <Col xs={2}>
                    <Row alignItems="center" justifyContent="space-between">
                      <Col className="primary" xs={10}>
                        {name}
                      </Col>
                      <IconButton onClick={openUserPortal}>
                        <OpenUserIcon />
                      </IconButton>
                    </Row>
                  </Col>
                )}
                <Col xs={4} className="steel">
                  <Row alignItems="center">
                    <Col xs={4}>
                      <CopyTooltip enableDoubleClick={false}>
                        {invoiceNumberString}
                      </CopyTooltip>
                    </Col>
                    <Col xs={4}>
                      <Row columnSpacing={1}>
                        <Col>
                          {invoiceDate
                            ? dayjs(invoiceDate).utc().format('DD.MM.YYYY')
                            : '-'}
                        </Col>
                        {autoSend && (
                          <Col>
                            <LightTooltip
                              title={t('invoices.item.tooltips.autoSend')}
                              placement="top"
                              arrow
                            >
                              <MuiIcons.InfoOutlined className="fs-16 success" />
                            </LightTooltip>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col xs={4}>
                      {dayjs(dueDate).utc().format('DD.MM.YYYY')}
                    </Col>
                  </Row>
                </Col>
                <Col xs={3} className="steel">
                  <Row alignItems="center" columnSpacing={2}>
                    <Col xs={4}>
                      {paidDate
                        ? dayjs(paidDate).utc().format('DD.MM.YYYY')
                        : '-'}
                    </Col>
                    <Col xs={5} className="text-right">
                      {numberToCurrency(+total, false)}
                    </Col>
                    <Col xs={3}>
                      <Chip status={chipStatusMap[status]}>
                        {t(`invoices.current.status.${status}`)}
                      </Chip>
                    </Col>
                  </Row>
                </Col>
                <Col xs={2}>
                  {doc && (
                    <Row
                      alignItems="center"
                      justifyContent="flex-end"
                      columnSpacing={1}
                      style={{ minWidth: remCalc(237) }}
                    >
                      <Col>
                        <LightTooltip title={t('invoices.buttons.openAsPdf')}>
                          <IconButton
                            size="small"
                            disabled={doc.state === 'INPROGRESS'}
                            onClick={(ev: React.MouseEvent) => {
                              ev.stopPropagation();
                              openInNewTab(doc?.url);
                            }}
                          >
                            <IconOpenAsPdf status="primary" />
                          </IconButton>
                        </LightTooltip>
                      </Col>
                      {showStripeButton && (
                        <Col>
                          <Button
                            size="small"
                            disabled={doc.state === 'INPROGRESS'}
                            onClick={(ev: React.MouseEvent) => {
                              ev.stopPropagation();
                              onPayWithStripe(id);
                            }}
                          >
                            {t('invoices.item.menu.pay')}
                          </Button>
                        </Col>
                      )}
                      <Col
                        className="pr-5"
                        onClick={(ev: React.MouseEvent) => ev.stopPropagation()}
                      >
                        <ListItemMenu
                          id={id}
                          onCancelInvoice={() =>
                            onCancelInvoice(status !== 'CANCELED', id)
                          }
                          onDelete={onDelete}
                          // showActions={isEditable && !isPaid}
                          showActions={showActions}
                          isCancelEnabled={isCancelEnabled}
                          isRequesting={isRequesting}
                          isResendEnabled={isResendEnabled}
                          accessLevel={accessLevel}
                          currentAccessLevel={currentAccessLevel}
                          status={status}
                          manualStatus={manualStatus}
                          total={+total}
                          onEdit={
                            showActions ? () => onEdit(invoice) : undefined
                          }
                          onMarkAsPaid={
                            showActions
                              ? () => onMarkAsPaid(invoice)
                              : undefined
                          }
                          onResetStatus={
                            showActions
                              ? () => onResetStatus(invoice)
                              : undefined
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pl-50 full-width">
            <div className="uppercase fs-14">
              {t('invoices.current.positions.title')}
            </div>
            <div className="pl-15">
              {positions.dataReceived && !positions.data.length ? (
                <div className="bolder steel pt-20 pb-20 fs-14">
                  {t('common.noData')}
                </div>
              ) : (
                <>
                  <Positions
                    positions={positions}
                    onDelete={(pos) => onDeletePosition(pos, id)}
                    onEdit={(pos) => onEditPosition(id, pos)}
                    isEditable={isEditable}
                    isPaid={isPaid}
                  />

                  <Row className="mb-30">
                    <Col xs={6}></Col>
                    <Col xs={6}>
                      <Row>
                        <Col xs={10}>
                          <TableTotalBlock>
                            <div className="flex justify-between align-center full-width mb-15">
                              <div className="steel fs-12">
                                {t('invoices.current.positions.subtotal')}
                              </div>
                              <div>
                                {numberToCurrency(
                                  round(positionTotals.clearTotal, 2),
                                  false,
                                  {
                                    minimumIntegerDigits: 1,
                                  }
                                )}
                              </div>
                            </div>
                            {aggregatedTaxes?.map((tax) => {
                              return (
                                <div
                                  key={tax.vatId + tax.startDate}
                                  className="flex justify-between align-center full-width mb-15"
                                >
                                  <div className="steel fs-12">
                                    {t('invoices.current.positions.vat', {
                                      value: parseFloat(tax.vatValue),
                                    })}
                                  </div>
                                  <div>
                                    {numberToCurrency(
                                      round(tax.taxValue, 2),
                                      false,
                                      {
                                        minimumIntegerDigits: 1,
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            <div className="flex justify-between align-center full-width mb-15">
                              <div className="steel fs-12">
                                {t(
                                  'invoices.current.positions.roundDifference'
                                )}
                              </div>
                              <div>
                                {numberToCurrency(
                                  round(+totalRoundDiff, 2),
                                  false,
                                  {
                                    minimumIntegerDigits: 1,
                                  }
                                )}
                              </div>
                            </div>
                            <div className="flex justify-between align-center full-width">
                              <div className="fs-14 bold">
                                {t('invoices.current.positions.totalIncVat')}
                              </div>
                              <div>
                                {numberToCurrency(
                                  round(positionTotals.total, 2),
                                  false,
                                  {
                                    minimumIntegerDigits: 1,
                                  }
                                )}
                              </div>
                            </div>
                          </TableTotalBlock>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </div>
            <div className="uppercase fs-14">
              {t('invoices.current.payments.title')}
            </div>
            <div className="pl-15">
              {payments.dataReceived && !payments.data.length ? (
                <div className="bolder steel pt-20 pb-20 fs-14">
                  {t('common.noData')}
                </div>
              ) : (
                <>
                  <LinearTable
                    headerCellClassName="steel fs-12"
                    cellClassName="pt-10 pb-10"
                    className={cn({ disabled: payments.isRequesting })}
                    collapseEdgePaddings={false}
                    columns={paymentsColumns}
                    data={payments.data}
                  />

                  <Row className="mb-35">
                    <Col xs={6}></Col>
                    <Col xs={6}>
                      <Row>
                        <Col xs={10}>
                          <TableTotalBlock>
                            <div className="flex justify-between align-center full-width">
                              <div className="steel fs-12">
                                {t('invoices.current.balance')}
                              </div>
                              <div
                                className={cn('bolder', {
                                  error: isNegativeBalance,
                                })}
                              >
                                {numberToCurrency(round(balance, 2), false, {
                                  minimumIntegerDigits: 1,
                                })}
                              </div>
                            </div>
                          </TableTotalBlock>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </div>
            {isEditable && !isPaid && (
              <Row justifyContent="flex-end" alignItems="center">
                <Col>
                  <Row columnSpacing={2}>
                    <Col>
                      <Button
                        onClick={() => onAddPayment(id)}
                        disabled={isRequesting}
                      >
                        {t('invoices.buttons.addPayment')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => onAddPosition(id)}
                        disabled={isRequesting}
                      >
                        {t('invoices.buttons.addPositions')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </AccordionDetails>
      </SAccordion>
    );
  }
);

const InvoiceListItem = (pr: Props) => (
  <View
    {...pr}
    positions={StateHandlers.invoicePositions}
    payments={StateHandlers.invoicePayments}
  />
);

export default InvoiceListItem;
