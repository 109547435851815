import * as React from 'react';
import { PALETTE } from 'enums';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { remCalc } from 'utils';
import { styled, Row } from 'elements';

export const LinearTabs = styled(Row)(() => ({
  boxShadow: `0 1px 0px 0 ${PALETTE.lightGrey}`,
  marginBottom: remCalc(12),
}));

export const LinearTabLink = styled(
  (props: NavLinkProps & { isActive: boolean }) => {
    const { isActive, ...rest } = props;
    return <NavLink {...rest} />;
  }
)(({ theme, isActive }) => ({
  padding: remCalc(15, 0),
  color: isActive ? 'inherit' : PALETTE.steel,
  fontWeight: 600,
  transition: 'all ease-in-out 0.2s',
  borderBottom: isActive
    ? `${remCalc(5)} solid ${theme.palette.primary.main}`
    : `${remCalc(5)} solid transparent`,
  '&:hover': {
    color: 'inherit',
  },
  '&:not(:last-of-type)': {
    marginRight: remCalc(40),
  },
}));

export const LinearTabButton = styled(
  (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > & { isActive: boolean }
  ) => {
    const { isActive, ...rest } = props;
    return <div {...rest} />;
  }
)(({ theme, isActive }) => ({
  padding: remCalc(15, 0),
  cursor: 'pointer',
  color: isActive ? 'inherit' : PALETTE.steel,
  fontWeight: 600,
  transition: 'all ease-in-out 0.2s',
  borderBottom: isActive
    ? `${remCalc(5)} solid ${theme.palette.primary.main}`
    : `${remCalc(5)} solid transparent`,
  '&:hover': {
    color: 'inherit',
  },
  '&:not(:last-of-type)': {
    marginRight: remCalc(40),
  },
}));
