import React from 'react';
import { SystemMessageStatus } from 'global-shapes';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { iconMapping } from 'elements/Alert';
import { Row, Col, styled } from 'elements';
import { remCalc } from 'utils';

type Props = {
  title: any;
  status: SystemMessageStatus;
};

const Root = styled('div')(() => ({
  minWidth: remCalc(270),
  padding: remCalc(5, 0, 8),
  maxWidth: remCalc(450),
}));

const Icon = styled('span')(() => ({
  fontSize: remCalc(24),
  marginRight: remCalc(15),
  lineHeight: remCalc(28),
  display: 'inline-block',

  '& .span': {
    fontSize: 'inherit',
  },
}));

const StatusMessage = styled('div')(() => ({
  fontSize: remCalc(14),
  lineHeight: remCalc(26),
  marginBottom: remCalc(5),
}));

const Title = styled('div')(() => ({
  fontSize: remCalc(16),
  lineHeight: 1,
  wordBreak: 'break-word',
}));

const CustomSystemMessage = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Row>
        <Col>
          {props.status && (
            <div>
              <Icon className={cn(props.status)}>
                {iconMapping[props.status]}
              </Icon>
            </div>
          )}
        </Col>

        <Col xs>
          {props.status && (
            <StatusMessage className={cn(props.status)}>
              {t(`app.alerts.${props.status}`)}
            </StatusMessage>
          )}
          <Title>{props.title}</Title>
        </Col>
      </Row>
    </Root>
  );
};

export default CustomSystemMessage;
