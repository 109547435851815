import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { find, propEq } from 'ramda';
import {
  Input,
  Row,
  Col,
  Switch,
  Select,
  DialogProps,
  Button,
  Checkbox,
  Tooltip,
  parseSups,
  MuiIcons,
} from 'elements';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { validateCallback, validator, confirm } from 'utils';
import { generatePreSharedKey, validationSchema } from './helpers';
import {
  INITIAL_SET_UP_IPSEC_VPN,
  IKE_OPTIONS,
  DIFFIE_HELLMAN_OPTIONS,
  DIGEST_ALGORITHM_OPTIONS,
  ENCRYPTION_ALGORITHM_OPTIONS,
} from '../constants';
import { ALL_TEST_IDS } from 'enums';
import { IIpSecVpnTunnel } from 'ipsec-vpn-tunnels';
import { IpsecVpnDialog, IpsecVpnSwitcherWrapper } from '../Styled';

type Props = DialogProps<IIpSecVpnTunnel, boolean> & {
  isNew: boolean;
  isRequesting: boolean;
  initialValues: IIpSecVpnTunnel | null;
  connectivity: IConnectivityTypes.Connectivity;
};

const IpsecVpnDetails = (props: Props) => {
  const { t } = useTranslation();
  const { initialValues, connectivity, isNew, isRequesting } = props;
  const onSubmit = useCallback(
    (val: IIpSecVpnTunnel) => Promise.resolve(props.onSave(val, isNew)),
    [isNew]
  );

  const initials = useMemo(
    () => ({
      ...INITIAL_SET_UP_IPSEC_VPN,
      preSharedKey: isNew ? generatePreSharedKey() : undefined,
    }),
    [props.open, isNew]
  );

  const {
    values,
    resetForm,
    handleChange,
    errors,
    handleSubmit,
    setFieldValue,
    setValues,
    submitCount,
  } = useFormik({
    initialValues: initials,
    validateOnMount: false,
    validationSchema: validationSchema(isNew),
    onSubmit,
  });

  React.useEffect(() => {
    if (!props.open) {
      resetForm();
    }
    if (props.open) {
      const newValue = isNew
        ? initials
        : initialValues
        ? {
            ...initialValues,
            // @ts-ignore
            remoteSubnets: initialValues.remoteSubnets.join(', ') || '',
            localSubnets: initialValues.localSubnets[0],
            ikeVersion: find(propEq('value', initialValues.ikeVersion))(
              IKE_OPTIONS
            ),
            digestAlgorithm: find(
              propEq('value', initialValues.digestAlgorithm)
            )(DIGEST_ALGORITHM_OPTIONS),
            diffieHellmanGroup: find(
              propEq('value', initialValues.diffieHellmanGroup)
            )(DIFFIE_HELLMAN_OPTIONS),
            encryptionAlgorithm: find(
              propEq('value', initialValues.encryptionAlgorithm)
            )(ENCRYPTION_ALGORITHM_OPTIONS),
          }
        : initials;
      // @ts-ignore
      setValues(newValue);
    }
  }, [props.open]);

  return (
    <IpsecVpnDialog
      open={props.open}
      onClose={props.onClose}
      title={t('services.dialog.sslVpn.tunnel.add.title')}
      handleSubmit={handleSubmit}
      testId={ALL_TEST_IDS.services.connectivity.ipsecVpnDialog}
      maxWidth={false}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
      classes={{
        paper: '_dialog',
      }}
    >
      <div className="steel mb-25">
        {t('services.dialog.sslVpn.tunnel.add.description')}
      </div>
      <div className="uppercase fs-14 bolder mb-15">
        {t('services.dialog.sslVpn.tunnel.add.subtitle.connection')}
      </div>
      <Row columnSpacing={4} alignItems="flex-start" className="mb-25">
        <Col xs={12} md={6}>
          <Input
            name="name"
            label="forms.name"
            placeholder="forms.placeholders.name"
            value={values.name}
            onChange={validateCallback({ max: 30 })(handleChange)}
            error={!!submitCount && !!errors.name}
            helperText={errors.name}
            helperTextOptions={{ max: 30 }}
          />
        </Col>
        <Col xs={12} md={6}>
          <div className="flex">
            <Switch
              className="mt-35"
              checked={values.isEnabled}
              name="isEnabled"
              onCheck={(isEnabled) => {
                if (!isEnabled) {
                  return confirm({
                    title:
                      'services.connectivity.ipsecVpnTunnels.confirm.disableConnection.title',
                    content:
                      'services.connectivity.ipsecVpnTunnels.confirm.disableConnection.content',
                    onSuccess: () => setFieldValue('isEnabled', isEnabled),
                    onCancel: () => undefined,
                  });
                }
                setFieldValue('isEnabled', isEnabled);
              }}
              label={t(
                'services.connectivity.ipsecVpnTunnels.connectionStatus'
              )}
            />
          </div>
        </Col>
      </Row>
      <Row columnSpacing={4} alignItems="flex-start" className="mb-25">
        <Col xs={12} sm={6}>
          <Row columnSpacing={2}>
            <Col xs={12} sm={6}>
              <Input
                name="localEndpoint"
                label="forms.localEndpoint"
                placeholder="forms.placeholders.localEndpoint"
                value={isNew ? connectivity.publicIp : values.localEndpoint}
                disabled
              />
            </Col>
            <Col xs={12} sm={6}>
              <Input
                name="localSubnet"
                label="forms.localSubnet"
                placeholder="forms.placeholders.localSubnet"
                value={
                  isNew
                    ? connectivity.internalNetworkAddress
                    : values.localSubnets
                }
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={6}>
          <Row columnSpacing={2}>
            <Col xs={12} sm={6}>
              <Input
                name="remoteEndpoint"
                label="forms.remoteEndpoint"
                placeholder="forms.placeholders.remoteEndpoint"
                value={values.remoteEndpoint}
                onChange={validateCallback({ restrict: validator.ipSymbols })(
                  handleChange
                )}
                error={!!submitCount && !!errors.remoteEndpoint}
                helperText={errors.remoteEndpoint}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Input
                name="remoteSubnets"
                label={
                  <span className="flex align-end">
                    <span>{t('forms.remoteSubnet')}</span>
                    <Tooltip
                      title={parseSups(t('forms.sups.remoteSubnets'))}
                      placement="right"
                      arrow
                    >
                      <sup>
                        <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                      </sup>
                    </Tooltip>
                  </span>
                }
                placeholder="forms.placeholders.remoteSubnet"
                value={values.remoteSubnets}
                onChange={validateCallback({
                  restrict: validator.ipAndSubnetSymbols,
                })(handleChange)}
                error={!!submitCount && !!errors.remoteSubnets}
                helperText={errors.remoteSubnets}
                helperTextOptions={{ max: 10 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row columnSpacing={4} className="mb-25">
        <Col xs={12} md={6}>
          <div className="uppercase fs-14 bolder mb-15">
            {t('services.dialog.sslVpn.tunnel.add.subtitle.security.phase1')}
          </div>
          <div className="mb-25">
            <Row columnSpacing={1}>
              {!isNew && (
                <Col className="pt-35" xs={4}>
                  <Checkbox
                    checked={values.editKey}
                    label={t('forms.editKey')}
                    onCheck={(isChecked) => {
                      setFieldValue('editKey', isChecked);
                      setFieldValue(
                        'preSharedKey',
                        isChecked ? '' : generatePreSharedKey()
                      );
                    }}
                  />
                </Col>
              )}
              <Col xs={isNew ? 12 : 8}>
                <Input
                  name="preSharedKey"
                  label={
                    <span className="flex align-end">
                      <span>{t('forms.preSharedKey')}</span>
                      <Tooltip
                        title={parseSups(t('forms.sups.preSharedKey'))}
                        placement="right"
                        arrow
                      >
                        <sup>
                          <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                        </sup>
                      </Tooltip>
                    </span>
                  }
                  placeholder="forms.placeholders.preSharedKey"
                  hideAdornment
                  value={values.preSharedKey}
                  type={isNew ? 'text' : values.editKey ? 'text' : 'password'}
                  onChange={handleChange}
                  disabled={isNew ? false : !values.editKey}
                  error={!!submitCount && !!errors.preSharedKey}
                  helperText={errors.preSharedKey}
                  helperTextOptions={{ min: 16, max: 64 }}
                />
              </Col>
            </Row>
          </div>
          <Row columnSpacing={2} className="mb-25">
            <Col xs={4}>
              <Select
                name="ikeVersion"
                label={t('forms.ikeVersion')}
                placeholder={t('forms.placeholders.select')}
                value={values.ikeVersion}
                onChange={(val) => setFieldValue('ikeVersion', val)}
                options={IKE_OPTIONS}
                error={!!submitCount && !!errors.ikeVersion}
                helperText={errors.ikeVersion}
              />
            </Col>
            <Col xs={4}>
              <Select
                name="encryptionAlgorithm"
                label={t('forms.encryptionAlgorithm')}
                placeholder={t('forms.placeholders.select')}
                value={values.encryptionAlgorithm}
                onChange={(val) => setFieldValue('encryptionAlgorithm', val)}
                options={ENCRYPTION_ALGORITHM_OPTIONS}
                error={!!submitCount && !!errors.encryptionAlgorithm}
                helperText={errors.encryptionAlgorithm}
              />
            </Col>
            <Col xs={4}>
              <Input
                disabled={true}
                value="Main"
                label={t(
                  'services.connectivity.ipsecVpnTunnels.negotiationMode'
                )}
              />
            </Col>
          </Row>
          <Row columnSpacing={2}>
            <Col xs={4}>
              <Select
                name="diffieHellmanGroup"
                label={t('forms.diffieHellmanGroup')}
                placeholder={t('forms.placeholders.select')}
                value={values.diffieHellmanGroup}
                options={DIFFIE_HELLMAN_OPTIONS}
                onChange={(val) => setFieldValue('diffieHellmanGroup', val)}
                error={!!submitCount && !!errors.diffieHellmanGroup}
                helperText={errors.diffieHellmanGroup}
              />
            </Col>
            <Col xs={4}>
              <Select
                name="digestAlgorithm"
                label={t('forms.authenticationAlgorithm')}
                placeholder={t('forms.placeholders.select')}
                value={values.digestAlgorithm}
                onChange={(val) => setFieldValue('digestAlgorithm', val)}
                options={DIGEST_ALGORITHM_OPTIONS}
                error={!!submitCount && !!errors.digestAlgorithm}
                helperText={errors.digestAlgorithm}
              />
            </Col>
            <Col xs={4}>
              <Input
                disabled={true}
                value={t(
                  'services.connectivity.ipsecVpnTunnels.saLifetime.28.8'
                )}
                label={t('forms.saLifetime')}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <div className="uppercase fs-14 bolder mb-35">
            {t('services.dialog.sslVpn.tunnel.add.subtitle.security.phase2')}
          </div>
          <IpsecVpnSwitcherWrapper className={cn('flex align-center')}>
            <Switch
              checked={values.perfectForwardSecrecy}
              name="perfectForwardSecrecy"
              onCheck={(isChecked) =>
                setFieldValue('perfectForwardSecrecy', isChecked)
              }
              label={t('forms.perfectForwardSecrecy')}
            />
          </IpsecVpnSwitcherWrapper>
          <Row columnSpacing={2} className="mb-25">
            <Col md={4} xs={12}>
              <Input
                label={t('forms.encryptionAlgorithm')}
                // @ts-ignore
                value={values.encryptionAlgorithm.label}
                disabled
              />
            </Col>

            <Col md={4} xs={12}>
              <Input
                label={t('forms.authenticationAlgorithm')}
                // @ts-ignore
                value={values.digestAlgorithm.label}
                disabled
              />
            </Col>

            <Col md={4} xs={12}>
              <Input
                disabled={true}
                value={t(
                  'services.connectivity.ipsecVpnTunnels.saLifetime.3.6'
                )}
                label={t('forms.saLifetime')}
              />
            </Col>
          </Row>
          <Row columnSpacing={2}>
            <Col md={4} xs={12}>
              <Input
                disabled={true}
                value="ESP"
                label={t('forms.tunnelMode')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </IpsecVpnDialog>
  );
};

export default IpsecVpnDetails;
