import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import { styled, MuiIcons, Skeleton } from 'elements';

export const CardWrapper = styled('div')(() => ({
  textAlign: 'center',
  position: 'relative',
  minHeight: remCalc(337),
}));

export const FolderWrapper = styled('div')(() => ({
  textAlign: 'center',
  position: 'relative',
  minHeight: remCalc(337),
}));

export const CardPlaceholder = styled('p')(() => ({
  padding: remCalc(0, 15),
  maxWidth: remCalc(415),
}));

export const CardToDeployContent = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  color: theme.palette.primary.main,
  fontSize: remCalc(17),
  fontWeight: 600,
}));

export const CardAddIconWrapper = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
}));

export const CardLabel = styled('div')(() => ({
  color: PALETTE.steel,
  fontSize: remCalc(12),
}));

export const VmSystemInfoValue = styled('strong')(() => ({
  lineHeight: 0.9,
  display: 'inline-block',
}));

export const SPublicServiceItem = styled('div')(() => ({
  padding: remCalc(4, 8),
  minHeight: remCalc(24),
  borderRadius: 4,
  backgroundColor: PALETTE.primary,
  color: PALETTE.white,
  lineHeight: remCalc(15),
  fontSize: remCalc(12),
  marginTop: remCalc(6),
  '&:not(:last-of-type)': {
    marginRight: remCalc(10),
  },
}));

export const AutoreviewIcon = styled(MuiIcons.Autorenew)(({ theme }) => ({
  fontSize: remCalc(20),
  color: theme.palette.primary.main,
  lineHeight: 0,
  verticalAlign: 'middle',
  marginLeft: remCalc(10),
  cursor: 'pointer',
}));

export const GoToIcon = styled(MuiIcons.ArrowForward)(({ theme }) => ({
  fontSize: remCalc(16),
  color: theme.palette.primary.main,
  lineHeight: 0,
  verticalAlign: 'middle',
  cursor: 'pointer',
}));

export const VmLoaderSkeleton = styled(Skeleton)(() => ({
  width: '100%',
  height: remCalc(422),
  marginBottom: remCalc(20),
}));

export const NetworkStatusColor = styled('div')(({ theme }) => ({
  '&._success': {
    color: PALETTE.success,
  },

  '&._warning': {
    color: PALETTE.warning,
  },

  '&._info': {
    color: PALETTE.primaryLight,
  },

  '&._systemInfo': {
    color: PALETTE.primaryLight,
  },

  '&._primary': {
    color: PALETTE.primary,
  },

  '&._default': {
    color: PALETTE.steel,
  },

  '&._error': {
    color: PALETTE.errorSecondary,
  },
  '&._brand-primary': {
    color: theme.palette.primary.main,
  },
}));
