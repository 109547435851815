import {
  AccessLevelLow,
  AccessLevelMap,
  NavItem,
  AnyShape,
  AccessLevel,
  ValueType,
} from 'global-shapes';
import { ICurrentUser, PermissionsMap } from 'auth-shapes';
import {
  DEFAULT_PARTNERS_QUERY,
  DEFAULT_INVOICES_PARAMS,
  DEFAULT_USERS_PARAMS,
  DEFAULT_BILLABLE_ITEMS_PARAMS,
  DEFAULT_CUSTOMERS_QUERY,
} from './defaultQueryForPages';
import { compact } from 'utils/customFunctions';
import { generateNumberSeq } from 'utils/global';
import * as Yup from 'yup';
import * as MuiIcons from '@mui/icons-material';

interface GlobalResponseInfo {
  status: number;
}

export interface GlobalResponse<D> {
  info?: GlobalResponseInfo;
  data: D;
  [extraProps: string]: any;
}

const getProviderNavItems = (permissions: PermissionsMap): NavItem[] => {
  const hasPricingPermission =
    permissions['PRICING'] && permissions['PRICING'].canView;
  const hasAppStorePermission =
    permissions['APP_STORE'] && permissions['APP_STORE'].canView;
  const hasInvoicesPermission =
    permissions['INVOICES'] && permissions['INVOICES'].canView;

  const items = compact([
    {
      label: 'app.navigation.partners',
      to: '/partners',
      namespace: '/partners',
      icon: MuiIcons.Group,
      permissionName: 'PARTNERS',
      defaultQuery: DEFAULT_PARTNERS_QUERY,
    },
    {
      label: 'app.navigation.usage',
      namespace: '/usage',
      to: '/usage',
      icon: MuiIcons.InsertChart,
      permissionName: 'USAGE',
      defaultQuery: {
        page: 1,
        perPage: 20,
        orderBy: 'name',
        orderType: 'asc',
      },
    },
    hasInvoicesPermission && {
      label: 'app.navigation.invoices',
      namespace: '/invoices',
      to: '/invoices',
      icon: MuiIcons.AttachMoney,
      permissionName: 'INVOICES',
      defaultQuery: DEFAULT_INVOICES_PARAMS,
    },
    {
      label: 'app.navigation.billableItems',
      namespace: '/billable-items',
      to: '/billable-items',
      icon: MuiIcons.Info,
      permissionName: 'BILLABLE_ITEMS',
      defaultQuery: DEFAULT_BILLABLE_ITEMS_PARAMS,
    },
    {
      label: 'app.navigation.users',
      to: '/users',
      namespace: '/users',
      icon: MuiIcons.Person,
      permissionName: 'USERS',
      defaultQuery: DEFAULT_USERS_PARAMS,
    },
  ]);

  if (hasAppStorePermission || hasPricingPermission) {
    items.push({
      label: 'app.navigation.settings',
      to: hasPricingPermission
        ? '/settings/base-pricing'
        : '/settings/app-store',
      defaultQuery: hasPricingPermission
        ? undefined
        : {
            page: 1,
            perPage: 10,
            orderBy: 'name',
            orderType: 'desc',
          },
      namespace: 'settings',
      icon: MuiIcons.Settings,
    });
  }

  return items;
};

const getPartnerNavItems = (
  permissions: PermissionsMap,
  userHash: string
): NavItem[] => {
  const hasInvoicesPermission =
    permissions['INVOICES'] && permissions['INVOICES'].canView;

  return compact([
    {
      label: 'app.navigation.tenants',
      to: `/${userHash}/tenants`,
      namespace: '/tenants',
      icon: MuiIcons.Group,
      permissionName: 'TENANTS',
      defaultQuery: DEFAULT_CUSTOMERS_QUERY,
    },
    {
      label: 'app.navigation.usage',
      to: `/${userHash}/usage`,
      namespace: '/usage',
      icon: MuiIcons.InsertChart,
      permissionName: 'USAGE',
      defaultQuery: {
        page: 1,
        perPage: 20,
        orderBy: 'name',
        orderType: 'asc',
      },
    },
    hasInvoicesPermission && {
      label: 'app.navigation.invoices',
      namespace: '/invoices',
      to: `/${userHash}/invoices`,
      icon: MuiIcons.AttachMoney,
      permissionName: 'INVOICES',
      guideStepId: 'invoice-view-nav-item',
      defaultQuery: {
        ...DEFAULT_INVOICES_PARAMS,
        inittab: 'base',
        initexp: undefined,
      },
    },
    {
      label: 'app.navigation.billableItems',
      namespace: '/billable-items',
      to: `/${userHash}/billable-items`,
      icon: MuiIcons.Info,
      permissionName: 'BILLABLE_ITEMS',
      defaultQuery: DEFAULT_BILLABLE_ITEMS_PARAMS,
      guideStepId: 'billable-items-nav-item',
    },
    {
      label: 'app.navigation.users',
      to: `/${userHash}/users`,
      namespace: '/users',
      icon: MuiIcons.Person,
      permissionName: 'USERS',
      defaultQuery: DEFAULT_USERS_PARAMS,
      guideStepId: 'users-items-nav-item',
    },
    {
      label: 'app.navigation.settings',
      to: `/${userHash}/settings/partner-info`,
      namespace: '/settings',
      icon: MuiIcons.Settings,
      guideStepId: 'settings-items-nav-item',
    },
  ]);
};

const getTenantNavItems = (
  permissions: PermissionsMap,
  userHash: string
): NavItem[] => {
  const hasInvoicesPermission =
    permissions['INVOICES'] && permissions['INVOICES'].canView;

  return compact([
    {
      label: 'app.navigation.services',
      to: `/${userHash}/services/all`,
      namespace: '/services',
      icon: MuiIcons.Build,
    },
    hasInvoicesPermission && {
      label: 'app.navigation.invoices',
      namespace: '/invoices',
      to: `/${userHash}/invoices`,
      icon: MuiIcons.AttachMoney,
      guideStepId: 'invoice-view-nav-item',
      permissionName: 'INVOICES',
      defaultQuery: DEFAULT_INVOICES_PARAMS,
    },
    {
      label: 'app.navigation.billableItems',
      namespace: '/billable-items',
      to: `/${userHash}/billable-items`,
      guideStepId: 'billable-items-nav-item',
      icon: MuiIcons.Info,
      permissionName: 'BILLABLE_ITEMS',
      defaultQuery: DEFAULT_BILLABLE_ITEMS_PARAMS,
    },
    {
      label: 'app.navigation.users',
      to: `/${userHash}/users`,
      namespace: '/users',
      icon: MuiIcons.Person,
      permissionName: 'USERS',
      defaultQuery: DEFAULT_USERS_PARAMS,
    },
    {
      label: 'app.navigation.settings',
      to: `/${userHash}/settings/company-info`,
      namespace: '/settings',
      icon: MuiIcons.Settings,
      guideStepId: 'settings-items-nav-item',
    },
  ]);
};

type NavItemsShape = {
  [field in AccessLevelLow]: (
    permissions: PermissionsMap,
    userHash: string
  ) => NavItem[];
};

export const HEADER_NAV_ITEMS: NavItemsShape = {
  provider: getProviderNavItems,
  partner: getPartnerNavItems,
  tenant: getTenantNavItems,
};

export type ILevelSwitcherLevel = 'base' | 'partner' | 'tenant';

export type ILevelSwitcherNavItem = {
  level: ILevelSwitcherLevel;
  label?: string;
  to: string;
  defaultQuery?: AnyShape;
};

export const defineSwitcherNavItemsByBaseLevel = (
  currentUser: ICurrentUser,
  level: AccessLevel
): ILevelSwitcherNavItem[] => {
  if (level === 'PROVIDER') {
    return [
      {
        level: 'base',
        label: `${currentUser.firstName} ${currentUser.lastName}`,
        to: '/partners',
        defaultQuery: DEFAULT_PARTNERS_QUERY,
      },
      {
        level: 'partner',
        label: currentUser.partner?.name,
        to: `/${currentUser.partner?.shortName}/tenants`,
        defaultQuery: DEFAULT_CUSTOMERS_QUERY,
      },
      {
        level: 'tenant',
        label: currentUser.tenant?.name,
        to: `/${currentUser.partner?.shortName}/${currentUser.tenant?.shortName}/services/all`,
      },
    ];
  }

  if (level === 'PARTNER') {
    return [
      {
        level: 'base',
        label: `${currentUser.partner?.name}`,
        to: `/${currentUser.partner?.shortName}/tenants`,
        defaultQuery: DEFAULT_CUSTOMERS_QUERY,
      },
      {
        level: 'tenant',
        label: currentUser.tenant?.name,
        to: `/${currentUser.partner?.shortName}/${currentUser.tenant?.shortName}/services/all`,
      },
    ];
  }

  return [];
};

export const LANGUAGES: ValueType<ILanguage>[] = [
  {
    label: 'app.lang.en',
    value: 'en',
  },
  {
    label: 'app.lang.de',
    value: 'de',
  },
];

export const LANG_MAP: AnyShape = {
  en: LANGUAGES[0],
  de: LANGUAGES[1],
};

export const ROLES: any[] = [
  {
    label: 'Admin',
    value: 1,
  },
];

/* eslint-disable */
export const REGEX = {
  password:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
  noWhitespace: /\S*/,
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  remoteSubnets: /[ ,]+/,
  preSharedKey:
    /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^0-9])(?=.*[@#$%^&+=]).{16,10000}$/,
  coreDomainName: /^((?!-)[A-Za-z0-9-]{1,}(?!-)\.)+[A-Za-z]{2,6}$/,
  ipAddress:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  ipAddressWithSubnet:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/()$/,
  fileShareName: /[^#,+"\\<>;äöüÄÖÜùûüÿàâæéèêëïîôœÙÛÜŸÀÂÆÉÈÊËÏÎÔŒ]/gm,
  phone: /^[+0-9.]+$/,
  alphanumerical: /^([a-zA-Z0-9]+)$/, // onli letters and numbers
  folderName: /^(?!-)(?![0-9]{1,30}$)(?![a-zA-Z0-9-]*-$)[a-zA-Z0-9- ]{1,30}$/i, // onli letters and numbers
};
/* eslint-disable */

export const ACCESS_LEVELS: AccessLevelMap = {
  PROVIDER: 'provider',
  PARTNER: 'partner',
  TENANT: 'tenant',
};

export const REDIRECT_LINKS: AnyShape = {
  PROVIDER: {
    link: '/partners?',
    query: {
      page: 1,
      perPage: 20,
      status: 'ALL',
      orderType: 'asc',
      orderBy: 'name',
    },
  },
  PARTNER: {
    link: '/tenants?',
    query: {
      page: 1,
      perPage: 20,
      orderType: 'asc',
      orderBy: 'name',
    },
  },
  TENANT: {
    link: '/services/all',
    query: {},
  },
};

export const GLOBAL_PER_PAGE = 20;
export const GLOBAL_DATE_FORMAT = 'DD.MM.YYYY';
export const GLOBAL_DATE_FORMAT_MASK = '99.99.9999';

export const CORE_PROD_DOMAIN = 'easydesktop.ch';

export const YUP_OPTION = { value: Yup.string(), label: Yup.string() };

export const CORE_DEV_AVAILABLE_DOMAINS = [
  'jetcloud.ch',
  'cloud-desktop.ch',
  'sb-test.ch',
];

export const CORE_PROD_AVAILABLE_DOMAIN_OPTIONS = [CORE_PROD_DOMAIN].map(
  (value) => ({
    value,
    label: value,
  })
);

export const CORE_DEV_AVAILABLE_DOMAIN_OPTIONS = CORE_DEV_AVAILABLE_DOMAINS.map(
  (value) => ({
    value,
    label: value,
  })
);

export const DEFAULT_COUNTRY = { value: 'CH', label: 'Switzerland' };

export const isProd = process.env.REACT_APP_ENV === 'production';
export const isDev = process.env.REACT_APP_ENV === 'development';

export const HOURS_ARRAY = generateNumberSeq(24);

export const MINUTES_ARRAY = generateNumberSeq(60);

export const VM_ORDER_OPTIONS = [
  { label: 'app.options.orderBy.name', value: 'name' },
  { label: 'app.options.orderBy.createdAt', value: 'createdAt' },
  { label: 'app.options.orderBy.os.name', value: 'os.name' },
  { label: 'app.options.orderBy.ramMb', value: 'ramMb' },
  { label: 'app.options.orderBy.totalDiskSizeGb', value: 'totalDiskSizeGb' },
  { label: 'app.options.orderBy.virtualCpus', value: 'virtualCpus' },
  { label: 'app.options.orderBy.nics.ipv4', value: 'nics.ipv4' },
  { label: 'app.options.orderBy.nics.ipv6', value: 'nics.ipv6' },
];

export const GLOBAL_BORDER_RADIUS = 6;

export const DEFAULT_QUOTAS: Record<'eval' | 'prod', IQuotaTypes.Quota> = {
  eval: {
    virtualCpus: 12,
    ramMb: 24,
    diskSizeGb: 500,
  },
  prod: {
    virtualCpus: 160,
    ramMb: 320,
    diskSizeGb: 10000,
  },
};
