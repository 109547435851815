import * as React from 'react';
import cn from 'classnames';
import {
  Alert,
  Col,
  Row,
  Select,
  PrimaryTextSpan,
  PrimaryTextH5,
  BackgroundRequester,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { useStateHandler, useCostEstimation } from 'hooks';
import { FormikProps } from 'formik/dist/types';
import { numberToCurrency } from 'utils';
import { getBillingCycleOptions } from 'enums';
import { CostInfoList } from 'pages/services/components/Styled';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

type IProps = FormikProps<VmDialogTypes.IVmFormValues> &
  React.PropsWithChildren<
    WithT & {
      isEvaluation?: boolean;
    }
  >;

const CostInfoSection = observer((props: IProps) => {
  const { setFieldValue, values, isEvaluation } = props;

  const hasBillingCycle = !['MONTH', 'NONE'].includes(
    values.billingCycleInterval.value
  );
  const { t } = useTranslation();

  const { calculation, displayList, isRequesting } = useCostEstimation({
    values,
    serviceIds: [],
    instance: { serviceMonitoring: undefined, entityName: 'Vm' },
  });

  const costEstimation = useStateHandler(StateHandlers.costEstimation);

  const BILLING_OPTIONS = React.useMemo(
    () => getBillingCycleOptions(isEvaluation),
    [isEvaluation]
  );

  return (
    <Row
      direction="column"
      justifyContent="space-between"
      columnSpacing={2}
      style={{ minHeight: '100%' }}
      className={cn({ disabled: costEstimation.isRequesting })}
    >
      <Col className="full-width" xs>
        <BackgroundRequester isRequesting={isRequesting}>
          <PrimaryTextH5 className="mb-25 primary">
            {t('costsInfo.title') as string}
          </PrimaryTextH5>
          <Select
            onChange={(interval) =>
              setFieldValue('billingCycleInterval', interval)
            }
            options={BILLING_OPTIONS}
            value={values.billingCycleInterval}
            className="mb-15"
          />
          <div className="steel mb-15">
            {t('costsInfo.billingCircle.subtext') as string}
          </div>
          <Row
            justifyContent="space-between"
            columnSpacing={2}
            className="fs-10 steel uppercase mb-15"
          >
            <Col xs={8}>
              <span>{t('costsInfo.head.serviceName') as string}</span>
            </Col>
            <Col xs={4} className="text-right">
              <span>{t('costsInfo.head.price') as string}</span>
            </Col>
          </Row>
          <h5 className="mb-15">{t('costsInfo.virtualServer') as string}</h5>
          <CostInfoList className="custom-list mb-20">
            <ul>
              {displayList.map((item, ind) => {
                return (
                  <li key={item.name + ind}>
                    <div className="flex justify-between full-width">
                      <span>{item.name}</span>
                      <span className="pl-10 steel">{item.cost}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </CostInfoList>
        </BackgroundRequester>
      </Col>
      <Col className="full-width" justifyItems="flex-end">
        {hasBillingCycle && values.isBackupEnabled && (
          <Alert severity="info" className="mb-20">
            {
              t(
                'services.dialog.vms.alerts.buckup.noIncludedInDiscount'
              ) as string
            }
          </Alert>
        )}
        {!!calculation.discountValue && (
          <div className="flex justify-between align-center">
            <div className="fs-12 mb-10">
              {
                t('costsInfo.discount', {
                  value: calculation.discountPercent,
                }) as string
              }
            </div>
            <div className="bolder">
              {numberToCurrency(-calculation.discountValue, false)}
            </div>
          </div>
        )}

        <div className="flex justify-between align-center">
          <h5 className="fs-17">
            {
              t(
                `costsInfo.totalCost.${values.billingCycleInterval?.value}`
              ) as string
            }
          </h5>
          <PrimaryTextSpan className="fs-20 bold">
            {numberToCurrency(costEstimation.data?.cost?.total, false)}
          </PrimaryTextSpan>
        </div>
        <div className="fs-12 steel mb-10">
          {t('costsInfo.chfExclVat') as string}
        </div>
        {isEvaluation && (
          <Alert className="fs-12 mb-15" severity="info">
            {t('costsInfo.evalAccount.notify') as string}
          </Alert>
        )}
      </Col>
    </Row>
  );
});

export default CostInfoSection;
