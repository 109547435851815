/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LevelSwitcher } from './LevelSwitcher';
import cn from 'classnames';
import { Alert, MuiIcons, Row, Col, Loader, styled } from 'elements';
import { usePermissions, useTask, useAccount, useAsync } from 'hooks';
import { ILanguage } from 'global-shapes';
import { usersService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  changeLang,
  Emitter,
  showSystemMessage,
  updatePageTitle,
  remCalc,
} from 'utils';
import { CustomEvents, PALETTE } from 'enums';
import Header from './header/Header';

type IProps = React.PropsWithChildren<{
  error?: string;
}>;

const PageHolder = styled(
  (props: React.ComponentProps<'div'> & { hasEvaluation: boolean }) => {
    const { hasEvaluation, ...rest } = props;
    return <div {...rest} />;
  }
)(({ hasEvaluation }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  paddingTop: hasEvaluation ? remCalc(64 + 48) : remCalc(64),
  backgroundColor: PALETTE.pageBackground,
}));

const ContentHolder = styled(
  (props: React.ComponentProps<'div'> & { hasEvaluation: boolean }) => {
    const { hasEvaluation, ...rest } = props;
    return <div {...rest} />;
  }
)(({ hasEvaluation }) => ({
  maxWidth: remCalc(1920),
  padding: remCalc(20, 10, 10),
  margin: 'auto',
  width: '100%',
  minHeight: `calc(100vh - ${remCalc(64 + 48 + (hasEvaluation ? 48 : 0))})`,
}));

const PageFooter = styled('footer')(() => ({
  minHeight: remCalc(45),
  backgroundColor: PALETTE.white,
  padding: remCalc(14, 40),
  lineHeight: remCalc(18),
}));

const PageRow = styled('div')(() => ({
  maxWidth: remCalc(1900),
  width: '100%',
  margin: 'auto',
}));

const FooterVersionLink = styled('a')(() => ({
  maxWidth: remCalc(340),
  display: 'block',
}));

const OBSERVERS = {
  appSettings: StateHandlers.appSettings,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { error, children, appSettings, currentUser } = props;
  const [, hasAccountData] = useAccount();
  const { t } = useTranslation();

  const {
    isEvaluation,
    isCurrentTenant,
    isDeleted,
    task: _task,
  } = usePermissions();

  const isLoadingUserData = currentUser.isLoading;

  const fetchCurrentUser = React.useCallback(
    () => StateHandlers.fetchAllAccountData({ shouldApplyTheme: true }),
    []
  );

  const handleUserLoading = React.useCallback((isLoading: boolean) => {
    currentUser.setLoading(isLoading);
  }, []);

  const task = useTask(_task, { onComplete: fetchCurrentUser });
  const updateUserLang = React.useCallback(
    (lang: ILanguage) =>
      usersService
        .updatePartially(
          'me',
          { lang },
          {
            headers: {
              'X-JBBF-PARTNER-SHORTNAME': undefined,
              'X-JBBF-TENANT-SHORTNAME': undefined,
            },
          }
        )
        .then((res) => {
          changeLang(lang);
          updatePageTitle(lang);
          currentUser.merge({ lang });
          return res;
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [currentUser]
  );

  const updateLangRequest = useAsync(updateUserLang as any);

  const pageWithEvaluation = !isDeleted && isEvaluation;

  React.useEffect(() => {
    Emitter.on(CustomEvents.changeLevel, handleUserLoading);

    return () => {
      Emitter.off(CustomEvents.changeLevel, handleUserLoading);
    };
  }, []);

  if (!hasAccountData) {
    return <Loader />;
  }

  return (
    <PageHolder
      hasEvaluation={pageWithEvaluation}
      className={cn({
        disabled: isLoadingUserData,
      })}
    >
      <Header isEvaluation={!isDeleted && isEvaluation} />
      <ContentHolder
        hasEvaluation={pageWithEvaluation}
        className={cn({
          disabled: task.isTaskActive,
        })}
      >
        <Row alignItems="center" justifyContent="space-between">
          <Col>
            <LevelSwitcher />
          </Col>
          {isCurrentTenant && <Col id="quotas-container" />}
        </Row>

        {error && <Alert severity="error">{error}</Alert>}
        {isDeleted ? (
          <div className="full-width text-center pt-25 pb-25">
            {t('app.accountIsDeleted')}
          </div>
        ) : (
          children
        )}
      </ContentHolder>
      <PageFooter>
        <PageRow>
          <Row
            className="fs-14 steel full-width"
            justifyContent="space-between"
            alignItems="center"
          >
            <Col>
              <Row columnSpacing={5}>
                <Col>
                  <Row
                    className={cn('bolder', {
                      disabled: updateLangRequest.isPending,
                    })}
                  >
                    <Col>
                      <div
                        className={cn('mr-5 pointer', {
                          primary: currentUser.data?.lang === 'en',
                        })}
                        onClick={() => updateLangRequest.execute('en')}
                      >
                        {t('app.lang.en')} /
                      </div>
                    </Col>
                    <Col>
                      <div
                        className={cn('pointer', {
                          primary: currentUser.data?.lang === 'de',
                        })}
                        onClick={() => updateLangRequest.execute('de')}
                      >
                        {t('app.lang.de')}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <FooterVersionLink
                    target="_blank"
                    rel="noreferrer"
                    className="steel link-hover ellipsis"
                    href={appSettings.data?.versionLinkUrl}
                  >
                    {appSettings.data?.versionLinkName}
                  </FooterVersionLink>
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="flex align-center">
                <MuiIcons.Info className="fs-18 mr-5" />
                <div>
                  <Trans
                    i18nKey="app.footer.roadmapText"
                    components={{
                      1: (
                        <a
                          href="https://roadmap.awesome.cloud"
                          className="bolder steel"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                      2: (
                        <a
                          href="https://roadmap.awesome.cloud/roadmap"
                          className="bolder steel"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </PageRow>
      </PageFooter>
    </PageHolder>
  );
});

const AppLayout = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default AppLayout;
