import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import DateRangePicker, {
  Props as DateRangePickerProps,
} from 'react-daterange-picker';
import { getCurrentLang, dayjs, Dayjs, remCalc } from 'utils';
import { styled } from './utilities';
import { Row, Col } from './Grid';
import { MuiIcons } from './MuiIcons';
import { IconButton } from './Button';
import { useTranslation } from 'react-i18next';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import { GLOBAL_DATE_FORMAT, PALETTE } from 'enums';
import { default as Popover } from './Popover';
import { DateInput } from './DateInput';
const moment = extendMoment(originalMoment as any);

type Props = Omit<DateRangePickerProps, 'value'> & {
  value?: Dayjs;
  label?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  displayClassName?: string;
  closeOnSelect?: boolean;
  onChange: (date: Dayjs) => void;
};

export const PickerLabel = styled('div')(() => ({
  fontSize: remCalc(12),
  lineHeight: remCalc(17),
  marginBottom: remCalc(8),
}));

const DisplayWrapper = styled(
  (
    props: React.ComponentProps<'div'> & {
      isDisabled: boolean;
      hasError: boolean;
    }
  ) => {
    const { isDisabled, hasError, ...rest } = props;
    return <div {...rest} />;
  }
  // @ts-ignore
)(({ theme, isDisabled, hasError }) => {
  const errorStyles = hasError
    ? {
        backgroundColor: '#FFF0EE',
        borderColor: theme.palette.error.main,
      }
    : {};

  const disabledStyles = isDisabled
    ? {
        backgroundColor: PALETTE.pageBackground,
        borderColor: PALETTE.pageBackground,
        pointerEvents: 'none',
      }
    : {};

  return {
    height: remCalc(48),
    padding: remCalc(0, 5, 0, 20),
    borderRadius: remCalc(6),
    backgroundColor: theme.palette.common.white,
    minWidth: remCalc(150),
    lineHeight: 1,
    transition: 'border-color 0.1s ease-in-out 0s',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    ...errorStyles,
    ...disabledStyles,
  };
});

const ErrorLabel = styled('div')(({ theme }) => ({
  lineHeight: remCalc(19),
  fontSize: remCalc(12),
  color: theme.palette.error.main,
  marginTop: remCalc(3),
  textAlign: 'right',
}));

export const SDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  '& .DateRangePicker__Date--is-highlighted': {
    '& .DateRangePicker__CalendarHighlight--single': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .DateRangePicker__Date--is-selected': {
    '& .DateRangePicker__CalendarSelection': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  '& .DateRangePicker__Date--today': {
    '& .DateRangePicker__CalendarSelection': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },

    '& .DateRangePicker__FullDateStates': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const MuiDatePicker: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { minimumDate, maximumDate } = props;

  const value = useMemo(
    () => (props.value ? props.value.toDate() : undefined),
    [JSON.stringify(props.value)]
  ) as Date;

  const momentValue = useMemo(() => {
    if (!props.value) return undefined;
    return moment(props.value.toDate(), GLOBAL_DATE_FORMAT) || undefined;
  }, [props.value]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = React.useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSelect = useCallback(
    (date: any) => {
      props.closeOnSelect && handleClose();
      props.onChange(dayjs(date));
    },
    [props.closeOnSelect]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'date-picker-popover' : undefined;

  return (
    <div>
      {props.label && <PickerLabel>{props.label}</PickerLabel>}
      <DisplayWrapper
        hasError={!!props.error}
        isDisabled={!!props.disabled}
        className={cn('bordered pointer', props.displayClassName)}
      >
        <Row
          justifyContent="space-between"
          alignItems="center"
          className="full-width full-height"
          gap={0}
        >
          <Col xs>
            <DateInput
              value={dayjs(value).format(GLOBAL_DATE_FORMAT)}
              onSelect={onSelect}
            />
          </Col>
          <Col>
            <IconButton onClick={handleClick}>
              <MuiIcons.EventNote className="fs-14" />
            </IconButton>
          </Col>
        </Row>
      </DisplayWrapper>
      {props.error && (
        <ErrorLabel>{t(props.helperText as string) as string}</ErrorLabel>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: 'pt-5 pl-5 pr-5 pb-20',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SDateRangePicker
          firstOfWeek={1}
          numberOfCalendars={1}
          selectionType={'single'}
          singleDateRange
          value={momentValue}
          onSelect={onSelect}
          locale={getCurrentLang()}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
        />
      </Popover>
    </div>
  );
};

MuiDatePicker.defaultProps = {
  value: dayjs(undefined, GLOBAL_DATE_FORMAT),
};

export default MuiDatePicker;
