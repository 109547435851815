import * as Yup from 'yup';
import { validator } from 'utils';

export const NAV_PARAMS = {
  'app-store': {
    page: 1,
    perPage: 10,
    orderBy: 'name',
    orderType: 'asc',
    q: '',
  },
  blacklist: {
    page: 1,
    perPage: 50,
    orderBy: 'domainName',
    orderType: 'desc',
  },
  badges: {
    page: 1,
    perPage: 20,
    orderBy: 'createdAt',
    orderType: 'desc',
  },
};

export const INITIAL_CUSTOMER_INVOICE_VALUES: IPartnerTypes.ICustomerInvoiceValues =
  {
    email: '',
    phone: '',
    vatId: '',
    iban: '',
    stripePaymentsEnabled: false,
    bankDetails: '',
    bankIdentificationCode: '',
    bankAccount: '',
    countryCode: undefined,
  };

export const customerInvoiceValidationSchema = Yup.object({
  countryCode: Yup.object().required('forms.required'),
  email: Yup.string()
    .trim()
    .email('forms.emailInvalid')
    .max(60, 'forms.invalid.max')
    .required('forms.required'),
  iban: Yup.string()
    .trim()
    .required('forms.required')
    .test('iban', 'forms.invalid', validator.isQRIBAN),
  vatId: Yup.string()
    .trim()
    .required('forms.required')
    .test('vatId', 'forms.invalid', validator.isVATSimplified),
  phone: Yup.string()
    .trim()
    .required('forms.required')
    .test('phone', 'forms.invalid', validator.isPhone),
});

export const customerInvoiceValidationSchemaForOthers = Yup.object({
  email: Yup.string()
    .trim()
    .email('forms.emailInvalid')
    .max(60, 'forms.invalid.max')
    .required('forms.required'),
  countryCode: Yup.object().required('forms.required'),
  bankDetails: Yup.string()
    .required('forms.required')
    .max(200, 'forms.invalid.max'),
  bankAccount: Yup.string().max(30, 'forms.invalid.max'),
  bankIdentificationCode: Yup.string()
    .required('forms.required')
    .max(11, 'forms.invalid.max')
    .min(8, 'forms.invalid.min')
    .test({
      message: 'sdsf',
      test: (val: any) => {
        if (!val) return true;
        return validator.onlyLettersAndNum(val);
      },
    }),
  iban: Yup.string()
    .trim()
    .required('forms.required')
    .test('iban', 'forms.invalid', validator.isIBAN),
  phone: Yup.string()
    .trim()
    .required('forms.required')
    .test('phone', 'forms.invalid', validator.isPhone),
});
