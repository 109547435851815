import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogProps, Input, Button, ColorPicker } from 'elements';
import { useFormik } from 'formik';
import { INewBadgePayload } from 'badges';
import { parseBadgeToForm } from './helpers';
import { validationSchema } from './constants';
import { InputWrapper } from './Styled';

type IProps = DialogProps<INewBadgePayload> & {
  initialValues?: IBadge;
  isRequesting?: boolean;
};

const BadgeDetailsDialog = (props: IProps) => {
  const { open, onClose, onSave, initialValues, isRequesting } = props;
  const { t } = useTranslation();

  const isNew = !initialValues;

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    errors,
    submitCount,
    resetForm,
    setValues,
  } = useFormik({
    validationSchema,
    initialValues: parseBadgeToForm(initialValues),
    onSubmit: onSave,
  });

  React.useEffect(() => {
    if (open && initialValues) {
      setValues(parseBadgeToForm(initialValues));
    }

    if (!open) {
      resetForm();
    }
  }, [open, initialValues]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      handleSubmit={handleSubmit}
      title={t(`badges.dialog.details.title.${isNew ? 'add' : 'edit'}`)}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="mb-30">{t('badges.dialog.details.content')}</div>
      <InputWrapper className="mb-25">
        <ColorPicker
          name="color"
          value={values.color}
          label={t('forms.badgeColor')}
          onChange={(color: string) => setFieldValue('color', color)}
          error={!!submitCount && !!errors.color}
          helperText={errors.color}
        />
      </InputWrapper>
      <Input
        name="description"
        value={values.description}
        label={t('forms.description')}
        placeholder={t('badges.dialog.details.placeholders.description')}
        onChange={handleChange}
        error={!!submitCount && !!errors.description}
        helperText={errors.description}
        helperTextOptions={{
          max: 60,
        }}
      />
    </Dialog>
  );
};

export default BadgeDetailsDialog;
