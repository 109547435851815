import * as React from 'react';
import cn from 'classnames';
import { TestSuffixes } from 'enums';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { remCalc } from 'utils/uxHelpers';
import { IAutomationTestProps } from 'automation-test';
import { styled } from './utilities';

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  width: remCalc(24),
  height: remCalc(24),
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: `2px auto rgba(19,124,189,.6)`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: 'none',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    position: 'absolute',
    left: remCalc(4),
    top: remCalc(4),
    width: remCalc(16),
    height: remCalc(16),
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
    animation: 'fade-in-pulse .25s ease-out',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

// Inspired by blueprintjs
function BpCheckbox(props: CheckboxProps) {
  return (
    <MuiCheckbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'ОИИА-сруслищч' }}
      {...props}
    />
  );
}

interface Props extends CheckboxProps {
  label?: any;
  wrapperClassName?: string;
  onCheck?: (checked: boolean) => void;
}

export const Checkbox = ({
  testId,
  wrapperClassName,
  onCheck,
  ...props
}: Props & IAutomationTestProps) => {
  return (
    <FormControlLabel
      className={cn(wrapperClassName, 'm-0')}
      control={
        <BpCheckbox
          color="primary"
          disableRipple
          {...props}
          className={cn(props.className, 'pl-0 pt-0 pb-0')}
          checked={!!props.checked}
          data-test-id={cn({
            [`${testId}-${TestSuffixes.checkbox}-${props.name}`]: !!testId,
          })}
          inputProps={{
            // @ts-ignore
            'data-test-id': cn({
              [`${testId}-${TestSuffixes.checkbox}-${TestSuffixes.input}`]:
                !!testId,
            }),
          }}
          onChange={(ev) => onCheck && onCheck(!!ev.target.checked)}
        />
      }
      label={props.label || ''}
    />
  );
};
