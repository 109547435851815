import * as React from 'react';
import { remCalc, important } from 'utils';
import { PALETTE } from 'enums';
import { styled, Paper, PaperProps, Skeleton as MSkeleton } from 'elements';
import { StatusLine } from '../StatusIdentifier';

export const CardHolderRoot = styled(
  React.forwardRef(
    (
      props: PaperProps & {
        isError: boolean;
        waitingToDeploy?: boolean;
        isDisabled: boolean;
      },
      ref: any
    ) => {
      const { isError, waitingToDeploy, isDisabled, ...rest } = props;
      return <Paper {...rest} ref={ref} />;
    }
  )
)(({ waitingToDeploy, isError, isDisabled }) => ({
  position: 'relative',
  width: '100%',
  height: remCalc(357),
  padding: remCalc(20),
  boxShadow: '0 4px 12px 0 rgba(23, 29, 41, 0.08)',
  borderRadius: remCalc(6),
  fontSize: remCalc(14),
  lineHeight: remCalc(21),
  paddingBottom: remCalc(50),
  backgroundColor: waitingToDeploy ? important('#f0f0f0') : undefined,
  '&::after': {
    backgroundColor: isError
      ? PALETTE.error
      : isDisabled
      ? '#cbccd4'
      : undefined,
  },
}));

export const FolderCardHolderRoot = styled(Paper)(() => ({
  position: 'relative',
  height: remCalc(357),
  padding: remCalc(20),
  boxShadow: '0 4px 12px 0 rgba(23, 29, 41, 0.08)',
  borderRadius: remCalc(6),
  fontSize: remCalc(14),
  lineHeight: remCalc(21),
  paddingBottom: remCalc(50),
  backgroundColor: PALETTE.folderBackground,
}));

export const HolderBadge = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  overflow: 'hidden',
  color: PALETTE.white,
  width: remCalc(23),
  height: remCalc(23),
  fontSize: remCalc(8),
  zIndex: 1,
  lineHeight: remCalc(18),
  paddingRight: remCalc(2),
  borderTopRightRadius: remCalc(4),
  '&:before': {
    content: "''",
    display: 'block',
    backgroundColor: PALETTE.success,
    position: 'absolute',
    top: remCalc(-17),
    right: remCalc(-17),
    width: remCalc(40),
    height: remCalc(40),
    borderRadius: '50%',
    zIndex: 0,
    transform: 'rotate(45deg)',
  },
});

export const HolderFooter = styled('div')({
  fontSize: remCalc(14),
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: remCalc(10, 20, 20),
});

export const Skeleton = styled(MSkeleton)({
  position: 'relative',
  height: remCalc(357),
  padding: remCalc(20),
  boxShadow: '0 4px 12px 0 rgba(23, 29, 41, 0.08)',
  borderRadius: remCalc(4),
  fontSize: remCalc(14),
  lineHeight: remCalc(21),
  marginBottom: remCalc(10),
  paddingBottom: remCalc(50),
});

export const SStatusLine = styled(StatusLine)({
  bottom: 0,
  left: 0,
  position: 'absolute',
  borderBottomLeftRadius: remCalc(4),
  borderBottomRightRadius: remCalc(4),
});

export const FolderIconWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  lineHeight: remCalc(12),
  marginRight: remCalc(8),
  textAlign: 'center',
  '& ._name-text': {
    position: 'absolute',
    fontSize: remCalc(8),
    left: 0,
    top: 0,
    color: theme.palette.primary.contrastText,
    zIndex: 1,
    width: '100%',
    lineHeight: remCalc(20),
    height: remCalc(20),
  },
}));
