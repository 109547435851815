import React, { useEffect, useMemo, useCallback } from 'react';
// @ts-ignore
import { Steps } from 'intro.js-react';
import { UserFormValues } from 'users-shapes';
import { DelayedRender } from 'hocs';
import { useTranslation } from 'react-i18next';
import UserDetailsDialog from './UserDetailsDialog';
import { getColumns } from './helpers';
import { usePermissions, useQuery, useUserHash, useState } from 'hooks';
import { Button, Table, Row, Loader, Col } from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { confirm, mapPermissions, showSystemMessage } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import { rolesService, usersService } from 'services';
import { DEFAULT_QUERY as DEFAULT_ROLES_QUERY } from 'pages/roles/constants';
import { AnyShape } from 'global-shapes';
import { EmptyMessageHolder, EmptyMessage } from './Styled';

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
  users: StateHandlers.users,
  appGuide: StateHandlers.guideTooltips,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { users, appGuide, currentUser } = props;
  const [, changeUrl] = useUserHash();
  const { query, queryStr, changeQuery } = useQuery();
  const { t } = useTranslation();
  const {
    permissions,
    accessLevelOriginal,
    accessLevel,
    isCurrentTenant,
    isCurrentPartner,
    isCurrentProvider,
  } = usePermissions('USERS');
  const { permissions: rolesPermissions } = usePermissions('ROLES');
  const [values, handleStateChange] = useState<any>(null);
  const [isOpen, handleOpenDetails] = React.useState(false);

  const isNew = useMemo(() => values === null, [values]);
  const isRequesting = users.isRequesting;

  const currentUserId = currentUser.data?.id;

  const canManageRoles = rolesPermissions.canView || rolesPermissions.canManage;

  const guideValue = useMemo(() => {
    const value: AnyShape = {};
    const tenantOpenUsersPage = appGuide.data?.tenantOpenUsersPage;
    const partnerOpenUsersPage = appGuide.data?.partnerOpenUsersPage;
    if (isCurrentTenant) {
      value.status = tenantOpenUsersPage && tenantOpenUsersPage.status;
      value.key = 'tenantOpenUsersPage';
    }
    if (isCurrentPartner) {
      value.status = partnerOpenUsersPage && partnerOpenUsersPage.status;
      value.key = 'partnerOpenUsersPage';
    }
    return value;
  }, [isCurrentTenant, isCurrentPartner, JSON.stringify(appGuide.data)]);

  const onDialogClose = useCallback(() => {
    handleOpenDetails(false);
    handleStateChange(null as any);
  }, []);

  const blockUser = React.useCallback(
    (block: boolean) => async (userId: number) => {
      const req = block ? usersService.blockUser : usersService.unBlockUser;
      try {
        await req(userId);
        return users.updateById(userId, { isBlocked: block });
      } catch (e: any) {
        showSystemMessage(e.message, 'error');
      }
    },
    []
  );
  const isCurrentUserData = !isNew && values?.id === currentUserId;

  const onSubmit = async (user: UserFormValues) => {
    if (isCurrentUserData) {
      await rolesService.getRole(values.role.value).then(({ data }) =>
        currentUser.merge({
          permissions: mapPermissions(data.permissions),
        })
      );
    }

    return isNew
      ? users.create(user).then(() => {
          confirm({
            title: t('users.notify.create.success.title'),
            content: t('users.notify.create.success.content'),
            onCancel: () => undefined,
            cancelLabel: 'common.ok',
          });
          users.get(query);
          onDialogClose();
        })
      : users.update(values.id, user).then(() => {
          onDialogClose();
          users.get(query);
        });
  };

  const onDelete = React.useCallback(
    async (id: number) => {
      await users.remove(id);
      return users.get(query);
    },
    [queryStr]
  );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        permissions,
        handleStateChange,
        handleOpenDetails,
        currentUserId,
        rolesPermissions,
        requestDeleteUser: onDelete,
        requestUnblockUser: blockUser(false),
        requestBlockUser: blockUser(true),
      }),
    [currentUserId, JSON.stringify([rolesPermissions, permissions])]
  );

  const isEnabledGuide = appGuide.dataReceived && guideValue.status !== 'SEEN';

  const guideSteps = useMemo(() => {
    const steps = [];
    if (canManageRoles) {
      steps.push({
        element: '#manage-roles-button',
        position: 'bottom-right-aligned',
        title: t('appGuide.users.step.2.title'),
        intro: t('appGuide.users.step.2.content'),
      });
    }
    return steps;
  }, [canManageRoles]);

  const guideOptions = useMemo(
    () => ({
      stepsEnabled: true,
      initialStep: 0,
      steps: guideSteps,
      options: {
        showBullets: false,
        exitOnOverlayClick: false,
        showButtons: false,
        disableInteraction: true,
        skipLabel: 'close',
        overlayOpacity: 0.3,
      },
    }),
    []
  );

  const onSeenGuide = useCallback(
    () =>
      appGuide.update(null, [
        {
          name: guideValue.key,
          status: 'SEEN',
        },
      ]),
    []
  );

  useEffect(() => {
    if (permissions.canView) {
      users.get(query);
    }
  }, [permissions.canView, queryStr]);

  const subtitle = useMemo(() => {
    switch (accessLevel) {
      case 'partner':
        return t('users.partner.subtitle');
      case 'tenant':
        return t('users.tenant.subtitle');
      default:
        return null;
    }
  }, [accessLevel]);

  if (!users.dataReceived) {
    return <Loader />;
  }

  const hasUsers = !!users.data.length;

  return (
    <>
      {!isCurrentProvider && (
        <DelayedRender delay={1500}>
          <Steps
            enabled={isEnabledGuide}
            steps={guideOptions.steps}
            initialStep={guideOptions.initialStep}
            onExit={onSeenGuide}
            options={guideOptions.options}
          />
        </DelayedRender>
      )}
      <Row justifyContent="space-between" className="mb-20">
        <Col>
          <h3 className="mb-10 pt-10">{t('users.title')}</h3>
          <div className="steel fs-14">{subtitle}</div>
        </Col>
        <Col>
          <Row alignItems="center">
            {canManageRoles && (
              <Col>
                <Button
                  color="default"
                  variant="outlined"
                  id="manage-roles-button"
                  onClick={() => changeUrl('/roles', DEFAULT_ROLES_QUERY)}
                  testId={ALL_TEST_IDS.users.goToRoles}
                >
                  {t('users.button.manageRoles')}
                </Button>
              </Col>
            )}
            {permissions.canManage && hasUsers && (
              <Col>
                <Button
                  className="ml-15"
                  onClick={() => {
                    handleStateChange(null as any);
                    handleOpenDetails(true);
                  }}
                  testId={ALL_TEST_IDS.users.createButton}
                >
                  {t('users.button.createUser')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {!hasUsers ? (
        <EmptyMessageHolder justifyContent="center" alignItems="center">
          <div>
            <EmptyMessage>{t('users.empty.message')}</EmptyMessage>
            {permissions.canManage && (
              <Button
                id="create-user-button"
                onClick={() => {
                  handleStateChange(null as any);
                  handleOpenDetails(true);
                }}
                testId={ALL_TEST_IDS.users.createButton}
              >
                {t('users.button.createFirstUser')}
              </Button>
            )}
          </div>
        </EmptyMessageHolder>
      ) : (
        <Table
          query={query}
          columns={columns}
          data={users.data}
          params={users.meta}
          onPageChange={changeQuery}
          testId={ALL_TEST_IDS.users.table}
        />
      )}

      <UserDetailsDialog
        open={isOpen}
        isNew={isNew}
        isYourself={isCurrentUserData}
        isRequesting={isRequesting}
        initialValues={values}
        onSubmit={onSubmit}
        accessLevel={accessLevelOriginal}
        rolesPermission={rolesPermissions}
        onClose={onDialogClose}
      />
    </>
  );
});

const Users = () => <View {...OBSERVERS} />;

export default Users;
