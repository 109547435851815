import { LANGUAGES } from 'enums';
import { ICustomer } from 'customer-shapes';
import { ValueType, ILanguage } from 'global-shapes';
import { getCountries } from 'utils';
import { INITIAL_CUSTOMER_INVOICE_VALUES } from './constants';

const countries = getCountries();

export const parseCustomerInvoiceSettingsForForm = (
  partner: IPartnerTypes.Partner
): IPartnerTypes.ICustomerInvoiceValues => {
  const settings = partner.invoiceSettings || INITIAL_CUSTOMER_INVOICE_VALUES;
  const country = countries.find((el) => el.value === settings.countryCode);
  const CH = countries.find((el) => el.value === 'CH');
  const hasCountryInitialy = !!settings.countryCode;

  const isOther = !['LI', 'CH'].includes(country?.value || '');
  return {
    bankAccount: settings.bankAccount || '',
    bankDetails: settings.bankDetails || '',
    bankIdentificationCode: settings.bankIdentificationCode || '',
    email: settings.email,
    iban: settings.iban,
    stripePaymentsEnabled: settings.stripePaymentsEnabled,
    vatId: settings.vatId,
    phone: settings.phone.replace('+', ''),
    countryCode: hasCountryInitialy
      ? isOther
        ? {
            label: 'common.other',
            value: 'other',
          }
        : country
      : CH,
  };
};

export function parsePartnerInfoForForm(
  partner: IPartnerTypes.Partner
): IPartnerTypes.IPartnerInfoFormValues['address'] & {
  lang: ValueType<ILanguage>;
  name: string;
  phone: string;
} {
  return {
    name: partner.name || '',
    line1: partner.address?.line1 || '',
    line2: partner.address?.line2 || '',
    zipCode: partner.address?.zipCode || '',
    city: partner.address?.city || '',
    phone: partner.phone || '',
    countryCode: countries.find(
      (el) => el.value === partner.address?.countryCode
    ),
    lang: LANGUAGES.find(
      (el) => el.value === partner.lang
    ) as ValueType<ILanguage>,
  };
}

export function parseCustomerInfoForForm(
  customer: ICustomer
): IPartnerTypes.IPartnerInfoFormValues['address'] & {
  lang: ValueType<ILanguage>;
  name: string;
  phone: string;
} {
  return {
    line1: customer.address?.line1 || '',
    line2: customer.address?.line2 || '',
    zipCode: customer.address?.zipCode || '',
    city: customer.address?.city || '',
    name: customer?.name || '',
    phone: customer?.phone || '',
    countryCode: countries.find(
      (el) => el.value === customer.address?.countryCode
    ),
    lang: LANGUAGES.find(
      (el) => el.value === customer.lang
    ) as ValueType<ILanguage>,
  };
}
