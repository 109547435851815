import { authService, usersService } from 'services';
import {
  Emitter,
  setSessionsData,
  changeLang,
  updatePageTitle,
  logOut,
  mapPermissions,
  showSystemMessage,
  defineActualColorScheme,
  findShortNamesInUrl,
  getCurrentToken,
  ISocketContextParams,
  getCurrentSessionInfo,
} from 'utils';
import { awesomeCloudSockets } from 'utils/sockets/sockets';
import { CustomEvents } from 'enums';
import { AnyShape } from 'global-shapes';
import { IUserContext } from 'users-shapes';
import { ICurrentUser } from 'auth-shapes';
import { taskManager, taskManagerOptions } from './taskManager';
import { meta, countries } from './meta';
import { badges, badgesAssignment } from './badges';
import { connectivity } from './connectivity';
import { vms } from './vms';
import { costBreakdown } from './costBreakdown';
import { forwardedPorts } from './forwardedPorts';
import { tenantPricing } from './pricing';

import { MetaData } from 'meta-data';
import { IRemoteSupport } from 'components/RemoteSupport/types';

const isPartnerThemeChanged = (user: ICurrentUser) => {
  const { partner, tenant } = user;
  const newPalette = defineActualColorScheme(user);
  const res = {
    isChanged: false,
    config: {
      palette: newPalette,
    },
  };

  const base = tenant ? tenant.partner : partner;

  if (!!base?.secondaryColor || !!base?.primaryColor) {
    res.isChanged = true;
  }

  return res;
};

function defineSocketContext(ctx: IUserContext): ISocketContextParams {
  if (ctx.tenant) {
    return {
      tenantId: ctx.tenant.id,
      tenantKey: ctx.tenantKey,
    };
  }
  if (ctx.partner) {
    return {
      partnerId: ctx.partner.id,
      partnerKey: ctx.partnerKey,
    };
  }

  return {};
}

export const getUserSession = async (
  query: AnyShape
): Promise<{ data: ICurrentUser }> => {
  const { psn, tsn } = findShortNamesInUrl(window.location);
  const _psn = query.psn || psn;
  const _tsn = query.tsn || tsn;
  const headers: any = {
    'X-JBBF-PARTNER-SHORTNAME': _psn,
    'X-JBBF-TENANT-SHORTNAME': _tsn,
  };

  await countries.get();

  if (query.shouldApplyTheme) {
    Emitter.emit(CustomEvents.changeTheme, null);
  }

  awesomeCloudSockets.disconnect();

  if (_psn) {
    sessionStorage.setItem('psn', _psn);
  } else {
    sessionStorage.removeItem('psn');
  }

  if (_tsn) {
    sessionStorage.setItem('tsn', _tsn);
  } else {
    sessionStorage.removeItem('tsn');
  }

  const context = await usersService.resolveContext({
    headers,
  });

  const socketContext = defineSocketContext(context.data);

  await awesomeCloudSockets
    .init(getCurrentToken(), socketContext)
    .catch(console.log);

  const { data: sessionInfo } = await getCurrentSessionInfo();

  const isTryingToSwitchUser = query.psn || query.tsn;
  const shouldUpdateSessionData = isTryingToSwitchUser || sessionInfo;

  if (shouldUpdateSessionData) {
    setSessionsData(sessionInfo, false);
  }

  const user: ICurrentUser = {
    ...context.data.user,
    partner: context.data.partner,
    tenant: context.data.tenant,
    permissions: mapPermissions(context.data.user.role?.permissions || []),
  };

  localStorage.setItem('lang', user.lang);

  const isTenant = !!user.tenant;
  const isPartner = !!user.partner;

  const currentLanguage = user.lang;
  changeLang(currentLanguage);
  updatePageTitle(currentLanguage);

  const newThemeInfo = isPartnerThemeChanged(user);

  if (!user.role.isDefault && isTenant && user.tenant?.isDeleted) {
    await logOut();
  }

  if (!user.role.isDefault && isPartner && user.partner?.isDeleted) {
    await logOut();
  }

  if (isTenant) {
    await taskManager.get();
    await taskManagerOptions.get();
  }

  await badges.get();
  await badgesAssignment.get();

  await authService
    .getCurrentIp()
    .then((res) => {
      meta.setData({
        ip: res.ip as string,
        monitorDisplay: 'card',
      });
    })
    .catch((er) => showSystemMessage(er.message, 'error'));

  if (query.shouldApplyTheme) {
    Emitter.emit(CustomEvents.changeTheme, newThemeInfo.config.palette);
  }

  return { data: user };
};

// ----->>>>>>> CONNECTIVITY <<<<<<<<-------

export const fetchConnectivity = () => connectivity.get();

// ----->>>>>>> VMS <<<<<<<<-------

export const fetchVms = () =>
  vms.get({
    include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
  });

// ----->>>>>>> VM-DETAILS <<<<<<<<-------

export const reloadVmInList = (id: number, params: any) =>
  vms.reload(id, params);

// ----->>>>>>> FORWARDED PORTS (Remote Suppurt feature on ui) <<<<<<<<-------

export function fetchForwardedPorts() {
  return forwardedPorts.get({
    perPage: 1000,
    page: 1,
  });
}

export function updateForwardedPorts(
  enable: boolean,
  attrs: Partial<IRemoteSupport.Payload>,
  onSuccess?: () => void
) {
  if (enable) {
    return forwardedPorts.create(attrs).then(() => {
      onSuccess && onSuccess();
    });
  }

  if (!enable) {
    return forwardedPorts.remove(attrs.id).then(() => {
      onSuccess && onSuccess();
    });
  }
}

// ----->>>>>>> APP META DATA <<<<<<<<-------

export function setMetaData(data: Partial<MetaData>) {
  return meta.merge(data);
}

// ----->>>>>>> TASK MANAGER <<<<<<<<-------

export const fetchTasks = () => taskManager.get();

// ----->>>>>>> COST BREAKDOWN <<<<<<<<-------

export const fetchCostBreakdown = () => costBreakdown.get();

export const fetchQuotas = () => {
  tenantPricing.get();
  costBreakdown.get();
};
