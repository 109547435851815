import React from 'react';
import { Button, Dialog, DialogProps, Input, Row } from 'elements';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type Props = DialogProps<{ email: string }> & {
  testId: string;
  isSaving: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('forms.emailInvalid').required('forms.required'),
});

const INITIALS = {
  email: '',
};

const AddEmailDialog = (props: Props) => {
  const { onSave, testId, isSaving, ...rest } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    values,
    errors,
    resetForm,
    handleChange,
    submitCount,
  } = useFormik({
    initialValues: INITIALS,
    validateOnMount: false,
    validationSchema,
    onSubmit: (val) => Promise.resolve(onSave(val)),
  });

  React.useEffect(() => {
    if (!rest.open) {
      resetForm();
    }
  }, [rest.open]);

  return (
    <Dialog
      {...rest}
      testId={testId}
      title={t('settings.page.partnerInfo.billingEmails.dialog.title')}
      handleSubmit={handleSubmit}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={rest.onClose}
            testId={`${testId}-cancel`}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" testId={`${testId}`} disabled={isSaving}>
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <div className="steel mb-20">
        {t('settings.page.partnerInfo.billingEmails.dialog.subtitle')}
      </div>
      <Input
        id="email"
        value={values.email}
        name="email"
        label="forms.email"
        placeholder="forms.placeholders.email"
        onChange={handleChange}
        error={!!submitCount && !!errors.email}
        helperText={errors.email}
      />
    </Dialog>
  );
};

export default AddEmailDialog;
