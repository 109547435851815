import React from 'react';
import cn from 'classnames';
import { Col, Row, Paper } from 'elements';
import { ALL_TEST_IDS, CustomEvents } from 'enums';
import { DndContext } from '@dnd-kit/core';
import VirtualMachineCard from '../services/components/cards/wrappers/VirtualMachine';
import DedicatedServerCard from '../services/components/cards/wrappers/DedicatedServer';
import { useUserHash, useQuery, useState, useAccount } from 'hooks';
import { useTranslation } from 'react-i18next';
import { remCalc, Emitter, confirm, showSystemMessage } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import FolderDetailsDialog from '../../components/Folders/FolderDetails';
import FolderList from './Folders/FolderList';
import ImportVmDialog from './ImportVm/ImportVmDialog';
import VmToolbar from './Toolbar';
import TablesWrapper from './TablesWrapper';
import { VmLoaderSkeleton } from './Styled';

const OBSERVERS = {
  currentVm: StateHandlers.vmDetails,
  assignments: StateHandlers.badgesAssignment,
  vms: StateHandlers.vms,
  meta: StateHandlers.meta,
  currentUser: StateHandlers.currentUser,
  taskManager: StateHandlers.taskManager,
  folders: StateHandlers.folders,
  privateQuery: StateHandlers.defaultVmQuery,
};

type IViewProps = typeof OBSERVERS;

const STATE = {
  importVmDialog: false,
  forderDetailsOpen: false,
  selectedFolder: undefined,
};

const EmptyTag = (props: any) => <React.Fragment children={props.children} />;

const VmView = observer((props: IVmListMainTypes.IProps & IViewProps) => {
  const {
    onAdd,
    shouldUseQuery,
    vms,
    currentUser,
    taskManager,
    folders,
    privateQuery,
  } = props;
  const { t } = useTranslation();
  const [, changeUrl] = useUserHash();
  const { query: urlQuery, changeQuery: changeUrlQuery } =
    useQuery<IVmTypes.VMParams>();
  const [account] = useAccount();
  const [state, handleState] = useState<typeof STATE>(STATE);

  const q = shouldUseQuery ? urlQuery : privateQuery.data;
  const changeQuery = shouldUseQuery ? changeUrlQuery : privateQuery.merge;

  const isOnVmDetailsPage = shouldUseQuery;
  const Wrapper = isOnVmDetailsPage ? Paper : EmptyTag;

  const onOpenDetails = React.useCallback((vm: IVmTypes.Vm) => {
    const isDedicated = !!vm.dedicatedServer;
    const routeNamespace = isDedicated ? 'dedicated' : 'vm';
    changeUrl(`/services/${routeNamespace}/info`, { id: vm.id });
  }, []);

  const reloadCurrentVm = React.useCallback(
    (id: number) =>
      vms.reload(id, { include: ['serviceMonitoring', 'serviceSnapshot'] }),
    []
  );

  const fetchFolders = React.useCallback(() => {
    const type = !q.networkType
      ? undefined
      : q.networkType === 'DEDICATED'
      ? 'DEDICATED_SERVER'
      : 'VIRTUAL_SERVER';

    return folders.get({ tenantId: account?.tenant?.id, type });
  }, [account?.tenant?.id, JSON.stringify(q)]);

  const fetchTasks = React.useCallback(() => taskManager.get(), []);

  const fetchVms = React.useCallback(() => {
    const isDedicated = q.networkType === 'DEDICATED';
    const type = isDedicated ? 'DEDICATED' : undefined;
    const networkType = isDedicated ? undefined : q.networkType;
    return vms.get({
      ...q,
      type,
      networkType,
      include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
    });
  }, [JSON.stringify(q)]);

  React.useEffect(() => {
    fetchVms();
    fetchFolders();
  }, [JSON.stringify(q)]);

  React.useEffect(() => {
    Emitter.on(CustomEvents.fetchVmList, fetchVms);
    Emitter.on(CustomEvents.fetchFolderList, fetchFolders);
    return () => {
      Emitter.off(CustomEvents.fetchVmList, fetchVms);
      Emitter.off(CustomEvents.fetchFolderList, fetchFolders);
    };
  }, []);

  const vmDisplayType = account?.uiServiceConfig?.viewType;

  const hasData = !!vms.data.length;

  const shouldUseDrop = !isOnVmDetailsPage;

  const handleDragEnd = React.useCallback(
    (event: any) => {
      const { active, over } = event;

      const folderElement = over?.data?.current;
      const vmElement = active?.data?.current;

      const isElementsSameType =
        folderElement?.elementType === vmElement?.elementType;

      if (isElementsSameType) {
        confirm({
          title: 'folders.confirm.moveToFolder.title',
          content: 'folders.confirm.moveToFolder.content',
          onSuccess: () =>
            folders
              .executeRequest('moveToFolder')(
                account?.tenant?.id,
                folderElement?.id,
                {
                  appEntityId: vmElement?.appEntityId,
                }
              )
              .then(() => {
                fetchVms();
                return fetchFolders();
              })
              .catch((er) => {
                showSystemMessage(er.message, 'error');
              }),
          onCancel: () => undefined,
        });
      }
    },
    [account?.tenant?.id]
  );

  if (!(folders.dataReceived && vms.dataReceived))
    return <VmLoaderSkeleton animation="wave" variant="rectangular" />;

  return (
    <div className="pb-30">
      <VmToolbar
        onAdd={onAdd}
        query={q}
        changeQuery={changeQuery}
        fetchInstance={fetchVms}
        onCreateFolderClick={() =>
          handleState({ forderDetailsOpen: true, selectedFolder: undefined })
        }
        onImportVm={() => handleState({ importVmDialog: true })}
      />
      <DndContext onDragEnd={handleDragEnd}>
        {!hasData ? (
          <>
            {vmDisplayType === 'card' ? (
              <Row
                spacing={2}
                className={cn({
                  disabled: vms.isRequesting || currentUser.isRequesting,
                })}
              >
                <FolderList />
              </Row>
            ) : (
              <FolderList />
            )}
            <Row
              justifyContent="center"
              alignItems="center"
              data-test-id={ALL_TEST_IDS.services.vm.emptyCard}
            >
              <div
                className={cn('steel fs-14')}
                style={{ lineHeight: remCalc(391) }}
              >
                {t('services.content.placeholder.services')}
              </div>
            </Row>
          </>
        ) : vmDisplayType === 'list' ? (
          <Wrapper className="pl-20 pr-20">
            <TablesWrapper
              changeQuery={changeQuery}
              onResolve={(id: number) => {
                reloadCurrentVm(id);
                fetchTasks();
              }}
              onOpenDetails={onOpenDetails}
              fetchVms={fetchVms}
              query={q}
            />
          </Wrapper>
        ) : (
          <>
            <Row
              spacing={2}
              className={cn({
                disabled: vms.isRequesting || currentUser.isRequesting,
              })}
            >
              <FolderList />
              {vms.data.map((vm: IVmTypes.Vm) => {
                if (vm.type === 'DEDICATED')
                  return (
                    <Col key={vm.id}>
                      <DedicatedServerCard
                        {...vm}
                        onAdd={onAdd}
                        dataReceived={vms.dataReceived}
                        shouldUseDrop={shouldUseDrop}
                      />
                    </Col>
                  );

                return (
                  <Col key={vm.id}>
                    <VirtualMachineCard
                      {...vm}
                      onAdd={onAdd}
                      dataReceived={vms.dataReceived}
                      shouldUseDrop={shouldUseDrop}
                    />
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </DndContext>

      <FolderDetailsDialog
        open={state.forderDetailsOpen}
        onClose={() => handleState({ forderDetailsOpen: false })}
        initialValues={state.selectedFolder}
        onSave={() => {
          handleState({ forderDetailsOpen: false });
          fetchFolders();
        }}
      />
      <ImportVmDialog
        open={state.importVmDialog}
        onClose={() => handleState({ importVmDialog: false })}
        onSave={() => {
          fetchVms();
          fetchFolders();
          handleState({ importVmDialog: false });
        }}
      />
    </div>
  );
});

const VmsList = (props: IVmListMainTypes.IProps) => (
  <VmView {...props} {...OBSERVERS} />
);

export default VmsList;
