import * as Yup from 'yup';

export const MAX_VM_DETAILS_VALUES = {
  virtualCpus: 64,
  ramMb: 512,
  osDiskSizeGb: 10000,
};

const BasePubServiceSchema = {
  name: Yup.string().trim().required('forms.required'),
  port: Yup.string()
    .trim()
    .test('port', 'forms.required', (value) => +(value as string) > 0)
    .test('port', 'services.vm.services.errors.ICMPtype', function (port: any) {
      const isIcmp = this.parent.type?.value === 'icmp';
      if (isIcmp) {
        return port === '49573';
      }
      return true;
    }),
  type: Yup.object()
    .required('forms.required')
    .when('port', {
      is: (port: string) => +port === 161,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.161Port',
          test: (el) => {
            return el?.value === 'udp';
          },
        }),
    })
    .when('port', {
      is: (port: string) => +port === 49573,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.ICMPPort',
          test: (el) => {
            return el?.value === 'icmp';
          },
        }),
    }),
};

export const publicServiceValidationSchema =
  Yup.object().shape(BasePubServiceSchema);

export const createPublicServiceValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('forms.required'),
  port: Yup.string()
    .trim()
    .test('port', 'forms.required', (value) => +(value as string) > 0)
    .test('port', 'services.vm.services.errors.ICMPtype', function (port: any) {
      const isIcmp = this.parent.type?.value === 'icmp';
      if (isIcmp) {
        return port === '49573';
      }
      return true;
    }),
  type: Yup.object()
    .required('forms.required')
    .when('port', {
      is: (port: string) => +port === 161,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.161Port',
          test: (el) => {
            return el?.value === 'udp';
          },
        }),
    })
    .when('port', {
      is: (port: string) => +port === 49573,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.ICMPPort',
          test: (el) => {
            return el?.value === 'icmp';
          },
        }),
    }),
  sources: Yup.array(Yup.string()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 0) return true;
      return false;
    },
    then: Yup.array(Yup.string()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.sources.required',
      (val) => !!val?.length
    ),
  }),
  countryCodes: Yup.array(Yup.object()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 1) return true;
      return false;
    },
    then: Yup.array(Yup.object()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.country.required',
      (val) => !!val?.length
    ),
  }),
});

export const editPublicServiceValidationSchema = Yup.object().shape({
  sources: Yup.array(Yup.string()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 0) return true;
      return false;
    },
    then: Yup.array(Yup.string()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.sources.required',
      (val) => !!val?.length
    ),
  }),
  countryCodes: Yup.array(Yup.object()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 1) return true;
      return false;
    },
    then: Yup.array(Yup.object()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.country.required',
      (val) => !!val?.length
    ),
  }),
});
