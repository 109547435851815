import { POST } from 'utils/fetcher/axiosWrappers';
import { AxiosPromise } from 'axios';

interface IResponseImage {
  id: number;
  link: string;
}

export const uploadImage = (
  file: File,
  destination: 'logos'
): AxiosPromise<IResponseImage> => {
  const data = new FormData();
  data.append('image', file);
  data.append('imageDist', destination);

  return POST<IResponseImage>('/images/images', {}, data);
};
