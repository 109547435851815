import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useStateHandler, useState, useForceUpdate } from 'hooks';
import {
  LinearTable,
  Row,
  Chip,
  Radio,
  Input,
  createColumnHelper,
} from 'elements';
import { defineStatusNamespace, defineSystemColorByStatus } from 'enums';
import { debounce } from 'utils';
import * as StateHandlers from 'states';
import * as TYPES from './types';

type IConfig = {
  t: any;
  selected?: IVmVcdTypes.Vm;
  onSelect: (vcd: IVmVcdTypes.Vm | undefined) => void;
};

const column = createColumnHelper<IVmVcdTypes.Vm>();

const getColumns = ({ t, onSelect, selected }: IConfig) => [
  column.accessor('name', {
    header: (
      <span className="steel fs-12 weight-normal">{t('table.head.name')}</span>
    ),
    disableSort: false,
  }),
  column.accessor('numberOfCpus', {
    header: (
      <span className="steel fs-12 weight-normal">{t('table.head.cpu')}</span>
    ),
    disableSort: true,
    size: 60,
    alignment: 'right',
  }),
  column.accessor('memoryMB', {
    header: (
      <span className="steel fs-12 weight-normal">{t('table.head.ram')}</span>
    ),
    disableSort: true,
    size: 90,
    alignment: 'right',
    cell: ({ row: { original } }: ICell<IVmVcdTypes.Vm>) => {
      return <span>{original.memoryMB / 1024}</span>;
    },
  }),
  column.accessor('totalStorageAllocatedMb', {
    header: (
      <span className="steel fs-12 weight-normal">
        {t('table.head.storage')}
      </span>
    ),
    size: 120,
    alignment: 'right',
    disableSort: true,
    cell: ({ row: { original } }: ICell<IVmVcdTypes.Vm>) => {
      return <span>{original.totalStorageAllocatedMb / 1024}</span>;
    },
  }),
  column.accessor('status', {
    header: (
      <span className="steel fs-12 weight-normal">
        {t('table.head.status')}
      </span>
    ),
    alignment: 'right',
    disableSort: true,
    cell: ({ row: { original } }: ICell<IVmVcdTypes.Vm>) => {
      const color = defineSystemColorByStatus(original.status);

      return (
        <Chip status={color as any}>
          {t(
            `services.card.content.status.${defineStatusNamespace(
              original.status
            )}`
          )}
        </Chip>
      );
    },
  }),

  column.accessor('id', {
    disableSort: true,
    size: 60,
    cell: ({ row: { original } }: ICell<IVmVcdTypes.Vm>) => {
      const isSelected = selected?.id === original.id;
      const isVmImported = !!original.innerVmId;

      if (isVmImported) {
        return null;
      }

      return (
        <Row
          alignItems="center"
          justifyContent="flex-end"
          className="full-width"
        >
          <Radio
            value={original.id}
            label={''}
            className="m-0"
            checked={isSelected}
            onChange={() => onSelect(isSelected ? undefined : original)}
          />
        </Row>
      );
    },
  }),
];

const SecondStep = observer((props: IProps) => {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const vcdVms = useStateHandler(StateHandlers.vcdVms);
  const searchRef = React.useRef({ value: '' });
  const [query, handleQuery] = useState<IQuery>({
    orderBy: 'name',
    orderType: 'asc',
    page: 1,
    perPage: 50,
  });

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
    forceUpdate();
  }, [query.q]);

  const changeSearchUrl = React.useCallback(
    (q: string) => handleQuery({ ...query, q, page: 1 }),
    [query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [query]
  );

  const columns = React.useMemo(
    () =>
      getColumns({ t, onSelect: props.onSelect, selected: props.values.vm }),
    [props.onSelect]
  );

  const fetchVms = React.useCallback(
    (params: IQuery) => {
      return vcdVms.get({
        vdcId: props.values.vcd?.id,
        orgId: props.values.vcd?.orgId,
        ...params,
      });
    },
    [props.values.vcd?.id, props.values.vcd?.orgId]
  );

  React.useEffect(() => {
    fetchVms(query);
  }, [props.values.vcd?.id, props.values.vcd?.orgId, JSON.stringify(query)]);

  return (
    <div>
      <div className="mb-25">
        <Input
          ref={searchRef}
          type="search"
          placeholder={t('forms.placeholders.search')}
          className="bg-white"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </div>
      <LinearTable
        data={vcdVms.data}
        columns={columns}
        query={query}
        params={vcdVms.meta}
        customizeRow={(vm: IVmVcdTypes.Vm) => cn({ disabled: !!vm.innerVmId })}
        hasSorting
        onSort={(orderBy, orderType) => handleQuery({ orderBy, orderType })}
        onPageChange={(newQuery) => handleQuery({ page: newQuery.page })}
        usePagination
        shouldUseQuery={false}
      />
    </div>
  );
});

type IProps = FormikProps<TYPES.IFormValues> & {
  onSelect: (vcd: IVmVcdTypes.Vm | undefined) => void;
};

type IQuery = {
  orderType: string;
  orderBy: string;
  q?: string;
  page: number;
  perPage: 50;
};

export default SecondStep;
