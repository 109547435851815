import * as React from 'react';
import { showSystemMessage, confirm } from 'utils';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ISnapshotEntityType } from 'snapshots';
import * as StateHandlers from 'states';
import { useStateHandler } from 'hooks/instanceHook';

export type ISnapshotAction =
  | 'create'
  | 'revert'
  | 'remove'
  | 'cancelAutoremoval';
export type ICreateSnapshotPayload = { description: string };
export type IConfig = {
  serviceEntityId: number;
  serviceEntityName: ISnapshotEntityType;
};

export const snapshotInputValidationSchema = Yup.object({
  input: Yup.string().trim().max(100, 'forms.invalid.max'),
});

export function useSnapshotRequest(
  config: IConfig,
  currentSnapshotId?: number
) {
  const { serviceEntityName, serviceEntityId } = config;
  const { t } = useTranslation();
  const snapshots = useStateHandler(StateHandlers.snapshots);

  const confirmSnapshotRequest = React.useCallback(
    (action: ISnapshotAction, payload?: ICreateSnapshotPayload) => {
      return new Promise((res) => {
        let result: any;
        // for create action we have another flow with more complicated dialog
        if (action === 'create') {
          return snapshots
            .create({
              serviceEntityId,
              serviceEntityName,
              ...payload,
            })
            .then((response) => {
              result = response;
              showSystemMessage(
                `snapshots.notify.${action}.success`,
                'success'
              );
              snapshots.get();
              res(result);
            });
        }

        return confirm({
          title: t(`snapshots.confirm.${serviceEntityName}.${action}.title`),
          content: t(
            `snapshots.confirm.${serviceEntityName}.${action}.content`
          ),
          onSuccess: async () => {
            if (action === 'revert') {
              // @ts-ignore
              result = await snapshots.update(currentSnapshotId);
            }
            if (action === 'remove') {
              result = await snapshots.remove(currentSnapshotId);
            }
            if (action === 'cancelAutoremoval') {
              result = await snapshots.executeRequest('cancelAutoremoval')(
                currentSnapshotId
              );
            }
            showSystemMessage(`snapshots.notify.${action}.success`, 'success');
            snapshots.get();
            return res(result);
          },
          onCancel: () => res(undefined),
        });
      });
    },
    [serviceEntityId, serviceEntityName, currentSnapshotId]
  );

  return {
    isRequesting: snapshots.isRequesting,
    execute: confirmSnapshotRequest,
  };
}
