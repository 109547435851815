import React, { useCallback } from 'react';
import cn from 'classnames';
import { AnyShape } from 'global-shapes';
import { isEmpty, pick } from 'ramda';
import {
  Alert,
  Button,
  Loader,
  TablePagination,
  Row,
  Col,
  IconButton,
  MuiIcons,
} from 'elements';
import { confirm, confirmDelete, showSystemMessage } from 'utils';
import { useTranslation } from 'react-i18next';
import { useAsync, useQuery, useTask, usePermissions, useState } from 'hooks';
import {
  IFileShare,
  IFileShareFormValues,
  IMigrationEndpointFormValues,
} from 'file-shares';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { withCoreActions, InjectedProps } from 'hocs/withCoreActions';
import FileShare from './FileShare';
import { fileShareService, coreInfService } from 'services';
import CreateFileShareDialog from './CreateFileShareDialog';
import MigrationEndpointDialog from './MigrationEndpointDialog';
import MigrationEndpointCreatedInfoDialog from './EndpointCreatedInfoDialog';
import FileShareStorageDetails from '../dialogs/FileShareStorageDetails';
import { reformatFileSharePayload } from './helpers';
import {
  INITIAL_STATE,
  DEFAULT_USERS_GET_PARAMS,
  DEFAULT_FS_GET_PARAMS,
} from './constants';
import CoreTitle from '../Title';
import { SnapshotAlert } from 'pages/vm-details/Styled';
import { FSDiskSizeHolder } from './Styled';

type IDialogNamespace =
  | 'fs'
  | 'migration'
  | 'command-line'
  | 'fs-storage'
  | null;

type IState = {
  openedDialog: IDialogNamespace;
  commandToCopy: string;
  currentFS: IFileShare | null;
};

type IViewProps = {
  core: StateHandlers.ICoreHandler;
  snapshots: StateHandlers.ISnapshotsHandler;
  fileShares: StateHandlers.IFileSharesHandler;
  selectedFS: StateHandlers.ISelectedFileShareHandler;
  usersWithFileSharePerms: StateHandlers.ICoreUsersWithFileSharePermsHandler;
};

const View = observer((props: InjectedProps & IViewProps) => {
  const {
    handlers,
    currentSnapshot,
    isRequesting,
    core,
    snapshots,
    fileShares,
    selectedFS,
    usersWithFileSharePerms,
  } = props;
  const { t } = useTranslation();
  const { queryStr, query, changeQuery } = useQuery();
  const { permissions } = usePermissions('SERVICES');
  const [state, handleChange] = useState<IState>(INITIAL_STATE);

  const hasSnapshot = !!currentSnapshot;

  const task = useTask(core ? core.data.task : null);

  const onUpdateSnTaskComplete = React.useCallback(() => {
    snapshots.get();
    core.get();
  }, []);

  const snapshotTask = useTask(currentSnapshot?.task, {
    onComplete: onUpdateSnTaskComplete,
  });

  const isAgentConnected = !!core.data?.agent && core.data?.agent.isConnected;

  const fetchAllFileShares = useCallback((q: AnyShape) => {
    fileShares.get({ ...DEFAULT_FS_GET_PARAMS, ...q });
  }, []);

  const fetchFSUsers = useCallback(() => {
    usersWithFileSharePerms.get({
      ...DEFAULT_USERS_GET_PARAMS,
      perPage: 1000,
    });
  }, []);

  const { execute: createMigrationEndpoint, isPending } = useAsync(
    (id: number, payload: IMigrationEndpointFormValues) =>
      fileShareService.createMigrationEndpoint(id, payload).then((res) => {
        fetchAllFileShares(query);
        showSystemMessage(
          'services.core.file-shares.migrationEndpoint.notify.create.success',
          'success'
        );
        return res;
      })
  );

  const { execute: updateMigrationEndpoint, isPending: isPendingMEUpdate } =
    useAsync((id: number, payload: IMigrationEndpointFormValues) =>
      fileShareService
        .updateMigrationEndpoint(
          id,
          pick(['localSourcePath', 'sourceWANIp', 'expireIn'])(payload)
        )
        .then((res) => {
          fetchAllFileShares(query);
          showSystemMessage(
            'services.core.file-shares.migrationEndpoint.notify.update.success',
            'success'
          );
          return res;
        })
    );

  const fetchFileShareDetails = useCallback((id: number) => {
    selectedFS.get({ id });
  }, []);

  const createFileShare = useCallback((values: IFileShareFormValues) => {
    fileShares.create(reformatFileSharePayload(values)).then(() => {
      fileShares.get();
      handleDialog(null)();
    });
  }, []);

  const editFileSharePermissions = useCallback(
    (values: IFileShareFormValues) => {
      fileShares
        .update(selectedFS.data.id, reformatFileSharePayload(values).users)
        .then(() => {
          fileShares.get();
          handleDialog(null)();
        });
    },
    [selectedFS.data?.id]
  );

  const onDeleteFS = useCallback((id: number) => {
    confirmDelete({
      title: t('services.core.file-shares.confirm.delete.title'),
      content: t('services.core.file-shares.confirm.delete.subtitle'),
      placeholder: 'forms.placeholders.confirm.delete',
      successLabel: 'common.delete',
      cancelLabel: 'common.cancel',
      onSuccess: () =>
        fileShares.remove(id).then(() => {
          fileShares.get();
          handleDialog(null)();
        }),
      onCancel: () => undefined,
    });
  }, []);

  const deleteMigrationEndpoint = useCallback(
    (id: number) => {
      confirm({
        title: t(
          'services.core.file-shares.migrationEndpoint.confirm.delete.title'
        ),
        content: (
          <div className="steel">
            {t(
              'services.core.file-shares.migrationEndpoint.confirm.delete.subtitle'
            )}
          </div>
        ),
        onSuccess: () =>
          fileShareService
            .deleteMigrationEndpoint(id)
            .then(() => {
              showSystemMessage(
                'services.core.file-shares.migrationEndpoint.notify.delete.success',
                'success'
              );
            })
            .catch((err) => showSystemMessage(err.message, 'error')),
        onCancel: () => undefined,
      });
    },
    [queryStr]
  );

  const createME = useCallback(
    async (values: IMigrationEndpointFormValues) => {
      const newME = await createMigrationEndpoint(state.currentFS?.id, values);
      const newFS = { ...state.currentFS };
      newFS.migrationEndpoint = newME.data;
      handleDialog('command-line')(newFS);
    },
    [state.currentFS]
  );

  const updateME = useCallback(
    async (values: IMigrationEndpointFormValues) => {
      const res = await updateMigrationEndpoint(state.currentFS?.id, values);
      handleDialog(null)();
      return res;
    },
    [state.currentFS]
  );

  const updateDiskSize = useCallback(
    async (size: number) => {
      if (isAgentConnected) {
        await coreInfService
          .updateDiskSize(size)
          .catch((e) => showSystemMessage(e.message, 'error'));
        core.get();
        handleChange({ openedDialog: null });
      }
    },
    [isAgentConnected]
  );

  const handleDialog = useCallback(
    (dialogType: IDialogNamespace) => (data?: any) => {
      const newState: AnyShape = {
        openedDialog: dialogType,
        currentFS: data || null,
      };
      if (dialogType === 'fs' && data && data.id) {
        return data.id && fetchFileShareDetails(data.id);
      }

      if (!dialogType) {
        selectedFS.reset();
      }

      handleChange(!dialogType ? INITIAL_STATE : newState);
    },
    []
  );

  const isNewFileShare = isEmpty(selectedFS.data);

  React.useEffect(() => {
    fetchAllFileShares(query);
  }, [queryStr]);

  React.useEffect(() => {
    fetchFSUsers();
  }, []);

  React.useEffect(() => {
    handleChange({ openedDialog: isNewFileShare ? null : 'fs' });
  }, [isNewFileShare]);

  if (core.data?.agent && !core.data?.agent.isVerified) {
    return (
      <Alert severity="error">{t('services.core.data.agentNotVerified')}</Alert>
    );
  }

  if (!fileShares.dataReceived || !usersWithFileSharePerms.dataReceived) {
    return <Loader />;
  }

  const hasFileShares = !!fileShares.data.length;
  const isNewME = !(state.currentFS && state.currentFS.migrationEndpoint);

  return (
    <div className={cn({ disabled: task.isTaskActive })}>
      <CoreTitle />
      <div className={cn({ disabled: !isAgentConnected })}>
        <div className="flex justify-between align-center mb-15">
          <div className="fs-14 uppercase bolder">
            {t('services.core.file-shares.title')}
          </div>
          {permissions.canManage && (
            <Button
              size="small"
              onClick={isAgentConnected ? handleDialog('fs') : undefined}
            >
              {t('services.core.file-shares.addButton')}
            </Button>
          )}
        </div>
        <div className="flex full-width align-center">
          <div className="mr-10">
            {t('services.core.file-shares.ssdStorage')}:
          </div>
          <FSDiskSizeHolder>
            <Row alignItems="center">
              <Col>
                {t('services.core.file-shares.ssdStorage.value', {
                  value: core.data?.fileShareDiskSizeGb,
                })}
              </Col>
              {permissions.canManage && !hasSnapshot && (
                <IconButton
                  size="small"
                  className="ml-10"
                  disabled={core.isRequesting}
                  onClick={() => handleChange({ openedDialog: 'fs-storage' })}
                >
                  <MuiIcons.Edit className="fs-18 steel" />
                </IconButton>
              )}
              <Col></Col>
            </Row>
          </FSDiskSizeHolder>
        </div>

        <div className="mb-25 fs-12 steel">
          {t('services.core.file-shares.ssdStorage.warning')}
        </div>
        {hasSnapshot && (
          <SnapshotAlert severity="info" className="mb-15 relative">
            <div className="flex align-center _text">
              <div>
                {t('services.core.file-shares.alert.snapshot.created.title')}
              </div>
              <Button
                size="small"
                className="ml-10 _button"
                color="default"
                variant="outlined"
                disabled={
                  isRequesting || snapshotTask.isTaskActive || task.isTaskActive
                }
                onClick={() => {
                  handlers.removeSnapshot().then(onUpdateSnTaskComplete);
                }}
              >
                {t('services.content.action.removeSnapshot')}
              </Button>
            </div>
          </SnapshotAlert>
        )}
        {hasFileShares ? (
          <div className={cn({ disabled: fileShares.isRequesting })}>
            {fileShares.data.map((fs: IFileShare) => (
              <FileShare
                {...fs}
                key={fs.id}
                fetchFS={() => {
                  fetchFSUsers();
                  fetchAllFileShares(query);
                }}
                isEditable={permissions.canManage}
                onEdit={handleDialog('fs')}
                onDelete={() => onDeleteFS(fs.id)}
                onStartMigration={handleDialog('migration')}
                onEditMigration={handleDialog('migration')}
                onRemoveMigration={deleteMigrationEndpoint}
                users={usersWithFileSharePerms.data}
                onFetchFSUsers={fetchFSUsers}
                onUpdateFS={() => fetchAllFileShares(query)}
              />
            ))}
            <TablePagination
              shouldUseQuery
              perPage={query.perPage}
              page={query.page}
              totalCount={fileShares.meta?.totalCount}
              onChange={({ page }) => {
                changeQuery({ page });
              }}
            />
          </div>
        ) : (
          <div className="text-center fs-14 p-30 light-grey">
            {t('services.core.file-shares.noData')}
          </div>
        )}
        <CreateFileShareDialog
          title={t(
            `services.core.file-shares.dialog.fs.title.${
              isNewFileShare ? 'create' : 'edit'
            }`
          )}
          onSave={isNewFileShare ? createFileShare : editFileSharePermissions}
          open={state.openedDialog === 'fs'}
          onClose={handleDialog(null)}
          isNew={isNewFileShare}
          initialValues={selectedFS.data}
        />
        <MigrationEndpointDialog
          title={t(
            `services.core.file-shares.dialog.migration.title.${
              isNewME ? 'create' : 'edit'
            }`
          )}
          initialValues={
            state.currentFS ? state.currentFS.migrationEndpoint : undefined
          }
          onSave={isNewME ? createME : updateME}
          idDisabledSubmit={isPending || isPendingMEUpdate}
          isNew={isNewME}
          open={state.openedDialog === 'migration'}
          onClose={
            isPending || isPendingMEUpdate
              ? () => undefined
              : handleDialog(null)
          }
        />
        <MigrationEndpointCreatedInfoDialog
          open={state.openedDialog === 'command-line'}
          onClose={handleDialog(null)}
          currentFileShare={state.currentFS || undefined}
          onSave={() => undefined}
        />
        <FileShareStorageDetails
          open={state.openedDialog === 'fs-storage'}
          onClose={handleDialog(null)}
          onSave={(result) => updateDiskSize(result.storage)}
        />
      </div>
    </div>
  );
});

const FileShares = (props: InjectedProps) => (
  <View
    {...props}
    core={StateHandlers.core}
    snapshots={StateHandlers.snapshots}
    fileShares={StateHandlers.fileShares}
    selectedFS={StateHandlers.selectedFileShare}
    usersWithFileSharePerms={StateHandlers.coreUsersWithFileSharePerms}
  />
);

export default withCoreActions(FileShares);
