import {
  styled,
  Accordion,
  AccordionSummary,
  ExpandIcon,
  Paper,
} from 'elements';
import { remCalc, important } from 'utils';

export const AccordionRoot = styled(Accordion)(() => ({
  boxShadow: important('0 1px 1px 0 rgba(23, 29, 41, 0.16)'),
  marginTop: important(0),
  borderRadius: remCalc(5),
  paddingTop: 0,
  paddingBottom: 0,
  '&:before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    minHeight: important(remCalc(48)),
  },
  '& .MuiAccordionDetails-root': {
    padding: remCalc(25, 15, 25, 75),
  },
}));

export const STableHeadsWrapper = styled(Paper)({
  paddingLeft: remCalc(75),
  paddingRight: remCalc(10),
  position: 'relative',
});

export const SAccordionSummary = styled(AccordionSummary)({
  paddingLeft: remCalc(75),
  paddingRight: remCalc(10),
  position: 'relative',
});

export const SExpandIcon = styled(ExpandIcon)({
  position: 'absolute',
  top: remCalc(12.5),
  left: remCalc(25),
});

export const HTMLContainer = styled('div')(() => ({
  '& p': {
    marginBottom: '0.2em',
  },
}));
