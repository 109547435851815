import React from 'react';
import cn from 'classnames';
import * as R from 'ramda';
import {
  AddIcon,
  Button,
  Col,
  Row,
  VmTabs,
  VmTab,
  MuiIcons,
  Dropdown,
  IconButton,
  ListItemButton,
  Popover,
  List,
  IconListSettings,
  generateSortingIndicator,
} from 'elements';
import ChipListPopover from 'components/ChipListPopover';
import { VM_ORDER_OPTIONS, defaultVmsQueryFilters, VmColumnKeys } from 'enums';
import { ICurrentUserVmDisplayType } from 'auth-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { usePermissions, useState, useStateHandler, useAccount } from 'hooks';
import { useTranslation } from 'react-i18next';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as CONSTANTS from './constants';
import FiltersDialog from './FiltersDialog';
import SelectTenantosServerDialog from './DedicatedServers/SelectTenantosServerDialog';
import DedicatedServerDialog from './DedicatedServers/DedicatedServerDialog';
import FiltersChips from './FiltersChips';
import {
  mapTabIndex,
  mapTabTypeToIndex,
  defineAppliedFilters,
} from './helpers';

type IProps = {
  onAdd: (type: string, index: number) => void;
  onCreateFolderClick: () => void;
  onImportVm: () => void;
  query: IVmTypes.AllVmQuery;
  changeQuery: (params: Partial<IVmTypes.AllVmQuery>) => void;
  fetchInstance: () => void;
};

type IState = {
  openFilters: boolean;
  openSelectTenantosServers: boolean;
  openDedicatedServers: boolean;
  columnsIcon: any;
  tenantosServerSelected?: IDedicatedServer.TenantosServer;
};

type ViewHandlers = {
  currentUser: StateHandlers.ICurrentUserHandler;
  vms: StateHandlers.IVmsHandler;
  osList: StateHandlers.IOsListHandler;
};

const View = observer((props: IProps & ViewHandlers) => {
  const {
    onAdd,
    query,
    changeQuery,
    currentUser,
    vms,
    fetchInstance,
    osList,
    onCreateFolderClick,
    onImportVm,
  } = props;
  const { t } = useTranslation();
  const { permissions, isProvider } = usePermissions('SERVICES');
  const [account] = useAccount();
  const dedicatedServers = useStateHandler(StateHandlers.dedicatedServers);
  const [state, handleState] = useState<IState>(CONSTANTS.ToolbarInitialState);
  const tabIndex = mapTabTypeToIndex(query.networkType);
  const applied = defineAppliedFilters(query);
  const hasFilters = !R.isEmpty(applied);

  const handleDialogOpen = React.useCallback(
    (key: string, open: boolean) => () => {
      handleState({ [key]: open });
    },
    []
  );

  const changeTableDisplayType = React.useCallback(
    (viewType: ICurrentUserVmDisplayType) => () => {
      const payload: Partial<IAccount.CurrentUser> = {
        uiServiceConfig: { ...account?.uiServiceConfig, viewType },
      };

      return currentUser.update(null, payload).then(() => {
        currentUser.merge(payload);
      });
    },
    []
  );

  const handleVmListPopover = React.useCallback((ev: any) => {
    handleState({ columnsIcon: ev.currentTarget });
  }, []);

  const changeTableView = React.useCallback(
    (vmListColumns: string[]) => {
      if (!vmListColumns.length) {
        vmListColumns = VmColumnKeys;
      }
      const payload: Partial<IAccount.CurrentUser> = {
        uiServiceConfig: { ...account?.uiServiceConfig, vmListColumns },
      };
      currentUser.update(null, payload).then(() => {
        currentUser.merge(payload);
      });
    },
    [account?.uiServiceConfig]
  );

  const handleTabChange = React.useCallback((ev: any, ind: number) => {
    changeQuery({ networkType: mapTabIndex(ind) });
  }, []);

  const createDedicatedServer = React.useCallback(
    async (newServer: IDedicatedServer.IRentDedicatedServerPayload) => {
      const res = await dedicatedServers.create(newServer);
      fetchInstance();
      handleState({
        tenantosServerSelected: undefined,
        openSelectTenantosServers: false,
        openDedicatedServers: false,
      });
      return res;
    },
    [fetchInstance]
  );

  const vmDisplayType = account?.uiServiceConfig?.viewType;

  React.useEffect(() => {
    osList.get({ q: '', tenantId: account?.tenant?.id });
  }, [account?.tenant?.id]);

  return (
    <>
      <Row
        className={cn('mb-10', {
          disabled: currentUser.isRequesting || vms.isRequesting,
        })}
        alignItems="center"
        justifyContent="space-between"
      >
        <Col>
          <Row columnSpacing={2} alignItems="center">
            <Col>
              <h5>{t('services.content.virtualServer')}</h5>
            </Col>
            <Col>
              <VmTabs onChange={handleTabChange} value={tabIndex}>
                <VmTab index={0} label={t('services.vmList.navigation.all')} />
                <VmTab
                  index={1}
                  label={t('services.vmList.navigation.private')}
                />
                <VmTab
                  index={2}
                  label={t('services.vmList.navigation.public')}
                />
                <VmTab
                  index={3}
                  label={t('services.vmList.navigation.dedicated')}
                />
              </VmTabs>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row alignItems="center">
            {vmDisplayType === 'card' && (
              <Col>
                <Row alignItems="center" columnSpacing={1}>
                  <Col className="fs-12 steel">{t('common.sortBy')}:</Col>
                  <Col>
                    <Dropdown
                      className="fs-12"
                      value={query.orderBy}
                      closeOnSelect
                      onChange={(orderBy) =>
                        changeQuery({
                          orderBy: orderBy as IVmTypes.AllVmQuery['orderBy'],
                        })
                      }
                      options={VM_ORDER_OPTIONS}
                    />
                  </Col>
                  <Col className="lh-1">
                    <IconButton
                      size="small"
                      onClick={() =>
                        changeQuery({
                          orderType:
                            query.orderType === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      {generateSortingIndicator(query.orderBy, query, 16)}
                    </IconButton>
                  </Col>
                </Row>
              </Col>
            )}

            {permissions.canManage && vmDisplayType === 'list' && (
              <Col>
                <IconButton size="small" onClick={handleVmListPopover}>
                  <IconListSettings className={cn('fs-18 steel')} />
                </IconButton>
                <ChipListPopover
                  translationHash="services.vmList.toolbar.columns"
                  anchorEl={state.columnsIcon}
                  onClose={() => handleState({ columnsIcon: null })}
                  selected={
                    account?.uiServiceConfig?.vmListColumns || VmColumnKeys
                  }
                  options={VmColumnKeys}
                  onSelect={changeTableView}
                />
              </Col>
            )}
            {permissions.canManage && (
              <Col>
                <IconButton size="small" onClick={onCreateFolderClick}>
                  <MuiIcons.CreateNewFolder className={cn('fs-20 steel')} />
                </IconButton>
              </Col>
            )}
            <Col>
              <IconButton size="small" onClick={changeTableDisplayType('list')}>
                <MuiIcons.Reorder
                  className={cn('fs-20 pointer', {
                    'page-color': vmDisplayType !== 'card',
                    steel: vmDisplayType === 'card',
                  })}
                />
              </IconButton>
            </Col>
            <Col>
              <IconButton size="small" onClick={changeTableDisplayType('card')}>
                <MuiIcons.CalendarViewMonth
                  className={cn('fs-20 pointer', {
                    'page-color': vmDisplayType !== 'list',
                    steel: vmDisplayType === 'list',
                  })}
                />
              </IconButton>
            </Col>
            {permissions.canManage && (
              <Col className="pl-10">
                <PopupState variant="popover">
                  {(popupState) => {
                    return (
                      <React.Fragment>
                        <AddIcon
                          className={cn({ _rotated: popupState.isOpen })}
                          size="small"
                          id="create-vm-button"
                          {...bindTrigger(popupState)}
                        />

                        <Popover
                          {...bindMenu(popupState)}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <List className="fs-15">
                            <ListItemButton
                              component="div"
                              onClick={() => {
                                onAdd('vms', tabIndex);
                                popupState.close();
                              }}
                            >
                              {t('services.vmList.toolbar.buttons.addVm')}
                            </ListItemButton>
                            <ListItemButton
                              component="div"
                              onClick={() => {
                                popupState.close();
                                handleDialogOpen(
                                  'openSelectTenantosServers',
                                  true
                                )();
                              }}
                            >
                              {t(
                                'services.vmList.toolbar.buttons.addDedicatedVm'
                              )}
                            </ListItemButton>
                            {isProvider && (
                              <ListItemButton
                                component="div"
                                onClick={() => {
                                  onImportVm();
                                  popupState.close();
                                }}
                              >
                                {t('services.vmList.toolbar.buttons.importVm')}
                              </ListItemButton>
                            )}
                          </List>
                        </Popover>
                      </React.Fragment>
                    );
                  }}
                </PopupState>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row alignItems="center" columnSpacing={1} className="mb-10 fs-14 steel">
        <Col>{t('services.vmList.toolbar.filters')}:</Col>
        <FiltersChips
          query={query}
          onClear={changeQuery}
          applied={applied}
          hasFilters={hasFilters}
        />
        <Col>
          <Row alignItems="center">
            <Col>
              <IconButton
                size="small"
                onClick={handleDialogOpen('openFilters', true)}
              >
                <MuiIcons.FilterAlt className="fs-14" />
              </IconButton>
            </Col>
            {hasFilters && (
              <Col>
                <Button
                  size="small"
                  variant="text"
                  className="pl-15 pr-15 pt-5 pb-5"
                  onClick={() => changeQuery(defaultVmsQueryFilters)}
                >
                  {t('services.vmList.toolbar.filters.clearFilters')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <FiltersDialog
        open={state.openFilters}
        onClose={handleDialogOpen('openFilters', false)}
        initialValues={query}
        onSave={(q) => {
          changeQuery(q);
          handleDialogOpen('openFilters', false)();
        }}
      />

      <SelectTenantosServerDialog
        open={state.openSelectTenantosServers}
        onClose={handleDialogOpen('openSelectTenantosServers', false)}
        initialValues={undefined}
        onSave={(tenantosServerSelected) => {
          handleState({
            tenantosServerSelected,
            openDedicatedServers: true,
          });
        }}
      />

      <DedicatedServerDialog
        open={state.openDedicatedServers}
        onClose={() =>
          handleState({
            tenantosServerSelected: undefined,
            openDedicatedServers: false,
          })
        }
        selectedServer={state.tenantosServerSelected}
        onSave={createDedicatedServer}
      />
    </>
  );
});

const VmToolbar = (props: IProps) => (
  <View
    {...props}
    currentUser={StateHandlers.currentUser}
    vms={StateHandlers.vms}
    osList={StateHandlers.osList}
  />
);

export default VmToolbar;
