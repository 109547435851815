import { styled, Row, Col, Paper } from 'elements';
import { remCalc, important } from 'utils';
import { PALETTE } from 'enums';
import ReactCodeInput from 'react-verification-code-input';

export const AuthRoot = styled(Row)(() => ({
  minHeight: '100vh',
  width: '100%',
  backgroundColor: '#fff',
}));

export const AuthMainColWrapper = styled(Col)(() => ({
  minHeight: '100vh',
  padding: remCalc(20),
}));

export const AuthMainColRight = styled(Col)(() => ({
  minHeight: '100vh',
  padding: remCalc(20),
  backdropFilter: 'blur(14px)',
  backgroundColor: '#1d62ab',
  overflow: 'hidden',
}));

export const AuthSignUpMainColRight = styled(Col)(() => ({
  minHeight: '100vh',
  padding: remCalc(20),
  backdropFilter: 'blur(14px)',
  backgroundColor: PALETTE.paleGrey,
  overflow: 'hidden',
}));

export const AuthLeftContent = styled('div')(() => ({
  maxWidth: remCalc(460),
  margin: 'auto',
}));

export const AuthMobileLogo = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const AuthFooterLink = styled(Col)(({ theme }) => ({
  textAlign: 'center',
  fontSize: remCalc(14),
  [theme.breakpoints.up('sm')]: {
    fontSize: remCalc(17),
    paddingBottom: remCalc(50),
  },
}));

export const AuthSignUpRightContent = styled(Row)(({ theme }) => ({
  minHeight: `calc(100vh - ${remCalc(50)})`,
  maxWidth: remCalc(490),
  margin: 'auto',
  width: '100%',
}));

export const AuthServiceIncludeList = styled('ul')(({ theme }) => ({
  padding: 0,
  listStyle: 'none',
  maxWidth: remCalc(480),
  margin: '0 auto',
  width: '100%',
}));

export const AuthSignUpLogo = styled('img')(({ theme }) => ({
  maxWidth: remCalc(310),
  width: '100%',
  marginBottom: remCalc(120),
}));

export const AuthSignUpWaves = styled('img')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  right: 0,
  bottom: 'calc(50% - 450px)',
}));

export const CreatePassPage = styled('div')(({ theme }) => ({
  background: 'linear-gradient(to bottom, #3e9bd5, #1d62ab)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  padding: remCalc(15),
}));

export const CreatePassPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: remCalc(495),
  padding: remCalc(30, 25),
  [theme.breakpoints.up('sm')]: {
    padding: remCalc(40, 50),
  },
  '& h3': {
    marginBottom: remCalc(15),
  },
  '& div': {
    fontSize: remCalc(20),
  },
}));

export const CreatePassInfoLabel = styled('sup')(({ theme }) => ({
  verticalAlign: 'middle',
  fontSize: important(remCalc(12)),
  display: 'flex',
  '& svg': {
    paddingLeft: remCalc(5),
    fontSize: remCalc(17),
  },
}));

export const CreatePassLogo = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: remCalc(55),
  width: remCalc(220),
  transform: 'translateX(-50%)',
  '& img': {
    width: '100%',
  },
  '@media only screen and (max-height: 790px)': {
    display: 'none',
  },
}));


export const Setup2FaLogo = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: remCalc(55),
  width: remCalc(220),
  transform: 'translateX(-50%)',
  '& img': {
    width: '100%',
  },
  '@media only screen and (max-height: 990px)': {
    display: 'none',
  },
}));

export const LoginCol = styled(Col)(({ theme }) => ({
  minHeight: '100vh',
  padding: remCalc(20),
  display: 'flex',
}));

export const LoginRightCol = styled(Col)(({ theme }) => ({
  minHeight: '100vh',
  padding: remCalc(20),
  display: 'flex',
  backgroundColor: '#1d62ab',
}));

export const LoginLeftContent = styled('div')(() => ({
  maxWidth: remCalc(435),
  margin: 'auto',
}));

export const SetupFaPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: remCalc(510),
  padding: remCalc(25, 25),
  [theme.breakpoints.up('md')]: {
    padding: remCalc(55, 50, 50),
  },
}));

export const SetupFaCode = styled(Row)(({ theme }) => ({
  transform: 'scale(0.7)',
  padding: remCalc(25),
  marginBottom: 0,
}));

export const SetupFaInstallText = styled(Row)(({ theme }) => ({
  marginBottom: remCalc(25),
  '& a': {
    display: 'inline-block',
    padding: remCalc(0, 5),
  },
}));

export const VerCodePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: remCalc(495),
  padding: remCalc(30, 25),
  [theme.breakpoints.up('sm')]: {
    padding: remCalc(55, 50, 50),
  },
}));

export const VerCode = styled(ReactCodeInput)(({ theme }) => ({
  width: '100% !important',
  marginBottom: remCalc(15),
  '& input': {
    fontFamily: important('inherit'),
    padding: remCalc(10),
    borderRadius: important('6px'),
    border: important('none'),
    boxShadow: `0 0 0 1px ${PALETTE.lightGrey}`,
    '&:focus': {
      boxShadow: `0 0 0 1px ${PALETTE.primary}`,
    },
    '&:not(:last-of-type)': {
      marginRight: remCalc(15),
    },
    [theme.breakpoints.down('xs')]: {
      width: important(remCalc(32)),
      height: important(remCalc(32)),
      padding: remCalc(5),
      borderRadius: 3,
      '&:not(:last-of-type)': {
        marginRight: remCalc(7),
      },
    },
  },
}));
