import * as React from 'react';
import { isEmpty } from 'ramda';
import {
  Dialog,
  DialogProps,
  Switch,
  Button,
  Row,
  Alert,
  Input,
  styled,
} from 'elements';
import * as Yup from 'yup';
import { useUserHash, useState } from 'hooks';
import { useTranslation, Trans } from 'react-i18next';
import { IBillingFormPayload, ICustomer } from 'customer-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import ManageBillingEmails from 'components/ManageBillingEmailsContainer';

const InputDaysHolder = styled('div')(() => ({
  maxWidth: 300,
  width: '100%',
}));

const invoiceDaysReminderSchema = Yup.number()
  .required('forms.required')
  .min(1, 'forms.invalid.min')
  .max(30, 'forms.invalid.max');

type IErrors = Partial<{ emails: boolean; dueDays: boolean }>;

interface IState {
  emails: IPartnerTypes.IBillingEmail[];
  billingEmailsEnabled: boolean;
  invoiceReminderEnabled: boolean;
  invoiceDueDateDays: number;
  errors: IErrors;
}

function init(props: IPartnerTypes.Partner | ICustomer | undefined) {
  return {
    emails: props?.billingEmails || [],
    invoiceReminderEnabled: props?.invoiceReminderEnabled || false,
    invoiceDueDateDays: props?.invoiceDueDateDays || 14,
    billingEmailsEnabled: props?.billingEmailsEnabled || false,
    errors: {},
  };
}

const OBSERVERS = {
  currentTenant: StateHandlers.currentTenant,
  currentPartner: StateHandlers.currentPartner,
};

type IProps = React.PropsWithChildren<
  DialogProps<IBillingFormPayload> & {
    translationNamespace: 'partners' | 'tenants';
    source: 'partner' | 'tenant';
    subtitle: string;
    isSaving: boolean;
  }
>;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    isSaving,
    onSave,
    currentTenant,
    currentPartner,
    source,
    open,
    onClose,
    translationNamespace,
  } = props;

  const { t } = useTranslation();
  const [userHash] = useUserHash();

  const sourceData = React.useMemo(() => {
    if (source === 'tenant') {
      return currentTenant.data;
    }
    return currentPartner.data;
  }, [source, currentTenant.data, currentPartner.data]);

  const userAddressLink = React.useMemo(() => {
    if (source === 'tenant') {
      return '/company-info';
    }
    return '/partner-info';
  }, [source]);

  const [state, handleState] = useState<IState>(init(sourceData));

  const hasAddress = !!sourceData.address;

  const isDisabled = isSaving || !hasAddress;

  const validate = React.useCallback(async (st: IState): Promise<boolean> => {
    const errors: IErrors = {};
    if (st.billingEmailsEnabled && !st.emails.length) {
      errors.emails = true;
    }

    await invoiceDaysReminderSchema
      .validate(st.invoiceDueDateDays)
      .catch((err) => {
        errors.dueDays = err.errors[0];
      });

    handleState({ errors });
    return isEmpty(errors);
  }, []);

  const onEmailsSave = React.useCallback(async () => {
    const isValid = await validate(state);

    if (isValid && onSave) {
      onSave({
        invoiceReminderEnabled: state.billingEmailsEnabled
          ? state.invoiceReminderEnabled
          : undefined,
        invoiceDueDateDays: state.invoiceDueDateDays,
        billingEmailsEnabled: state.billingEmailsEnabled,
        emails: state.billingEmailsEnabled ? state.emails : undefined,
      });
    }
  }, [state]);

  React.useEffect(() => {
    if (open) {
      handleState(init(sourceData));
    }
  }, [open, sourceData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t(`${translationNamespace}.dialog.manageBilling.title`) as string}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button disabled={isDisabled} onClick={onEmailsSave}>
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <div className="mb-25 steel">
        {t(`${translationNamespace}.dialog.manageBilling.content`) as string}
      </div>
      <div className="mb-25">
        <Switch
          label={t(
            `${translationNamespace}.dialog.manageBilling.switcher.billingsEmail`
          )}
          checked={state.billingEmailsEnabled}
          disabled={isDisabled}
          onCheck={(billingEmailsEnabled) => {
            return handleState({ billingEmailsEnabled });
          }}
        />
      </div>
      {state.billingEmailsEnabled && (
        <>
          <div className="mb-25">
            <Switch
              label={t(
                `${translationNamespace}.dialog.manageBilling.switcher.sendInvoiceReminders`
              )}
              checked={state.invoiceReminderEnabled}
              disabled={isDisabled}
              onCheck={(invoiceReminderEnabled) => {
                return handleState({ invoiceReminderEnabled });
              }}
            />
          </div>
          <InputDaysHolder className="mb-25">
            <Input
              name="invoiceDueDateDays"
              label={t('forms.dueDate')}
              value={state.invoiceDueDateDays}
              type="number"
              error={!!state.errors.dueDays}
              helperText={state.errors.dueDays}
              helperTextOptions={{ min: 1, max: 30 }}
              disabled={!hasAddress}
              onChange={(ev) =>
                handleState({ invoiceDueDateDays: +ev.target.value })
              }
            />
          </InputDaysHolder>
        </>
      )}

      {!hasAddress && (
        <Alert severity="info" className="mb-20">
          <Trans
            i18nKey={`${translationNamespace}.dialog.manageBilling.notify.enterAddress`}
            components={{
              1: (
                <span
                  className="primary pointer"
                  onClick={() =>
                    (window.location.pathname = `${
                      userHash ? '/' + userHash : ''
                    }/${sourceData.shortName}/settings${userAddressLink}`)
                  }
                />
              ),
            }}
          />
        </Alert>
      )}
      <ManageBillingEmails
        useFormElement
        emails={state.emails}
        isDisabled={isDisabled}
        billingEmailsEnabled={state.billingEmailsEnabled}
        onEmailsChange={(emails: IPartnerTypes.IBillingEmail[]) => handleState({ emails })}
      />
      {state.errors.emails && !state.emails.length && (
        <Alert severity="error" className="mb-10">
          {t('partners.dialog.manageBilling.alert.noEmail') as string}
        </Alert>
      )}
    </Dialog>
  );
});

const ManageBillingDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default ManageBillingDialog;
