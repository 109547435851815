import * as React from 'react';
import { Popover } from 'elements';
import { toggleArrayState } from 'utils';
import EntityFilterItem from './header/EntitySearch/EntityFilterItem';

type IProps = {
  anchorEl: Node['ELEMENT_NODE'] | any;
  translationHash: string;
  selected: string[];
  onClose: AnyFunc;
  onSelect: (val: string[], key?: string) => void;
  options: string[];
};

const ChipListPopover = (props: IProps) => {
  const { anchorEl, onClose, options, selected, onSelect, translationHash } =
    props;

  const handleSelect = React.useCallback(
    (key: string) => () => {
      onSelect(toggleArrayState(selected, key), key);
    },
    [selected]
  );

  return (
    <div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          className: 'pt-10 pr-15 pb-10 pl-15 fs-14',
        }}
      >
        <div className="steel">
          {options.map((key) => {
            return (
              <EntityFilterItem
                key={key}
                parameter={key}
                checked={selected.includes(key)}
                onClick={handleSelect(key)}
                translationHash={translationHash}
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default ChipListPopover;
