import React from 'react';
import { Dialog, DialogProps, Input, Button } from 'elements';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type Props = DialogProps<{ letter: string }> & {
  isPending?: boolean;
  subtitle?: string;
};

const validationSchema = Yup.object().shape({
  letter: Yup.string()
    .trim()
    .required('forms.required')
    // @ts-ignore
    .test('letter', 'forms.invalid', (val: string) => {
      if (!val) return;
      if (val !== 'DELETE') return;
      return 'valid';
    }),
});

const ConfirmDeleteDialog = (props: Props) => {
  const { isPending, onSave, subtitle, title, open, ...rest } = props;
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    dirty,
  } = useFormik({
    initialValues: { letter: '' },
    validationSchema,
    validateOnMount: true,
    onSubmit: (val) => Promise.resolve(onSave && onSave(val)),
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      title={<div>{title}</div>}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button disabled={!values.letter || props.isPending} type="submit">
            {t('common.delete')}
          </Button>
        </>
      }
    >
      {subtitle && <div className="mb-20 steel">{t(subtitle)}</div>}
      <Input
        name="letter"
        placeholder="forms.placeholders.confirm.delete"
        aria-autocomplete="none"
        autoComplete="off"
        inputProps={{
          autoComplete: 'off',
        }}
        value={values.letter}
        onChange={handleChange}
        error={dirty && !!errors.letter}
        helperText="forms.invalid"
      />
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
