import * as React from 'react';
import { CostResourceIds, APP_SERVICES_GROUP } from 'enums';
import { compact, debounce, numberToCurrency, remCalc, round } from 'utils';
import { ICostPerResource } from 'usage';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import * as StateHandlers from '../states';
import { useStateHandler } from './instanceHook';

const DEFAULT_CALCULATION: IUseCostEstimationTypes.IResponse['calculation'] = {
  discountValue: 0,
  discountPercent: 0,
  total: 0,
  vat: null,
  totalIncludeVat: 0,
};

function calculateAdditionalDisks(
  disks: IVmTypes.VMDisk[] | IVmTypes.NewVMDisk[]
) {
  return R.sum(disks.map((d) => +d.sizeGb));
}

function mapResourcePrices(
  resources: ICostPerResource[]
): Record<string, ICostPerResource> {
  return R.reduce<ICostPerResource, Record<string, ICostPerResource>>(
    (res, val) => {
      res[val.resourceId] = val;
      return res;
    },
    {}
  )(resources);
}

export function buildBackupCalculationPayload(
  values: VmDialogTypes.IVmFormValues
): {
  backupEnabled: boolean;
  backupSchedule: ICostTypes.CostEstimationPayload['backupSchedule'];
} {
  const dailyJobSchedule = values.dailyJobSchedule
    ? {
        startTime: values.dailyFrom?.value,
        retentionDays: values.dailyRetention?.value,
        interval: values.dailyHours?.value,
      }
    : null;

  const weeklyJobSchedule = values.weeklyJobSchedule
    ? {
        retentionWeeks: values.weeklyWeeks?.value,
        dayOfWeek: values.weeklyDay?.value,
      }
    : null;

  const monthlyJobSchedule = values.monthlyJobSchedule
    ? {
        retentionMonths: values.monthlyMonths?.value,
      }
    : null;

  const backupEnabled =
    values.isBackupEnabled &&
    (values.monthlyJobSchedule ||
      values.weeklyJobSchedule ||
      values.dailyJobSchedule);

  return {
    backupEnabled,
    backupSchedule: backupEnabled
      ? {
          dailyJobSchedule,
          weeklyJobSchedule,
          monthlyJobSchedule,
        }
      : null,
  };
}

export function parseCostEstimationForVm(
  values: VmDialogTypes.IVmFormValues,
  sIds: number[],
  instance: IUseCostEstimationTypes.IInstance
): ICostTypes.CostEstimationPayload {
  const forceMonitoringToShow = sIds.includes(APP_SERVICES_GROUP.Monitoring.id);
  const monitoringManagementEnabled =
    forceMonitoringToShow || !!instance?.serviceMonitoring;
  const entity = instance?.entityName;
  const isVm = entity === 'Vm';

  const { backupEnabled, backupSchedule } =
    buildBackupCalculationPayload(values);

  const serviceIds = sIds.length
    ? sIds
    : compact([
        APP_SERVICES_GROUP.VirtualServer.id,
        backupEnabled && APP_SERVICES_GROUP.Backup.id,
        monitoringManagementEnabled && APP_SERVICES_GROUP.Monitoring.id,
      ]);

  const billingEnabled = isVm
    ? !['MONTH', 'NONE'].includes(values.billingCycleInterval?.value)
    : true;
  const billingCycleInterval = billingEnabled
    ? values.billingCycleInterval?.value
    : null;
  const includesWindowsLicense = values.templateId?.familyId === 1;
  const monitoringInstanceQty = monitoringManagementEnabled ? 1 : 0;

  const billingCycleId = R.has('billingCycleId', instance)
    ? (instance?.billingCycleId as number) || null
    : null;

  return {
    serviceIds,
    billingSettings: {
      billingCycleId,
      billingCycleInterval,
      billingType: billingCycleInterval ? 'billingCycle' : 'usage',
    },
    backupSchedule,
    serviceHardwareResources: {
      virtualCpus: values.virtualCpus,
      ramMb: values.ramMb * 1024,
      diskSizeGb: values.osDiskSizeGb + calculateAdditionalDisks(values.disks),
    },
    includesWindowsLicense,
    extraPublicIpQty: 0,
    monitoringManagementEnabled,
    monitoringInstanceQty,
  };
}

const observableKeys = [
  'billingCycleInterval',
  'ports',
  'disks',
  'isBackupEnabled',
  'templateId',
  'networkId',
  'virtualCpus',
  'ramMb',
  'osDiskSizeGb',
  'dailyFrom',
  'dailyRetention',
  'dailyHours',
  'weeklyWeeks',
  'weeklyDay',
  'monthlyMonths',
  'monthlyJobSchedule',
  'weeklyJobSchedule',
  'dailyJobSchedule',
  'chartsEnabled',
  'servicesEnabled',
  'outsourceManagementEnabled',
];

const getObservabeValues = R.pick(observableKeys);

const getEstimation = debounce((config: ICostTypes.CostEstimationPayload) => {
  return StateHandlers.costEstimation.get(config);
}, 1300);

const getCost = (price: number) => {
  return numberToCurrency(price, false);
};

export function useCostEstimation(
  config: IUseCostEstimationTypes.IConfig
): IUseCostEstimationTypes.IResponse {
  const { values, instance, serviceIds, paralyzeHook } = config;
  const { t } = useTranslation();
  const [isRequesting, setRequest] = React.useState(false);

  const costEstimation = useStateHandler(StateHandlers.costEstimation);

  const observableValues = React.useMemo(() => {
    return getObservabeValues(values);
  }, [JSON.stringify(values)]);

  const params = React.useMemo((): ICostTypes.CostEstimationPayload => {
    return parseCostEstimationForVm(observableValues, serviceIds, instance);
  }, [
    JSON.stringify(observableValues),
    JSON.stringify(instance),
    JSON.stringify(serviceIds),
    paralyzeHook,
  ]);

  React.useEffect(() => {
    if (!paralyzeHook) {
      setRequest(true);
      getEstimation(params).finally(() => {
        setRequest(false);
      });
    }
  }, [JSON.stringify(params), paralyzeHook]);

  const pricing = mapResourcePrices(
    costEstimation.data.cost?.costPerResource || []
  );

  const isWin = values.templateId?.familyId === 1;

  const vmBasePrice = pricing[CostResourceIds.virtualServerBase];
  const vmCpuPrice = pricing[CostResourceIds.virtualServerVirtualCpus];
  const vmRamPrice = pricing[CostResourceIds.virtualServerRamMb];
  const vmStoragePrice = pricing[CostResourceIds.virtualServerGBStorage];
  const winLicensePrice = pricing[CostResourceIds.winLicense];
  const backupBasePrice = pricing[CostResourceIds.backupBase];
  const backupStoragePrice = pricing[CostResourceIds.backupStorage];
  const monitoringPrice = pricing[CostResourceIds.monitoring];
  const outsourceManagementPrice = pricing[CostResourceIds.outsourceManagement];

  const buildBackupRangePrice = React.useCallback(
    (size: number) => {
      const periodInHours = backupStoragePrice?.periodInMinutes / 60;
      return backupStoragePrice?.resourcePrice * periodInHours * size || 0;
    },
    [backupStoragePrice]
  );

  const backupMin = buildBackupRangePrice(
    costEstimation.data?.meta?.backupStorageOptions?.minTb
  );
  const backupMax = buildBackupRangePrice(
    costEstimation.data?.meta?.backupStorageOptions?.maxTb
  );

  const displayList =
    React.useMemo((): IUseCostEstimationTypes.IDisplayListItem[] => {
      let list: IUseCostEstimationTypes.IDisplayListItem[] = [];
      if (R.includes(APP_SERVICES_GROUP.VirtualServer.id, params.serviceIds)) {
        list = compact([
          {
            name: t('costsInfo.baseFee'),
            cost: getCost(vmBasePrice?.cost),
          },
          {
            name: t('costsInfo.virtualCpus', {
              value: values.virtualCpus,
            }),
            cost: getCost(vmCpuPrice?.cost),
          },
          {
            name: t('costsInfo.ramMb', { value: values.ramMb }),
            cost: getCost(vmRamPrice?.cost),
          },
          {
            name: t('costsInfo.osDiskSizeGb', {
              value: values.osDiskSizeGb,
            }),
            cost: getCost(vmStoragePrice?.cost),
          },

          isWin && {
            name: t('costsInfo.winLicense'),
            cost: getCost(winLicensePrice?.cost),
          },

          ...values.disks.map((d) => ({
            name: (
              <div className="flex">
                <span
                  className="ellipsis pr-10"
                  style={{ maxWidth: remCalc(200) }}
                >
                  {d.name}
                </span>
                {d.sizeGb} GB
              </div>
            ),
            cost: getCost(
              round(vmStoragePrice?.resourcePrice * d.sizeGb * 24 * 30, 2)
            ),
          })),
        ]);
      }

      if (R.includes(APP_SERVICES_GROUP.Monitoring.id, serviceIds)) {
        list.push({
          name: t('costsInfo.monitoring'),
          cost: getCost(monitoringPrice?.cost || 0),
          meta: { cost: monitoringPrice?.cost || 0 },
        });
      }

      if (
        R.includes(APP_SERVICES_GROUP.Monitoring.id, params.serviceIds) &&
        params.monitoringManagementEnabled
      ) {
        list = compact([
          ...list,
          {
            name: t('costsInfo.monitoring'),
            cost: getCost(monitoringPrice?.cost || 0),
            meta: { cost: monitoringPrice?.cost || 0 },
          },
          outsourceManagementPrice?.cost
            ? {
                name: t('costsInfo.outsourceManagement'),
                cost: getCost(outsourceManagementPrice?.cost || 0),
                meta: { cost: outsourceManagementPrice?.cost || 0 },
              }
            : undefined,
        ]);
      }

      if (R.includes(APP_SERVICES_GROUP.Backup.id, params.serviceIds)) {
        if (params.backupSchedule) {
          list.push({
            name: t('costsInfo.backupService'),
            cost: getCost(+(backupBasePrice?.cost || 0)),
            meta: { cost: backupBasePrice?.cost || 0 },
          });
          list.push({
            name: t('costsInfo.backupStorage'),
            cost: `${getCost(backupMin)} - ${getCost(backupMax)}`,
            meta: { min: backupMin || 0, max: backupMax || 0 },
          });
        }
      }

      return compact(list);
    }, [costEstimation.data.cost?.costPerResource, observableValues]);

  if (paralyzeHook) {
    return {
      calculation: DEFAULT_CALCULATION,
      displayList: [],
      params,
      isRequesting: isRequesting || costEstimation.isRequesting,
    };
  }

  return {
    calculation: costEstimation.data?.cost
      ? {
          discountValue: costEstimation.data.cost.discountValue,
          discountPercent: costEstimation.data.cost.discountPercent,
          total: costEstimation.data.cost.total,
          vat: costEstimation.data.cost.vat,
          totalIncludeVat: costEstimation.data.cost.totalIncludeVat,
        }
      : DEFAULT_CALCULATION,
    displayList,
    params,
    isRequesting: isRequesting || costEstimation.isRequesting,
  };
}
