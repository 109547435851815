import * as React from 'react';
import cn from 'classnames';
import { Select, styled } from 'elements';
import { remCalc } from 'utils';
import { useTranslation } from 'react-i18next';
import { IInstanceSelectorProps } from './types';

const InstanceSelector = styled((props: IInstanceSelectorProps) => {
  const { selected, instances, onChange, className } = props;
  const { t } = useTranslation();
  return (
    <div className={cn(className, 'mb-30')}>
      <div className="mb-25 fs-14 steel">
        {t('monitoring.instanceSelector.description')}
      </div>
      <div className="_select">
        <Select
          value={selected}
          options={instances.map((instance) => ({
            value: instance.id,
            label: instance.vm.name,
          }))}
          onChange={onChange}
        />
      </div>
    </div>
  );
})(() => ({
  '& ._select': {
    maxWidth: remCalc(250),
  },
}));

export default InstanceSelector;
