import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Loader } from 'elements';
import { usePermissions } from 'hooks';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { numberToCurrency, dayjs } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import UsageServiceBlock from './UsageServiceBlock';
import { groupServiceEntities } from './helpers';

const OBSERVERS = {
  actualUsage: StateHandlers.usageSelected,
};

type IProps = {
  accountId?: number;
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { accountId, actualUsage } = props;
  const { currentAccessLevel } = usePermissions();
  const { t } = useTranslation();
  const cost = actualUsage.data?.cost;
  const period = React.useMemo(() => {
    return `${dayjs().startOf('month').format('DD.MM')} - ${dayjs().format(
      GLOBAL_DATE_FORMAT
    )}`;
  }, []);

  const groupedData = React.useMemo(() => groupServiceEntities(cost), [cost]);

  React.useEffect(() => {
    if (accountId) {
      actualUsage.get({
        id: accountId,
        accessLevel: currentAccessLevel,
      });
    }
  }, []);

  if (!cost) return <Loader />;

  // const monthlyVat = (groupedData.total / 100) * +(cost?.vat?.value || 0);

  // const totals = {
  //   total: groupedData.total,
  //   vat: monthlyVat,
  //   totalIncVat: groupedData.total + monthlyVat,
  // };

  return (
    <>
      <div className="pl-40 pr-20">
        <Row className="uppercase steel fs-10 mb-5" columnSpacing={1}>
          <Col xs={10}>{t('usage.details.table.head.services')}</Col>
          <Col xs={2}>
            {t('usage.details.table.head.costPeriaod')}, <br /> {period} CHF
          </Col>
          {/* <Col xs={2}>
            {t('usage.details.table.head.costMonth')}, <br /> CHF
          </Col> */}
        </Row>
      </div>
      {groupedData.data.map((ent) => (
        <UsageServiceBlock
          key={`usage-service-${
            ent.service.serviceId + ent.service.serviceTitle
          }`}
          {...ent}
          cost={groupedData.costForMonth[ent.service.serviceId]}
        />
      ))}
      <div className="pt-20 pl-40 pr-20">
        <Row className="fs-17 mb-5" columnSpacing={1}>
          <Col xs={10} className="bold">
            {t('usage.details.totalPrice')}, {t('usage.priceRow.chf')}
          </Col>
          <Col xs={2} className="bold">
            {numberToCurrency(cost?.total, false)}
          </Col>
          {/* <Col xs={2} className="light-grey">
            {numberToCurrency(totals.total, false)}
          </Col> */}
        </Row>
        {cost.vat && (
          <Row className="fs-17 mb-5" columnSpacing={1}>
            <Col xs={10}>
              {t('usage.details.vat')}, {cost?.vat?.value} %
            </Col>
            <Col xs={2}>
              {numberToCurrency(
                (cost?.total / 100) * +(cost?.vat?.value || 0),
                false
              )}
            </Col>
            {/* <Col xs={2} className="light-grey">
              {numberToCurrency(totals.vat, false)}
            </Col> */}
          </Row>
        )}

        <Row className="fs-17 mb-5" columnSpacing={1}>
          <Col xs={10}>{t('usage.details.roundDifference')}</Col>
          <Col xs={2}>{numberToCurrency(cost?.totalRoundDiff, false)}</Col>
          {/* <Col xs={2} className="light-grey">
            {numberToCurrency(cost?.totalRoundDiff, false)}
          </Col> */}
        </Row>
        <Row className="fs-17 mb-5" columnSpacing={1}>
          <Col xs={10} className="bold">
            {t('usage.details.totalIncVat')}, CHF
          </Col>
          <Col xs={2} className="bold">
            {numberToCurrency(cost?.totalIncludeVat, false)}
          </Col>
          {/* <Col xs={2} className="light-grey">
            {numberToCurrency(totals.totalIncVat, false)}
          </Col> */}
        </Row>
      </div>
    </>
  );
});

const UsageServiceTab = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default UsageServiceTab;
