import { important, remCalc } from 'utils';
import { PALETTE } from 'enums';
import { styled, Select, Row, Alert, MuiIcons } from 'elements';
import { EditableText } from 'components';
import AdditionalDisksTable from './dialogs/AdditionalDisks';

export const SAdditionalDisksTable = styled(AdditionalDisksTable)(() => ({
  '& .th:first-of-type': {
    paddingLeft: important(0),
  },
  '& .th:last-of-type': {
    paddingRight: important(0),
  },
  '& .td:first-of-type': {
    paddingLeft: important(0),
  },
  '& .td:last-of-type': {
    paddingRight: important(0),
  },
}));

export const PublicServiceSelect = styled(Select)(() => ({
  '& input[aria-autocomplete="list"]': { opacity: important(1) },
}));

export const ManageIpMainContent = styled(Row)(() => ({
  minHeight: remCalc(400),
}));

export const ManageIpLeftContent = styled('div')(() => ({
  maxWidth: remCalc(500),
  '& ul': {
    listStyle: 'disc',
    paddingLeft: remCalc(25),
  },
  '& li': {
    listStyleType: 'disk',
  },
}));

export const VmDetailsContent = styled(Row)(() => ({
  maxWidth: remCalc(900),
  height: '100%',
}));

export const SExternalIpRow = styled(Row)(() => ({
  borderBottom: `1px solid ${PALETTE.pageBackground}`,
  paddingBottom: remCalc(5),
  marginBottom: remCalc(10),
}));

export const SEditableText = styled(EditableText)(() => ({
  '& ._display': {
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: 1.2,
    maxWidth: remCalc(160),
  },
  '& input': {
    padding: remCalc(4, 7),
  },
}));

export const SnapshotAlert = styled(Alert)(() => ({
  '& ._text': {
    position: 'relative',
    paddingRight: remCalc(180),
  },
  '& ._button': {
    position: 'absolute',
    right: remCalc(10),
  },
}));

export const InfoIcon = styled(MuiIcons.Info)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
