import * as React from 'react';
import Fab, { FabProps } from '@mui/material/Fab';
import { MuiIcons } from './MuiIcons';
import { styled } from './utilities';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';

interface IIconProps {
  testId: string;
  className?: string;
}

export const CancelIconRounded = styled(
  ({
    testId,
    ...props
  }: React.PropsWithChildren<IIconProps & { onClick?: () => void }>) => {
    return <MuiIcons.Clear {...props} data-test-id={testId} />;
  }
)(() => ({
  width: remCalc(27),
  height: remCalc(27),
  padding: remCalc(4),
  fontSize: remCalc(17),
  borderRadius: '50%',
  border: `1px solid ${PALETTE.lightGrey}`,
  textAlign: 'center',
  lineHeight: remCalc(30),
  cursor: 'pointer',
}));

export const SuccessIcon = styled(
  ({
    testId,
    ...props
  }: React.PropsWithChildren<IIconProps & { onClick?: () => void }>) => {
    return <MuiIcons.Check {...props} data-test-id={testId} />;
  }
)(({ theme }) => ({
  width: remCalc(27),
  height: remCalc(27),
  padding: remCalc(4),
  fontSize: remCalc(17),
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: PALETTE.white,
  textAlign: 'center',
  lineHeight: remCalc(31),
  cursor: 'pointer',
}));

export const AddIcon = styled(
  React.forwardRef<
    any,
    FabProps &
      React.PropsWithChildren<{
        testId?: string;
        id?: string;
      }>
  >(function (props, ref) {
    const { size, id, testId, className, ...rest } = props;

    return (
      <Fab
        id={id}
        size="small"
        data-test-id={testId}
        ref={ref}
        className={className}
        {...rest}
        color="primary"
      >
        <MuiIcons.Add />
      </Fab>
    );
  })
)(({ theme }) => ({
  textAlign: 'center',
  verticalAlign: 'middle',
  borderRadius: '50%',
  fontWeight: '600',
  fontSize: remCalc(18),
  backgroundColor: theme.palette.primary.main,
  color: PALETTE.white,
  cursor: 'pointer',
  transition: 'all 200ms ease',
  boxShadow: '0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0)',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '&:active': {
    backgroundColor: theme.palette.primary.main,
  },

  '& svg': {
    fontSize: '1em',
    transform: 'rotate(0)',
    transition: 'transform .25s ease-in-out 0s',
  },

  '&._rotated svg': {
    transform: 'rotate(225deg)',
  },

  '&.lg': {
    width: remCalc(48),
    height: remCalc(48),
    fontSize: remCalc(25),
  },

  '&.md': {
    width: remCalc(36),
    height: remCalc(36),
    fontSize: remCalc(18),
  },

  '&.sm': {
    width: remCalc(48),
    height: remCalc(48),
    fontSize: remCalc(25),
  },

  '&._disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    boxShadow: '0 0 0 0 rgba(0, 127, 255, 0)',
  },
}));

export const ExpandIcon = styled((props: any & { expanded: boolean }) => {
  const { expanded, ...rest } = props;
  const Icon = props.expanded ? MuiIcons.ExpandLess : MuiIcons.ExpandMore;
  return <Icon {...rest} />;
})(({ theme }) => ({
  backgroundColor: PALETTE.paleGrey,
  color: PALETTE.primary,
  width: remCalc(24),
  height: remCalc(24),
  lineHeight: remCalc(24),
  textAlign: 'center',
  borderRadius: remCalc(3),
  transition: theme.transitions.create('background-color', {
    easing: 'ease-in-out',
    duration: 150,
    delay: 0,
  }),
  '&:hover': {
    backgroundColor: PALETTE.info,
  },
}));

export const RdpIcon = styled(
  React.forwardRef((props: any, ref: any) => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="24"
        viewBox="0 0 26 24"
        ref={ref}
        data-draggable="ignore"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-908 -242) translate(903.5 237) translate(4.5 5)">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path
                  fillRule="nonzero"
                  className="_primaryLine"
                  d="M20 2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4c0-1.11-.9-2-2-2z"
                />
                <circle
                  cx="21"
                  cy="15"
                  r="5"
                  fill="transparent"
                  className="_primaryStroke"
                />
                <path
                  className="_primaryStroke"
                  stroke="#1D62AB"
                  strokeWidth=".5"
                  d="M19.073 14.76c.092-.079.239-.079.33 0h0l1.547 1.328c.073.062.073.163 0 .225h0l-1.547 1.329c-.091.078-.238.078-.33 0-.09-.077-.09-.205-.002-.282h0l1.35-1.159-1.348-1.159c-.09-.078-.09-.204 0-.283zm3.533-2c.092-.079.239-.079.33 0 .091.076.091.204.002.28h0l-1.35 1.16 1.348 1.159c.091.078.091.204 0 .283-.091.078-.238.078-.33 0h0l-1.547-1.329c-.073-.062-.073-.163 0-.225h0z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  })
)(({ theme }) => ({
  '& ._primaryLine': {
    fill: theme.palette.primary.main,
  },
  '& ._primaryStroke': {
    stroke: theme.palette.primary.main,
  },
}));

const generateFillColor = (status: string) => {
  if (status === 'success') {
    return PALETTE.success;
  }

  if (status === 'primary') {
    return PALETTE.primary;
  }

  if (status === 'failed' || status === 'error') {
    return PALETTE.error;
  }

  if (status === 'info') {
    return PALETTE.info;
  }

  if (status === 'warning') {
    return PALETTE.warning;
  }

  return PALETTE.steel;
};

export const IconBackup = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const { className, status, ...rest } = props;
    const fill = generateFillColor(props.status);
    return (
      <svg
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className={props.className}
        ref={ref}
        data-draggable="ignore"
      >
        <mask
          id="mask0_1106_2711"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect width="18" height="18" fill="url(#pattern745)" />
        </mask>
        <g mask="url(#mask0_1106_2711)">
          <rect width="18" height="18" fill={fill} />
        </g>
        <defs>
          <pattern
            id="pattern745"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#image0_1106_2228" transform="scale(0.0208333)" />
          </pattern>
          <image
            id="image0_1106_2228"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA2NJREFUaEPtmI2RDVEQhc9GgAgQASJABIgAESACRIAI2AgQASJABIgAEVDf1u1XZ+/83L4zU7W7VdNVr+rtvvtzTvfpn5kjXXA7uuD4tRM46wjuEdgjsNIDu4QqB16TdEPSzfLhb75/Lus+SPoi6dtKxx+2bxWBy5KeS3qaBAaBZ0YsuW24bAsCTyS9kASJXntXiPzp3Rjr1xL4WiQS5/2VhEz4AArpQAwZIaf7ku5VYInG3bK+m8caAm8lPbIb35RItLwJETx/2/YuJrGUAJJB8xhehwhe7zE/g32QetxzAGuXEEAG7+0iwh9Vpvf+mgQEIJK2JQRc98gmW3mmQCEfSi/2U9L1NPoFESAZIYD9KsnZ0nwLj5/J2q4o9EbgtSTKJrZGOjUpl9LHUq1axE9+7yXw2+p9Zi/exVqd946kT2UtEb2SQp8kQNl7WCoN3zHGAS6dM8AHKKLVIvHPDktHd86LAHg1AfS46gE1kQAf3RmvtkiQwFd75TlFAO9SKqfGA+YY8mHMavCxpkWCPhJd+kG2r4wRoCnRZd0ol1yADOaqzhT4DAlP5JelqzdToSaAx3+Y578XqbT0y0Ut8C0STgAncWd8qEyjjqsJ0AVJWAzwUUVanqjBM17QnUMSAECWl6zS1DlRd2W/k/ygPww6vhOoG8qtROUY8zzgAcvIEfMSkkCCAJgiMUcgyAyk5QT8gJ4RwXMmwBP6MU3H01mQmOq6MYLjBM6P9RA5VWKdgFeBrnZeLqEq4fnIl6mkDBLMUJnBjd4DNp+XUMdJTjgBH9Ky8nGd4jVPtLmqUq9t5RgkcExE4lDGnYB3wsyY0Lp0UVmcOZSI0VixQyN1oD7Wplv5zIVbE/B56TDKOAEvob05MMZjawLcMVCJE/AQkTS08zW2NQEv84ceNdcH0vPIBMutCXi5Hs0BcPgDCxWFx7ulT1xbEqhHnNEqFI70ZAY8+dD7xoGztiIAeCbjeP5APnwf9IEgUI8U/D9eVrE5M9htQQAcNC9U4WP9qR41Ve/ZTFWK7pdNZp9VvChk97fWDapjq2F5TrQO53cngNeYIn2OyZwxtmZyrG8R4DCigebilflcVOppkT3kQv0+NEMk5IpkUUPqeSBz8Llak4nAuQJcg9kJnHV49gjsEVjpgQsvof8MPNIxzb3d8wAAAABJRU5ErkJggg=="
          />
        </defs>
      </svg>
    );
  }
);

export const IconSnapshot = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const { className, status, ...rest } = props;
    const fill = generateFillColor(props.status);
    return (
      <svg
        {...rest}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={props.className}
        data-draggable="ignore"
        ref={ref}
      >
        <mask
          id="mask0_1107_2575"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect width="18" height="18" fill="url(#pattern0)" />
        </mask>
        <g mask="url(#mask0_1107_2575)">
          <rect x="0.428528" width="18" height="18" fill={fill} />
        </g>
        <rect x="6" y="8" width="12" height="9" fill="white" />
        <mask
          id="mask1_1107_2575"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="6"
          width="13"
          height="13"
        >
          <rect
            x="5.99988"
            y="6.00098"
            width="12"
            height="12"
            fill="url(#pattern1)"
          />
        </mask>
        <g mask="url(#mask1_1107_2575)">
          <rect x="5.99988" y="6.00098" width="12" height="12" fill={fill} />
        </g>
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_1107_2575"
              transform="translate(-0.0714286 -0.0714286) scale(0.0238095)"
            />
          </pattern>
          <pattern
            id="pattern1"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#image1_1107_2575" transform="scale(0.0208333)" />
          </pattern>
          <image
            id="image0_1107_2575"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAQ1JREFUaEPtmLENwlAMRC/rUNAyAhMwDB10NDAILBJqBgK5+0IfKb6cQUH3a/viu+c0HrDwNyx8ftjArwmagAnMTOBvV2gF4AJgSwZ0BHAge1NtPQIbAGNKpV8cBsJI6esZiOHDhOKVm+gZeDaTM//IFcBO4b7RuAM4A7i961YYiG9UmAjdPYBTa6LKQJWJB4D1twwot+jjWlcSsIEmARNQrgOjZQJMasoeE1CmyWiZAJOasscElGkyWibApKbsMQFlmoyWCTCpKXtMQJkmo2UCTGrKnhQB5WlRaSK0Jt2FVMdd9fCTL3NROPe8rh4+dRtVf7xUj7k+lw6UFbeBbGLqehNQJ5rVM4FsYur6F84ARTEZlrGpAAAAAElFTkSuQmCC"
          />
          <image
            id="image1_1107_2575"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAlZJREFUaEPt2cvLTWEUBvDfN2MsGRiQS8LAgJhK5FJK/AGkZGBg5PYXuJWhgZLLUPHFwC0yMUCiJJRcpgaUCUmipUPfZZ9z9vvud8f3tdfkDM5az3qetfZ+z3rXGTHFbWSK89cJ+Ncd7DowXTpwACcSxRzEycSYSe4lHqGdOJ9JZBcuZMb+DmsqYDOuNyGALbiRi9FEwCrcxKzc5L24j9iExzk4gwQcxsYBoHOwNCdpRcxLfBiA9QiHqr6vEjATXwoRKw0zA9/GglYJuIztpTMXwjuNfcME/CyUrC2YcUWv6kAnoK3S93C7DtQt8Fe8xQ8sRpxuJaz1DuzHHbyYwHYJtuFYQxWtCXiPGC1eDSG4FpcwO1NIKwLeYFEioYdYnRgzaX4rcYx+wgJ8TiQT78RrzE2MK96BeK6vJpL4474OdxNjiwqIU2ZhIoGJ7u8wPwGjqIA4bTYkJK9yvYd4setaUQFnsLdu5j5+Z7E7AaOogLgHV87pCYQCI+7Uda2ogCvYUTdzH79r2JqAUVTAM6xISF7l+hzLEzCKCoh5JwTEeZ5j8xDXyZQ5qaiAIH17yN15kLA4BPYkKi8uIPLHgHYkkUjshM4lxoR7KwICOBZcF2sSio3H0Zq+E91aExCJRnEcMahV2fpet1Zmkm+1A2M5PcUT3Md3rOlNnvHZ1FrtQFNydeI7AXWq1KbP9O/Ag95L12YVc7Fv9TbZf+OrrpRxUoSI/9FiZhq37ei3Xl+GUw1GhNLi43cl1jWTfl+a/MFRmmQWXicgq2wFg7oOFCxmFtSU78Avh/9eMU8dZv4AAAAASUVORK5CYII="
          />
        </defs>
      </svg>
    );
  }
);

export const IconRemoteSupport = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        className={props.className}
        ref={ref}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-draggable="ignore"
      >
        <circle
          cx="9"
          cy="9"
          r="8"
          fill="#EBF4FF"
          stroke={color}
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0229 4.06066L11.9623 3L9.31063 5.65165L8.24997 6.71231L9.31063 7.77297L11.9623 10.4246L13.0229 9.36396L10.3713 6.71231L13.0229 4.06066Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90162 13.8632L5.96228 14.9238L8.61393 12.2722L9.67459 11.2115L8.61393 10.1509L5.96228 7.49921L4.90162 8.55987L7.55327 11.2115L4.90162 13.8632Z"
          fill={color}
        />
      </svg>
    );
  }
);

export const IconSecondaryIp = styled(
  React.forwardRef(
    (
      props: {
        className?: string;
        onClick: () => void;
        status: string;
      },
      ref: any
    ) => {
      const color = generateFillColor(props.status);

      const { className, status, ...rest } = props;
      return (
        <span
          className={className}
          style={{ color }}
          {...rest}
          ref={ref}
          data-draggable="ignore"
        >
          <MuiIcons.Public className="fs-20" />
          <span className="_counter">2</span>
        </span>
      );
    }
  )
)(() => {
  return {
    position: 'relative',
    zIndex: 2,
    height: remCalc(20),
    lineHeight: 1,
    '& ._counter': {
      backgroundColor: PALETTE.white,
      borderRadius: '50%',
      position: 'absolute',
      right: -2,
      bottom: remCalc(3),
      width: 13,
      height: remCalc(11),
      lineHeight: remCalc(11),
      zIndex: 1,
      fontSize: remCalc(8),
      textAlign: 'center',
    },
  };
});

export const IconPublicServices = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        className={props.className}
        ref={ref}
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        data-draggable="ignore"
      >
        <mask
          id="mask0_1122_2281"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect width="18" height="18" fill="url(#pattern-pubsr)" />
        </mask>
        <g mask="url(#mask0_1122_2281)">
          <rect y="-1.05859" width="21" height="21.065" fill={color} />
        </g>
        <path d="M9 8H12L13.3013 9H18V18H9V8Z" fill="white" />
        <mask
          id="mask1_1122_2281"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="8"
          width="9"
          height="11"
        >
          <path
            d="M11.7787 14.1459L11.25 13.4997L9.75 15.333L11.25 17.1663L11.7787 16.5201L11.1862 15.7913H16.875V14.8747H11.1862L11.7787 14.1459Z"
            fill="black"
          />
          <path
            d="M15.2213 12.8534L15.75 13.4997L17.25 11.6663L15.75 9.83301L15.2213 10.4793L15.8138 11.208H10.125V12.1247H15.8138L15.2213 12.8534Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask1_1122_2281)">
          <rect x="9" y="8" width="9" height="11" fill={color} />
        </g>
        <defs>
          <pattern
            id="pattern-pubsr"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_1122_2281"
              transform="matrix(0.0227273 0 0 0.0227976 -0.0454545 -0.0486912)"
            />
          </pattern>
          <image
            id="image0_1122_2281"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA89JREFUaEPtmUnIjlEUx3/fSpmipAgZyoIyhMxFVopiIYVEskEyZdpgg5VhgUKGQpKQothQZMqYSFHGbJQxYad/nUfX9Qz3Ps/zvvrqO/X2vd/bueee/z3nnum20MqppZXrTxuA/23BOi3QF5gEDAX0PfkI4yvn8wi4av9Xxl8VwCBgsSk+LFKbhwbkAPA0cu0f9rIAOgOrgJWAvlehr8BOYAeg71FUBsAcYCMwOGqnf5l/Ae2cn58AW4ETMXJjAawHtsVskMM72aw43ePZAGwP3SMGwF1gRKjgAD5ZUL6/zID0c9bcA0YGyAjOAy8tqoTIDOF5DwwAfhpzb2CtgUnWK3K5oFLlhljgMLAgRKsIni3A5hT+3cBy5/cjwMI8uUUApLgA1EkKAHn36CQw29lQAAQk2gIKjzdKRhslKp2ySz+A58DHgtPoBJy33CJWRadxWSE2zwIy8aaSRy8AijJlaSBwBehpArJcLvMSK8PerJCkqgKQ3u4BKsGNTcvYWRZQVlSWLUt1AOgBKHQnVlC2Vvb/i7IA3AJGl9XeapwqLpRs7VrhNjAmBEAv4G0F5bV0KbC3ogwt962gfPHOlZtmgbnAMWNSBMirefxIo2VJlfm5BgAScQqYZbLmAceLAMjXVhiTYvCaHBCZ0aEm5SVGddE6k7fLv5tpFjgLzLAFSuXt7RTSLNEMAOo39ps+54CZRRZ4ACTNSQJQYVWm9EE0A8AE4JopLfccXgTgE9DFmFwLpYFoBoBuwAfTR/eqawwAAfniLMiyRIzLq8oU8Mz6xhMmF/4eA8B1oSHAY09gHSC+Ad2dcjrvABQ638S4kHuJpwEXUqTXAUKJThm7iOTz92MusRtGlwD7MnaoCiIUgKLOmZgw6iaygzY2yTqlKiBCAewBdJCioETmlhKaHOjWq5avG0QogDvAKNs8qJQQr1vMrbaZTZ6vaiKn+j2GQgCooJQuouBiTsxuOX0dmFigWaMAqCaTS4uiymm/oVkEHMoBUQaAWwhetvbV3WIqcNF+iG5otM6txTUGmQI8ywDRB3gd4z8pvC+smVfI7Ahcsl5YrNEtpRb5Tf1pp6xN01WuNr4iiMTPjwLzTVbppl7r/bFKUe1TxwBMe7jDhNJjleQw/cFWEQjV7/0LrBVqqMqDrWQj/2SLQJS51D6o2kaLiWB/uKsmQx8NYn2qCqD24W6iYNp4PQ1IFQANG68nILIeOJTyFUX0V5OD2MzclAeOBETRE5MakA6BN7XpT0yuXq32kS/tcFvlM2uglzSOreiBo3E71yS5DUBNB1lazG8XqdUxSy3o4AAAAABJRU5ErkJggg=="
          />
        </defs>
      </svg>
    );
  }
);

export const IconConnectDomain = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-draggable="ignore"
      >
        <rect width="18" height="18" rx="1.57143" fill="white" />
        <rect
          x="3.75"
          y="1.75"
          width="10.5"
          height="14.5"
          rx="0.821429"
          fill="white"
          stroke={color}
          strokeWidth="1.5"
        />
        <rect x="6" y="4.14258" width="6" height="1.5" fill={color} />
        <rect x="6" y="10.2861" width="6" height="1.5" fill={color} />
        <rect x="6" y="12.8574" width="6" height="1.5" fill={color} />
      </svg>
    );
  }
);
export const IconOpenAsPdf = React.forwardRef(
  (
    props: {
      className?: string;
      onClick?: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M22.5 13.5V12H18v7.5h1.5v-3h2.25V15H19.5v-1.5h3zm-8.25 6h-3V12h3a2.252 2.252 0 0 1 2.25 2.25v3a2.252 2.252 0 0 1-2.25 2.25zm-1.5-1.5h1.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75h-1.5V18zm-4.5-6H4.5v7.5H6v-2.25h2.25a1.502 1.502 0 0 0 1.5-1.5V13.5a1.501 1.501 0 0 0-1.5-1.5zM6 15.75V13.5h2.25v2.25H6z"
          fill={color}
        />
        <path
          d="M16.5 10.5v-3a.684.684 0 0 0-.225-.525l-5.25-5.25A.682.682 0 0 0 10.5 1.5H3A1.505 1.505 0 0 0 1.5 3v18A1.5 1.5 0 0 0 3 22.5h12V21H3V3h6v4.5A1.505 1.505 0 0 0 10.5 9H15v1.5h1.5zm-6-3V3.3l4.2 4.2h-4.2z"
          fill={color}
        />
      </svg>
    );
  }
);

export const IconPatching = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-draggable="ignore"
      >
        <path
          d="M8.99982 4.49902V6.74902L11.9998 3.74902L8.99982 0.749023V2.99902C5.68482 2.99902 2.99982 5.68402 2.99982 8.99902C2.99982 10.1765 3.34482 11.2715 3.92982 12.194L5.02482 11.099C4.68732 10.4765 4.49982 9.75652 4.49982 8.99902C4.49982 6.51652 6.51732 4.49902 8.99982 4.49902ZM14.0698 5.80402L12.9748 6.89902C13.3048 7.52902 13.4998 8.24152 13.4998 8.99902C13.4998 11.4815 11.4823 13.499 8.99982 13.499V11.249L5.99982 14.249L8.99982 17.249V14.999C12.3148 14.999 14.9998 12.314 14.9998 8.99902C14.9998 7.82152 14.6548 6.72652 14.0698 5.80402Z"
          fill={color}
        />
        <mask
          id="mask0_1345_patching"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="6"
          width="6"
          height="6"
        >
          <rect
            x="6"
            y="6"
            width="6"
            height="6"
            fill="url(#patternPatchingIcon)"
          />
        </mask>
        <g mask="url(#mask0_1345_patching)">
          <rect x="6" y="6" width="6" height="6" fill={color} />
        </g>
        <defs>
          <pattern
            id="patternPatchingIcon"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_1345_234_patching"
              transform="scale(0.0208333)"
            />
          </pattern>
          <image
            id="image0_1345_234_patching"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAMKADAAQAAAABAAAAMAAAAADbN2wMAAABsklEQVRoBe1ZXUrEMBDOxAV9ENwj5AhewRt4DZ+kYB+sWFlkFdyF6km8gm++6k3UhwWxNibFxNHWtmtS02UnLO2Qbma+n7bsbBijQQqstwIwVPpZlo0Xb0wU+buQDIQEKaCQggEIxqRIk3issY/0IcRoA/iyyHe+cEmFufyogwrQ6J3A+cV8v07BrgAR1trwzwSaFdw4SJPoXlcspLw18pXa/VCwFtUSk78SaAYoRZOCnOebS2Bw+qolMLmcX+OHpAmgU0XPiy0B5fVhHxZ7xltJxyszKzZBBEIbRg6QA44K0C3kKKDzcnLAWULHBOSAo4DOy+2POc7ZnnO2zwTbW6MHk8tnXpOTzkNSwP4rMZnOvnfLDij1bXN6HN/pFD7zYkhnJ3GJnd5CWJUQMTkQQnVckxzAaoSIyYEQquOa5ABWI0RMDoRQHde0DgDAIwB7xhdXIbYNTZoc7WrAbfsCan8Cbf2Ep2gJGChRFD2pWHdUtqsy1/R5aAQrBDDYurgLQSnlq13L4QZvnPh20DY0tmDPgS8HTUPz7wTa9OlK0BBoyze465rg9CorXziDA7eWgD4AwMPcMozZMoUAAAAASUVORK5CYII="
          />
        </defs>
      </svg>
    );
  }
);

export const OpenUserIcon = styled(MuiIcons.OpenInNew)(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    fontSize: remCalc(20),
    cursor: 'pointer',
  },
}));

export const IconNatRoutedNetworkMonitor = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-draggable="ignore"
      >
        <mask
          id="l3vtaoaecb"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="18"
          height="18"
        >
          <path d="M20 3H2v18h18V3z" fill="url(#cv3iuivnia)" />
        </mask>
        <g mask="url(#l3vtaoaecb)">
          <path d="M22 3H2v18h20V3z" fill={color} />
        </g>
        <path d="M23 9H11v12h12V9z" fill="#fff" />
        <path
          d="M17 20c-1.158-.292-2.115-.956-2.869-1.994A5.74 5.74 0 0 1 13 14.55V11.5l4-1.5 4 1.5v3.05a5.744 5.744 0 0 1-1.131 3.456C19.115 19.044 18.159 19.71 17 20zm0-1.05c.867-.275 1.583-.825 2.15-1.65s.85-1.742.85-2.75v-2.363l-3-1.124-3 1.124v2.363c0 1.008.283 1.925.85 2.75s1.283 1.375 2.15 1.65z"
          fill="#1D62AB"
        />
        <defs>
          <pattern
            id="cv3iuivnia"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#c8mgsk6edc" transform="scale(.04167)" />
          </pattern>
          <image
            id="c8mgsk6edc"
            width="24"
            height="24"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTIKGjIGIjIOHjoGFi8h2adsAAAAEdFJOUwDjSUhBizWNAAAAMElEQVQY02NgIA4wibhAgaMCA6MLHAgwsDjA1ABZg4yD4lAkLxiAFYCYcA+icAgDAKcpEzFn2njTAAAAAElFTkSuQmCC"
          />
        </defs>
      </svg>
    );
  }
);

export const IconDirectNetworkMonitor = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-draggable="ignore"
      >
        <mask
          id="hj3eu6cpvb"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="18"
          height="18"
        >
          <path d="M20 3H2v18h18V3z" fill="url(#luqfw7trta)" />
        </mask>
        <g mask="url(#hj3eu6cpvb)">
          <path d="M22 3H2v18h20V3z" fill={color} />
        </g>
        <path d="M23 9H11v12h12V9z" fill="#fff" />
        <mask
          id="sbigjhzzqd"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="9"
          width="12"
          height="12"
        >
          <path d="M23 9H11v12h12V9z" fill="url(#r818dghk9c)" />
        </mask>
        <g mask="url(#sbigjhzzqd)">
          <path d="M23 9H11v12h12V9z" fill="#1D62AB" />
        </g>
        <defs>
          <pattern
            id="luqfw7trta"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#ao7yvo1p8e" transform="scale(.04167)" />
          </pattern>
          <pattern
            id="r818dghk9c"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#6jyqnfz0ff" transform="scale(.02083)" />
          </pattern>
          <image
            id="ao7yvo1p8e"
            width="24"
            height="24"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTIKGjIGIjIOHjoGFi8h2adsAAAAEdFJOUwDjSUhBizWNAAAAMElEQVQY02NgIA4wibhAgaMCA6MLHAgwsDjA1ABZg4yD4lAkLxiAFYCYcA+icAgDAKcpEzFn2njTAAAAAElFTkSuQmCC"
          />
          <image
            id="6jyqnfz0ff"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA45JREFUaEPtmY+RTEEQxr+LABEgAkSACBABIkAEiAARIAJEgAgQARkgAuqnprf6zc686Tczu+eqrqu2rm533nR//fW/mXeiMy4nZ9x+nQM4bQZnMnBF0h1JtyRdTJ/rCeBXSb/S55OkD5J+zAA/CgBDnyajzdioXYACzPMELPrcYl0vAAx/JOlx8nSX8vQQzLyU9KoHSA+Au5JeTzA8Bw2Qh5Leb/HGVgCEy7MtClbWYuwDSTezNexPWIUkCoCQwet4f5bcTjnAnoTQZbcxLAAQVlYlCuDdZON/S6JqmYE4iHyCYZM3CcQwACj1G7ecEvmdECmFYq6rtm6no8UA9OL9mdIyCs/fdwrvrSX2GgBo/d5Zbb6lkMiBU/cj4kEQZldr+bAGYCR0PqfmFjG2tAbn0egssaus1QCMeB+DRgGwByWWyodUWagBoKzRaXtlBgB0My8ZC3RqKtVCagCIfcpcr8wC4FkgpG5EAGA4AEak6K3ODQmfC+lZknkxxZYYgKYXQWV4Oq8sKKCKzBK6MmM68iR17d3eJQD+ATwJjeaB3KhWTZ8BwldDzhGLcaYEAI/agGUxx3clEMcA4PNgL7dKAL5IssOJ/c7/JRDHAMAJ72Oici+RSwD+ON797yUQxwBAT/pZsal4K1EDwB41JrbGOn2GhIxK1aYSA9B0rVa2JoIgv9AVEQPAjLU4e7eS2A4duZIZTNT2znX5HAglsS+jnIpqNX0URBSAr0KhMuob2dvUB2o0j4CIAvBzWaiR+VGCrkr7XpNeEFEAfi4LjRIY6xM5oqjn5BbZ18f/XgJjaGScpoGhbE28okhVYU0EAA2MvZFN4zTNg/Cx8aGlrAcAxcEmSxinwvhrFL9nfouxc1L0SIkCQNTuaWaM4OyNDnThQLxvNX/zkRKEOQutexo/BEbDKF9nR0fGeconUvX+Wg7YxnlyrvUFWMB7tdE7CgpHWNzzTPe1iinMbyfWQMAa6zHAxpGo4aV1zWGxdbFlm/ruzHetC9iepM4BtJrov/VRAHiWHLCjnfUK2CgNZKMAMJ6JYNrlbi2c+B5g1GgPZARAM2w8VVEG/DMkNkbnyQoAQo2/l9ylVDQHqDZUnoO+4DBj7DocmkerDoYzsPFphkzujR4G/B4jVYfZhpJJQdhsuBkxCsCDoQ8QXv41q5VSjPWvWQmT/+I1azS+D7ZuJgMHM3Jt43MAp+J2p/QvFEPNMTcmtn0AAAAASUVORK5CYII="
          />
        </defs>
      </svg>
    );
  }
);

export const IconIsolatedNetworkMonitor = React.forwardRef(
  (
    props: {
      className?: string;
      onClick: () => void;
      status: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status);

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-draggable="ignore"
      >
        <mask
          id="7s1wthiexb"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="3"
          width="18"
          height="18"
        >
          <path d="M20 3H2v18h18V3z" fill="url(#6a28o8h4za)" />
        </mask>
        <g mask="url(#7s1wthiexb)">
          <path d="M22 3H2v18h20V3z" fill={color} />
        </g>
        <path d="M23 9H11v12h12V9z" fill="#fff" />
        <path
          d="M14 20a.987.987 0 0 1-.706-.28.898.898 0 0 1-.294-.672v-4.762c0-.262.098-.486.294-.673a.99.99 0 0 1 .706-.28h.5v-.952c0-.659.244-1.22.732-1.684A2.474 2.474 0 0 1 17 10c.692 0 1.281.232 1.769.697.488.464.731 1.026.731 1.684v.952h.5c.275 0 .51.094.706.28.197.187.294.411.294.673v4.762a.897.897 0 0 1-.294.673A.986.986 0 0 1 20 20h-6zm0-.952h6v-4.762h-6v4.762zm3-1.429c.275 0 .51-.093.706-.28a.893.893 0 0 0 .294-.672.895.895 0 0 0-.294-.673.99.99 0 0 0-.706-.28.986.986 0 0 0-.706.28.898.898 0 0 0-.294.673c0 .262.098.486.294.672.196.187.431.28.706.28zm-1.5-4.286h3v-.952c0-.397-.146-.734-.438-1.012A1.484 1.484 0 0 0 17 10.952c-.417 0-.77.14-1.063.417a1.345 1.345 0 0 0-.437 1.012v.952z"
          fill="#1D62AB"
        />
        <defs>
          <pattern
            id="6a28o8h4za"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#45b596qogc" transform="scale(.04167)" />
          </pattern>
          <image
            id="45b596qogc"
            width="24"
            height="24"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURUdwTIKGjIGIjIOHjoGFi8h2adsAAAAEdFJOUwDjSUhBizWNAAAAMElEQVQY02NgIA4wibhAgaMCA6MLHAgwsDjA1ABZg4yD4lAkLxiAFYCYcA+icAgDAKcpEzFn2njTAAAAAElFTkSuQmCC"
          />
        </defs>
      </svg>
    );
  }
);

export const IconListSettings = React.forwardRef(
  (
    props: {
      className?: string;
      onClick?: () => void;
      status?: string;
    },
    ref: any
  ) => {
    const color = generateFillColor(props.status || '');

    const { className, status, ...rest } = props;
    return (
      <svg
        {...rest}
        ref={ref}
        className={props.className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#cyrz2qoada)">
          <mask
            id="d04x4yeiyc"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path d="M24 0H0v24h24V0z" fill="url(#8utmxak53b)" />
          </mask>
          <g mask="url(#d04x4yeiyc)">
            <path d="M24 0H0v24h24V0z" fill={color} />
          </g>
          <path d="M22 7H9v14h13V7z" fill="#fff" />
          <path
            d="m14.396 19.833-.234-1.867a2.198 2.198 0 0 1-.357-.175 4.966 4.966 0 0 1-.328-.219l-1.735.73-1.605-2.771 1.502-1.138a1.394 1.394 0 0 1-.014-.197v-.393c0-.064.005-.13.014-.197l-1.502-1.138 1.605-2.77 1.735.728c.107-.077.219-.15.335-.218.117-.068.234-.127.35-.175l.234-1.867h3.208l.233 1.867c.127.048.246.107.358.175.112.068.221.14.328.218l1.735-.729 1.604 2.771-1.502 1.138c.01.068.015.133.015.197v.393c0 .063-.01.129-.03.197l1.503 1.138-1.604 2.77-1.721-.729a4 4 0 0 1-.335.219 2.936 2.936 0 0 1-.35.175l-.234 1.867h-3.208zm1.633-3.792c.564 0 1.045-.2 1.444-.598.399-.399.598-.88.598-1.444s-.2-1.045-.598-1.443a1.967 1.967 0 0 0-1.444-.598c-.573 0-1.057.199-1.451.598-.394.398-.59.88-.59 1.443 0 .564.197 1.045.59 1.444a1.96 1.96 0 0 0 1.451.598z"
            fill="#81858B"
          />
        </g>
        <defs>
          <clipPath id="cyrz2qoada">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
          <pattern
            id="8utmxak53b"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#67ixsnfhcd" transform="scale(.02083)" />
          </pattern>
          <image
            id="67ixsnfhcd"
            width="48"
            height="48"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAARBJREFUaEPtl8ERAjEIRd9WoFagJWgl2oGWph2snViCdqAdOFwcPWjYIVlmx59LLgT4/8MQOiZ+uonnjwBkKygFpECQAZVQkMDw879QYA7sAbvHPHfgBNj99XgUuALLMTN/i3UBNlEAxsAsCcCjpLxHgTWwSwLQA6ZCqISScveF9Sjg85RkJQBJxL/CehTQHAiopDlg5GkOBEqo+NTTxEUnmQYCkMm+xfYoYE28TUr0XOMzl/mdttiL6G80c6G5AasoAPtKHEqLRYMSM/aPNVbKBrnVc+lp4nrRGngSgAakDnLpUUBzYBCln8aaA8aH5kCghIpPPU1cdJJpIACZ7Hv3gewcf8ZXCWXLIwWkQJCBJ9y9JDEIfp/XAAAAAElFTkSuQmCC"
          />
        </defs>
      </svg>
    );
  }
);
