import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { IIpSecVpnTunnel } from 'ipsec-vpn-tunnels';
import { PALETTE, NETWORK_STATUS_MAP } from 'enums';
import { showSystemMessage } from 'utils';
import { useAsync, useTask, useUserHash } from 'hooks';
import { Row, CopyTooltip, ColorDot, ChipOutlined, Col } from 'elements';
import { connectivityService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { AutoreviewIcon, GoToIcon, NetworkStatusColor } from './Styled';

const OBSERVERS = {
  tunnels: StateHandlers.ipSecVpnTunnels,
};

type IViewProps = typeof OBSERVERS;

const isConnectedVpnTunnel = (tunnel: IIpSecVpnTunnel) =>
  tunnel.ikeStatus === 'UP' && tunnel.tunnelStatus === 'UP';

type IProps = IConnectivityTypes.Connectivity;

const DEFAULT_QUERY = {
  orderBy: 'name',
  orderType: 'asc',
  offset: 0,
};

const IpsecTunnelListItem = (
  props: IIpSecVpnTunnel & {
    callback: () => void;
    isLast: boolean;
    goTo: AnyFunc;
  }
) => {
  const isConnected = isConnectedVpnTunnel(props);

  useTask(props.task, { onComplete: props.callback });

  const hasError = !!(
    props.ikeFailureMessage ||
    props.tunnelFailureMessage ||
    !isConnected
  );

  return (
    <Row
      alignItems="center"
      key={props.name}
      className={cn({ 'pr-5': !props.isLast })}
    >
      <ColorDot size={6} color={hasError ? PALETTE.error : PALETTE.success} />
      <div
        className={cn('steel ml-5 ellipsis pointer')}
        style={{ maxWidth: 120 }}
        onClick={() => props.goTo('/services/connectivity/edges')}
      >
        {props.name}
      </div>
      {!props.isLast && <span>,</span>}
    </Row>
  );
};

const View = observer((props: IProps & IViewProps) => {
  const { tunnels } = props;
  const { t } = useTranslation();
  const [, goTo] = useUserHash();

  const refreshTunnelsPromise = useCallback(
    () =>
      props.id &&
      connectivityService
        .refreshTunnels(props.id)
        .then((res) => {
          getTunnels();
          return res;
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [props.id]
  );

  const getLimitedNetworks = React.useCallback(async () => {
    const res = await connectivityService.getNetworks({
      page: 1,
      perPage: 20,
      q: undefined,
      type: ['NAT_ROUTED', 'ISOLATED'],
    });
    const withPrimary = res.data.filter((el) => el.isPrimary);
    const withoutPrimary = res.data.filter((el) => !el.isPrimary);

    res.data = [...withPrimary, ...withoutPrimary].slice(0, 2);

    return res;
  }, [props.id]);

  const limitedTunnels = tunnels.data.slice(0, 4);

  const { execute: refreshTunnels, isPending: isRefreshing } = useAsync(
    refreshTunnelsPromise as any
  );

  const {
    execute: getNetworks,
    isPending: isFetchingNets,
    value: networks,
  } = useAsync(getLimitedNetworks);

  const getTunnels = useCallback(
    () =>
      props.id &&
      tunnels.get({
        ...DEFAULT_QUERY,
        id: props.id,
      }),
    [props.id]
  );

  React.useEffect(() => {
    if (props.id) {
      getTunnels();
      getNetworks();
    }
  }, [props.id]);

  return (
    <div className="fs-14">
      {props.publicIp && (
        <>
          <div className="primary-dark">{t('services.card.content.wanIp')}</div>
          <div className="steel mb-10">
            <CopyTooltip>{props.publicIp}</CopyTooltip>
          </div>
        </>
      )}

      <div className={cn('mb-10', { disabled: isFetchingNets })}>
        <div className="flex justify-between align-center">
          <span> {t('services.card.content.internalNetAddress')}</span>
          <GoToIcon onClick={() => goTo('/services/connectivity/networks')} />
        </div>
        {(networks || []).map((n) => {
          return (
            <div key={n.id} className="mb-5">
              <div className="flex align-center">
                <div className="steel mr-10">{n.name}</div>
                {n.isPrimary && (
                  <ChipOutlined className="fs-8" status="primary">
                    Primary
                  </ChipOutlined>
                )}
              </div>
              <div className="flex align-center justify-between">
                <div className="mr-10">{n.subnets[0]?.cidr}</div>
                <NetworkStatusColor
                  className={`lowercase _${NETWORK_STATUS_MAP[n.vcdStatus]}`}
                >
                  {t(`services.connectivity.networks.status.${n.vcdStatus}`)}
                </NetworkStatusColor>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex justify-between align-center">
        <span className="relative flex align-center">
          {t('services.card.content.ipSecTunnels')}
          <AutoreviewIcon
            className={cn('fs-18 ml-10 pointer', {
              spinning: tunnels.isRequesting || isRefreshing,
            })}
            onClick={refreshTunnels}
          />
        </span>
        <GoToIcon onClick={() => goTo('/services/connectivity/edges')} />
      </div>

      <Row>
        {!limitedTunnels.length ? (
          <span className="steel">
            {t('services.card.content.noIpSecTunnels')}
          </span>
        ) : (
          limitedTunnels.map((t, ind) => (
            <Col key={t.id}>
              <IpsecTunnelListItem
                {...t}
                callback={getTunnels}
                isLast={ind + 1 === limitedTunnels.length}
                goTo={goTo}
              />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
});

const ConnectivityCard = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default ConnectivityCard;
