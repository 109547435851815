import { ValueType } from 'global-shapes';
import dayjs from 'dayjs';
import {
  IDailyJobInterval,
  IDailyJobStartTime,
  IDaysOfWeek,
  IJobScheduleKey,
  IPlanTextStateKey,
  IBackupNotificationValues,
} from 'backup';

export type StateKeys = IJobScheduleKey | IPlanTextStateKey;

export type IState = Record<StateKeys, any>;

function createNumberRange(start: number, end: number): number[] {
  return (
    Array(end - start + 1)
      // @ts-ignore
      .fill()
      .map((_, idx) => start + idx)
  );
}

export enum BackupCosts {
  Cb = 2.5, //BEST Compression/Deduplication Factor
  Cw = 2, // WORST Compression/Deduplication Factor
  Gb = 0.05, // BEST Incremental Grow Ratio
  Gw = 0.1, //WORST Incremental Grow Ratio
}

export const backupNamespaces: IJobScheduleKey[] = [
  'dailyJobSchedule',
  'weeklyJobSchedule',
  'monthlyJobSchedule',
];

export const backupNotificationNamespaces: (keyof IBackupNotificationValues)[] =
  ['isSuccessNotification', 'isWarningNotification', 'isFailedNotification'];

export const DAILY_INTERVAL: IDailyJobInterval[] = [2, 4, 8, 12, 24];

export const DAILY_START_TIME: IDailyJobStartTime[] = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export const DAILY_RETENTION: number[] = createNumberRange(1, 365);

export const WEEKLY_INTERVAL: number[] = [7];

export const WEEKLY_START_DAY: IDaysOfWeek[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const WEEKLY_RETENTION: number[] = createNumberRange(1, 52);

export const MONTHLY_RETENTION: number[] = createNumberRange(1, 24);

export const DAILY_INTERVAL_OPTIONS: ValueType<IDailyJobInterval>[] =
  DAILY_INTERVAL.map((interval) => ({
    label: interval,
    value: interval,
  }));

export const DAILY_START_TIME_OPTIONS: ValueType<IDailyJobStartTime>[] =
  DAILY_START_TIME.map((time) => ({
    label: time,
    value: time,
  }));

export const DAILY_RETENTION_OPTIONS: ValueType<number>[] = DAILY_RETENTION.map(
  (retention) => ({
    label: retention,
    value: retention,
  })
);

export const WEEKLY_INTERVAL_OPTIONS: ValueType<number>[] = WEEKLY_INTERVAL.map(
  (interval) => ({
    label: interval,
    value: interval,
  })
);

export const WEEKLY_START_DAY_OPTIONS: ValueType<IDaysOfWeek>[] =
  WEEKLY_START_DAY.map((day) => ({
    label: day,
    value: day,
  }));

export const WEEKLY_RETENTION_OPTIONS: ValueType<number>[] =
  WEEKLY_RETENTION.map((retention) => ({
    label: retention,
    value: retention,
  }));

export const MONTHLY_RETENTION_OPTIONS: ValueType<number>[] =
  MONTHLY_RETENTION.map((retention) => ({
    label: retention,
    value: retention,
  }));

export const ALL_PLAN_OPTIONS: Record<IPlanTextStateKey, ValueType[]> = {
  dailyHours: DAILY_INTERVAL_OPTIONS,
  dailyFrom: DAILY_START_TIME_OPTIONS,
  dailyRetention: DAILY_RETENTION_OPTIONS,
  weeklyDay: WEEKLY_START_DAY_OPTIONS,
  weeklyWeeks: WEEKLY_RETENTION_OPTIONS,
  monthlyMonths: MONTHLY_RETENTION_OPTIONS,
};

const currentDayOfWeek = WEEKLY_START_DAY_OPTIONS.find(
  (day) => day.value === dayjs().format('dddd')
);

export const INITIAL_PLAN_STATE: IState = {
  dailyJobSchedule: undefined,
  weeklyJobSchedule: undefined,
  monthlyJobSchedule: undefined,
  dailyHours: DAILY_INTERVAL_OPTIONS[4],
  dailyFrom: DAILY_START_TIME_OPTIONS[2],
  dailyRetention: DAILY_RETENTION_OPTIONS[6],
  weeklyDay: currentDayOfWeek,
  weeklyWeeks: WEEKLY_RETENTION_OPTIONS[3],
  monthlyMonths: MONTHLY_RETENTION_OPTIONS[2],
};
