import { remCalc } from 'utils';
import { styled } from 'elements';

export const InvoiceHolder = styled('div')(() => ({
  padding: remCalc(40, 115, 70, 115),
  minHeight: remCalc(400),
}));

export const InvoiceSettingsEmptyWrapper = styled('div')(() => ({
  maxWidth: remCalc(500),
}));

export const PartnerInfoHolder = styled('div')(() => ({
  padding: remCalc(40, 115, 70, 115),
  maxWidth: remCalc(780),
}));

export const VersionHolder = styled('div')(() => ({
  padding: remCalc(40, 115, 70, 115),
  maxWidth: remCalc(780),
}));
