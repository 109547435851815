import { ISearchEntity } from 'types';
import { AccessLevel } from 'global-shapes';

export const drawerContainer =
  window !== undefined ? () => window.document.body : undefined;

export const EVAL_BAR_HEIGHT = 48;
export const HEADER_HEIGHT = 64;

export const DEFAULT_SEARCH_ENTITY_QUERY: ISearchEntity.Query = {
  page: 1,
  perPage: 25,
};

const RDS_FILTER_VALUES: ISearchEntity.Entity[] = [
  'Rds',
  'RdsHost',
  'RdsGateway',
  'RdsUser',
  'VmSoftwarePackage',
  'Certificate',
  'Backup',
];

const CORE_FILTER_VALUES: ISearchEntity.Entity[] = [
  'CoreInfrastructure',
  'CoreInfrastructureUser',
  'FileShare',
  'FileShareUser',
  'FileShareMigrationEndpoint',
  'Backup',
];

const VM_FILTER_VALUES: ISearchEntity.Entity[] = [
  'Vm',
  'VmDisk',
  'PublicService',
  'Backup',
];
const CONNECTIVITY_FILTER_VALUES: ISearchEntity.Entity[] = [
  'Connectivity',
  'IpsecVpnTunnel',
  'SslVpn',
  'SslVpnUser',
];
const DNS_FILTER_VALUES: ISearchEntity.Entity[] = ['Dns', 'DnsZone'];
const INVOICE_VALUES: ISearchEntity.Entity[] = ['Invoice'];

export const FILTER_OPTIONS: Record<
  AccessLevel,
  Partial<Record<ISearchEntity.FilterType, ISearchEntity.Entity[]>>
> = {
  PROVIDER: {
    Partners: ['Partner'],
    Customers: ['Tenant'],
    Users: ['AppUser'],
    Connectivity: CONNECTIVITY_FILTER_VALUES,
    Vm: VM_FILTER_VALUES,
    Core: CORE_FILTER_VALUES,
    RDS: RDS_FILTER_VALUES,
    DNS: DNS_FILTER_VALUES,
    Invoice: INVOICE_VALUES,
    BillableItems: ['BillableItem'],
  },
  PARTNER: {
    Customers: ['Tenant'],
    Users: ['AppUser'],
    Connectivity: CONNECTIVITY_FILTER_VALUES,
    Vm: VM_FILTER_VALUES,
    Core: CORE_FILTER_VALUES,
    RDS: RDS_FILTER_VALUES,
    DNS: DNS_FILTER_VALUES,
    Invoice: INVOICE_VALUES,
    BillableItems: ['BillableItem'],
  },
  TENANT: {
    Users: ['AppUser'],
    Connectivity: CONNECTIVITY_FILTER_VALUES,
    Vm: VM_FILTER_VALUES,
    Core: CORE_FILTER_VALUES,
    RDS: RDS_FILTER_VALUES,
    DNS: DNS_FILTER_VALUES,
  },
};

export const PREFER_SORT_ENTITIES: ISearchEntity.FilterType[] = [
  'Partners',
  'Customers',
  'Users',
  'Vm',
  'Core',
  'RDS',
  'DNS',
  'Connectivity',
  'Invoice',
];
