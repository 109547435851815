import * as React from 'react';
import { ISnapshot } from 'snapshots';
import {
  showSystemMessage,
  noop,
  Emitter,
  confirm,
  confirmDeleteWithReasons,
  parseConfirmDeleteWithReasonResponse,
  IConfirmDeleteReasonsRes,
} from 'utils';
import { openWebConsoleWindow } from 'utils/openWebConsole';
import { useCoreMenuItems } from 'hooks/useInstanceMenuItems';
import SnapshotDialog from 'components/SnapshotDialog';
import { IActionHandlers, ActionOption } from 'components/ActionButtons/types';
import {
  useState,
  useTask,
  useUserHash,
  useSnapshotRequest,
  useRemoteSupport,
  useStateHandler,
  usePermissions,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { coreInfService } from 'services';
import { CustomEvents } from 'enums';
import { RemoteSupportDialog } from 'components';
import { BaseDecorator } from 'states/types';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

export type InjectedProps<I = any> = {
  fetchCore: AnyFunc;
  isRequesting: boolean;
  handlers: IActionHandlers;
  onDelete: AnyFunc;
  currentSnapshot?: ISnapshot;
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  instance: BaseDecorator<I, false>;
};

type IState = {
  isRemoteSupportOpen: boolean;
  snapshotDialogOpen: boolean;
  rsActionType?: 'enable' | 'disable';
};

const showError = (err: any) => showSystemMessage(err.message, 'error');

const initial: IState = {
  isRemoteSupportOpen: false,
  snapshotDialogOpen: false,
  rsActionType: undefined,
};

const buildDefaultListParams = (orderBy: string, other = {}) => ({
  page: 1,
  perPage: 20,
  orderBy,
  orderType: 'asc',
  ...other,
});

export const defaultNavQuery = buildDefaultListParams('username');

export function withCoreActions(Component: React.ComponentType<any>) {
  return observer((props: any) => {
    const { t } = useTranslation();
    const { isProvider } = usePermissions();
    const [, changeQuery] = useUserHash();
    const snapshots = useStateHandler(StateHandlers.snapshots);
    const tenantPricing = useStateHandler(StateHandlers.tenantPricing);
    const costBreakdown = useStateHandler(StateHandlers.costBreakdown);
    const core = useStateHandler(StateHandlers.core);
    const agent = useStateHandler(StateHandlers.agent);

    const currentSnapshot = core.data?.serviceSnapshot;

    const onMainTaskComplete = React.useCallback((action: string) => {
      core.get();
      tenantPricing.get();
      costBreakdown.get();
      snapshots.get();
      action === 'delete' && changeQuery('/services/all');
    }, []);

    const snapshotCompleteCallback = React.useCallback(() => {
      snapshots.get();
      core.get();
    }, []);

    const task = useTask(core.data?.task, { onComplete: onMainTaskComplete });
    const snpashotTask = useTask(core.data?.serviceSnapshot?.task, {
      onComplete: snapshotCompleteCallback,
    });

    const remoteSupport = useRemoteSupport({
      type: 'core',
      onSuccess: core.get,
    });

    const [state, handleState] = useState<IState>(initial);

    const agentData = core.data?.agent;
    const requiresAgentUpdate = isProvider ? agentData?.requiresUpdate : false;

    const { execute: confirmSnapshotRequest, isRequesting: isPendingSnapshot } =
      useSnapshotRequest(
        {
          serviceEntityId: core.data?.id,
          serviceEntityName: 'CoreInfrastructure',
        },
        currentSnapshot?.id
      );

    const isRequesting =
      isPendingSnapshot ||
      core.isRequesting ||
      task.isTaskActive ||
      snpashotTask.isTaskActive ||
      agent.isRequesting;

    const onDeleteCore = React.useCallback(
      () =>
        confirmDeleteWithReasons({
          title: t('services.core.confirm.delete.title'),
          content: t('services.core.confirm.delete.subtitle'),
          onSuccess: (res: IConfirmDeleteReasonsRes) =>
            core.remove(parseConfirmDeleteWithReasonResponse(res)).then(() => {
              core.get();
              changeQuery('/services/all');
            }),
          onCancel: () => undefined,
          successLabel: 'common.remove',
          cancelLabel: 'common.cancel',
        }),
      []
    );

    const onActionSuccess = React.useCallback(() => {
      core.get();
    }, []);

    const onOpenDetails = React.useCallback(
      () => changeQuery(`/services/infrastructure/admins`, defaultNavQuery),
      [defaultNavQuery]
    );

    const handlers = React.useMemo(() => {
      return {
        powerOn: () => {
          return coreInfService
            .powerOn()
            .then(onActionSuccess)
            .catch(showError);
        },
        powerOff: () => {
          return confirm({
            title: t(`services.core.confirm.powerOff.title`, {
              title: core.data?.vm?.name,
            }),
            content: t(`services.core.confirm.powerOff.subtitle`, {
              title: core.data?.vm?.name,
            }),
            onSuccess: () =>
              coreInfService.powerOff().then(onActionSuccess).catch(showError),
            onCancel: noop,
          });
        },
        reset: () => {
          return confirm({
            title: t(`services.core.confirm.reset.title`, {
              title: core.data?.vm?.name,
            }),
            content: t(`services.core.confirm.reset.subtitle`, {
              title: core.data?.vm?.name,
            }),
            onSuccess: () =>
              coreInfService.reset().then(onActionSuccess).catch(showError),
            onCancel: noop,
          });
        },
        delete: () => onDeleteCore(),
        shutDown: () => {
          return confirm({
            title: t(`services.core.confirm.shutDown.title`, {
              title: core.data?.vm?.name,
            }),
            content: t(`services.core.confirm.shutDown.subtitle`, {
              title: core.data?.vm?.name,
            }),
            onSuccess: () =>
              coreInfService.shutDown().then(onActionSuccess).catch(showError),
            onCancel: noop,
          });
        },
        openDetails: onOpenDetails,
        assignBadges: () => Emitter.emit(CustomEvents.assignDialog, core.data),
        console: () => openWebConsoleWindow(core.data?.vm?.id),
        enableRemoteSupport: () => {
          handleState({
            rsActionType: 'enable',
            isRemoteSupportOpen: true,
          });
        },
        disableRemoteSupport: () => {
          handleState({
            rsActionType: 'disable',
            isRemoteSupportOpen: true,
          });
        },
        createSnapshot: () => handleState(true, 'snapshotDialogOpen'),
        revertSnapshot: () =>
          confirmSnapshotRequest('revert').then(() => core.get()),
        removeSnapshot: () =>
          confirmSnapshotRequest('remove').then(() => core.get()),
        cancelSnapshotAutoremoval: () =>
          confirmSnapshotRequest('cancelAutoremoval').then(() => core.get()),
        updateAgent: () =>
          agent.executeRequest('runAgentUpdate')(core.data?.agent?.id),
      };
    }, [JSON.stringify(core.data), onActionSuccess]) as IActionHandlers;

    const { menuItems, actionItems } = useCoreMenuItems({
      instance: core.data,
      isRequesting,
      remoteSupport,
      handlers,
      agent: {
        requiresUpdate: requiresAgentUpdate,
      },
    });

    return (
      <>
        <Component
          {...props}
          fetchCore={core.get}
          isRequesting={isRequesting}
          onDelete={onDeleteCore}
          currentSnapshot={currentSnapshot}
          handlers={handlers}
          menuItems={menuItems}
          actionItems={actionItems}
          instance={core}
          requireAgentUpdate={requiresAgentUpdate}
        />

        <SnapshotDialog
          action="create"
          serviceEntityName="Rds"
          open={state.snapshotDialogOpen}
          onClose={() => handleState(false, 'snapshotDialogOpen')}
          onSave={(payload) =>
            confirmSnapshotRequest('create', payload).then(() => {
              handleState(false, 'snapshotDialogOpen');
              return core.get();
            })
          }
        />

        <RemoteSupportDialog
          subtitle="remoteSupport.dialog.core.subtitle"
          open={state.isRemoteSupportOpen}
          attributes={{
            coreInfrastructureId: core.data?.id,
            vmId: core.data?.vm?.id,
            osType: 'win',
          }}
          type="core"
          onSave={noop}
          onClose={() => handleState({ isRemoteSupportOpen: false })}
          onSuccess={onActionSuccess}
        />
      </>
    );
  });
}
