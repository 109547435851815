import * as R from 'ramda';
import { compact, getCountries, validator } from 'utils';
import * as Yup from 'yup';
import { ConnectivityFormValues } from 'services-shapes';
import { Address4 } from 'ip-address';
import { NETWORK_TYPES_OPTIONS, NETWOTK_INITIAL_VALUES } from './constants';
import { validateIpPoolRange } from '../services/helpers';
import { NETWORK_MASK, PORT_TYPES_OPTIONS_SHORT } from '../services/constants';
import * as ENUMS from './constants';

const countries = getCountries();

export function isValidDomain(val: string) {
  return validator.isFQDN(val || '') ? undefined : 'forms.invalid.domainName';
}

export function parseNetworkForForm(
  network?: IConnectivityTypes.INetwork
): IConnectivityTypes.INetworkFormValues {
  if (!network) {
    return NETWOTK_INITIAL_VALUES;
  }

  const networkAddress = network.subnets[0].cidr;
  const networkAddressSplited = networkAddress.split('.0/');

  const clearIntAddress = networkAddressSplited[0];
  const ipRanges = network.subnets[0]?.ipRanges[0] || {};
  const start = R.takeLast(1, ipRanges.startAddress?.split('.') || [])[0];
  const end = R.takeLast(1, ipRanges.endAddress?.split('.') || [])[0];

  return {
    name: network.name,
    type:
      NETWORK_TYPES_OPTIONS.find((opt) => network?.type === opt.value) ||
      NETWORK_TYPES_OPTIONS[0],
    networkAddress: clearIntAddress,
    intNetSubnet: networkAddressSplited[1],
    ipPoolRange: `${start}-${end}`,
  };
}

export const parseConFormValuesToPayload = (
  payload: ConnectivityFormValues,
  isNew: boolean
) => {
  const internalNetworkAddress =
    payload.internalNetworkAddress +
    (isNew ? `.0/${payload.intNetSubnet}` : '');
  const clearIntAddress = internalNetworkAddress.split('.0/').shift();
  const points = payload.ipPoolRange.split('-');
  const internalIpPoolStart = `${clearIntAddress}.${points[0]}`;
  const internalIpPoolEnd = `${clearIntAddress}.${points[1]}`;
  return { internalNetworkAddress, internalIpPoolEnd, internalIpPoolStart };
};

export const parseNetworkFormValuesToPayload = (
  payload: IConnectivityTypes.INetworkFormValues,
  isNew: boolean
): IConnectivityTypes.INetworkPayload => {
  const isIsolated = payload.type?.value === 'ISOLATED';
  const networkAddress =
    payload.networkAddress + (isNew ? `.0/${payload.intNetSubnet}` : '');
  const clearIntAddress = networkAddress.split('.0/').shift();
  const points = isIsolated ? [10, 59] : payload.ipPoolRange.split('-');
  const ipPoolStart = `${clearIntAddress}.${points[0]}`;
  const ipPoolEnd = `${clearIntAddress}.${points[1]}`;

  return {
    networkAddress,
    ipPoolStart,
    ipPoolEnd,
    name: payload.name,
    type: payload.type?.value,
  };
};

export const networkNameValidationSchema = Yup.string()
  .trim()
  .max(30, 'forms.invalid.max')
  .required('forms.required');

export const networkTypeValidationSchema =
  Yup.object().required('forms.required');

export const validateNetworkDetailValues = async (
  values: IConnectivityTypes.INetworkFormValues
) => {
  const errors: AnyShape = {};

  if (!values.networkAddress) {
    errors.networkAddress = 'forms.required';
  }

  if (!values.ipPoolRange) {
    errors.ipPoolRange = 'forms.required';
  }

  try {
    new Address4(`${values.networkAddress}.0/${NETWORK_MASK}`);

    if (values.ipPoolRange) {
      const ipPoolError = validateIpPoolRange(
        values.ipPoolRange,
        values.networkAddress
      );
      if (ipPoolError) errors.ipPoolRange = ipPoolError;
    }
  } catch (err) {
    errors.networkAddress = 'forms.invalid';
  }

  await networkNameValidationSchema.validate(values.name).catch((err) => {
    errors.name = err.errors[0];
  });

  await networkTypeValidationSchema.validate(values.type).catch((err) => {
    errors.type = err.errors[0];
  });

  return errors;
};

export function parseFRulePayload(
  values: IConnectivityTypes.IFirewallRuleFormValues
): IConnectivityTypes.IFirewallRulePayload {
  return {
    name: values.name,
    ports: !values.isICMPEnabled
      ? values.ports?.map((p) => {
          const sourcePorts = compact(
            p.sourcePorts?.split(',').map((p) => p.trim()) || []
          );
          const destinationPorts = compact(
            p.destinationPorts?.split(',').map((p) => p.trim()) || []
          );
          return {
            sourcePorts: sourcePorts.length ? sourcePorts : undefined,
            destinationPorts: destinationPorts.length
              ? destinationPorts
              : undefined,
            protocol: p.protocol?.value,
          };
        }) || []
      : [],
    isICMPEnabled: values.isICMPEnabled,
    isEnabled: values.state,
    actionValue: values.action?.value,
    sourceIpAddresses: !values.anySource ? values.sources : [],
    sourceCountryCodes: compact(
      !values.anySource ? values.countryCodes.map((c) => c.value) : []
    ),
    destinationIpAddresses: compact(
      !values.destination ? values.destinations : []
    ),
  };
}

export const parseFWRuleToFormValues = (
  rule: IConnectivityTypes.IFirewallRule
): IConnectivityTypes.IFirewallRuleFormValues => {
  const sourceCountryCodes = rule.sourceCountryCodes || [];
  const countryCodes = countries.filter((c) =>
    sourceCountryCodes?.includes(c.value)
  );
  const destinationIpAddresses = rule.destinationIpAddresses || [];
  const sourceIpAddresses = rule.sourceIpAddresses || [];
  return {
    id: rule.id,
    name: rule.name,
    sourcePorts: '',
    ports:
      rule?.rawPorts?.map((p) => {
        return {
          sourcePorts: p.sourcePorts?.join(', '),
          destinationPorts: p.destinationPorts?.join(', '),
          protocol: R.find(
            R.propEq('value', p.protocol?.toLowerCase()),
            PORT_TYPES_OPTIONS_SHORT
          ) as IVmTypes.IPortTypeValue,
        };
      }) || [],
    destinationPorts: '',
    isICMPEnabled: rule.isICMPEnabled,
    protocol: PORT_TYPES_OPTIONS_SHORT[0],
    anySource: !sourceIpAddresses.length && !sourceCountryCodes.length,
    tab: 0,
    source: '',
    sources: sourceIpAddresses,
    state: rule.isEnabled,
    geoip: undefined,
    countryCodes: countryCodes,
    destination: !destinationIpAddresses.length,
    destinationText: '',
    destinations: destinationIpAddresses,
    action: R.find(
      R.propEq('value', rule.action),
      ENUMS.FIREWALL_ACTION_OPTIONS
    ) as IConnectivityTypes.IFirewallRuleFormValues['action'],
  };
};

export const validateFWRuleValues = async (
  values: IConnectivityTypes.IFirewallRuleFormValues
) => {
  const errors: AnyShape = {};
  const {
    anySource,
    sources,
    countryCodes,
    ports,
    name,
    destinations,
    destination,
  } = values;

  if (!anySource && !(sources?.length || countryCodes?.length)) {
    errors.anySource =
      'services.vm.services.dialog.errors.invalid.sources.required';
  }

  if (!destination && !destinations.length) {
    errors.destinations =
      'services.vm.services.dialog.errors.invalid.sources.required';
  }

  await ENUMS.furewallRuleValidationSchemas.name.validate(name).catch((err) => {
    errors.name = err.errors[0];
  });

  return errors;
};

function findStrInArray(str: string, arr: string | string[]) {
  let hasMatch = false;

  if (Array.isArray(arr)) {
    arr.forEach((item) => {
      if (R.includes(str?.toLowerCase(), item?.toLowerCase())) {
        hasMatch = true;
      }
    });
  }

  if (typeof arr === 'string') {
    hasMatch = R.includes(str?.toLowerCase(), arr?.toLowerCase());
  }

  return hasMatch;
}

export const filterFRs = (
  query: AnyShape,
  rules: IConnectivityTypes.IFirewallRule[]
): IConnectivityTypes.IFirewallRule[] => {
  return rules.filter((r) => {
    let result = true;
    const seqrchStr = query.q?.trim() || '';
    if (query.q?.trim()) {
      result =
        findStrInArray(seqrchStr, r.name) ||
        findStrInArray(seqrchStr, r.applications || 'Any') ||
        findStrInArray(
          seqrchStr,
          r.sourceIpAddresses || r.sourceCountryCodes || 'Any'
        ) ||
        findStrInArray(seqrchStr, r.destinationIpAddresses || 'Any');
    }

    if (query.action?.length) {
      if (!query.action.includes(r.action)) {
        result = false;
      }
    }

    if (query.isEnabled?.length) {
      if (!query.isEnabled.includes('ACTIVE') && r.isEnabled) {
        result = false;
      }
      if (!query.isEnabled.includes('INACTIVE') && !r.isEnabled) {
        result = false;
      }
    }

    return result;
  });
};
