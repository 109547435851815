import * as React from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
import { DedicatedServerSystemInfo } from 'components';
import {
  Dialog,
  DialogProps,
  Button,
  Row,
  Col,
  SPrimarySpan,
  SCustomList,
  MuiIcons,
  Input,
  Select,
  Switch,
  Alert,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { GLOBAL_DATE_FORMAT } from 'enums';
import {
  numberToCurrency,
  dayjs,
  round,
  buildMonthCountByBillingInterval,
} from 'utils';
import { useVmBillingDisplay, usePermissions, useStateHandler } from 'hooks';
import { VmDetailsContent } from '../Styled';
import { getBillingCycleDsOptions } from '../../services/constants';
import * as HELPERS from './helpers';
import * as CONSTANTS from '../constants';

type IValues = IDedicatedServer.IRentDedicatedServerFormValues;

type Props = DialogProps<IDedicatedServer.IRentDedicatedServerPayload> & {
  selectedServer?: IDedicatedServer.TenantosServer;
  initialValues?: null | IValues;
};

const DedicatedServerDialog = observer((props: Props) => {
  const { open, onSave, selectedServer, initialValues } = props;
  const { t } = useTranslation();
  const billingInfo = useStateHandler(StateHandlers.billingInfo);
  const { isProvider, isEvaluation } = usePermissions();
  const isNew = !initialValues;

  const {
    handleSubmit,
    resetForm,
    setFieldValue,
    submitCount,
    values,
    errors,
    setValues,
    handleChange,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: CONSTANTS.DedicatedServersInitialValues,
    validateOnMount: false,
    validationSchema: isNew
      ? CONSTANTS.NewDedicatedServerValidationSchema
      : CONSTANTS.EditDedicatedServerValidationSchema,
    onSubmit: async (val) => {
      const payload = {
        name: val.name,
        osPassword: val.osPassword,
        templateId: val.templateId?.value as number,
        description: val.description,
        tenantosServerId: selectedServer?.id as number,
        reinstall: val.reinstall,
        billingCycleInterval: val.isUsageEnabled
          ? val.billingCycleInterval?.value
          : 'NONE',
      };

      return onSave(payload);
    },
  });

  const isWindows = values.templateId && values.templateId.osFamilyId === 1;

  const isCycledChanged =
    initialValues?.billingCycleInterval?.value !==
    values.billingCycleInterval?.value;

  const { isEditableResources, showCostInfo, showEnableSwitcher, discount } =
    useVmBillingDisplay({
      isRootProvider: isProvider,
      billingInterval: values.billingCycleInterval?.value,
      isUsageEnabled: values.isUsageEnabled,
      billingCycleId: initialValues?.billingCycleId,
      isNew,
      isDedicated: true,
    });

  const monthCount = React.useMemo(
    () => buildMonthCountByBillingInterval(values.billingCycleInterval?.value),
    [values.billingCycleInterval?.value]
  );

  const baseFeePrice =
    Number(selectedServer?.tenantHardwareMonthlyPrice || 0) * monthCount;

  const showWinLicense = isNew ? true : values.reinstall;

  const winLicensePrice = React.useMemo(() => {
    return isWindows && showWinLicense
      ? Number(selectedServer?.tenantWindowsLicenseMonthlyPrice || 0) *
          monthCount
      : 0;
  }, [
    showWinLicense,
    selectedServer?.tenantWindowsLicenseMonthlyPrice,
    monthCount,
    isWindows,
  ]);

  const clearPrice = R.sum([baseFeePrice, winLicensePrice]);
  const discountPrice = clearPrice * discount;

  const totalPrice = round(clearPrice - discountPrice, 2);

  const fetchBillingInfo = React.useCallback(() => {
    if (initialValues?.billingCycleId) {
      billingInfo.get({ id: initialValues?.billingCycleId });
    }
  }, [initialValues?.billingCycleId]);

  React.useEffect(() => {
    if (!open) resetForm();
    if (open && initialValues) {
      fetchBillingInfo();

      setValues({
        ...CONSTANTS.DedicatedServersInitialValues,
        ...initialValues,
        isUsageEnabled: initialValues?.billingCycleId
          ? initialValues?.billingCycleInterval?.value !== 'NONE'
          : false,
      });
    }
  }, [open, initialValues]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      handleSubmit={handleSubmit}
      title={t(`rentDedicatedServer.dialog.${isNew ? 'create' : 'edit'}.title`)}
      onClose={props.onClose}
      onSave={() => undefined}
      keepMounted={false}
      actions={
        <div className="full-width flex justify-end">
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" className="ml-15" disabled={isSubmitting}>
            {t(
              isNew ? 'rentDedicatedServer.dialog.buttons.rent' : 'common.save'
            )}
          </Button>
        </div>
      }
    >
      <Row justifyContent="space-between" columnSpacing={6}>
        <Col xs={7}>
          {isNew && (
            <div className="steel mb-20 fs-18">{selectedServer?.model}</div>
          )}
          <div className="mb-30 steel">
            {t('rentDedicatedServer.dialog.content')}
          </div>
          <DedicatedServerSystemInfo
            className="mb-30"
            {...(selectedServer as IDedicatedServer.TenantosServer)}
          />
          <div className="mb-25">
            <Input
              label="forms.hostname"
              placeholder="forms.placeholders.hostname"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={!!(submitCount && errors.name)}
              helperText={errors.name}
              helperTextOptions={{
                max: 15,
              }}
            />
          </div>
          <div className="mb-25">
            <Input
              label="forms.description"
              placeholder="forms.placeholders.description"
              name="description"
              multiline
              minRows={3}
              value={values.description}
              onChange={handleChange}
              error={!!(submitCount && errors.description)}
              helperText={errors.description}
              helperTextOptions={{
                max: 100,
              }}
            />
          </div>
          {isNew && (
            <>
              <div className="mb-25">
                <Select
                  label="forms.operatingSystem"
                  placeholder="forms.placeholders.operatingSystem"
                  aria-autocomplete="none"
                  auto-complete="off"
                  name="osId"
                  defaultOptions
                  useAsync
                  onLoad={HELPERS.loadOsListToOptions}
                  value={values.templateId}
                  onChange={(val) => setFieldValue('templateId', val)}
                  error={!!(submitCount && errors.templateId)}
                  helperText={errors.templateId}
                />
              </div>
              <div className="mb-25">
                <Input
                  label="forms.password"
                  placeholder="forms.placeholders.password"
                  name="osPassword"
                  type="password"
                  aria-autocomplete="none"
                  autoComplete="off"
                  auto-complete="off"
                  value={values.osPassword}
                  onChange={handleChange}
                  error={!!(submitCount && errors.osPassword)}
                  helperText={errors.osPassword}
                  helperTextOptions={{ min: 8, max: 32 }}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                />
              </div>
            </>
          )}
        </Col>
        <Col xs={5}>
          <VmDetailsContent
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
          >
            <Col>
              {!isEditableResources && (
                <div className="steel text-center pl-30 pr-30">
                  <div className="mb-15 fs-15">
                    {t('costsInfo.billingDisabled') as string}
                  </div>
                  <MuiIcons.CreditCardOff style={CONSTANTS.noUsageIconStyles} />
                </div>
              )}
              {showEnableSwitcher && (
                <div>
                  <div className="mb-5">
                    <Switch
                      label={t('costsInfo.billing.enable.label')}
                      checked={values.isUsageEnabled}
                      onCheck={(isUsageEnabled) => {
                        setFieldValue('isUsageEnabled', isUsageEnabled);
                      }}
                    />
                  </div>
                  <div className="fs-12 steel mb-15">
                    {t('costsInfo.billing.enable.ds.subtitle') as string}
                  </div>
                </div>
              )}

              {showCostInfo && (
                <>
                  <h5 className="mb-25 primary">
                    <SPrimarySpan>
                      {t('costsInfo.title') as string}
                    </SPrimarySpan>
                  </h5>
                  <Select
                    options={getBillingCycleDsOptions(isEvaluation)}
                    value={values.billingCycleInterval}
                    onChange={(val) =>
                      setFieldValue('billingCycleInterval', val)
                    }
                    className="mb-15"
                  />
                  {initialValues?.billingCycleId && isCycledChanged && (
                    <Alert severity="info" className="mb-15">
                      <ul>
                        {isCycledChanged && dirty && (
                          <li>
                            {
                              t('costsInfo.billingCircle.warnings.onlyCycle', {
                                date: dayjs(
                                  billingInfo.data.periodEnd,
                                  'YYYY-MM-DD'
                                ).format(GLOBAL_DATE_FORMAT),
                              }) as string
                            }
                          </li>
                        )}
                        {dirty && (
                          <li>
                            {
                              t(
                                'costsInfo.billingCircle.warnings.cycleWithVmValues',
                                {
                                  date: '2122.11.24',
                                }
                              ) as string
                            }
                          </li>
                        )}
                      </ul>
                    </Alert>
                  )}
                  <Row
                    justifyContent="space-between"
                    columnSpacing={2}
                    className="fs-10 steel uppercase mb-15"
                  >
                    <Col xs={8}>
                      <span>{t('costsInfo.head.serviceName') as string}</span>
                    </Col>
                    <Col xs={4} className="text-right">
                      <span>{t('costsInfo.head.price') as string}</span>
                    </Col>
                  </Row>
                  <h5 className="mb-15">
                    {t('costsInfo.dedicatedServer') as string}
                  </h5>
                  <SCustomList className="custom-list">
                    <ul>
                      <li>
                        <div className="flex justify-between">
                          <span>{t('costsInfo.baseFee') as string}</span>
                          <span className="pl-10 steel">
                            {numberToCurrency(baseFeePrice, false)}
                          </span>
                        </div>
                      </li>
                      {showWinLicense && isWindows && (
                        <li>
                          <Row
                            className="flex justify-between"
                            columnSpacing={2}
                          >
                            <Col xs>{values.templateId?.label}</Col>
                            <Col className="pl-10 steel">
                              {numberToCurrency(winLicensePrice, false)}
                            </Col>
                          </Row>
                        </li>
                      )}
                    </ul>
                  </SCustomList>
                </>
              )}
            </Col>
            {showCostInfo && (
              <Col>
                {!!discount && (
                  <div className="flex justify-between align-center">
                    <div className="fs-12 mb-10">
                      {
                        t('costsInfo.discount', {
                          value: discount * 100,
                        }) as string
                      }
                    </div>
                    <div className="bolder">
                      {numberToCurrency(-discountPrice, false)}
                    </div>
                  </div>
                )}
                <div className="flex justify-between align-center">
                  <h5 className="fs-17">
                    {
                      t(
                        `costsInfo.totalCost.${values.billingCycleInterval?.value}`
                      ) as string
                    }
                  </h5>
                  <SPrimarySpan className="fs-20 bold">
                    {numberToCurrency(totalPrice, false)}
                  </SPrimarySpan>
                </div>
                <div className="fs-12 steel">
                  {t('costsInfo.chfExclVat') as string}
                </div>
              </Col>
            )}
          </VmDetailsContent>
        </Col>
      </Row>
    </Dialog>
  );
});

export default DedicatedServerDialog;
