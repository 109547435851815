import { GET, POST, PUT, DELETE, buildListParams } from 'utils';
import {
  ICommonListParams,
  IGlobalValidationErrorResponse,
} from 'global-shapes';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const get = (params: IVmTypes.VMParams): IAxiosPromise<IVmTypes.Vm[]> =>
  GET(
    '/vms',
    buildListParams(params, [
      'orderBy',
      'orderType',
      'networkType',
      'type',
      'include',
      'q',
      'badgeId',
      'osId',
      'status',
      'billingType',
      'activeService',
      'cpuMin',
      'cpuMax',
      'ramMin',
      'ramMax',
      'totalDiskSizeMin',
      'totalDiskSizeMax',
      'serviceGroupId',
      'serviceGroupIsEmpty',
      'networkId',
    ])
  );

export const getById = (id: number, params: any): AxiosPromise<IVmTypes.Vm> =>
  GET(`/vms/${id}`, params);

export const remove = (
  id: number,
  deletionReason: string
): AxiosPromise<Task> => DELETE(`/vms/${id}`, {}, { deletionReason });

export const update = (
  id: number,
  payload: VmDialogTypes.IVmUpdatePayload
): AxiosPromise<IVmTypes.Vm> => {
  return PUT(`/vms/${id}`, {}, payload);
};

export const powerOn = (id: number): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${id}/action/power-on`);

export const powerOff = (id: number): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${id}/action/power-off`);

export const reset = (id: number): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${id}/action/reset`);

export const shutDown = (id: number): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${id}/action/shutdown`);

export const acquireMksTicket = (
  id: number
): AxiosPromise<IVmTypes.IVmMksTicket> =>
  POST(`/vms/${id}/action/acquireMksTicket`);

export const create = (
  data: VmDialogTypes.IVmCreatePayload
): AxiosPromise<IVmTypes.Vm> => POST('/vms', {}, data);

export const getTemplates = (
  params: IVmTypes.VMParams
): IAxiosPromise<IVmTypes.IVmTemplate[]> => GET('/vm-templates', params);

export const getDisks = (
  vmId: number,
  params: ICommonListParams
): IAxiosPromise<IVmTypes.VMDisk[]> =>
  GET(
    `/vms/${vmId}/vm-disks`,
    buildListParams(params, ['orderType', 'orderBy'])
  );

export const getDiskById = (
  vmId: number,
  diskId: number
): AxiosPromise<IVmTypes.VMDisk> => GET(`/vms/${vmId}/vm-disks/${diskId}`);

export const createDisk = (
  vmId: number,
  payload: IVmTypes.NewVMDisk
): AxiosPromise<IVmTypes.VMDisk> => POST(`/vms/${vmId}/vm-disks`, {}, payload);

export const updateDisk = (
  vmId: number,
  diskId: number,
  payload: IVmTypes.NewVMDisk
): AxiosPromise<IVmTypes.VMDisk> =>
  PUT(`/vms/${vmId}/vm-disks/${diskId}`, {}, payload);

export const deleteDisk = (vmId: number, diskId: number): AxiosPromise<Task> =>
  DELETE(`/vms/${vmId}/vm-disks/${diskId}`);

export const getPorts = (vmId: number): IAxiosPromise<IVmTypes.VMPort[]> =>
  GET(`/vms/${vmId}/public-services`, {
    limit: 1000,
    orderBy: 'port',
    orderType: 'asc',
    include: ['nic'],
  });

export const getPortById = (
  vmId: number,
  portId: number
): AxiosPromise<IVmTypes.VMPort> =>
  GET(`/vms/${vmId}/public-services/${portId}`);

export const createPort = (
  vmId: number,
  payload: IVmTypes.NewVMPorts
): AxiosPromise<IVmTypes.VMPort> =>
  POST(`/vms/${vmId}/public-services`, {}, payload);

export const updatePort = (
  vmId: number,
  portId: number,
  payload: IVmTypes.PortUpdatePayload
): AxiosPromise<IVmTypes.VMPort> =>
  PUT(`/vms/${vmId}/public-services/${portId}`, {}, payload);

export const deletePort = (vmId: number, portId: number): AxiosPromise<Task> =>
  DELETE(`/vms/${vmId}/public-services/${portId}`);

export const getTicketApi = (
  vmId: number
): AxiosPromise<IVmTypes.IVmMksTicket> =>
  POST(`/vms/${vmId}/action/acquireMksTicket`);

export const createSnapshot = (
  vmId: number,
  payload: { description: string }
): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${vmId}/action/create-snapshot`, {}, payload);

export const revertToCurrentSnapshot = (
  vmId: number
): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${vmId}/action/revert-to-current-snapshot`);

export const removeSnapshots = (vmId: number): AxiosPromise<IVmTypes.Vm> =>
  POST(`/vms/${vmId}/action/remove-all-snapshots`);

export const validateVm = (
  payload: Partial<IVmTypes.IValidateVmPayload>
): AxiosPromise<IGlobalValidationErrorResponse> =>
  PUT(`/vms/validate`, {}, payload);

export const getTenantosTemplates = (
  params: ICommonListParams
): IAxiosPromise<IVmTypes.IVmTemplateOs[]> =>
  GET(
    `/tenantos-templates`,
    buildListParams(params, ['orderType', 'orderBy', 'q', 'tenantId'])
  );

export const getOsList = (
  params: ICommonListParams
): IAxiosPromise<IVmTypes.IVmOs[]> =>
  GET(
    `/os/os`,
    buildListParams(params, ['orderType', 'orderBy', 'q', 'tenantId'])
  );

export const importVm = (
  payload: IVmTypes.IVmImportPayload
): IAxiosPromise<IVmTypes.Vm> => POST(`/vms/import`, {}, payload);

export const getMedia = (params: AnyShape): IAxiosPromise<IVmMedia.Media[]> =>
  GET('/media', buildListParams(params, ['orderType', 'orderBy', 'q']));

export const injectMedia = (
  vmId: number,
  payload: IVmMedia.InjectPayload
): IAxiosPromise<IVmMedia.Media[]> =>
  POST(`/vms/${vmId}/action/insert-media`, {}, payload);

export const ejectMedia = (vmId: number): IAxiosPromise<IVmMedia.Media[]> =>
  POST(`/vms/${vmId}/action/eject-media`);

export const switchVmType = (
  vmId: number,
  payload: IVmTypes.IVmSwitchVmTypePayload
): IAxiosPromise<IVmTypes.Vm> => POST(`/vms/${vmId}/action/move`, {}, payload);

export const getVmNics = (
  vmId: number,
  params: ICommonListParams
): IAxiosPromise<IVmTypes.Nic[]> =>
  GET(
    `/vms/${vmId}/nics`,
    buildListParams(params, [
      'orderType',
      'orderBy',
      'q',
      'networkId',
      'networkType',
    ])
  );

export const createNic = (
  vmId: number,
  payload: IVmTypes.NicPayload
): AxiosPromise<IVmTypes.Nic> =>
  POST(
    `/vms/${vmId}/nics`,
    {},
    {
      nics: [
        {
          ipv4Enabled: payload.ipv4Enabled,
          ipv6Enabled: payload.ipv6Enabled,
          networkId: payload.networkId,
        },
      ],
    }
  );

export const updateNic = (
  vmId: number,
  nicId: number,
  payload: IVmTypes.NicUpdatePayload
): AxiosPromise<IVmTypes.Vm> =>
  PUT(
    `/vms/${vmId}/nics/${nicId}`,
    {},
    {
      ipv4Enabled: payload.ipv4Enabled,
      ipv6Enabled: payload.ipv6Enabled,
      isPrimary: payload.isPrimary,
    }
  );

export const removeNic = (vmId: number, nicId: number): AxiosPromise<Task> =>
  DELETE(`/vms/${vmId}/nics/${nicId}`);
