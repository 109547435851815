import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paper, List, styled, ListItemButton } from 'elements';
import { useQuery, useUserHash } from 'hooks';
import { important, remCalc } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import { AnyShape } from 'global-shapes';

const CPaper = styled(Paper)(() => ({
  overflow: 'hidden',
}));

export const CListItem = styled(ListItemButton)(() => ({
  padding: important(0),
}));

export const CItem = styled('div')(({ theme }) => ({
  padding: remCalc(20, 25),
  lineHeight: remCalc(20),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  '&._active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const CItemRemove = styled('div')(({ theme }) => ({
  padding: remCalc(20, 25),
  lineHeight: remCalc(20),
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  items: string[];
  params?: AnyShape;
  staticQuery?: AnyShape;
  tHash: string;
  baseUrl: string;
  header?: any;
  footer?: any;
}

const LeftBarNavigation = ({
  items,
  baseUrl,
  tHash,
  header,
  footer,
  params: query,
  staticQuery,
}: Props) => {
  const { t } = useTranslation();
  const { location } = useQuery();
  const [, changeQuery] = useUserHash();
  const onTabClick = (key: string) => () => {
    const newParams = { ...(query ? query[key] : {}), ...(staticQuery || {}) };

    return changeQuery(`/${baseUrl}/${key}`, newParams);
  };

  return (
    <CPaper data-test-id={ALL_TEST_IDS.navigation.leftBar}>
      {header}
      <List className="p-0">
        {items.map((key) => (
          <CListItem
            key={key}
            onClick={onTabClick(key)}
            data-test-id={`${ALL_TEST_IDS.navigation.leftBarItem}-${key}`}
          >
            <CItem
              className={cn('full-width', {
                _active: location.pathname.includes(key),
              })}
            >
              {t(`${tHash}.navigation.${key}`)}
            </CItem>
          </CListItem>
        ))}
      </List>
      {footer}
    </CPaper>
  );
};

export default LeftBarNavigation;
