import * as React from 'react';
import SliderMui, {
  SliderProps as MuiSliderProps,
  SliderValueLabelProps,
  sliderClasses,
} from '@mui/material/Slider';
import { styled } from './utilities';
import Tooltip from '@mui/material/Tooltip';
import { remCalc } from 'utils';

export type SliderProps = MuiSliderProps;

export function SliderValueLabel(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const SliderBold = styled(SliderMui)(() => ({
  height: remCalc(4),
  padding: remCalc(15, 0),
  '& .MuiSlider-thumb': {
    height: remCalc(24),
    width: remCalc(24),
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &:active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: remCalc(4),
  },
  '& .MuiSlider-rail': {
    height: remCalc(4),
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#fff',
    height: remCalc(4),
    width: 1,
  },
  [`& .${sliderClasses.markActive}`]: {
    opacity: 1,
    backgroundColor: 'white',
    '&:before': {
      content: "''",
      position: 'absolute',
      left: remCalc(-100),
      bottom: remCalc(0),
      display: 'none',
      height: remCalc(4),
      width: remCalc(100),
    },
    '&[data-index="0"]': {
      '&:before': {
        display: 'none',
      },
    },
    '&[data-index="3"]': {
      '&:before': {
        display: 'block',
        backgroundColor: '#113d6b',
      },
    },
    '&[data-index="2"]': {
      '&:before': {
        width: remCalc(100),
        left: remCalc(-100),
        display: 'block',
        backgroundColor: '#3374bb',
      },
    },
    '&[data-index="1"]': {
      '&:before': {
        display: 'block',
        backgroundColor: '#5ca7f5',
      },
    },
  },
}));

export const Slider = styled(SliderMui)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 2,
  padding: remCalc(15, 0),
  '& .MuiSlider-thumb': {
    height: remCalc(24),
    width: remCalc(24),
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: remCalc(12),
    fontWeight: 'normal',
    top: remCalc(-4),
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
  '& .MuiSlider-markLabel': {
    transform: 'none',
  },
}));
