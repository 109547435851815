import * as React from 'react';
import cn from 'classnames';
import Divider from '@mui/material/Divider';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  Row,
  Col,
  CopyTooltip,
  MuiIcons,
  Menu,
  MenuItem,
  IconButton,
} from 'elements';
import { RemoteSupportDialog } from 'components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  useTask,
  usePermissions,
  useRemoteSupport,
  useUserHash,
  useState,
} from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { noop, shouldDisableWebConsoleLaunching } from 'utils';
import { withCoreActions, InjectedProps } from 'hocs/withCoreActions';
import ButtonList from 'components/ActionButtons/ButtonList';
import SnapshotInfo from 'components/SnapshotInfo';

type IProps = InjectedProps &
  React.PropsWithChildren<{
    t: TFunction;
    requireAgentUpdate?: boolean;
  }>;

interface IState {
  isRemoteSupportOpen: boolean;
  isDeleteDialogOpen: boolean;
}

const INITIAL_STATE = {
  isRemoteSupportOpen: false,
  isDeleteDialogOpen: false,
};

const OBSERVERS = {
  core: StateHandlers.core,
  tenantPricing: StateHandlers.tenantPricing,
  costBreakdown: StateHandlers.costBreakdown,
  snapshots: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    handlers,
    isRequesting,
    actionItems,
    core,
    tenantPricing,
    costBreakdown,
    snapshots,
    requireAgentUpdate,
  } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const coreData = core.data;

  const currentSnapshot = coreData.serviceSnapshot;
  const hasSnapshot = !!currentSnapshot;

  const { permissions } = usePermissions('SERVICES');
  const [, changeUrl] = useUserHash();

  const onMainTaskComplete = React.useCallback((action: string) => {
    core.get();
    tenantPricing.get();
    costBreakdown.get();
    snapshots.get();
    action === 'delete' && changeUrl('/services/all');
  }, []);

  const onSnapshotTaskComplete = React.useCallback(() => {
    core.get();
    snapshots.get();
  }, [coreData.id]);

  const coreTask = useTask(core.data?.task || null, {
    onComplete: onMainTaskComplete,
  });

  const snapshotTask = useTask(currentSnapshot?.task, {
    onComplete: onSnapshotTaskComplete,
  });

  const rs = useRemoteSupport({ type: 'core', onSuccess: core.get });

  const ableToDelete = coreTask.isCompleted || coreTask.isFailed;

  return (
    <div>
      <Row className="mb-25" alignItems="center" justifyContent="space-between">
        <Col>
          <div className="uppercase fs-14">
            {t('services.core.title') as string}
          </div>
        </Col>
        <Col>
          {permissions.canManage && (
            <div className="flex align-center">
              <div>
                <ButtonList actions={actionItems || []} />
              </div>
              <div className="pl-10">
                <PopupState variant="popover">
                  {(popupState) => (
                    <>
                      <IconButton size="small" {...bindTrigger(popupState)}>
                        <MuiIcons.MoreVert className="fs-20 steel pointer" />
                      </IconButton>

                      <Menu {...bindMenu(popupState)}>
                        {!hasSnapshot && (
                          <MenuItem
                            data-test-id={`core-inf-menu-item-snapshot-create`}
                            disabled={isRequesting}
                            onClick={() => {
                              handlers.createSnapshot();
                              popupState.close();
                            }}
                          >
                            {t(`snapshots.options.create`) as string}
                          </MenuItem>
                        )}
                        {hasSnapshot && (
                          <MenuItem
                            disabled={snapshotTask.isTaskActive || isRequesting}
                            data-test-id={`core-inf-menu-item-snapshot-revert`}
                            onClick={() => {
                              handlers.revertSnapshot().then(() => core.get());
                              popupState.close();
                            }}
                          >
                            {t(`snapshots.options.revert`) as string}
                          </MenuItem>
                        )}

                        {hasSnapshot && (
                          <MenuItem
                            disabled={snapshotTask.isTaskActive || isRequesting}
                            data-test-id={`core-inf-menu-item-snapshot-remove`}
                            onClick={() => {
                              handlers.removeSnapshot().then(() => core.get());
                              popupState.close();
                            }}
                          >
                            {t(`snapshots.options.remove`) as string}
                          </MenuItem>
                        )}

                        {hasSnapshot && (
                          <MenuItem
                            data-test-id={`core-inf-menu-item-cancel-autoremoval-snapshot`}
                            disabled={
                              isRequesting ||
                              snapshotTask.isTaskActive ||
                              !currentSnapshot?.removalOn
                            }
                            onClick={() => {
                              handlers.cancelSnapshotAutoremoval();
                              popupState.close();
                            }}
                          >
                            {t(`snapshots.options.cancelAutoRemoval`)}
                          </MenuItem>
                        )}

                        <Divider />
                        {requireAgentUpdate && (
                          <MenuItem
                            data-test-id={`core-inf-menu-item-update-agent`}
                            onClick={() => {
                              popupState.close();
                              return handlers.updateAgent();
                            }}
                          >
                            {t(`services.content.action.updateAgent`) as string}
                          </MenuItem>
                        )}
                        <MenuItem
                          data-test-id={`core-inf-menu-item-remote-support`}
                          disabled={
                            !(coreTask.isCompleted || coreTask.isFailed) ||
                            rs.isRequesting ||
                            rs.isTaskActive
                          }
                          onClick={() => {
                            rs.isUsedForCore
                              ? handlers.disableRemoteSupport()
                              : handlers.enableRemoteSupport();
                            popupState.close();
                          }}
                        >
                          {
                            t(
                              `services.content.action.remoteSupport.${
                                rs.isUsedForCore ? 'disable' : 'enable'
                              }`
                            ) as string
                          }
                        </MenuItem>
                        {!shouldDisableWebConsoleLaunching(
                          core.data?.displayStatus
                        ) && (
                          <MenuItem
                            data-test-id={`core-inf-menu-item`}
                            onClick={() => {
                              handlers.console();
                              popupState.close();
                            }}
                          >
                            {t(`services.content.action.console`) as string}
                          </MenuItem>
                        )}
                        <MenuItem
                          disabled={!ableToDelete}
                          data-test-id={`core-inf-menu-item`}
                          onClick={async () => {
                            popupState.close();
                            await handlers.delete();
                            return onMainTaskComplete('');
                          }}
                        >
                          {t(`common.removeService`) as string}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row
        className="fs-14 mb-30"
        alignItems="center"
        justifyContent="space-between"
      >
        <Col>
          {t('services.card.content.domainName') as string}:
          <span className="steel pl-5">
            <CopyTooltip>{coreData.domainName}</CopyTooltip>
          </span>
        </Col>
        {currentSnapshot && (
          <Col className={cn({ disabled: snapshotTask.isTaskActive })}>
            <SnapshotInfo snapshot={currentSnapshot} />
          </Col>
        )}
      </Row>
      <RemoteSupportDialog
        subtitle="remoteSupport.dialog.core.subtitle"
        open={state.isRemoteSupportOpen}
        attributes={{
          coreInfrastructureId: coreData?.id,
          vmId: coreData?.vm?.id,
          osType: 'win',
        }}
        type="core"
        onSave={noop}
        onClose={() => handleState({ isRemoteSupportOpen: false })}
      />
    </div>
  );
});

const Title = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withCoreActions(Title);
