import { Session } from 'sessions';
import dayjs from 'dayjs';
import axios, { AxiosPromise } from 'axios';
import { ICurrentUser } from 'auth-shapes';
import isBetween from 'dayjs/plugin/isBetween';
import { defaultHeaders, apiPrefix } from './constants';
import { getContrastColor, lightenColor } from './colors';
import { THEME } from 'enums';
import { PaletteColor } from '@mui/material';

interface IShouldUpdateSessionResponse {
  shouldUpdate: boolean;
  session: Session;
}

dayjs.extend(isBetween);

export const getCurrentToken = (): string => localStorage.getItem('_t') || '';
export const getCurrentRefreshToken = (): string =>
  localStorage.getItem('_rt') || '';

export const getCurrentSessionId = (): string =>
  localStorage.getItem('_sid') || '';

export const getCurrentSession = (): Session => {
  return {
    accessToken: localStorage.getItem('_t') || '',
    refreshToken: localStorage.getItem('_rt') || '',
    sessionId: localStorage.getItem('_sid') || '',
    accessTokenCreatedAt: +dayjs(localStorage.getItem('_tca') || undefined),
    accessTokenExpiresAt: +dayjs(localStorage.getItem('_texp') || undefined),
    accessTokenExpiresIn: +(localStorage.getItem('_texpin') || 0),
    sessionCreatedAt: +dayjs(localStorage.getItem('_sca') || undefined),
    sessionExpiresAt: +dayjs(localStorage.getItem('_sexp') || undefined),
    sessionExpiresIn: +(localStorage.getItem('_sexpin') || 0),
  };
};

export const isRootSession = (): boolean =>
  !sessionStorage.getItem('_t') && !!localStorage.getItem('_t');

export const setTokens = (
  accessToken: string,
  refreshToken: string,
  isRoot = true
) => {
  const session = isRoot ? localStorage : sessionStorage;
  session.setItem('_t', accessToken);
  session.setItem('_rt', refreshToken);
  return session;
};

export const setSessionsData = (session: Session, setTokens = true) => {
  if (setTokens) {
    localStorage.setItem('_t', session.accessToken);
    localStorage.setItem('_rt', session.refreshToken);
  }

  localStorage.setItem('_sid', session.sessionId);
  localStorage.setItem(
    '_sexp',
    dayjs(session.sessionExpiresAt * 1000).toISOString()
  );
  localStorage.setItem('_sexpin', session.sessionExpiresIn.toString());
  localStorage.setItem(
    '_sca',
    dayjs(session.sessionCreatedAt * 1000).toISOString()
  );
  localStorage.setItem(
    '_texp',
    dayjs(session.accessTokenExpiresAt * 1000).toISOString()
  );
  localStorage.setItem('_texpin', session.accessTokenExpiresIn.toString());
  localStorage.setItem(
    '_tca',
    dayjs(session.accessTokenCreatedAt * 1000).toISOString()
  );
  return localStorage;
};

export interface IExpirationInfoResponse {
  tokenExpiresIn: number;
  tokenCreatedAt: number;
  tokenExpiresAt: number;
  sessionExpiresIn: number;
  sessionExpiresAt: dayjs.Dayjs;
}

export const getExpirationInfo = (): IExpirationInfoResponse => {
  const currentSession = getCurrentSession();
  const tokenCreatedAt = +dayjs(currentSession.accessTokenCreatedAt);
  const tokenExpiresAt = +dayjs(currentSession.accessTokenExpiresAt);
  const tokenExpiresIn = +currentSession.accessTokenExpiresIn;
  const sessionExpiresIn = +currentSession.accessTokenExpiresIn;
  const sessionExpiresAt = dayjs(currentSession.sessionExpiresAt);

  return {
    tokenCreatedAt,
    tokenExpiresAt,
    tokenExpiresIn,
    sessionExpiresIn,
    sessionExpiresAt,
  };
};

export const checkIfTokenExpired = async () => {
  const { data: session } = await getCurrentSessionInfo();
  return !session || session?.accessTokenExpiresIn < 2;
};

export const checkIfSessionSoonExpire = async (
  currentSession?: Session
): Promise<IShouldUpdateSessionResponse> => {
  let session = currentSession;
  if (!session) {
    const { data: fetchedSession } = await getCurrentSessionInfo();
    session = fetchedSession;
  }

  const expireStartTime = dayjs(session.sessionExpiresAt).subtract(
    120,
    'seconds'
  );
  const expireEndTime = dayjs(session.sessionExpiresAt);

  return {
    shouldUpdate:
      !session || dayjs().isBetween(expireStartTime, expireEndTime, 'seconds'),
    session: session,
  };
};

export interface INewSessionColorPalette {
  appBar: PaletteColor;
  primary: PaletteColor;
}

export function defineActualColorScheme(
  user: ICurrentUser
): INewSessionColorPalette {
  const { partner, tenant } = user;
  const palette = {
    appBar: {
      main: THEME.palette.appBar.main,
      light: THEME.palette.appBar.light,
      dark: THEME.palette.appBar.dark,
      contrastText: THEME.palette.appBar.contrastText,
    },
    primary: {
      main: THEME.palette.primary.main,
      light: THEME.palette.primary.light,
      dark: THEME.palette.primary.dark,
      contrastText: THEME.palette.primary.contrastText,
    },
  };

  if (!tenant && !partner) return palette;

  const base = tenant ? tenant.partner : partner;

  if (!!base?.secondaryColor || !!base?.primaryColor) {
    if (base.primaryColor) {
      const main = `#${base.primaryColor}`;
      const light = lightenColor(main, 0.1);
      const dark = lightenColor(main, -0.1);
      palette.primary.light = light;
      palette.primary.main = main;
      palette.primary.dark = dark;
      palette.primary.contrastText = getContrastColor(main);
    }

    if (base.secondaryColor) {
      const main = `#${base.secondaryColor}`;
      const light = lightenColor(main, 0.1);
      const dark = lightenColor(main, -0.1);
      palette.appBar.light = light;
      palette.appBar.main = main;
      palette.appBar.dark = dark;
      palette.appBar.contrastText = getContrastColor(main);
    }
  }

  return palette;
}

export async function getCurrentSessionInfo(): Promise<AxiosPromise<Session>> {
  const session = getCurrentSession();
  const sessionInfo = await axios.post(
    `${apiPrefix}/auth/session/current`,
    {
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    },
    { headers: defaultHeaders() }
  );

  return sessionInfo.data;
}

export const clearClientSession = () => {
  sessionStorage.clear();
  localStorage.clear();
};
